import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-verifyaccount',
	templateUrl: './verifyaccount.component.html',
	styleUrls: ['./verifyaccount.component.scss']
})
export class VerifyaccountComponent implements OnInit {
	public isCollapsed = true;

	id: any;
	otp: any;
	verificationCode: any = {
		myotp: ''
	}
	submitted = false;
	verifyotp: NgForm;
	otpdetails: any = {
		companies: []
	};
	otpDivVisibility = true;
	constructor(
		private router: Router,
		private route: ActivatedRoute) {
		this.otpdetails = JSON.parse(atob(this.route.snapshot.queryParamMap.get('otpdetails')));
	}

	gotoVerifyAccount() {
		this.router.navigate(['./verifyaccount'])
	}

	ngOnInit(): void {

	}


	VerifyOTP(): void {
		if (this.otpdetails.otp != this.verificationCode.myotp) {
			Swal.fire('', 'Incorrect otp please try again', 'error');
			return;
		}
		if (this.otpdetails.companies != undefined && this.otpdetails.companies != null && this.otpdetails.companies.length > 0) {
			if (this.otpdetails.companies.length > 0) {
				this.otpdetails.companies[0].ischecked = true;
			}
			this.otpDivVisibility = false;
			return;
		}
		this.gotoChooseIndustry();
	}
	gotoChooseIndustry() {
		this.router.navigate(['./public/chooseindustry'], { queryParams: { otpdetails: btoa(JSON.stringify(this.otpdetails)) }, replaceUrl: true });
	}
	gotoLogin() {
		var compid = 0;
		this.otpdetails.companies.forEach(comp => {
			if (comp.ischecked != undefined && comp.ischecked) {
				compid = comp.companyid;
			}
		})
		this.router.navigate(['./auth/login'], { queryParams: { email: btoa(this.otpdetails.email), companyid: btoa('' + compid), from: 'verifyotp' }, replaceUrl: true });
	}
	//#region Navigation Link Redirection
	gotoAbout() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'aboutus' } });
	}
	gotoHome() {
		this.router.navigate(['/public/index']);
	}
	gotoOverview() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'overview' } });
	}
	gotoFeature() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'feature' } });
	}
	gotoPricing() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'pricing' } });
	}
	gotoContact() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'contact' } });
	}

	back() {
		this.router.navigate(['./public/createaccount'], { replaceUrl: true });
	}
	//#endregion Navigation Link Redirection
}