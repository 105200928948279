<div class="row">
  <div class="col-sm-12">
    <form class="card" name="form" #f="ngForm" (keydown.enter)="$event.preventDefault();">
      <div class="card-body tab2-card">
        <div class="top-sticky-buttons">
          <button type="button" class="btn btn-outline-secondary mb-1 mr-1" routerLink="/products">Back</button>
          <button type="button" class="btn btn-primary mb-1 mr-1" [disabled]="!f.valid || buttonaction =='view'"
            (click)="addProduct('saveonly')">Save
            only</button>
          <button type="button" class="btn btn-primary mb-1 mr-1" [disabled]="!f.valid || buttonaction =='view'"
            (click)="addProduct('saveclose')">Save
            & close</button>
        </div>
        <ngb-tabset class="tab-coupon" (tabChange)="tabClick($event)">
          <ngb-tab title="Basic Info" [id]="0">
            <ng-template ngbTabContent aria-labelledby="account-tab">
              <div class="InfoTooltip">
                <i class="fa fa-info-circle InfoBlock" [ngbTooltip]="Information"></i>
              </div>
              <ng-template #Information>
                <div class="d-flex flex-column">
                  <span><b>Created on :</b> {{product.createdate}} <b>by</b>
                    {{product.createby}}</span>
                  <span><b>Updated on :</b> {{product.updatedate}} <b>by</b>
                    {{product.updateby}}</span>
                  <span><b>Deleted on :</b> {{product.deletedate}} <b>by</b>
                    {{product.deleteby}}</span>
                </div>
              </ng-template>
              <div class="clearfix"></div>
              <fieldset [disabled]="fieldsetDisabled">
                <div class="row product-adding">
                  <div class="col-lg-3">
                    <div class="picture-div">
                      <button class="capture-image-btn" (click)="file.click()"><i class="fa fa-edit"></i>
                      </button>
                      <img [src]="product.pic" onerror="this.src='assets/images/no-image.png';">
                    </div>
                    <div class="uid">UID: {{product.uid}}</div>
                    <input type="file" style="visibility: hidden;" ng2FileSelect [uploader]="uploader"
                      (change)="selectedFileOnChanged($event)" accept="image/*" accept="image/jpeg, image/png" #file />
                  </div>
                  <div class="col-lg-9">
                    <div class="row">
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="required">Product Type</label>
                          <select class="form-control" id="ptype" required="" [(ngModel)]="product.ptype"
                            #ptype="ngModel" name="ptype"
                            [ngClass]="{ 'is-invalid': ptype.invalid && ptype.errors , 'is-valid': ptype.valid}"
                            (change)="getDeptData()">
                            <option value="">Select Type</option>
                            <option *ngFor="let item of productType" [value]="item.stringValue">
                              {{item.Text}}</option>
                          </select>
                          <div *ngIf="ptype.invalid && ptype.errors" class="invalid-feedback d-block">
                            <div *ngIf="ptype.errors.required">Please select Product
                              type</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="required"> Lookupcode (Max 20 Chars)</label>
                          <input class="form-control" id="ilc" type="text" required maxlength="20"
                            [(ngModel)]="product.ilc" #ilc="ngModel" name="ilc" (focusout)="validateIlc()"
                            [ngClass]="{ 'is-invalid': ilc.invalid && ilc?.errors , 'is-valid': ilc.valid}" />
                          <div *ngIf="ilc.invalid && ilc.errors" class="invalid-feedback d-block">
                            <div *ngIf="ilc.errors.required">Lookupcode / Product code is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <label class="required">Description</label>
                        <input [readonly]="fieldsetDisabled" class="form-control" id="description" required="true"
                          [(ngModel)]="product.description" #description="ngModel" name="description"
                          [ngClass]="{ 'is-invalid': description.invalid && description.errors , 'is-valid': description.valid}">
                        <div *ngIf="description.invalid && description.errors" class="invalid-feedback d-block">
                          <div *ngIf="description.errors.required">Description is required
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="dept">Department</label>
                          <select class="form-control" [(ngModel)]="product.dept" (change)="
                          getCatData($event.target.value)" #dept="ngModel" required name="dept"
                            [ngClass]="{ 'is-invalid': dept.invalid && dept.errors , 'is-valid': dept.valid}">
                            <option value="">Select Department</option>
                            <option *ngFor="let item of deptList" [value]="item.Value">{{item.Text}}
                            </option>
                          </select>
                          <div *ngIf="dept.invalid && dept.errors" class="invalid-feedback d-block">
                            <div *ngIf="dept.errors.required">Please select
                              department</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="cat">Category</label>
                          <select class="form-control" [(ngModel)]="product.cat" (change)="
                          getSubCatdata($event.target.value)" #cat="ngModel" name="cat">
                            <option value="">Select Category</option>
                            <option *ngFor="let item of categoryList" [value]="item.Value">
                              {{item.Text}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="subcat">Sub Category</label>
                          <select class="form-control" [(ngModel)]="product.subcat" #subcat="ngModel" name="subcat">
                            <option value="">Select Sub Category</option>
                            <option *ngFor="let item of subCategoryList" [value]="item.Value">
                              {{item.Text}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label>Retail%
                            <i class="fa fa-info-circle InfoBlock" [ngbTooltip]="retailper"></i>
                            <ng-template #retailper>
                              Value/100
                            </ng-template>
                          </label>
                          <input class="form-control" id="retail" type="number"
                            [(ngModel)]="product.salepricepercentage" #retail="ngModel" name="retail">
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label>Store/Retail Price</label>
                          <input class="form-control" id="store" type="number" [(ngModel)]="product.store"
                            #store="ngModel" name="store">
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label>Qty on Hand</label>
                          <input readonly class="form-control" id="store" type="number" [(ngModel)]="product.qtyonhand"
                            #qtyonhand="ngModel" name="qtyonhand">
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label>Qty on Order</label>
                          <input readonly class="form-control" id="store" type="number" [(ngModel)]="product.qtyonorder"
                            #qtyonorder="ngModel" name="qtyonorder">
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label>Qty Available</label>
                          <input readonly class="form-control" id="store" type="number"
                            [(ngModel)]="product.qtyavailable" #qtyavailable="ngModel" name="qtyavailable">
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label>Qty on Hold</label>
                          <input readonly class="form-control" id="store" type="number" [(ngModel)]="product.qtyonhold"
                            #qtyonhold="ngModel" name="qtyonhold">
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" *ngIf="product.isserilized">
                        <div class="form-group">
                          <label>Number of Serials</label>
                          <input readonly class="form-control" id="store" type="number" [(ngModel)]="product.nos"
                            #nos="ngModel" name="nos">
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="required">Status </label>
                          <select class="form-control" required="" [(ngModel)]="product.status" #status="ngModel"
                            name="status"
                            [ngClass]="{ 'is-invalid': status.invalid && status?.errors , 'is-valid': status.valid}">
                            <option value="">Select Status</option>
                            <option *ngFor="let item of statusList" [value]="item.stringValue">
                              {{item.Text}}
                            </option>
                          </select>
                          <div *ngIf="status.invalid && status.errors" class="invalid-feedback d-block">
                            <div *ngIf="status.errors.required">Please select status
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="cardBlock">
                    <h5 (click)="collapseshow()">
                      <i class="fa fa-plus-circle" *ngIf="!isCollapsed"></i>
                      <i class="fa fa-minus-circle" *ngIf="isCollapsed"></i>
                      Purchase Config
                    </h5>
                    <div class="cardBlock-body" *ngIf="isCollapsed">
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Manufacturer</label>
                            <div class="input-group">
                              <ng-select placeholder="Select Manufacturer" id="manuafacturer"
                                [(ngModel)]="product.manuafacturer" #manuafacturer="ngModel" name="manuafacturer"
                                [clearable]="product.manuafacturer">
                                <ng-option *ngFor="let item of manifractureList" [value]="item.Value">
                                  {{item.Text}}</ng-option>
                              </ng-select>
                              <div class="input-group-append">
                                <span class="input-group-text btn-primary cursor-pointer" container="body"
                                  ngbTooltip="Add Manufacturer" id="basic-addon2" (click)="openMasterModal()"
                                  [class.disabled]="fieldsetDisabled"><i class="fa fa-plus"></i>
                                </span>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Manufacturer Lookupcode
                            </label>
                            <input class="form-control" id="milc" type="text" [(ngModel)]="product.milc"
                              name="Manufacturerlookupcode">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Supplier</label>
                            <!-- <ng-select #supplier placeholder="Search Supplier" [items]="supplierList" 
                                      bindLabel="Text" 
                                      bindValue="Value" 
                                      [(ngModel)]="product.supplierid" name="Supplier">
                            </ng-select> -->
                            <ng-select placeholder="Search Supplier" [(ngModel)]="product.supplierid" name="supplierid"
                              [clearable]="product.supplierid">
                              <ng-option *ngFor="let item of supplierList" [value]="item.Value">
                                {{item.Text}}
                              </ng-option>
                            </ng-select>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Purchase Currency</label>
                            <select class="form-control" id="currency" [(ngModel)]="product.pcurrency"
                              #pcurrency="ngModel" name="pcurrency">
                              <option value="">Select Currency</option>
                              <option *ngFor="let item of currencyList" [value]="item.Value">
                                {{item.Text}}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>PO Cost</label>
                            <input class="form-control" id="pocost" type="number" [(ngModel)]="product.pocost"
                              #pocost="ngModel" name="pocost" />
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Landed at(%)</label>
                            <input class="form-control" id="landedat" type="number" [(ngModel)]="product.landedat"
                              #landedat="ngModel" name="landedat">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Landed Cost</label>
                            <input class="form-control" id="landedcost" type="number" [(ngModel)]="product.landedcost"
                              #landedcost="ngModel" name="landedcost">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Avg Cost</label>
                            <input class="form-control" id="avgcost" type="number" [(ngModel)]="product.avgcost"
                              #avgcost="ngModel" name="avgcost">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Purchase UOM</label>
                            <div class="input-group">
                              <select class="form-control" id="puom" [(ngModel)]="product.puom" (change)="selectPUOM()"
                                #puom="ngModel" name="puom">
                                <option value="">Select UOM</option>
                                <option *ngFor="let item of productSellUOMList" [value]="item.Value">
                                  {{item.Text}}</option>
                              </select>
                              <div class="input-group-append">
                                <span class="input-group-text btn-primary cursor-pointer" container="body"
                                  ngbTooltip="Add Purchase UOM" id="basic-addon2" (click)="openUOMModal()"
                                  [class.disabled]="fieldsetDisabled"><i class="fa fa-plus"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Qty in Pack/Box (Quantity should be greater than zero)</label>
                            <input class="form-control" id="qtyinpack" type="number" [(ngModel)]="product.qtyinpack"
                              #qtyinpack="ngModel" name="qtyinpack">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Reorder Point</label>
                            <input class="form-control" id="reorderpoint" type="number"
                              [(ngModel)]="product.reorderpoint" #reorderpoint="ngModel" name="reorderpoint">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Reorder Quantity</label>
                            <input class="form-control" id="reorderqty" type="number" [(ngModel)]="product.reorderqty"
                              #reorderqty="ngModel" name="reorderqty">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Min Quantity</label>
                            <input class="form-control" id="minqty" type="number" [(ngModel)]="product.minqty"
                              #minqty="ngModel" name="minqty">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Max Quantity</label>
                            <input class="form-control" id="maxqty" type="number" [(ngModel)]="product.maxqty"
                              #maxqty="ngModel" name="maxqty">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Mfg Date </label>
                            <mat-form-field>
                              <input matInput [matDatepicker]="picker108" [disabled]="fieldsetDisabled"
                                [(ngModel)]="product.mfgdate" #mfgdate="ngModel" name="mfgdate"
                                placeholder="Choose a date" (dateChange)="updateCalcs1()">
                              <mat-datepicker-toggle matSuffix [for]="picker108">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker108></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Pkg Date</label>
                            <mat-form-field>
                              <input #clearDateField matInput [matDatepicker]="picker109" [disabled]="fieldsetDisabled"
                                [(ngModel)]="product.pckgdate" #pckgdate="ngModel" name="pckgdate"
                                placeholder="Choose a date" (dateChange)="updateCalcs1()">
                              <mat-datepicker-toggle matSuffix [for]="picker109">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker109></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Warranty in</label>
                            <select class="form-control" id="warranty_in" [(ngModel)]="product.warranty_in"
                              #warranty_in="ngModel" name="warranty_in">
                              <option value="">Select Warranty</option>
                              <option *ngFor="let item of warrantyList" [value]="item.stringValue">
                                {{item.Text}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Warranty Period (Numeric
                              only)</label>
                            <input class="form-control" id="warranty_period" type="number"
                              [(ngModel)]="product.warranty_period" #warranty_period="ngModel" name="warranty_period">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Tariff Code</label>
                            <div class="position-relative">
                              <input class="form-control" id="commoditycode" type="text"
                                [(ngModel)]="product.commoditycode" #commoditycode="ngModel" name="commoditycode">
                              <i class="fa fa-search searchBtn" container="body" ngbTooltip="Get Code"
                                (click)="clicked('itemcodelist')"></i>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Is Serialized</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="isserilized">
                                <input class="radio_animated" [value]="true" [(ngModel)]="product.isserilized"
                                  #isserilized="ngModel" name="isserilized" id=" isserilized" type="radio">
                                Yes
                              </label>
                              <label class="d-block" for="isserilized1">
                                <input class="radio_animated" [value]="false" [(ngModel)]="product.isserilized"
                                  #isserilized="ngModel" name="isserilized" id=" isserilized1" type="radio">
                                No
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Time to Restock (In
                              Days)</label>
                            <input class="form-control" id="days_to_restock" type="number"
                              [(ngModel)]="product.days_to_restock" #days_to_restock="ngModel" name="days_to_restock">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Do Not Order</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="Donotorder">
                                <input class="radio_animated" [value]="true" id="donotorder"
                                  [(ngModel)]="product.donotorder" #donotorder="ngModel" name="donotorder" type="radio"
                                  name="rdo-Order">
                                Yes
                              </label>
                              <label class="d-block" for="Donotorder1">
                                <input class="radio_animated" [value]="false" id="donotorder1"
                                  [(ngModel)]="product.donotorder" #donotorder="ngModel" name="donotorder" type="radio"
                                  name="rdo-Order1">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Wastage%
                              <i class="fa fa-info-circle InfoBlock" [ngbTooltip]="wastageper"></i>
                              <ng-template #wastageper>
                                Value/100
                              </ng-template>
                            </label>
                            <input class="form-control" id="wastage_per" type="number" [(ngModel)]="product.wastage_per"
                              #wastage_per="ngModel" name="wastage_per">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Prefer to Purchase</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="perfertopurchase">
                                <input class="radio_animated" [value]="true" id="prefer_to_purchase"
                                  [(ngModel)]="product.prefer_to_purchase" #prefer_to_purchase="ngModel"
                                  name="prefer_to_purchase" type="radio" name="prefer_to_purchase">
                                Yes
                              </label>
                              <label class="d-block" for="perfertopurchase1">
                                <input class="radio_animated" [value]="false" id="prefer_to_purchase1"
                                  [(ngModel)]="product.prefer_to_purchase" #prefer_to_purchase="ngModel"
                                  name="prefer_to_purchase" type="radio" name="prefer_to_purchase1">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Inventory Lead Time (Hours)</label>
                            <input class="form-control" id="inventory_lead_time" type="number"
                              [(ngModel)]="product.inventory_lead_time" #inventory_lead_time="ngModel"
                              name="inventory_lead_time">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cardBlock">
                    <h5 (click)="collapseshow2()">
                      <i class="fa fa-plus-circle" *ngIf="!isCollapsed2"></i>
                      <i class="fa fa-minus-circle" *ngIf="isCollapsed2"></i> Sales Config
                    </h5>
                    <div class="cardBlock-body" *ngIf="isCollapsed2">
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <label>Sales Description(Max 40 chars)</label>
                          <input [readonly]="fieldsetDisabled" class="form-control" id="pos_description"
                            [(ngModel)]="product.pos_description" #pos_description="ngModel" name="pos_description">
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Alternate Lookupcode
                            </label>
                            <input class="form-control" id="ailc" type="text" [(ngModel)]="product.ailc"
                              name="alternetlookupcode">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label> Catalog Number</label>
                            <input class="form-control" id="catalog_no" type="text" [(ngModel)]="product.catalog_no"
                              #catalog_no="ngModel" name="catalog_no">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Third Party Code (eg. Qb, CP, PH
                              etc)</label>
                            <input class="form-control" id="tpcode" type="text" [(ngModel)]="product.tpcode"
                              #tpcode="ngModel" name="tpcode">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Third Party Code Extra (eg. Qb, CP, PH
                              etc)</label>
                            <input class="form-control" id="tpcodeextra" type="text" [(ngModel)]="product.tpcodeextra"
                              #tpcodeextra="ngModel" name="tpcodeextra">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Min Retail%
                              <i class="fa fa-info-circle InfoBlock" [ngbTooltip]="minretailper"></i>
                              <ng-template #minretailper>
                                Value/100
                              </ng-template>
                            </label>
                            <input class="form-control" id="minretail" type="number"
                              [(ngModel)]="product.salespricepercentageminimum" #minretail="ngModel" name="minretail">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Min Store/Retail</label>
                            <input class="form-control" id="minstoreretail" type="number" [(ngModel)]="product.storemin"
                              #minstoreretail="ngModel" name="storemin">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Service</label>
                            <input class="form-control" id="service" type="number" [(ngModel)]="product.service"
                              #service="ngModel" name="service">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Wholesale</label>
                            <input class="form-control" id="wholesale" type="number" [(ngModel)]="product.wholesale"
                              #wholesale="ngModel" name="wholesale">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Web/Online</label>
                            <input class="form-control" id="online" type="number" [(ngModel)]="product.online"
                              #online="ngModel" name="online">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Offer Price</label>
                            <input class="form-control" id="offerprice" type="number" [(ngModel)]="product.offerprice"
                              #offerprice="ngModel" name="offerprice">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Flat Price</label>
                            <input class="form-control" id="flatprice" type="number" [(ngModel)]="product.flatprice"
                              #flatprice="ngModel" name="flatprice">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Apply Flat Price</label>

                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block " for="flatapp">
                                <input class="radio_animated" id="flatpp" type="radio" [value]="true"
                                  [(ngModel)]="product.flatpp" #flatpp="ngModel" name="flatpp">
                                Yes
                              </label>
                              <label class="d-block" for="flatapp1">
                                <input class="radio_animated" id="flatpp1" [value]="false" [(ngModel)]="product.flatpp"
                                  #flatpp="ngModel" name="flatpp" type="radio" name="rdo-applyflat1">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label class="required">Sell UOM</label>
                            <div class="input-group">
                              <select class="form-control" id="uom" [(ngModel)]="product.uom" #uom="ngModel" name="uom"
                                required [ngClass]="{ 'is-invalid': uom.invalid && uom.errors , 'is-valid': uom.valid}">
                                <option value="">Select Sell UOM</option>
                                <option *ngFor="let item of newproductSellUOMList" [value]="item.Value">
                                  {{item.Text}}
                                </option>
                              </select>
                              <div class="input-group-append">
                                <span class="input-group-text btn-primary cursor-pointer" container="body"
                                  ngbTooltip="Add Sell UOM" id="basic-addon2" (click)="openSellUOMModal()"
                                  [class.disabled]="fieldsetDisabled"><i class="fa fa-plus"></i>
                                </span>
                              </div>
                            </div>
                            <div *ngIf="uom.invalid && uom.errors" class="invalid-feedback d-block">
                              <div *ngIf="uom.errors.required">Please select
                                Sell UOM</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Sales
                              Commision</label>
                            <input class="form-control" id="commissionpercentage" type="number"
                              [(ngModel)]="product.commissionpercentage" #commissionpercentage="ngModel"
                              name="commissionpercentage">
                          </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Sales Start Date</label>
                            <mat-form-field>
                              <input matInput [matDatepicker]="picker110" [disabled]="fieldsetDisabled"
                                [(ngModel)]="product.salestartdate" #salestartdate="ngModel" name="salestartdate"
                                placeholder="Choose a date">
                              <mat-datepicker-toggle matSuffix [for]="picker110">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker110></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Offer Start Date</label>
                            <mat-form-field>
                              <input matInput [matDatepicker]="picker111" [disabled]="fieldsetDisabled"
                                [(ngModel)]="product.offerstartdate" #offerstartdate="ngModel" name="offerstartdate"
                                placeholder="Choose a date" (dateChange)="updateCalcs()">
                              <mat-datepicker-toggle matSuffix [for]="picker111">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker111></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Offer End Date</label>
                            <mat-form-field>
                              <input #clearDateField1 matInput [matDatepicker]="picker112" [disabled]="fieldsetDisabled"
                                id="offerenddate" [(ngModel)]="product.offerenddate" #offerenddate="ngModel"
                                name="offerenddate" placeholder="Choose a date" (dateChange)="updateCalcs()">
                              <mat-datepicker-toggle matSuffix [for]="picker112">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker112></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Authorization on
                              Sale?</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="authonsale">
                                <input class="radio_animated" [value]="true" [(ngModel)]="product.auth_onsale"
                                  #auth_onsale="ngModel" name="auth_onsale" id="auth_onsale" type="radio">
                                Yes
                              </label>
                              <label class="d-block" for="authonsale1">
                                <input class="radio_animated" [value]="false" [(ngModel)]="product.auth_onsale"
                                  #auth_onsale="ngModel" name="auth_onsale" id="auth_onsale1" type="radio">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Authorizer Emails (Comma
                              Separated)</label>
                            <input class="form-control" id="emails_onsale" type="text"
                              [(ngModel)]="product.emails_onsale" #emails_onsale="ngModel" name="emails_onsale">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Sale Online</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block " for="saleonline">
                                <input class="radio_animated" [value]="true" [(ngModel)]="product.sale_online"
                                  #sale_online="ngModel" name="sale_online" id="sale_online" type="radio">
                                Yes
                              </label>
                              <label class="d-block" for="saleonline1">
                                <input class="radio_animated" [value]="false" [(ngModel)]="product.sale_online"
                                  #sale_online="ngModel" name="sale_online" id="sale_online1" type="radio">
                                No
                              </label>
                            </div>

                          </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Is Returnable</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="returNable">
                                <input class="radio_animated" [value]="true" [(ngModel)]="product.returnable"
                                  #returnable="ngModel" name="returnable" id="returnable" type="radio">
                                Yes
                              </label>
                              <label class="d-block" for="returNable1">
                                <input class="radio_animated" [value]="false" [(ngModel)]="product.returnable"
                                  #returnable="ngModel" name="returnable" id=" returnable1" type="radio">
                                No
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Lot Tracking</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="lottracking">
                                <input class="radio_animated" [value]="true" [(ngModel)]="product.lot_tracking"
                                  #lot_tracking="ngModel" name="lot_tracking" id=" lot_tracking" type="radio">
                                Yes
                              </label>
                              <label class="d-block" for="lottracking1">
                                <input class="radio_animated" [value]="false" [(ngModel)]="product.lot_tracking"
                                  #lot_tracking="ngModel" name="lot_tracking" id="lot_tracking1" type="radio">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label for="notdiscountable">Is Discountable</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="notDiscountable">
                                <input class="radio_animated" [value]="true" [(ngModel)]="product.notdiscountable"
                                  #notdiscountable="ngModel" name="notdiscountable" id=" notdiscountable" type="radio"
                                  name="rdo-Discountable">
                                Yes
                              </label>
                              <label class="d-block" for="notDiscountable1">
                                <input class="radio_animated" [value]="false" [(ngModel)]="product.notdiscountable"
                                  #notdiscountable="ngModel" name="notdiscountable" id=" notdiscountable1" type="radio"
                                  name="rdo-Discountable1">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Discount Type</label>
                            <select class="form-control" id="discount_type" [(ngModel)]="product.discount_type"
                              #discount_type="ngModel" name="discount_type">
                              <option value="">Select Discount Type</option>
                              <option *ngFor="let item of discountTypeList" [value]="item.stringValue">
                                {{item.Text}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Discount</label>
                            <input class="form-control" id="discount" type="number" [(ngModel)]="product.discount"
                              #discount="ngModel" name="discount">
                          </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Do Not Sell </label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="donotSell">
                                <input class="radio_animated" [value]="true" id="donotsell" type="radio"
                                  [(ngModel)]="product.donotsell" #donotsell="ngModel" name="donotsell">
                                Yes
                              </label>
                              <label class="d-block" for="donotSell1">
                                <input class="radio_animated" [value]="false" id="donotsell1" type="radio"
                                  [(ngModel)]="product.donotsell" #donotsell="ngModel" name="donotsell">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>ABC Type</label>
                            <select class="form-control" [(ngModel)]="product.abc_type" #abc_type="ngModel"
                              name="abc_type">
                              <option value="">Select Type</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>GL Income</label>
                            <!-- <select class="form-control input-sm" name="coa_income"
															[(ngModel)]="product.coa_income">
															<option value="">Select GL Income</option>
															<optgroup *ngFor="let act of accountGroupingList"
																label={{act}}>
																<ng-container *ngFor="let item of accounts">
																	<option *ngIf="item.Value1 === act"
																		value={{item.Value}}>
																		{{item.Text}}
																	</option>
																</ng-container>
															</optgroup>
														</select> -->
                            <angular2-multiselect name="coa_income" class="form-control input-sm" [data]="glAccountList"
                              [(ngModel)]="coa_income" [settings]="glIncomeAccountSettings"
                              (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)">
                            </angular2-multiselect>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>GL Cost of Sales</label>
                            <!-- <select class="form-control input-sm" name="coa_costofsales"
                              [(ngModel)]="product.coa_costofsales">
                              <option value="">Select GL Cost of Sales</option>
                              <optgroup *ngFor="let act of accountGroupingList" label={{act}}>
                                <ng-container *ngFor="let item of accounts">
                                  <option *ngIf="item.Value1 === act" value={{item.Value}}>
                                    {{item.Text}}
                                  </option>
                                </ng-container>
                              </optgroup>
                            </select> -->
                            <angular2-multiselect name="coa_costofsales" class="form-control input-sm"
                              [data]="glAccountList" [(ngModel)]="coa_costofsales" [settings]="glCostofSalesSettings"
                              (onSelect)="onCosSelect($event)" (onDeSelect)="OnCosDeSelect($event)">
                            </angular2-multiselect>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>GL Inventory Stock </label>

                            <!-- <select class="form-control input-sm" name="coa_inventorystock"
                              [(ngModel)]="product.coa_inventorystock">
                              <option value="">Select GL Inventory</option>
                              <optgroup *ngFor="let act of accountGroupingList" label={{act}}>
                                <ng-container *ngFor="let item of accounts">
                                  <option *ngIf="item.Value1 === act" value={{item.Value}}>
                                    {{item.Text}}
                                  </option>
                                </ng-container>
                              </optgroup>
                            </select> -->
                            <angular2-multiselect name="coa_inventorystock" class="form-control input-sm"
                              [data]="glAccountList" [(ngModel)]="coa_inventorystock"
                              [settings]="glInventoryStockSettings" (onSelect)="onInvSelect($event)"
                              (onDeSelect)="onInvDeSelect($event)"></angular2-multiselect>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="cardBlock">
                    <h5 (click)="collapseshow3()">
                      <i class="fa fa-plus-circle" *ngIf="!isCollapsed3"></i>
                      <i class="fa fa-minus-circle" *ngIf="isCollapsed3"></i> Production Config
                    </h5>
                    <div class="cardBlock-body" *ngIf="isCollapsed3">
                      <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Prefer to Produce</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="preferToproduce">
                                <input class="radio_animated" [value]="true" id="prefer_to_produce"
                                  [(ngModel)]="product.prefer_to_produce" #prefer_to_produce="ngModel"
                                  name="prefer_to_produce" type="radio" name="prefer_to_produce">
                                Yes
                              </label>
                              <label class="d-block" for="preferToproduce1">
                                <input class="radio_animated" [value]="false" id="prefer_to_produce1"
                                  [(ngModel)]="product.prefer_to_produce" #prefer_to_produce="ngModel"
                                  name="prefer_to_produce" type="radio" name="prefer_to_produce1">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Production Lead Time (Hours)</label>
                            <input class="form-control" id="production_lead_time" type="number"
                              [(ngModel)]="product.production_lead_time" #production_lead_time="ngModel"
                              name="production_lead_time">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cardBlock">
                    <h5 (click)="collapseshow4()">
                      <i class="fa fa-plus-circle" *ngIf="!isCollapsed4"></i>
                      <i class="fa fa-minus-circle" *ngIf="isCollapsed4"></i> Optional
                    </h5>
                    <div class="cardBlock-body" *ngIf="isCollapsed4">
                      <div class="row">

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Color</label>
                            <input class="form-control" id="color" type="color" [(ngModel)]="product.color"
                              #color="ngModel" name="color">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Size</label>
                            <input class="form-control" id="size" type="number" [(ngModel)]="product.size"
                              #size="ngModel" name="size">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Weight</label>
                            <input class="form-control" id="weight" type="number" [(ngModel)]="product.weight"
                              #weight="ngModel" name="weight">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Length</label>
                            <input class="form-control" id="length" type="number" [(ngModel)]="product.length"
                              #length="ngModel" name="length">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>width</label>
                            <input class="form-control" id="width" type="number" [(ngModel)]="product.width"
                              #width="ngModel" name="width">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Height</label>
                            <input class="form-control" id="height" type="number" [(ngModel)]="product.height"
                              #height="ngModel" name="height">
                          </div>
                        </div>


                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Web URL</label>
                            <input class="form-control" id="web_url" type="url" [(ngModel)]="product.web_url"
                              #web_url="ngModel" name="web_url">
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Show on Mobile </label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="Showinmobileapp1">
                                <input class="radio_animated" [value]="true" id="showinmobileapp"
                                  [(ngModel)]="product.showinmobileapp" #showinmobileapp="ngModel"
                                  name="showinmobileapp" type="radio">
                                Yes
                              </label>
                              <label class="d-block" for="Showinmobileapp2">
                                <input class="radio_animated" [value]="false" id="showinmobileapp1"
                                  [(ngModel)]="product.showinmobileapp" #showinmobileapp="ngModel"
                                  name="showinmobileapp" type="radio">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                          <div class="form-group">
                            <label>Audit</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                              <label class="d-block" for="Manualstockaudit">
                                <input class="radio_animated" [value]="true" [(ngModel)]="product.manualstockaudit"
                                  #manualstockaudit="ngModel" name="manualstockaudit" id="manualstockaudit"
                                  type="radio">
                                Yes
                              </label>
                              <label class="d-block" for="Manualstockaudit1">
                                <input class="radio_animated" [value]="false" [(ngModel)]="product.manualstockaudit"
                                  #manualstockaudit="ngModel" name="manualstockaudit" id="manualstockaudit1"
                                  type="radio">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group">
                            <label>Specification</label>
                            <textarea class="form-control" rows="5" [readonly]="fieldsetDisabled" [id]="specification"
                              [(ngModel)]="product.specification" #specification="ngModel" name="specification">
											</textarea>
                          </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group">
                            <label>Ext Description</label>
                            <textarea class="form-control" rows="5" [readonly]="fieldsetDisabled" [id]="extdescription"
                              [(ngModel)]="product.extdescription" #extdescription="ngModel" name="extdescription">
											</textarea>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group">
                            <label>Tags</label>
                            <mat-form-field class="ezybooksChipList" appearance="fill">
                              <mat-chip-list #tagList aria-label="Tag selection">
                                <mat-chip *ngFor="let tag of tags" (removed)="remove(tag)">
                                  {{tag}}
                                  <button matChipRemove>
                                    <mat-icon><i class="fa fa-times-circle" aria-hidden="true"></i>
                                    </mat-icon>
                                  </button>
                                </mat-chip>
                                <input placeholder="Select or add new tag" #tagInput [formControl]="tagCtrl"
                                  [matAutocomplete]="auto" [matChipInputFor]="tagList"
                                  [matChipInputAddOnBlur]="addOnFocusOut"
                                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                  (matChipInputTokenEnd)="add($event)" [ngModelOptions]="{standalone: true}">
                              </mat-chip-list>
                              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                                  {{tag}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div class="form-group">
                            <label>Notes</label>
                            <textarea class="form-control" rows="5" [readonly]="fieldsetDisabled" [id]="notes"
                              [(ngModel)]="product.notes" #notes="ngModel" name="notes">
											</textarea>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </fieldset>
            </ng-template>
          </ngb-tab>

          <ngb-tab title="Bin Locations" *ngIf="isEdit" [id]="2">
            <ng-template ngbTabContent>
              <app-binlocations></app-binlocations>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Serials" *ngIf="product.isserilized && isEdit" [id]="10">
            <ng-template ngbTabContent>
              <app-serial></app-serial>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Suppliers" *ngIf="isEdit" [id]="11">
            <ng-template ngbTabContent>
              <app-suppliers></app-suppliers>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Lots" *ngIf="isEdit && product.lot_tracking" [id]="8">
            <ng-template ngbTabContent>
              <app-lottracking></app-lottracking>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="PO History" *ngIf="isEdit" [id]="6">
            <ng-template ngbTabContent>
              <app-pohistory></app-pohistory>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Transaction History" *ngIf="isEdit" [id]="12">
            <ng-template ngbTabContent>
              <app-transactionhistory></app-transactionhistory>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Tax" *ngIf="isEdit && userdata.taxfree" [id]="14">
            <ng-template ngbTabContent>
              <app-tax></app-tax>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Pricing Log" *ngIf="isEdit" [id]="7">
            <ng-template ngbTabContent>
              <app-priceauditlogs></app-priceauditlogs>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Alias" *ngIf="isEdit" [id]="1">
            <ng-template ngbTabContent>
              <app-alias></app-alias>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Details" *ngIf="isEdit" [id]="20">
            <ng-template ngbTabContent>
              <app-details></app-details>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Compatibility" *ngIf="isEdit" [id]="21">
            <ng-template ngbTabContent>
              <app-compatibility></app-compatibility>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Documents" *ngIf="isEdit" [id]="3">
            <ng-template ngbTabContent>
              <app-documents></app-documents>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Loyalty Programs" *ngIf="isEdit" [id]="4">
            <ng-template ngbTabContent>
              <app-loyaltyprograms></app-loyaltyprograms>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Pictures" *ngIf="isEdit" [id]="5">
            <ng-template ngbTabContent>
              <app-pictures></app-pictures>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Reviews" *ngIf="isEdit" [id]="19">
            <ng-template ngbTabContent>

              <app-review></app-review>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Substitute Products" *ngIf="isEdit" [id]="9">
            <ng-template ngbTabContent>
              <app-substituteproduct></app-substituteproduct>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Addons" *ngIf="isEdit && userdata.trade=='FD'" [id]="17">
            <ng-template ngbTabContent>
              <app-addons></app-addons>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Timings" *ngIf="isEdit && userdata.trade=='FD'" [id]="18">
            <ng-template ngbTabContent>
              <app-timeing></app-timeing>
            </ng-template>
          </ngb-tab>
          <ngb-tab title="Audit Logs" *ngIf="isEdit" [id]="13">
            <ng-template ngbTabContent>
              <app-auditlogs></app-auditlogs>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </form>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>