import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-departmentmodal',
  templateUrl: './departmentmodal.component.html',
  styleUrls: ['./departmentmodal.component.scss']
})
export class DepartmentmodalComponent implements OnInit {
  fullScreen = false;
  custDept: any;
  @Input('params') params;
  siteList: any = [];
  deptList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  isDataSave = false;
  constructor(private activemodal: NgbActiveModal, private datepipe: DatePipe, private commonService: CommonServices, private toastr: NotificationService, private loader: NgxUiLoaderService,) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.initialize();
  }

  ngOnInit(): void {
    this.custDept.customerid = this.params.customerid ? this.params.customerid : null;
    this.custDept.siteid = this.params.siteid ? this.params.siteid : '';
    this.getCustomerSite();
    this.getCustomerDeptIndexValues();
  }

  initialize() {
    this.custDept = {
      id: null,
      customerid: null,
      siteid: '',
      name: '',
      poc: '',
      phone: '',
      createby: '',
      createdate: null,
      updateby: '',
      updatedate: null,
      active: true,
    }
  }
  refresh() {
    this.initialize();
    this.custDept.customerid = this.params.customerid;
  }
  closeModal(action) {
    this.activemodal.close(this.isDataSave ? 'save' : action);
  }
  getCustomerSite() {
    this.commonService.getCustomerSiteList(this.custDept.customerid).subscribe(res => {
      this.siteList = res;
    }, err => {
    });
  }

  getCustomerDeptIndexValues() {
    this.deptList = [];
    this.loader.start();
    this.commonService.getCustomerDeptIndex(this.custDept.customerid, '').subscribe(res => {
      this.loader.stop();
      this.deptList = res;
      this.deptList.forEach(item => {
        item.active = item.active == 1 ? true : false;
      })
    }, err => {
      this.loader.stop();
    })
  }
  postCustomerDept() {
    if (this.custDept.id) {
      this.custDept.updateby = this.userdata.email;
      this.custDept.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.custDept.createdate = this.datepipe.transform(new Date(this.custDept.createdate), this.userdata.postdatetimeformat);
    } else {
      this.custDept.createby = this.userdata.email;
      this.custDept.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.custDept.companyid = this.userdata.companyid;
    }
    this.loader.start();
    this.commonService.postCustomerDept(this.custDept).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isDataSave = true;
        this.refresh();
        this.getCustomerDeptIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post commonService call');
    })
  }
  getCustDepartmentById(id) {
    this.loader.start();
    this.commonService.getCustomerDeptByID(id).subscribe((res: any) => {
      this.loader.stop();
      this.custDept = res;
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while delete cpc');
    })
  }
  deleteCustomerDept(id) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.commonService.deleteCustomerDept(id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initialize();
            this.getCustomerDeptIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete cpc');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

}
