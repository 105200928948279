<div class="pos-screen">
  <div class="pos-header">
    <div class="left-flex"><b>Company:</b> {{userdata.companyname}} &nbsp; {{today}}</div>
    <div class="right-flex">
      <div class=" btn-group CsearchBtn" role="group">
        <button type="button" container="body" ngbTooltip="Back to Ezybooks"
          class="btn btn-primary right-radius top-button" (click)="gotoDashboard()"><i
            class="fa fa-home top-icons"></i></button>

        <button type="button" container="body" (click)="refreshScreen()" ngbTooltip="Refresh"
          class="btn btn-primary right-radius left-radius ml-2 top-button" popoverTitle="Refresh"><i
            class="eb-refresh top-icons"></i></button>

        <button type="button" container="body" (click)="openRecentOrder(recentOrder)" ngbTooltip="Recent Sales"
          class="btn btn-primary right-radius left-radius ml-2 top-button" popoverTitle="Sales Return"><i
            class="eb_sell-return top-icons"></i></button>
        <!-- <ng-template #popContent>
          <div class="mb-2 mt-2 ml-2 mr-2">
            <input class="form-control" placeholder="Invoice no.">
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button class="btn btn-primary">Send</button>
          </div>
        </ng-template> -->

        <!-- <button type="button" container="body" ngbTooltip="Register Details"
          class="btn btn-primary right-radius left-radius ml-2 top-button" (click)="registerDetails(registerdetails)"><i
            class="fa fa-file-text top-icons"></i></button> -->
        <button type="button" container="body" ngbTooltip="Close Register"
          class="btn btn-primary right-radius left-radius ml-2 top-button" (click)="closeRegister(closeregister)"><i
            class="fa fa-window-close top-icons"></i></button>
      </div>
    </div>
  </div>
  <div class="pos-body">
    <div class="row">
      <div class="col-md-6 pr-0">
        <div class="left-box">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <div class="input-group back" ngbDropdown>
                  <span class="input-group-addon">
                    <i class="fa fa-user"></i>
                  </span>
                  <input class="form-control" type="text" autocomplete="off" readonly [(ngModel)]="customerinfo.name"
                    Placeholder="Customer Name" ngbDropdownToggle>
                  <i class="fa fa-plus right-hover" (click)="openAddCustomer(newCustomer)"></i>
                  <ul ngbDropdownMenu class="main-dropdown ActionCell-List customer-dropdown"
                    aria-labelledby="dropdownBasic1">
                    <app-customerlistmodal (click)="SelectedCustomer()"></app-customerlistmodal>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group proj-group">
                <div class="input-group back" ngbDropdown>
                  <span class="input-group-addon">
                    <i class="fa fa-sticky-note"></i>
                  </span>
                  <input class="form-control" type="text" [(ngModel)]="postOrder.title" Placeholder="Project/Job/Ticket"
                    ngbDropdownToggle>
                  <i class="fa fa-chevron-down right-hover" ngbDropdownToggle></i>
                  <ul ngbDropdownMenu class="main-dropdown ActionCell-List customer-dropdown"
                    aria-labelledby="dropdownBasic1">
                    <app-projjoblistmodal (click)="selectedJob()"></app-projjoblistmodal>
                  </ul>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-4 pl-0">
              <div class="form-group">
                <div class="input-group position-relative">
                  <div class="input-group-btn">
                    <button type="button" class="btn" data-toggle="modal" data-original-title="test"
                      data-target="#exampleModal" (click)="open(searchProduct)"><i
                        class="fa fa-search-plus"></i></button>
                  </div>
                  <input class="form-control" type="text" autocomplete="off" (input)="getItems()"
                    [(ngModel)]="searchItem.searchtext" Placeholder="Search by">
                 
                </div>
              </div>
            </div> -->
          </div>
          <div class="row detail-row" *ngIf="customerinfo.name">
            <div class="col-lg-2">
              <b>Credit Limit</b><br> {{customerinfo.creditlimit | number:'1.2-2'}}
            </div>
            <div class="col-lg-1">
              <b>Deposit</b><br> {{customerinfo.deposit | number:'1.2-2'}}
            </div>
            <div class="col-lg-1">
              <b>Giftcard</b><br> {{customerinfo.giftcard | number:'1.2-2'}}
            </div>
            <div class="col-lg-1">
              <b>Loyality</b><br> {{customerinfo.loyality | number:'1.2-2'}}
            </div>
            <div class="col-lg-2">
              <b>Discount</b><br> {{customerinfo.discount | number:'1.2-2'}}{{customerinfo.discount_type}}
            </div>
            <div class="col-lg-3">
              <b>Email</b><br>
              <span class="email-div">{{customerinfo.mainemail}}</span>
            </div>
            <div class="col-lg-2">
              <b>Phone</b><br> {{customerinfo.primaryphone}}
            </div>
          </div>
          <div class="product-div">
            <div class="table-responsive product_divList">
              <table class="custom-table">
                <thead>
                  <tr class="custom-tr">
                    <th class="custom-th text-center" style="min-width: 60px;">Action </th>
                    <th class="custom-th text-center col-md-4 ">Product </th>
                    <th class="custom-th text-center col-md-3"> Quantity </th>
                    <th class="custom-th text-center col-md-2"> UOM </th>
                    <th class="custom-th text-center col-md-2 "> Price </th>
                    <th class="custom-th text-center col-md-2"> Dis Amt </th>
                    <th class="custom-th text-center col-md-2"> Tax Amt </th>
                    <th class="custom-th text-center col-md-3"> Ext. Total </th>
                  </tr>
                </thead>

                <tbody class="tbody">
                  <ng-container *ngFor="let entry of orderEnteries;let i=index">
                    <tr class="custom-tr">
                      <td class="text-center">
                        <span (click)="selectSerials(i,entry)" *ngIf="entry.isserilized && isReturnOrder"
                          class="fa fa-list ml-2 cursor-pointer"></span>
                        <span (click)="deleteItem(entry,i)" class="eb-delete ml-2 cursor-pointer"></span>
                      </td>
                      <td class="px-2">
                        <div>
                          <span class="">{{entry.ilc}}<br>{{entry.description}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="input-group input-number"
                          [style.pointer-events]="(entry.isserilized && isReturnOrder) ? 'none' : ''">
                          <span class="input-group-btn"><button type="button" class="btn minplus-btn"
                              (click)="minus(entry)"><i class="fa fa-minus"></i></button></span>
                          <input type="number" class="form-control text-center" style="padding: 0px;"
                            [(ngModel)]="entry.qty" (ngModelChange)="calculateLineItemTotal(entry)">
                          <span class="input-group-btn"><button type="button" class="btn minplus-btn"
                              (click)="plus(entry)"><i class="fa fa-plus"></i></button></span>
                        </div>
                      </td>
                      <td>
                        <select name="" class="form-control" [(ngModel)]="entry.uom">
                          <option *ngFor="let unit of productSellUOMList" [value]="unit.Value">{{unit.Text}}</option>
                        </select>
                      </td>
                      <td class="">
                        <input type="number" name="" class="form-control text-right" [(ngModel)]="entry.price"
                          (ngModelChange)="calculateLineItemTotal(entry)">
                      </td>
                      <td class="position-relative right-30">
                        {{entry.dis_amt | number:'1.2-2'}}
                        <span class="drop-button" *ngIf="!entry.showDis" (click)="entry.showDis = !entry.showDis">
                          <i class="fa fa-caret-square-o-down"></i>
                        </span>
                        <span class="drop-button" *ngIf="entry.showDis" (click)="entry.showDis = !entry.showDis">
                          <i class="fa fa-caret-square-o-up"></i>
                        </span>
                        <div class="overflow-div row" *ngIf="entry.showDis">
                          <div class="col-lg-6 pl-0 pr-0">
                            <label>Discount</label>
                            <input type="number" name="discount-{{i}}" class="form-control text-center"
                              style="padding: 0px;" [(ngModel)]="entry.discount"
                              (input)="calculateLineItemTotal(entry)">
                          </div>
                          <div class="col-lg-6 pl-0 pr-0">
                            <label>Dis Type</label>
                            <select name="discount_type-{{i}}" class="form-control" [(ngModel)]="entry.discount_type"
                              (change)="calculateLineItemTotal(entry)">
                              <option *ngFor="let dis of discountTypeList" [value]="dis.stringValue">{{dis.Text}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="clickable-background" *ngIf="entry.showDis"
                          (click)="entry.showDis = !entry.showDis">
                        </div>
                      </td>

                      <td class="position-relative right-30">
                        {{entry.tax_amt | number:'1.2-2'}}
                        <span class="drop-button" *ngIf="!entry.showTax" (click)="entry.showTax = !entry.showTax">
                          <i class="fa fa-caret-square-o-down"></i>
                        </span>
                        <span class="drop-button" *ngIf="entry.showTax" (click)="entry.showTax = !entry.showTax">
                          <i class="fa fa-caret-square-o-up"></i>
                        </span>
                        <div class="overflow-div row" *ngIf="entry.showTax">
                          <div class="col-lg-6 pl-0 pr-0">
                            <label>Tax</label>
                            <select name="taxid-{{i}}" class="form-control" [(ngModel)]="entry.taxid"
                              (change)="calculateLineItemTotal(entry)">
                              <option *ngFor="let tax of taxesList" [value]="tax.Value">{{tax.Text}}</option>
                            </select>
                          </div>
                          <div class="col-lg-6 pl-0 pr-0">
                            <label>Tax Amt</label>
                            <input name="entrytax-{{i}}" type="number" readonly [value]="entry.tax"
                              class="form-control text-center" style="padding: 0px;">
                          </div>
                        </div>
                        <div class="clickable-background" *ngIf="entry.showTax"
                          (click)="entry.showTax = !entry.showTax">
                        </div>
                      </td>
                      <td class="text-right pr-1">
                        <span class="display_currency">{{entry.line_total |
                          number:'1.2-2'}}</span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                <tfoot *ngIf="orderEnteries.length != 0">
                  <tr>
                    <td colspan="5" class="text-right"><b>Totals</b></td>
                    <td class="text-right">
                      <span><b>{{totalDisc | number:'1.2-2'}}</b></span>
                    </td>
                    <td class="text-right">
                      <span><b>{{totalTax | number:'1.2-2'}}</b></span>
                    </td>
                    <td class="text-right">
                      <span><b>{{itemTotal | number:'1.2-2'}}</b></span>
                    </td>

                  </tr>
                </tfoot>
              </table>
            </div>
            <table class="table mb-0">
              <tbody>
                <tr style="height: 30px;">
                  <td>

                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <b>Priority</b> &nbsp;
                      <select class="form-control high-25" id="priority" name="priority"
                        [(ngModel)]="postOrder.priority" required>
                        <option value="">Select Priority</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                        <option value="urgent">Urgent</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <b>Type</b> &nbsp;
                      <select class="form-control high-25" id="ordertype" name="ordertype"
                        [(ngModel)]="postOrder.ordertype" required>
                        <option value="">Select Type</option>
                        <option *ngFor="let item of soOrderTypes" [value]="item.Value">
                          {{item.Text}}</option>
                      </select>

                    </div>
                  </td>
                  <td colspan="2" style="width: 300px;">
                    <div class="d-flex align-items-center  justify-content-end">
                      <b>Delivery Fee:</b> &nbsp;
                      <span>
                        <input type="number" name="shippingcharges" class="form-control text-right"
                          style="height: 25px;" [(ngModel)]="postOrder.shippingcharges"
                          (focusout)="calculateOrderTotal()">
                      </span>
                    </div>
                  </td>
                </tr>
                <tr style="height: 30px;">
                  <td>

                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <b>SalesRep</b> &nbsp;
                      <span>
                        <input type="text" name="salesrep" class="form-control text-left" style="height: 25px;"
                          [(ngModel)]="postOrder.salesrep">
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex align-items-center">
                      <b>Ref#</b> &nbsp;
                      <span>
                        <input type="text" name="cno" class="form-control text-left" style="height: 25px;"
                          [(ngModel)]="postOrder.cno">
                      </span>
                    </div>
                  </td>
                  <td colspan="2">
                    <div class="d-flex align-items-center justify-content-end">
                      <b>Adjustments:</b> &nbsp;
                      <span>
                        <input type="number" name="adjustment" class="form-control text-right" style="height: 25px;"
                          [(ngModel)]="postOrder.adjustment" (focusout)="calculateOrderTotal()">
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <b>Payment Terms</b> &nbsp;
                      <select class="form-control high-25" id="orderterm" name="payment_terms"
                        [(ngModel)]="postOrder.payment_terms" required>
                        <option value="">Select Term</option>
                        <option *ngFor="let item of paymentTermsList" [value]="item.stringValue">
                          {{item.Text}}</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center position-relative">
                      <b>Add. Terms</b> &nbsp;
                      <span class="position-relative">
                        <input type="text" readonly name="addterms" class="form-control text-left" style="height: 25px;"
                          placeholder="Additional terms" (click)="openAdditionalTerms(additionalterms)">
                        <i class="fa fa-chevron-down drop-icon"></i>
                      </span>
                    </div>
                  </td>
                  <td colspan="2" class="text-right">
                    <div class="d-flex align-items-center justify-content-end">
                      <b>Grand Total:</b> &nbsp;
                      <span>
                        <input type="text" readonly name="ordertotal" class="form-control text-right"
                          style="height: 25px;" value="{{orderTotal | number:'1.2-2'}}">
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6 pl-0">
        <div class="right-box">
          <div class="col-lg-12">
            <div class="form-row">
              <div class="col-lg-4 form-group col-md-6 pl-1 pr-1">
                <ng-autocomplete #Department [data]="departmentList" [initialValue]="setDepartmentValue"
                  [searchKeyword]="departmentKeyword" placeholder="Select Department"
                  (selected)='selectDepartment($event)' (inputChanged)='onAnySearchChange($event)'
                  [itemTemplate]="itemTemplate" (inputCleared)="inputClearedDepartment()"
                  (inputFocused)='onfocusedDepartment($event)' [notFoundTemplate]="notFoundTemplate" [name]="deptid"
                  #deptid="ngModel" [(ngModel)]="validatedept">
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
                <!-- <select class="form-control" required="" [(ngModel)]="searchItem.deptid" (change)="getCategories()">
                  <option value="">Departments</option>
                  <option *ngFor="let item of departmentList" [value]="item.Value">{{item.Text}}</option>
                </select> -->
              </div>
              <div class="col-lg-4 form-group col-md-6 pl-1 pr-1">
                <ng-autocomplete #Category [data]="categoryList" [initialValue]="setCategoryValue"
                  [searchKeyword]="categoryKeyword" placeholder="Select Category" (selected)='selectCategory($event)'
                  (inputChanged)='onAnySearchChange($event)' [itemTemplate]="itemTemplate"
                  (inputCleared)="inputClearedCategory()" (inputFocused)='onfocusedCategory($event)'
                  [notFoundTemplate]="notFoundTemplate" [name]="categoryid" #categoryid="ngModel"
                  [(ngModel)]="validateCateg">
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>

                <!-- <select class="form-control" required="" [(ngModel)]="searchItem.categoryid"
                  (change)="getSubCategories()">
                  <option value="">Categories</option>
                  <option *ngFor="let item of categoryList" [value]="item.Value">{{item.Text}}</option>
                </select> -->
              </div>
              <div class="col-lg-4 form-group col-md-6 pl-1 pr-1">
                <ng-autocomplete #SubCatg [data]="subCategoryList" [initialValue]="setSubCatgValue"
                  [searchKeyword]="subCatgKeyword" placeholder="Select Sub Category" (selected)='selectSubCatg($event)'
                  (inputChanged)='onAnySearchChange($event)' [itemTemplate]="itemTemplate"
                  (inputCleared)="inputClearedSubCatg()" (inputFocused)='onfocusedSubCatg($event)'
                  [notFoundTemplate]="notFoundTemplate" [name]="subcategoryid" #subcategoryid="ngModel"
                  [(ngModel)]="validatesubcatg">
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>

                <!-- <select name="" id="" class="form-control" [(ngModel)]="searchItem.subcategoryid"
                  (change)="getItems()">
                  <option value="">Sub Categories</option>
                  <option *ngFor="let item of subCategoryList" [value]="item.Value">{{item.Text}}</option>
                </select> -->
              </div>
              <div class="col-lg-12 col-md-12 pl-1 pr-1">
                <div class="custom-row">
                  <div class="form-row">
                    <div class="col-lg-8 col-md-6">
                      <input class="form-control" type="text" autocomplete="off" [(ngModel)]="searchItem.searchtext"
                        Placeholder="Item name, description">
                    </div>
                    <div class="col-lg-2 col-md-6 d-flex align-items-center">
                      <select class="form-control" id="priceingtype" name="priceingtype"
                        [(ngModel)]="postOrder.pricingtype" (change)="getItems()">
                        <option *ngFor="let item of priceingTypeList" [value]="item.stringValue">
                          {{item.Text}}</option>
                      </select>
                    </div>
                    <div class="col-lg-2 col-md-6 d-flex align-items-center">
                      <input class="input-check" type="checkbox" name="maincheckbox" [(ngModel)]="searchItem.qtyonhand"
                        (change)="getItems()" value="" id="flexCheckDefault">
                      <label class="mb-0 ml-1 text-nowrap">On Hand</label>
                    </div>
                  </div>
                  <div class="btn-group CsearchBtn1 CsearchBtn mt-0 ml-1" role="group">
                    <button class="btn btn-primary right-radius" (click)="getItems();">Search</button>
                    <div ngbDropdown class="d-inline-block ActionCell" container="body">
                      <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
                      <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                        <li><span ngbDropdownItem (click)="openAssemblyCatalog()"> Assembly Catalog</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="custom-row">
              <div class="btn-group CsearchBtn1 CsearchBtn mt-0" role="group">
                <div ngbDropdown class="d-inline-block ActionCell" container="body">
                  <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
                  <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                    <li><span ngbDropdownItem (click)="openAssemblyCatalog()">Assembly Catalog</span></li>
                  </ul>
                </div>
              </div>
            </div> -->
            <br>
            <div class="product-list">
              <div class="row">
                <div class="col-md-3 col-sm-4" *ngFor="let item of productList">
                  <div class="product-item">
                    <div ngbDropdown class="d-inline-block ActionCell" container="body">
                      <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
                      <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                        <li><span ngbDropdownItem (click)="addPurchaseReq(item)"> Add to Purchase Request</span></li>
                        <li><span ngbDropdownItem (click)="addSalesRequest(item)"> Add to Sales Request</span></li>
                        <li><span ngbDropdownItem (click)="openItemAnalysisModal(item)">Item Analysis</span></li>
                        <li><span ngbDropdownItem (click)="openPurchaseHistoryModal(item)">Purchase History</span></li>
                        <li><span ngbDropdownItem (click)="openItemCompatibilityModal(item)">Item Compatibility</span>
                        </li>
                        <li><span ngbDropdownItem (click)="openBinDetails(item)">Bin Locations</span></li>
                        <li><span ngbDropdownItem (click)="viewPicture(picture,item)"> View Pictures</span></li>
                      </ul>
                    </div>
                    <div class="image-container" (click)="addItem(item)">
                      <img [src]="item.pic" onerror="this.src='assets/images/100.png';" alt="" class="img-fluid">
                    </div>
                    <div class="text_div" (click)="addItem(item)">
                      <small class="text text-muted" [innerHtml]="item.ilc"></small>
                      <small class="text text-muted" [innerHtml]="item.desc" container="body"
                        [ngbTooltip]="item.desc"></small>
                      <!-- <small>QtyInStock {{item.qtyonhand}},</small> -->
                      <small class="price text-muted">On Hand: {{item.qtyonhand}}, {{userdata.currency}} {{item.price |
                        number:'1.2-2'}}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pos-form-action">
      <div class="">
        <div class="col-lg-12 d-flex align-items-center">
          <button type="button" class=" btn btn-primary text-white bottom-button" (click)="refreshOrder()">Reset
            Cart</button>
          <button type="button" class=" btn btn-primary text-white bottom-button"
            (click)="submitOrder('draft')">Draft</button>
          <button type="button" class=" btn btn btn-primary text-white bottom-button"
            (click)="openTransaction(paymentTransaction)">Cash Out</button>
          <button type="button" class=" btn btn btn-primary text-white bottom-button"
            (click)="openRecentOrder(recentOrder)">Recent Sales</button>
          <button type="button" class=" btn btn btn-primary text-white bottom-button"
            (click)="openQuote()">Quotes</button>
          <button type="button" class=" btn btn btn-primary text-white bottom-button"
            (click)="openPurchaseReq()">Purchase Requests</button>
          <button type="button" class=" btn btn btn-primary text-white bottom-button" (click)="openSalesReq()">Sales
            Requests</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Add new Contact Modal  -->
<ng-template #newCustomer let-modal [formGroup]="customerForm">
  <resize-border [dragHolder]="header">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">New Customer</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12 form-group">
          <label for="" class="required"> Name
          </label>
          <input class="form-control" id="" formControlName="name" [(ngModel)]="customer.name"
            [ngModelOptions]="{standalone: true}" type="text"
            [ngClass]="{ 'is-invalid': submitted && f['name']?.errors , 'is-valid': submitted && f['name']?.valid}">
          <div *ngIf="submitted && f['name']?.errors" class="invalid-feedback">
            <div *ngIf="f['name']?.errors['required']">Name is required</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class="">Short Name</label>
            <input class="form-control" id="" formControlName="shortname" [(ngModel)]="customer.shortname"
              [ngModelOptions]="{standalone: true}" type="text">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for=""> Point of Contact</label>
            <input class="form-control" id="" [(ngModel)]="customer.poc" type="text" formControlName="poc"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class="">Third Party#
            </label>
            <input class="form-control" id="" formControlName="tpaccno" [ngModelOptions]="{standalone: true}"
              [(ngModel)]="customer.tpaccno" type="text">
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class="required">Primary Phone</label>
            <input class="form-control" id="" [(ngModel)]="customer.primaryphone" type="text"
              formControlName="primaryphone" [ngModelOptions]="{standalone: true}"
              [ngClass]="{ 'is-invalid': submitted && f['primaryphone']?.errors , 'is-valid': submitted && f['primaryphone']?.valid}">
            <div *ngIf="submitted && f['primaryphone']?.errors" class="invalid-feedback">
              <div *ngIf="f['primaryphone']?.errors['required']"> Primary
                Phone required</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="">Primary
              Email</label>
            <input class="form-control" id="" [(ngModel)]="customer.mainemail" type="text" formControlName="mainemail">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class="required">Billing
              Address1</label>
            <input class="form-control" id="" [(ngModel)]="customer.billaddress1" type="text"
              formControlName="billaddress1" [ngModelOptions]="{standalone: true}"
              [ngClass]="{ 'is-invalid': submitted && f['billaddress1']?.errors , 'is-valid': submitted && f['billaddress1']?.valid}">
            <div *ngIf="submitted && f['billaddress1']?.errors" class="invalid-feedback">
              <div *ngIf="f['billaddress1']?.errors['required']">
                Billing Address1 required
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="">Billing Address2 </label>
            <input class="form-control" id="" [(ngModel)]="customer.billaddress2" type="text"
              formControlName="billaddress2" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="">Billing Address3</label>
            <input class="form-control" id="" [(ngModel)]="customer.billaddress3" type="text"
              formControlName="billaddress3" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="">Billing Address4</label>
            <input class="form-control" id="" [(ngModel)]="customer.billaddress4" type="text"
              formControlName="billaddress4" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="">Discount%</label>
            <input class="form-control" id="" [(ngModel)]="customer.discount" type="text" formControlName="discount"
              [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Status</label>
            <select class="form-control" name="status" [(ngModel)]="customer.status"
              [ngModelOptions]="{standalone: true}" formControlName="status" (change)="changestatus($event)"
              [ngClass]="{ 'is-invalid': submitted && f['status']?.errors , 'is-valid': submitted && f['status']?.valid}">
              <option value="">Select</option>
              <option *ngFor="let item of custStatusList" [value]="item.stringValue">
                {{item.Text}}
              </option>
            </select>
            <div *ngIf="submitted && f['status']?.errors" class="invalid-feedback">
              <div *ngIf="f['status']?.errors['required']">
                Please select Status
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
          (click)="modal.dismiss('Cross click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="addCustomer()">Save</button>
      </div>
    </div>
  </resize-border>
</ng-template>
<!-- Close Register Modal -->

<ng-template #closeregister let-modal>
  <resize-border [dragHolder]="header1">
    <div #header1 class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Current Register {{currentDateTime}}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="table-responsive2">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center">Payment Method</th>
            <th class="custom-th text-center">Currency</th>
            <th class="custom-th text-center">Amount</th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of closingReport">
            <td class="custom-td text-left">{{item.mop}}</td>
            <td class="custom-td text-center">{{item.currency}}</td>
            <td class="custom-td text-right">{{item.amt}}</td>
          </tr>
        </table>
      </div>
      <!-- <div class="table-responsive2">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-td text-left">Total Sales</th>
            <td class="custom-td text-right">$0.00</td>
          </tr>
          <tr class="custom-tr">
            <th class="custom-td text-left">Total Fund</th>
            <td class="custom-td text-right">$0.00</td>
          </tr>
        </table>
      </div> -->
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="modal.dismiss('Cross click')">Cancel</button>
      <button class="btn btn-primary" type="button" data-dismiss="modal" (click)="openReport('printx')">Print X
        Report</button>
      <button class="btn btn-primary" type="button" data-dismiss="modal" (click)="openReport('printy')">Print Z
        Report</button>
    </div>
  </resize-border>
</ng-template>
<!-- Register Details Modal -->
<ng-template #registerdetails let-modal>
  <resize-border [dragHolder]="header2">
    <div #header2 class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Current Register (12th Aug, 2022 08:31 AM - 12th Aug, 2022
        08:45 AM)</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="table-responsive2">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center">Payment Method</th>
            <th class="custom-th text-center">Sell</th>
            <th class="custom-th text-center">Expense</th>
          </tr>
          <tr class="custom-tr">
            <td class="custom-td text-left">Cash in hand</td>
            <td class="custom-td text-right">$1000</td>
            <td class="custom-td text-right">$0.00</td>
          </tr>

        </table>
      </div>
      <hr>
      <div class="table-responsive2">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-td text-left">Total Sales</th>
            <td class="custom-td text-right">$0.00</td>
          </tr>
          <tr class="custom-tr">
            <th class="custom-td text-left">Total Fund</th>
            <td class="custom-td text-right">$0.00</td>
          </tr>
          <tr class="custom-tr">
            <th class="custom-td text-left">Total Payment</th>
            <td class="custom-td text-right">$0.00</td>
          </tr>
          <tr class="custom-tr">
            <th class="custom-td text-left">Credit Sales</th>
            <td class="custom-td text-right">$0.00</td>
          </tr>
          <tr class="custom-tr">
            <th class="custom-td text-left">Total Sales</th>
            <td class="custom-td text-right">$0.00</td>
          </tr>
          <tr class="custom-tr">
            <th class="custom-td text-left">Total Expenses</th>
            <td class="custom-td text-right">$0.00</td>
          </tr>
        </table>
      </div>
      <hr>
      <h3>Details of Product Sold</h3>
      <div class="table-responsive2">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center">#</th>
            <th class="custom-th text-center">SKU</th>
            <th class="custom-th text-center">Product</th>
            <th class="custom-th text-center">Quantity</th>
            <th class="custom-th text-center">Total Amount</th>
          </tr>
          <tr class="custom-tr">
            <td class="custom-td text-left">1.</td>
            <td class="custom-td text-right">ser</td>
            <td class="custom-td text-right">service</td>
            <td class="custom-td text-right">2.00</td>
            <td class="custom-td text-right">$ 110.00</td>
          </tr>
          <tr class="custom-tr">
            <td class="custom-td text-left" colspan="3">#</td>
            <td class="custom-td text-right">2</td>
            <td class="custom-td text-right">
              <div><b>Order Tax: (+) $ 10.90</b></div>
              <div><b>Discount: (-) $ 1.00</b></div>
              <div><b>Grand Total: $ 119.90</b></div>
            </td>
          </tr>
        </table>
      </div>
      <hr>
      <h3>Details of Product Sold (By Category)</h3>
      <div class="table-responsive2">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center">#</th>
            <th class="custom-th text-center">SKU</th>
            <th class="custom-th text-center">Quantity</th>
            <th class="custom-th text-center">Total Amount</th>
          </tr>
          <tr class="custom-tr">
            <td class="custom-td text-left">1.</td>
            <td class="custom-td text-right">ser</td>
            <td class="custom-td text-right">2.00</td>
            <td class="custom-td text-right">$ 110.00</td>
          </tr>
          <tr class="custom-tr">
            <td class="custom-td text-left" colspan="2">#</td>
            <td class="custom-td text-right">2</td>
            <td class="custom-td text-right">
              <div><b>Order Tax: (+) $ 10.90</b></div>
              <div><b>Discount: (-) $ 1.00</b></div>
              <div><b>Grand Total: $ 119.90</b></div>
            </td>
          </tr>
        </table>
      </div>
      <hr>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <b>User:</b> Admin<br>
          <b>Email:</b> admin@example.com<br>
          <b>Business Location</b> Awesome Shop
        </div>
      </div>


    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button class="btn btn-primary" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')"><i
          class="fa fa-print"></i> Print</button>
    </div>
  </resize-border>
</ng-template>
<!-- Suspended Sales Modal -->
<ng-template #suspendedSales let-modal>
  <resize-border [dragHolder]="header3">
    <div #header3 class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Suspended Sales</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="modal.dismiss('Cross click')">Close</button>
    </div>
  </resize-border>
</ng-template>
<ng-template #addexpenses let-modal>
  <resize-border [dragHolder]="header4">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Suspended Sales</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Type</label>
            <select class="form-control">
              <option value="">Select</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class=""> Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker95" placeholder="Choose a date" name="expensedate">
              <mat-datepicker-toggle matSuffix [for]="picker95">
              </mat-datepicker-toggle>
              <mat-datepicker #picker95></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Vendor/Supplier</label>
            <select class="form-control">
              <option value="">Select</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class=""> Saved
              By/Employee</label>
            <input class="form-control" id="" type="text">

          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class=""> Receipt
              Number</label>
            <input class="form-control" id="" type="text">

          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Currency</label>
            <select class="form-control">
              <option value="">Select</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class="">Reimbursable%</label>
            <input class="form-control" id="" type="text">

          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Billable?</label>
            <select class="form-control">
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>

            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Customer</label>
            <select class="form-control">
              <option value="">Select</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Project </label>
            <select class="form-control">
              <option value="">Select</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Ticket/WO# </label>
            <select class="form-control" id="customer" name="customer">
              <option value="">Select</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class="">Approved By</label>
            <input class="form-control" id="" type="text" name="approvedby">

          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Status </label>
            <select class="form-control">
              <option value="">Select</option>
            </select>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label for="" class="">Notes / Memo</label>
            <textarea class="form-control" rows="5">
          </textarea>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="form-group">
            <label for="" class="">Attachments</label>
            <input class="form-control" id="" type="file">
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="modal.dismiss('Cross click')">Close</button>
    </div>
  </resize-border>
</ng-template>

<!-- Recent Order -->
<ng-template #recentOrder let-modal>
  <resize-border [dragHolder]="header6">
    <div #header6 class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Recent Sales</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body recentSalesModal">
      <div class="custom-row">
        <div class="form-row">
          <div class="form-group col-lg-4 col-md-6 col-sm-12">
            <label for="">Search by</label>
            <input type="text" placeholder="Search by Order#, Ref#, Type, Customer Name" name="orderno" id=""
              class="form-control" [(ngModel)]="recentOrders.searchtxt">
          </div>
          <div class="form-group col-lg-2 col-md-6 col-sm-12">
            <label for="">From Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker501" placeholder="Choose a date" [(ngModel)]="recentOrders.fdate"
                name="fdate">
              <mat-datepicker-toggle matSuffix [for]="picker501"></mat-datepicker-toggle>
              <mat-datepicker #picker501></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-lg-2 col-md-6 col-sm-12">
            <label for="">To Date</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker502" placeholder="Choose a date" [(ngModel)]="recentOrders.edate"
                name="edate">
              <mat-datepicker-toggle matSuffix [for]="picker502"></mat-datepicker-toggle>
              <mat-datepicker #picker502></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group col-lg-2 col-md-6 col-sm-12">
            <label for="">Type</label>
            <select class="form-control" id="type" name="type" [(ngModel)]="recentOrders.type"
              (change)="getPOSRecentOrders()">
              <option value="">All</option>
              <option *ngFor="let item of soOrderTypes" [value]="item.Value">
                {{item.Text}}</option>
            </select>
          </div>
          <div class="form-group col-lg-2 col-md-6 col-sm-12">
            <label for="status">Status</label>
            <select class="form-control" [(ngModel)]="recentOrders.status" name="status"
              (change)="getPOSRecentOrders()">
              <option value="">Select Status</option>
              <option *ngFor="let cust of orderStatusList" [value]="cust.stringValue">
                {{cust.Text}}</option>
            </select>
          </div>
        </div>
        <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
          <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
            (click)="getPOSRecentOrders()"><i class="refresh-btn fa fa-search"></i>
          </button>
          <button type="button" container="body" ngbTooltip="Refresh"
            class="ml-2 btn btn-primary right-radius left-radius" (click)="refreshRecentOrders()"><i
              class="refresh-btn fa fa-refresh"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive2 col-lg-12 form-group">
          <table class="custom-table">
            <tr class="custom-tr">
              <th class="custom-th text-center" style="width:20px;min-width:20px;">Action</th>
              <th class="custom-th text-center">Order#</th>
              <th class="custom-th text-center">Proj/Job</th>
              <th class="custom-th text-center">Order Ref#</th>
              <th class="custom-th text-center">Type</th>
              <th class="custom-th text-center">Txn Type</th>
              <th class="custom-th text-center">Origin</th>
              <th class="custom-th text-center">Customer</th>
              <th class="custom-th text-center">Order Total</th>
              <th class="custom-th text-center">Paid Amt</th>
              <th class="custom-th text-center">Due Amt</th>
              <th class="custom-th text-center">Sales Rep</th>
              <th class="custom-th text-center">Status</th>
              <th class="custom-th text-center">Order Date</th>
              <th class="custom-th text-center">CreateBy</th>
              <th class="custom-th text-center">CreateDate</th>
            </tr>
            <ng-container *ngFor="let item of recentOrderList">
              <tr class="custom-tr">
                <td class="custom-td text-center">
                  <span ngbDropdown class="d-inline-block ActionCell" style="padding: 2%;" container="body">
                    <i class="fa fa-th-list" title="Print" id="dropdownBasic1" ngbDropdownToggle></i>
                    <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                      <li *ngIf="item.isshowedit"><span ngbDropdownItem (click)="getPOSOrderById(item.id,'regular')">
                          Edit</span></li>
                      <li><span ngbDropdownItem (click)="getPOSOrderById(item.id,'clone')"> Clone</span></li>
                      <li><span ngbDropdownItem (click)="doPayment(item)"> Payment</span></li>
                      <li *ngIf="item.isshowpickup"><span ngbDropdownItem (click)="pickupOrder(item)"> Pick-up</span>
                      </li>
                      <li *ngIf="item.isshowreturn"><span ngbDropdownItem (click)="getPOSOrderById(item.id,'return')">
                          Return</span></li>
                      <li><span ngbDropdownItem (click)="openEmailState(item.id)"> Email</span></li>
                      <li><span ngbDropdownItem (click)="print(item,'printso')"> Print A4</span></li>
                      <li><span ngbDropdownItem (click)="print(item,'printsr')"> Print Receipt</span></li>
                      <li><span ngbDropdownItem (click)="print(item,'printpt')"> Print Pick Ticket</span></li>
                      <li><span ngbDropdownItem (click)="print(item,'printptwithbarcodes')"> Print Pick Ticket-
                          Barcode</span></li>
                    </ul>
                  </span>
                  <!-- <span style="padding: 2%;">
                    <i class="fa fa-dollar table-icon" title="Payment" (click)="doPayment(item)"></i>
                  </span>
                  <span>
                    <i class="fa fa-caret-square-o-left table-icon" *ngIf="item.isshowreturn" title="Return"
                      (click)="getPOSOrderById(item.id,'return')"></i>
                  </span>
                  <span style="padding: 2%;">
                    <i class="fa fa-envelope table-icon" title="Email" (click)="openEmailState(item.id)"></i>
                  </span>
                  <span style="padding: 2%;">
                    <i class="eb_pickup-1 table-icon" *ngIf="item.isshowpickup" title="Pickup"
                      (click)="pickupOrder(item)"></i>
                  </span>
                  <span style="padding: 2%;">
                    <i class="fa fa-clone table-icon" title="Clone" (click)="getPOSOrderById(item.id,'clone')"></i>
                  </span>
                  <span style="padding: 2%;">
                    <i class="fa fa-pencil-square-o table-icon" title="Edit" *ngIf="item.isshowedit"
                      (click)="getPOSOrderById(item.id,'regular')"></i>
                  </span> -->
                </td>
                <td class="custom-td text-center">{{item.orderno}}</td>
                <td class="custom-td text-center">{{item.orderref}}</td>
                <td class="custom-td text-center">{{item.cno}}</td>
                <td class="custom-td text-center">{{item.ordertype}}</td>
                <td class="custom-td text-center">{{item.txntype}}</td>
                <td class="custom-td text-center">{{item.origin}}</td>
                <td class="custom-td text-left">{{item.customer}}</td>
                <td class="custom-td text-right">{{item.orderprice | number:'1.2-2'}}</td>
                <td class="custom-td text-right">{{item.totalpaid | number:'1.2-2'}}</td>
                <td class="custom-td text-right">{{item.dueamount | number:'1.2-2'}}</td>
                <td class="custom-td text-left">{{item.salesrep}}</td>
                <td class="custom-td text-center">{{item.status}}</td>
                <td class="custom-td text-center text-nowrap">{{item.orderdate|date:userdata.displaydatetimeformat}}
                </td>
                <td class="custom-td text-left">{{item.createby}}</td>
                <td class="custom-td text-center text-nowrap">{{item.createdate | date:userdata.displaydatetimeformat}}
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </resize-border>
</ng-template>

<!-- Payments -->
<ng-template #paymentTransaction let-modal>
  <resize-border [dragHolder]="header7">
    <div #header7 class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Payment Transaction</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="tRow">
        <div class="cashout-left"><b>Total</b> </div>
        <div class="cashout-right">{{userdata.currency}} {{orderTotal|number:'1.2-2'}}</div>
      </div>
      <div class="tRow" *ngFor="let item of paymentMethodList;let i=index;">
        <div class="cashout-left"><b>{{item.Text}}</b></div>
        <!-- <span class="spanInput"><span class="mr-2">USD</span> <input type="number" value="0" class="form-control"></span> -->
        <div class="cashout-right spanInput text-right">
          <div class="mr-2">{{userdata.currency}}</div> <input name="cash-{{i}}" (input)="calculateDueBalance()"
            type="number" [(ngModel)]="item.pay" value="0" class="form-control">
        </div>
      </div>
      <div class="tRow">
        <div class="cashout-left"><b>Balance Due</b></div>
        <div class="cashout-right">{{userdata.currency}} {{paying.duebalance|number:'1.2-2'}}</div>
      </div>
      <div class="tRow">
        <div class="cashout-left"> <b>Change</b></div>
        <div class="cashout-right">{{userdata.currency}} {{paying.change|number:'1.2-2'}}</div>
      </div>
      <div class="tRow" *ngIf="paying.change>0">
        <div class="cashout-left"><b>Leave Credit/Change on Account?</b></div>
        <div class="cashout-right m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
          <label class="d-block" for="keepchange">
            <input class="radio_animated" [value]=true id="keepchange" type="radio" name="keepchange"
              [(ngModel)]="postOrder.keepchange">
            Yes
          </label>
          <label class="d-block" for="keepchange">
            <input class="radio_animated" [value]=false id="keepchange" type="radio" name="keepchange"
              [(ngModel)]="postOrder.keepchange">
            No
          </label>
        </div>
      </div>
      <div class="tRow">
        <div class="cashout-left"><b>Technician/Auth Person</b></div>
        <div class="cashout-right">
          <ng-autocomplete #Customer [data]="technicianList" [initialValue]="editSetTech" [searchKeyword]="techkeyword"
            placeholder="Select Technician" (selected)='selectTechnician($event)' [itemTemplate]="itemTemplate"
            (inputCleared)="inputTechnicianCleared()" (inputFocused)='onFocused($event)'
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.title"></a>
          </ng-template>
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </div>
      <div class="custom-control custom-checkbox d-flex">
        <input type="checkbox" class="custom-control-input" (change)="calculateDueBalance()"
          [(ngModel)]="paying.isauthorizecredit" id="customCheck11" name="example1">
        <label class="custom-control-label" for="customCheck11">Authorise Credit/On Account</label> &nbsp;&nbsp; <span
          *ngIf="paying.onaccount>0"><b>{{userdata.currency}}
            {{paying.onaccount|number:'1.2-2'}}</b></span>
      </div>
      <br>
      <input type="text" class="form-control" [(ngModal)]="paying.memo" placeholder="Payment Reference">
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button class="btn btn-primary" [disabled]="paying.duebalance>0" (click)="submitOrder('paid')"
        type="button">Submit</button>
    </div>
  </resize-border>
</ng-template>

<!-- View Pictures -->
<ng-template #picture let-modal>
  <resize-border [dragHolder]="header8">
    <div #header8 class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Pictures</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="">
        <owl-carousel-o [options]="owlcarouselOptions">
          <ng-container *ngFor="let data of itemPictures">
            <ng-template carouselSlide class="item">
              <div class="owl-item">
                <img [src]="data.filename" onerror="this.src='assets/images/100.png';" alt="" class="img-fluid">
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </resize-border>
</ng-template>
<!-- Additional Terms -->
<ng-template #additionalterms let-modal>
  <resize-border [dragHolder]="header9">
    <div #header9 class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Additional Terms</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="termList">
        <div class="d-flex mb-1"> <input type="checkbox" name="selectall" class="mr-1"
            (change)="onChangeTermSelectAll()" [(ngModel)]="additionaltermsfilter">
          <label for="" class="m-0">Select All</label>
        </div>
        <div class="term" *ngFor="let item of soTermsList;let i=index;"><input type="checkbox" class="mr-1"
            name="term-{{i}}" [checked]="item.checked" [(ngModel)]="item.checked" (change)="onTermChange()">
          <label for="">{{item.Text}}</label>
        </div>
      </div>
    </div>
  </resize-border>
</ng-template>
<ngx-ui-loader></ngx-ui-loader>