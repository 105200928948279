<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">Quick Add Product
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" name="form" #f="ngForm">
        <div class="form">
          <div class="form row">
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1 required">Product Type</label>
              <select class="form-control" #ptype="ngModel" name="ptype" [(ngModel)]="product.ptype"
                [ngClass]="{ 'is-invalid': ptype.invalid && ptype?.errors , 'is-valid': ptype.valid}" required>
                <option value="">Product Type</option>
                <option *ngFor="let ptype of productType" [value]="ptype.stringValue">{{ptype.Text}}</option>
              </select>
              <div *ngIf="ptype.touched && ptype.invalid && ptype.errors" class="invalid-feedback d-block">
                <div *ngIf="ptype.errors.required">This field is required</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1 required">ILC/ Product code (Max 20
                Chars)</label>
              <input type="text" name="ilc" #ilc="ngModel" id="ilc" class="form-control" [(ngModel)]="product.ilc"
                (focusout)="validateIlc()" placeholder="ILC" required maxlength="20"
                [ngClass]="{ 'is-invalid': ilc.invalid && ilc?.errors , 'is-valid': ilc.valid}" required>
              <div *ngIf="ilc.touched && ilc.invalid && ilc.errors" class="invalid-feedback d-block">
                <div *ngIf="ilc.errors.required">This field is required</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1 required">Description</label>
              <input class="form-control" id="" type="text" #description="ngModel" placeholder="Description"
                name="description"
                [ngClass]="{ 'is-invalid': description.invalid && description?.errors , 'is-valid': description.valid}"
                required [(ngModel)]="product.description">
              <div *ngIf="description.touched && description.invalid && description.errors"
                class="invalid-feedback d-block">
                <div *ngIf="description.errors.required">This field is required</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1 required">Department</label>
              <ng-select placeholder="Select Department" id="department" [(ngModel)]="product.dept" #dept="ngModel"
                name="department" [clearable]="product.dept"
                [ngClass]="{ 'is-invalid': dept.invalid && dept?.errors , 'is-valid': dept.valid}" required
                (change)="getCatData()">
                <ng-option *ngFor="let item of deptList" [value]="item.Value">
                  {{item.Text}}</ng-option>
              </ng-select>
              <div *ngIf="dept.touched && dept.invalid && dept.errors" class="invalid-feedback d-block">
                <div *ngIf="dept.errors.required">This field is required</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1">Category</label>
              <ng-select placeholder="Select Category" id="category" #cat="ngModel" name="category"
                [(ngModel)]="product.cat" [clearable]="product.cat">
                <ng-option *ngFor="let item of categoryList" [value]="item.Value">
                  {{item.Text}}</ng-option>
              </ng-select>
            </div>
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1 required">PO Cost</label>
              <input class="form-control" id="" #pocost="ngModel" type="text" placeholder="PO Cost" name="pocost"
                [ngClass]="{ 'is-invalid': pocost.invalid && pocost?.errors , 'is-valid': pocost.valid}" required
                [(ngModel)]="product.pocost">
              <div *ngIf="pocost.touched && pocost.invalid && pocost.errors" class="invalid-feedback d-block">
                <div *ngIf="pocost.errors.required">This field is required</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1 required">Landed Cost</label>
              <input class="form-control" id="" type="text" placeholder="Landed Cost" name="landedcost"
                [(ngModel)]="product.landedcost" #landedcost="ngModel"
                [ngClass]="{ 'is-invalid': landedcost.invalid && landedcost?.errors , 'is-valid': landedcost.valid}"
                required>
              <div *ngIf="landedcost.touched && landedcost.invalid && landedcost.errors"
                class="invalid-feedback d-block">
                <div *ngIf="landedcost.errors.required">This field is required</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1 required">Retail Price</label>
              <input class="form-control" id="" #store="ngModel" type="text" placeholder="Retail Price" name="retail"
                [(ngModel)]="product.store"
                [ngClass]="{ 'is-invalid': store.invalid && store?.errors , 'is-valid': store.valid}" required>
              <div *ngIf="store.touched && store.invalid && store.errors" class="invalid-feedback d-block">
                <div *ngIf="store.errors.required">This field is required</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1 required">Supplier</label>
              <ng-select placeholder="Select Supplier" id="supplierid" #supplierid="ngModel" name="supplierid"
                [(ngModel)]="product.supplierid" [clearable]="product.supplierid"
                [ngClass]="{ 'is-invalid': supplierid.invalid && supplierid?.errors , 'is-valid': supplierid.valid}"
                required>
                <ng-option *ngFor="let item of supplierList" [value]="item.Value">
                  {{item.Text}}</ng-option>
              </ng-select>
              <div *ngIf="supplierid.touched && supplierid.invalid && supplierid.errors"
                class="invalid-feedback d-block">
                <div *ngIf="supplierid.errors.required">This field is required</div>
              </div>
            </div>
            <div class="form-group col-lg-6 col-12">
              <label for="" class="mb-1 required">Status</label>
              <select class="form-control" name="status" #status="ngModel" [(ngModel)]="product.status"
                [ngClass]="{ 'is-invalid': status.invalid && status?.errors , 'is-valid': status.valid}" required>
                <option value="">Select Status</option>
                <option *ngFor="let item of statusList" [value]="item.stringValue">{{item.Text}}</option>
              </select>
              <div *ngIf="status.touched && status.invalid && status.errors" class="invalid-feedback d-block">
                <div *ngIf="status.errors.required">This field is required</div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button class="btn btn-primary" type="button" (click)="postQuickItem()" [disabled]="!f.valid">Save</button>
    </div>
  </div>
</resize-border>