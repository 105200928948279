import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Chart } from 'angular-highcharts';
import { doughnutData, pieData } from '../../shared/data/chart';
import { ChatSignalr } from '../customerservice/chatsignalr.service';
import { DashboardServices } from './dashboard.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { hrServices } from '../hr/hr.services';
import highcharts3D from 'highcharts/highcharts-3d.src';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { Events } from 'src/app/shared/service/events.service';
import { SchedulerServices } from '../scheduler/scheduler.service';

interface CustomPoint extends Highcharts.Point {
	value: number;
	currency: string
}
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	//#region userdata block
	appname = environment.APP_NAME;
	resTransactions: any = [];
	expiryPopup: any = false;
	userdata: any;
	companyLogo: any = 'http://ezybooks.net/docs/logos/';
	format: any;
	totalRows: any;
	//#endregion userdata block
	fullScreen = false;
	public ischeckIn = false;
	public isToggleSwitch = false;
	public doughnutData = doughnutData;
	public pieData = pieData;
	public projectData = [];
	reasonsList: any = [];
	title;
	notes;
	reminder: any = {};
	sales_trend: any = [];
	purchase_trend: any = [];
	dashboardData: any = {
		officeClockings: []
	};
	transactions: any = [];
	stickeys: any;
	userprofile: any;
	otherReason: any
	reminderList: any = [];
	filteredReminders: any = [];
	reminderTypes: any = [];
	departmentlist: any = [];
	reminderbyID: any;
	stringData = '';
	arrayData = [];
	isShow = false;
	isShow2 = false;
	dropdownList = [];
	selectedItems = [];
	selectedAssignTo = [];
	LeavesStatus: any;
	LeavesType: any;
	totalLeaves: any;
	employeeLeaveList: any = [];
	empLeave: any = {
		status: '',
		type: '',
		halfday1: false,
		halfday2: false,
		year: ''
	}
	shiftDetails: any;
	salesChart: Chart;
	invoiceChart: Chart;
	purchaseChart: Chart;
	snagChart: Chart;
	ticketChart: Chart;
	taskChart: Chart;
	dropdownSettings: IDropdownSettings;
	removedArr: any;
	TransTypes: any = [];
	masterSelected: boolean = false;
	checklist: any;
	checkedList: any;
	closeResult: string;
	transType: any = '';
	remindertype: any = ''
	InvoiceTrendArr: any = [];
	filteredTransactions: any;
	recentTrTypeList: any = []
	sticketNotesList: any = []
	dashboard: any;
	technicianList: any = [];
	// Shift related variables
	timestamp: number;
	timing: any[];
	brkStarted: boolean = false;
	shiftStarted: boolean = false;
	startShiftData: any;
	minutes: string;
	hours: string;
	reason: any;
	totalShiftTime: any;
	totalTimeinEntries: any;
	breakTime: any;
	isDisable: boolean;
	isBreakOption: boolean;
	startShiftOption: boolean;
	startMainTimeinhours: any;
	startMainMin: any;
	timeStart: any;
	totalMainMin: any;
	timestartmin: any;
	totalStartTime: any
	endTimeinHr: any;
	endTimeInMin: any;
	timeEnd: any;
	totelEndTime: any;
	leaveBalance: any = [];
	userName;
	totalBreakHrs: any = [];
	totalWorkHrs: any = [];
	isEndShift: boolean = false;
	totalWorkTime: any = '00:00';
	totalBreakTime: any = '00:00';
	array = [{ name: 'value1' }, { name: 'value2' }];
	@ViewChild('clearDateField') clearDateField: ElementRef;
	leaveReason: any;
	isalertshown: any;
	currentDateTime = new Date();
	reasonId: any;
	buttonType: any;
	hourHandStyle;
	minuteHandStyle;
	secondHandStyle;
	isRunning = true;
	timerId: any;
	date: Date;
	date1: any;
	hour: number = 0;
	minute: number = 0;
	second: number = 0;
	hour1: number = 0;
	minute1: number = 0;
	second1: number = 0;
	timeZoneList: any = [];
	formatedList: any = [{
		hourHandStyle: '',
		minuteHandStyle: '',
		secondHandStyle: '',
		hour: '',
		minute: '',
		second: '',
		country: '',
	}]
	leaveYearList: any = []
	lastMonth: any = [];
	leaveType: any
	leaveMode: string;
	public menuItems: any;
	public url: any;
	public fileurl: any;
	logoutEvent: any;
	salesTrend: any = [];
	purchaseTrend: any = [];
	officeClockings: any = [];
	snagList: any = [];
	taskList: any = [];
	ticketList: any = [];
	ezbcostingFilter: any = {
		monthyear: ''
	};
	reminderFilter: any = {
		type: '',
		searchtxt: ''
	}
	constructor(private service: DashboardServices,
		private events: Events,
		private toastr: NotificationService,
		private hrServices: hrServices,
		private hrService: hrServices,
		public signalrHUB: ChatSignalr,
		private event: Events,
		private commonservice: CommonServices,
		private cd: ChangeDetectorRef,
		private datePipe: DatePipe,
		private router: Router,
		private loader: NgxUiLoaderService,
		private schedulerService: SchedulerServices,
		private modalService: NgbModal) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.initializeData();
		// const currentDate = moment(this.userdata.expiry_date);
		const currentDate = moment('2023-05-25');
		const startDate = currentDate.format('YYYY-MM-DD');
		const endDate = datePipe.transform(new Date(), 'yyy-MM-dd');
		const differenceInDays = this.getDaysDifference(new Date(startDate), new Date(endDate));
		this.logoutEvent = this.event.subscribe('event:logout', (event: any) => {
			environment.production ? '' : console.log('event status' + event.data.from);
			if (localStorage.getItem(this.appname + 'userprofile')) {
				this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
				this.initializeData();
				this.ngOnInit();
			}
		});
	}
	ngOnDestroy() {
		this.logoutEvent.unsubscribe();
		this.modalService.dismissAll();
		window.sessionStorage.clear();
		sessionStorage.clear();
	}
	initializeData() {
		this.dashboard = {
			tiles: false,
			salessummary: false,
			purchasesummary: false,
			recenttransactions: false,
			reminders: false,
			cloking: false,
			leaves: false,
			worldclok: false,
			ticketsummary: false,
			tasksummary: false,
			snagsummary: false,
			ezbcosting: false
		};
		this.getDashboardMenus();
		var d = new Date();
		var n = new Date();
		var c = new Date();
		var pastYear = d.getFullYear() - 1;
		var nextYear = n.getFullYear() + 1;
		var currentyear = c.getFullYear();
		d.setFullYear(pastYear);
		n.setFullYear(nextYear);
		c.setFullYear(currentyear);
		this.leaveYearList.push(this.datePipe.transform(d, 'YYYY'));
		this.leaveYearList.push(this.datePipe.transform(c, 'YYYY'));
		this.leaveYearList.push(this.datePipe.transform(n, 'YYYY'));
		environment.production ? '' : console.log('Year List', this.leaveYearList);
		Object.assign(this, { doughnutData, pieData });
		this.format = this.userdata.date_format
		this.companyLogo += this.userdata.logo;
		this.settings.pager.perPage = Number(this.userdata.grid_page_size ? this.userdata.grid_page_size : 15);
		MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();

		// var dt = new Date()
		// var dt = moment.tz(moment(), 'Asia/Karachi').format('DD/MM/YYYY HH:mm')
		environment.production ? '' : console.log('UserData :', this.userdata);

		this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		if (this.userprofile.dashboard_sections != null) {
			var dashboardSections = this.userprofile.dashboard_sections.split(',');
			this.counts = [];
			this.sales_trend = [];
			this.purchase_trend = [];
			this.transactions = [];
			this.reminderList = [];
			this.timing = [];
			this.employeeLeaveList = [];
			this.timeZoneList = [];
			this.ticketList = [];
			this.taskList = [];
			this.snagList = [];
			this.InvoiceTrendArr = [];

			dashboardSections.forEach(element => {
				if (element == 'tiles') {
					this.dashboard.tiles = true;
					this.getCountList();
				} else if (element == 'salessummary') {
					this.dashboard.salessummary = true;
					this.getSalesSummary()
				} else if (element == 'purchasesummary') {
					this.dashboard.purchasesummary = true;
					this.getPurchaseSummary()
				} else if (element == 'recenttransactions') {
					this.dashboard.recenttransactions = true;
					this.getTransactionList();
				} else if (element == 'reminders') {
					this.dashboard.reminders = true;
					this.getReminderType();
					this.getDashboardReminders();
				} else if (element == 'clockin') {
					this.dashboard.clockin = true;
					this.getshiftDetails();
					this.getOfficeClockingList();
				} else if (element == 'leaves') {
					this.dashboard.leaves = true;
					this.getEmployeeLeaveList()
				} else if (element == 'worldclok') {
					this.dashboard.worldclok = true;
					this.getWorldClockList()
				} else if (element == 'ticketsummary') {
					this.dashboard.ticketsummary = true;
					this.getTicketSummary()
				} else if (element == 'tasksummary') {
					this.dashboard.tasksummary = true;
					this.getTaskSummary()
				} else if (element == 'snagsummary') {
					this.dashboard.snagsummary = true;
					this.getSnagSummary()
				} else if (element == 'ezbcosting') {
					this.getHomeEzbCosting()
					this.dashboard.ezbcosting = true;
				}
			});
		}
	}
	ngAfterViewInit() {
	}

	animateAnalogClock(hour, minute, second, index, country, date) {
		this.hourHandStyle = { transform: `translate3d(-50%, 0, 0) rotate(${(hour * 30) + (minute * 0.5) + (second * (0.5 / 60))}deg)` };
		this.minuteHandStyle = { transform: `translate3d(-50%, 0, 0) rotate(${(minute * 6) + (second * 0.1)}deg)` };
		this.secondHandStyle = { transform: `translate3d(-50%, 0, 0) rotate(${second * 6}deg)` };
		this.formatedList[index] = {
			hourHandStyle: this.hourHandStyle,
			minuteHandStyle: this.minuteHandStyle,
			secondHandStyle: this.secondHandStyle,
			hour: hour,
			minute: minute,
			second: second,
			country: country,
			date: date
		};
	}

	closepopup() {
		this.modalService.dismissAll();
	}

	getTime() {
		this.timeZoneList.forEach((element, index) => {
			return setInterval(() => {
				var date = moment.tz(moment(), element.zone).format('DD-MM-yyyy')
				var hour = moment.tz(moment(), element.zone).format('HH')
				var minute = moment.tz(moment(), element.zone).format('mm')
				var second = moment.tz(moment(), element.zone).format('ss')
				this.animateAnalogClock(hour, minute, second, index, element.country, date);
			}, 1000);
		});
	}

	formatclock(num: number) {
		return (num + '').length === 1 ? '0' + num : num + '';
	}

	formatclock12Hrs(clock: any) {
		if (clock.country == '') {
			return;
		}
		var hour = (clock.hour + '').length === 1 ? '0' + clock.hour : clock.hour + '';
		var minute = (clock.minute + '').length === 1 ? '0' + clock.minute : clock.minute + '';
		var second = (clock.second + '').length === 1 ? '0' + clock.second : clock.second + '';
		var hrTime = this.datePipe.transform(new Date(), 'yyy-MM-dd') + ' ' + hour + ':' + minute + ':' + second;
		var momentTime = moment(hrTime).format('hh:mm a');
		// return this.datePipe.transform(new Date(hrTime), 'hh:mm a');
		return momentTime;
	}

	openLeaveModal(content) {
		this.getleaveStatus();
		this.empLeave = {
			// year: '25-01-2023'
			finyear: this.datePipe.transform(new Date(), 'YYYY'),
			halfday1: false,
			halfday2: false,

		}
		this.getLeavesType();
		this.totalLeaves = 0
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
	dropdowntoggle2() {
		this.isShow2 = !this.isShow2;
	}
	toggleCheckBtn() {
		this.ischeckIn = !this.ischeckIn;
	}

	public settings = {
		actions: false,
		hideSubHeader: false,
		perPage: 25,
		pager: {
			display: true,
			perPage: 0
		},
		columns: {
			txndate: {
				title: 'Date',
			},
			customer: {
				title: 'Payee'
			},
			num: {
				title: 'Ref#',
				type: 'html',
			},
			amt: {
				title: 'Amount',
			},
			status: {
				title: 'Status',
				type: 'html',
				valuePrepareFunction: (status) => {
					return `<span class="badge" title="${status}">${status}</span>`
				}

			}
		},
	};

	counts: any = {
		cust: 0,
		docs: 0,
		expense: 0,
		inv: 0,
		kits: 0,
		leads: 0,
		licenses: 0,
		orders: 0,
		pos: 0,
		prods: 0,
		proj: 0,
		qte: 0,
		salesrequests: 0,
		suppliers: 0,
		tasks: 0,
		tickets: 0,
		purchaserequests: 0,
		assets: 0,
		employees: 0,
		schedules: 0
	}


	// events
	public chartClicked(e: any): void {

	}
	public chartHovered(e: any): void {

	}

	ngOnInit() {
		var monthArr = [];
		for (let i = 11; i >= 0; i--) {
			let now = new Date();
			let newdate = now.setMonth(now.getMonth() - i);
			if (i == 0) {
				this.ezbcostingFilter.monthyear = this.formatDate(newdate);
			}
			// environment.production ? '' : console.log(this.formatDate(newdate));
			monthArr.push(this.formatDate(newdate));
			// environment.production ? '' : console.log('month list', this.lastMonth);
		}
		this.lastMonth = monthArr.reverse();
		this.dropdownSettings = {
			singleSelection: false,
			idField: 'Value',
			textField: 'Text',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 2,
			allowSearchFilter: true
		}

		//#region Grid Settings
		if (this.userdata.grid_inline_filter === false) {
			this.settings.hideSubHeader = true;
			environment.production ? '' : console.log('settings.hideSubHeader :', this.settings.hideSubHeader);
		} else {
			this.settings.hideSubHeader = false;
			environment.production ? '' : console.log('settings.hideSubHeader :', this.settings.hideSubHeader);
		}
		this.settings.perPage = this.userdata.grid_page_size;
		environment.production ? '' : console.log('settings.perPage :', this.settings.perPage);
		//#region Grid Settings
		var editDataForHeader = '';
		this.commonservice.headerEditItemData.next(editDataForHeader); //end '
		this.settings.perPage = this.userdata.grid_page_size;
		this.cd.detectChanges();
		//Uncomment this for connecting signalr
		// this.signalrHUB.connect(); //Uncomment it for signalr connection
		// setTimeout(() => { this.signalrHUB.subscribeListners(); }, 2000);
	}

	dropdowntoggle() {
		this.isShow = !this.isShow;
	}

	getReminderType() {
		this.commonservice.getComboEnums('remindertype').subscribe(data => {
			this.reminderTypes = data;
			environment.production ? '' : console.log('Reminder Types', this.reminderTypes);
		})
	}
	deleteReminder(id) {
		Swal.fire({
			title: 'Do you want to delete?',
			// text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.deleteReminder(id).subscribe(res => {
					environment.production ? '' : console.log('delete response ', res);
					this.loader.stop();
					if (res != null) {
						this.toastr.Success('Reminder deleted')
						this.getDashboardReminders()
					} else {
						this.toastr.Error('Reminder not deleted');
					}
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While loading get purchase req action ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}
	getRecentTrType() {
		this.service.getRecentTransactionType().subscribe(data => {
			this.recentTrTypeList = data;
			environment.production ? '' : console.log('recent tr type list', this.recentTrTypeList);
		})
	}

	getDepartmentsList() {
		this.hrService.getEmployeeDeptList().subscribe(data => {
			this.departmentlist = data;
		})
	}
	getTechnicians() {
		this.commonservice.getAssigntoForTask().subscribe((res: any) => {
			this.technicianList = res;
			this.setEmployee();
		});
	}
	setEmployee() {
		var techArray = [];
		this.selectedAssignTo = [];
		if (this.reminder.assign_to) {
			techArray = this.reminder.assign_to.split(',');
		}
		this.technicianList.forEach(res => {
			res.checked = false;
		})
		techArray.forEach(tech => {
			this.technicianList.forEach(res => {
				if (tech == res.Value) {
					res.checked = true;
					this.selectedAssignTo.push(res);
				}
			})
		})
	}
	onItemSelect(item: any) {
		this.reminder.assign_to = [];
		let arrayData = [];
		this.selectedAssignTo.forEach(data => {
			arrayData.push('' + data.Value);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.reminder.assign_to = this.stringData;
		environment.production ? '' : console.log(this.reminder.assign_to);
	}
	onSelectAll(items: any) {
		environment.production ? '' : console.log(this.reminder.assign_to);
	}

	onItemDeSelect($event) {
		let arrayData = [];
		this.selectedAssignTo.forEach(data => {
			arrayData.push('' + data.Value);
		})
		arrayData = arrayData.filter((c, index) => {
			return arrayData.indexOf(c) === index;
		});
		this.stringData = arrayData.toString();
		this.reminder.assign_to = this.stringData;
		environment.production ? '' : console.log(this.reminder.assign_to);
	}

	// onDropDownClose() {
	// 	let filteredArray = [];
	// 	// filteredArray = this.myArray.filter(val => !this.removedArr.includes(val));
	// 	this.myArray.forEach(data => {
	// 		filteredArray = this.myArray.filter()
	// 	})
	// 	filteredArray = this.myArray.filter(data => data != this.removedArr);
	// 	console.log(filteredArray)
	// }

	searchTransactions(value) {
		this.transactions = []
		var searchText = value.toLowerCase();
		this.transactions = this.resTransactions.filter(f => {
			if (f.customer.toLowerCase().includes(searchText))
				return f.customer.toLowerCase().includes(searchText)
		});

	}


	clockingModal(content, i) {
		this.getshiftReasons(i);
		if (i == 'B') {
			this.buttonType = 'break';
			if (this.brkStarted && this.shiftStarted) {
				this.pause();
				return;
			}
		} else {
			this.buttonType = 'checkin';
		}
		setTimeout(() => {
			this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {

			});
		}, 1000)
	}
	searchReminder() {
		this.filteredReminders = [];
		this.reminderList.forEach(element => {
			if ((this.reminderFilter.searchtext != null ? element.title.toLowerCase().indexOf(this.reminderFilter.searchtext) > -1 : true) && (this.reminderFilter.type == '' ? true : element.type == this.reminderFilter.type)) {
				this.filteredReminders.push(element);
			}
		});
	}

	refresh(): void {
		window.location.reload();
	}
	getDashboardReminders() {
		this.loader.start();
		this.service.getHomeReminders().subscribe(res => {
			this.loader.stop();
			this.reminderList = res;
			this.filteredReminders = res;
			this.events.publish('event:dashboard', {
				data: {
					'from': 'dashboard',
					'reminders': this.reminderList ? this.reminderList : []
				}
			});
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading bin list ', err);
		});
	}
	getDashboardMenus() {
		this.loader.start();
		this.service.getHomeMenu().subscribe(res => {
			this.loader.stop();
			this.menuItems = res;

			// const nestedArray = buildNestedStructure(this.menuItems);
			// console.log(JSON.stringify(nestedArray, null, 2));

			localStorage.setItem(this.appname + 'menu', JSON.stringify(this.menuItems));
			this.events.publish('dashboard:menu', {});

			environment.production ? '' : console.log(' dashboard menu data ', this.dashboardData);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading bin list ', err);
		});
	}

	// getDashBoardData() {
	// 	this.loader.start();
	// 	this.service.getHomeData().subscribe(res => {
	// 		this.loader.stop();
	// 		this.dashboardData = res;
	// 		// added code for menu list
	// 		this.menuItems = this.dashboardData.menus;
	// 		environment.production ? '' : console.log('menu', this.menuItems);
	// 		localStorage.setItem(this.appname + 'menu', JSON.stringify(this.menuItems));
	// 		this.events.publish('dashboard:menu', {});
	// 		this.counts = this.dashboardData.counts ? this.dashboardData.counts : {};
	// 		this.transactions = this.dashboardData.lstRecentTransactions;
	// 		this.stickeys = this.dashboardData.lstnotes;
	// 		environment.production ? '' : console.log(' dashboard data ', this.dashboardData);
	// 	}, err => {
	// 		this.loader.stop();
	// 		environment.production ? '' : console.log('Error : While loading bin list ', err);
	// 	});
	// }
	openReminderModal(content, id) {
		this.getTechnicians();
		this.getDepartmentsList();
		if (id == '') {
			this.reminder = {
				dept: '',
				type: ''
			}
		} else {
			this.loader.start()
			this.service.getReminderById(id).subscribe(res => {
				this.loader.stop();
				if (res != null) {
					this.reminder = res;
					this.setEmployee();
				}
			}, err => {
				this.loader.stop();
			})
		}
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
		}, (reason) => {
		});
	}

	closeModal() {
		this.reminder = {};
		this.selectedAssignTo = [];
		this.modalService.dismissAll()
	}

	getTransactionTypes() {
		this.service.getTransactionType().subscribe(res => {
			this.TransTypes = res;
			environment.production ? '' : console.log('TransTypes', res);
		})
	}

	// The master checkbox will check/ uncheck all items
	checkUncheckAll() {
		for (var i = 0; i < this.reminderList.length; i++) {
			this.reminderList[i].isSelected = this.masterSelected;
		}
		this.getCheckedItemList();
	}

	// Check All Checkbox Checked
	isAllSelected() {
		this.masterSelected = this.reminderList.every(function (item: any) {
			return item.isSelected == true;
		})
		this.getCheckedItemList();
	}

	// Get List of Checked Items
	getCheckedItemList() {
		this.checkedList = [];
		for (var i = 0; i < this.reminderList.length; i++) {
			if (this.reminderList[i].isSelected)
				this.checkedList.push(this.reminderList[i]);
		}
		this.checkedList = JSON.stringify(this.checkedList);
	}
	submitReminder() {
		if (this.reminder.title == undefined || this.reminder.title == null) {
			this.toastr.Error('Please enter title');
			return;
		}
		if (this.reminder.start_date == undefined || this.reminder.start_date == null) {
			this.toastr.Error('Please select start date');
			return;
		}
		if (this.reminder.end_date == undefined || this.reminder.end_date == null) {
			this.toastr.Error('Please select end date');
			return;
		}
		if (this.reminder.type == undefined || this.reminder.type == null || this.reminder.type == '') {
			this.toastr.Error('Please select type');
			return;
		}
		this.loader.start();
		var data;
		if (this.reminder.id) {
			data = {
				id: this.reminder.id,
				updateby: this.userprofile.email,
				updatedate: this.datePipe.transform(new Date(), this.userprofile.postdatetimeformat),
				title: this.reminder.title,
				description: this.reminder.description,
				active: true,
				mark_read: false,
				companyid: this.userprofile.companyid,
				start_date: this.datePipe.transform(this.reminder.start_date, this.userprofile.postdatetimeformat),
				end_date: this.datePipe.transform(this.reminder.end_date, this.userprofile.postdatetimeformat),
				due_date: this.datePipe.transform(this.reminder.due_date, this.userprofile.postdatetimeformat),
				type: this.reminder.type,
				pic: this.reminder.pic,
				add_to_calender: 0,
			}
		} else {
			data = {
				id: null,
				createby: this.userprofile.email,
				createdate: this.datePipe.transform(new Date(), this.userprofile.postdatetimeformat),
				title: this.reminder.title,
				description: this.reminder.description,
				active: true,
				companyid: this.userprofile.companyid,
				mark_read: false,
				start_date: this.datePipe.transform(this.reminder.start_date, this.userprofile.postdatetimeformat),
				end_date: this.datePipe.transform(this.reminder.end_date, this.userprofile.postdatetimeformat),
				due_date: this.datePipe.transform(this.reminder.due_date, this.userprofile.postdatetimeformat),
				type: this.reminder.type,
				pic: '',
				add_to_calender: 0,
				dept: this.reminder.dept,
			}
		}
		data.assign_to = this.reminder.assign_to ? this.reminder.assign_to : '';
		environment.production ? '' : console.log(data);
		this.service.postReminder(data).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.reminder = {};
				this.selectedAssignTo = [];
				this.modalService.dismissAll();
				this.getDashboardReminders();
			}
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading bin list ', err);
		})
	}

	//#region Sorting Table Header
	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr('status');
	}

	sortArr(colName: any) {
		this.transactions.sort((a, b) => {
			if (a[colName] != null) {
				a = a[colName].toLowerCase();
			}
			if (b[colName] != null) {
				b = b[colName].toLowerCase();
			}
			return a.localeCompare(b) * this.sortDir;
		});
	}
	//#endregion Sorting Table Header

	//Employee Leave
	getleaveStatus() {
		this.commonservice.getComboEnums('leavestatus').subscribe(res => {
			environment.production ? '' : console.log('LeaveStatus', res)
			this.LeavesStatus = res;
		})
	}
	getLeavesType() {
		this.hrServices.getLeaveConfig(this.empLeave.finyear).subscribe(res => {
			environment.production ? '' : console.log('LeavesType', res)
			this.LeavesType = res;
		})
	}
	getEmployeeLeaveList() {
		this.service.getHomeEmpLeaves().subscribe(res => {
			this.employeeLeaveList = res;
		}, err => {
			this.loader.stop();
		});
	}
	onChangeType(id) {
		environment.production ? '' : console.log('selectedx date', id);
		let obj = this.LeavesType.find(x => x.Value == id);
		environment.production ? '' : console.log('selected data', obj);
		this.empLeave.type = obj.Text;

	}
	goToView(item, LeaveModal) {
		this.leaveMode = 'view';
		this.getleaveStatus();
		environment.production ? '' : console.log('edited data', item);
		this.service.getLeavesById(item.id).subscribe(res => {
			this.empLeave = res;
			setTimeout(() => {
				this.getLeavesType();

			}, 1000);
			this.totalLeaves = this.empLeave.tlp;
			// this.checkDate()
			environment.production ? '' : console.log('get Employee Leave List', this.employeeLeaveList)
		})
		this.modalService.open(LeaveModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
	goToEdit(item, LeaveModal) {
		this.leaveMode = 'edit';
		this.getleaveStatus();
		environment.production ? '' : console.log('edited data', item);
		this.service.getLeavesById(item.id).subscribe(res => {
			this.empLeave = res;
			setTimeout(() => {
				this.getLeavesType();

			}, 1000);
			this.totalLeaves = this.empLeave.tlp;
			// this.checkDate()
			environment.production ? '' : console.log('get Employee Leave List', this.employeeLeaveList)
		})
		this.modalService.open(LeaveModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', windowClass: 'index-class', backdropClass: 'index-backdrop' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
	saveEmployeeLeave() {
		if (this.empLeave.id) {
			this.empLeave.createby = this.empLeave.createby;
			this.empLeave.createdate = this.datePipe.transform(this.empLeave.createdate, this.userdata.postdatetimeformat);
			this.empLeave.updateby = this.userdata.email;
			this.empLeave.updatedate = this.datePipe.transform(new Date(), this.userdata.postdatetimeformat);
		} else {
			this.empLeave.createby = this.userprofile.email;
			this.empLeave.createdate = this.datePipe.transform(new Date(), this.userdata.postdatetimeformat);

		}
		this.empLeave.fromdate = this.datePipe.transform(this.empLeave.fromdate, this.userdata.postdatetimeformat);
		this.empLeave.todate = this.datePipe.transform(this.empLeave.todate, this.userdata.postdatetimeformat);
		this.empLeave.companyid = this.userprofile.companyid;
		this.empLeave.empid = this.userprofile.empid;
		this.empLeave.tlp = this.totalLeaves;

		this.empLeave.status = 'submitted';
		environment.production ? '' : console.log('leave data', this.empLeave);
		//validation for end date
		var startDate = this.datePipe.transform(this.empLeave.fromdate, ('dd/MM/yyyy'));
		var endDate = this.datePipe.transform(this.empLeave.todate, ('dd/MM/yyyy'));
		if (endDate == null) {
			this.toastr.Error("Please enter proper date")
			return false
		}
		var regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
		if (parseInt(endDate.replace(regExp, "$3$2$1")) >= parseInt(startDate.replace(regExp, "$3$2$1"))) {
			environment.production ? '' : console.log('start date is greater than end date');
		} else {
			this.empLeave.todate = null
			this.totalLeaves = 0;
			this.toastr.Error("End date should be greater than start date")
			return false
		}
		this.loader.start();
		this.service.postEmployeeLeave(this.empLeave).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.getEmployeeLeaveList();
				this.modalService.dismissAll();
			}
		})
	}
	selectYear() {
		this.getLeavesType()
	}
	onChange(deviceValue) {
		environment.production ? '' : console.log(deviceValue);
		this.getLeavesType()
	}
	updateCalcs() {
		if (this.empLeave.fromdate != null && this.empLeave.todate != null) {
			var a = moment(this.empLeave.fromdate, "YYYY-MM-DD");
			var b = moment(this.empLeave.todate, "YYYY-MM-DD");
			this.totalLeaves = moment.duration(b.diff(a)).asDays();

			if (this.datePipe.transform(this.empLeave.fromdate, ('dd/MM/yyyy')) == this.datePipe.transform(this.empLeave.todate, ('dd/MM/yyyy'))) {
				this.totalLeaves += 1;
			}
			else {
				this.totalLeaves += 1;
			}
			if (this.empLeave.halfday1 == true) {
				var halfday1 = true;
				this.totalLeaves = parseFloat(this.totalLeaves) - 0.5;
			}
			if (this.empLeave.halfday2 == true) {
				var halfday2 = true;
				this.totalLeaves = parseFloat(this.totalLeaves) - 0.5;
			}
			if (this.totalLeaves) {
				this.checkDate()

			}
		}
	}

	getshiftDetails() {
		this.totalShiftTime = 0;
		this.breakTime = 0;
		this.timing = [];
		// this.brkStarted = JSON.parse(localStorage.getItem(this.appName+'brkStarted')); end_time, start_time
		this.loader.start();
		this.service.getShiftDetails().subscribe(data => {
			this.loader.stop();
			var totalShiftTimeinMin = 0
			this.shiftDetails = data;
			if (this.shiftDetails != null) {
				this.shiftStarted = true;
				this.processCalTotalHrs();
			} else {
				this.shiftDetails = {
					start_time: null,
					end_time: null,
					timings: []
				}
			}
			if (this.shiftDetails.start_time != null && this.shiftDetails.end_time == null) {
				this.isBreakOption = true;
				this.isEndShift = true;
				this.startShiftOption = false;
			} if (this.shiftDetails.start_time != null && this.shiftDetails.end_time != null) {
				this.startShiftOption = false;
			}
			if (this.shiftDetails.start_time == null && this.shiftDetails.end_time == null) {
				this.startShiftOption = true;
				this.isBreakOption = false;
				this.isEndShift = false;
			}

			// ************************************
			//   this.loader.start();
			this.timing = this.shiftDetails.timings ? this.shiftDetails.timings : [];
			environment.production ? '' : console.log('Timing array : ', this.timing);
			if (this.shiftDetails.start_time != null) {

				this.startMainTimeinhours = moment(this.shiftDetails.start_time).format('HH');
				this.startMainMin = moment(this.shiftDetails.start_time).format('mm');
				environment.production ? '' : console.log('hr nd time', this.startMainTimeinhours, this.startMainMin);

				// var startMainTimeinhours = new Date(this.shiftDetails.start_time).getHours();
				// var this.startMainMin = new Date(this.shiftDetails.start_time).getMinutes();
				this.totalMainMin = (Number(this.startMainTimeinhours) * 60) + Number(this.startMainMin)

				this.endTimeinHr = moment().format('HH');
				this.endTimeInMin = moment().format('mm');

				var totalEndTimeinMin = (Number(this.endTimeinHr) * 60) + Number(this.endTimeInMin)
				var diffTime = totalEndTimeinMin - this.totalMainMin;
				this.totalShiftTime = diffTime;
				//added by mayur
				if (this.timing.length == 0) {
					if (this.totalShiftTime > 60) {
						var hours = Math.floor(this.totalShiftTime / 60);
						var minutes = this.totalShiftTime % 60;
						if (minutes < 10) {
							this.totalShiftTime = hours + ':' + '0' + minutes
						} else {
							this.totalShiftTime = hours + ':' + minutes
						}
					} else if (this.totalShiftTime < 60 && this.totalShiftTime > 10) {
						this.totalShiftTime = '00' + ':' + this.totalShiftTime
					} else {
						this.totalShiftTime = '00' + ':' + '0' + this.totalShiftTime
					}
				}
				// return false;
			}
			if (this.timing == null) {
				this.brkStarted = false;
				localStorage.setItem(this.appname + 'brkStarted', 'false');
				return false;
			} else if (this.timing.length > 0) {

				for (var i = 0; i < this.timing.length; i++) {
					//create date format          
					this.timeStart = moment(this.timing[i].start_time).format('HH');
					this.timing[i].startHrs = moment(this.timing[i].start_time).format('HH');
					this.timing[i].startMin = moment(this.timing[i].start_time).format('mm');

					//  new Date(this.timing[i].start_time).getHours();

					this.timestartmin = moment(this.timing[i].start_time).format('mm');
					this.totalStartTime = (Number(this.timeStart) * 60) + Number(this.timestartmin)

					if (this.timing[i].end_time == null) {
						this.timing[i].endHrs = '--'
						this.timeEnd = moment().format('HH');
						var timeinmin = moment().format('mm');
						this.totelEndTime = (Number(this.timeEnd) * 60) + Number(timeinmin)
						var diffTime = this.totelEndTime - this.totalStartTime;
					} else {
						this.timing[i].endHrs = moment(this.timing[i].end_time).format('HH');
						this.timing[i].endMin = moment(this.timing[i].end_time).format('mm');
						this.timeEnd = moment(this.timing[i].end_time).format('HH');
						var timeinmin = moment(this.timing[i].end_time).format('mm');
						this.totelEndTime = (Number(this.timeEnd) * 60) + Number(timeinmin)
						var diffTime = this.totelEndTime - this.totalStartTime;
					}


					totalShiftTimeinMin += diffTime;
					if (i == this.timing.length - 1) {
						if (totalShiftTimeinMin > 60) {
							this.totalTimeinEntries = totalShiftTimeinMin / 60

						}
						this.totalShiftTime -= totalShiftTimeinMin;
						this.breakTime = totalShiftTimeinMin;
						if (this.breakTime > 60) {
							// this.breakTime = (this.breakTime / 60).toFixed(2);
							var hours = Math.floor(this.breakTime / 60);
							var minutes = this.breakTime % 60;
							if (minutes < 10) {
								this.breakTime = hours + ':' + '0' + minutes

							} else {
								this.breakTime = hours + ':' + minutes

							}
						}
						else if (this.breakTime < 60 && this.breakTime > 10) {
							this.breakTime = '00' + ':' + this.breakTime
						} else {
							this.breakTime = '00' + ':' + '0' + this.breakTime
						}
						if (this.totalShiftTime > 60) {
							var hours = Math.floor(this.totalShiftTime / 60);
							var minutes = this.totalShiftTime % 60;
							if (minutes < 10) {
								this.totalShiftTime = hours + ':' + '0' + minutes

							} else {
								this.totalShiftTime = hours + ':' + minutes

							}
						} else if (this.totalShiftTime < 60 && this.totalShiftTime > 10) {
							this.totalShiftTime = '00' + ':' + this.totalShiftTime
						} else {
							this.totalShiftTime = '00' + ':' + '0' + this.totalShiftTime

						}
						environment.production ? '' : console.log('total shift time in minutes', this.totalShiftTime);

					}
				}
				for (var j = 0; j < this.timing.length; j++) {
					if (this.timing.length > 1) {
						var lasttime = this.timing[j].end_time;
						environment.production ? '' : console.log('end t', lasttime);
						var newstart = this.timing[j].start_time;
						environment.production ? '' : console.log('start t', newstart);
					}
				}
				environment.production ? '' : console.log(' last item of timing array is : ', this.timing[this.timing.length - 1]);
				if (this.timing[this.timing.length - 1].type === 'break-start') {
					environment.production ? '' : console.log('new Breakstarted code executed :)');
					this.brkStarted = true;
					localStorage.setItem(this.appname + 'brkStarted', 'true');
				} else {
					this.brkStarted = false;
					localStorage.setItem(this.appname + 'brkStarted', 'false');
				}
			} else {
				this.brkStarted = false;
				localStorage.setItem(this.appname + 'brkStarted', 'false');
			}

			// **********************************

			environment.production ? '' : console.log('Shift details data : ', this.shiftDetails);
			if (this.shiftDetails.shift_id == 0) {
				this.shiftStarted = false;
				localStorage.setItem(this.appname + 'shiftStarted', 'false');

			}
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('error : ', err);
		});

	}

	processCalTotalHrs() {
		this.totalWorkHrs = [];
		this.totalBreakHrs = [];
		this.totalWorkTime = '00:00';
		this.totalBreakTime = '00:00';
		const dateFormat = "YYYY-MM-DD HH:mm:ss";
		if (this.shiftDetails.shift_id > 0) {
			var cDate = new Date();
			this.calculateHrsInTwoDte('work', this.shiftDetails.start_time, moment(cDate).format(dateFormat));
			if (this.shiftDetails.timings != null && this.shiftDetails.timings.length > 0) {
				this.shiftDetails.timings.forEach((element, index) => {
					if (element.end_time != null) {
						this.calculateHrsInTwoDte('break', element.start_time, element.end_time);
					} else {
						var cDate = new Date();
						this.calculateHrsInTwoDte('break', element.start_time, moment(cDate).format(dateFormat));
					}
					if (index == this.shiftDetails.timings.length - 1) {
						var totalWorkHrsMinutes = (this.totalWorkHrs[0].hours * 60) + this.totalWorkHrs[0].minutes;
						var totalBreakHrsMinutes = 0;
						this.totalBreakHrs.forEach(brk => {
							totalBreakHrsMinutes += (brk.hours * 60) + brk.minutes;
						})
						var actualWorkingHrs = totalWorkHrsMinutes - totalBreakHrsMinutes;
						this.totalWorkTime = actualWorkingHrs / 60;
						const minutes = actualWorkingHrs % 60;
						const hrs = '' + Math.floor(actualWorkingHrs / 60);
						if (hrs.length == 1) {
							this.totalWorkTime = '0' + hrs;
						} else {
							this.totalWorkTime = hrs;
						}
						var hrsString = '' + parseInt('' + minutes);
						if (hrsString.length == 1) {
							hrsString = '0' + hrsString;
						}
						this.totalWorkTime += ':' + hrsString;
					}
				});
			}
		}
	}
	calculateHrsInTwoDte(from, startDte, endDte) {
		//Works for iOS
		// var timeStart = new Date((startDte).replace(/-/g, "/")).getTime().valueOf();
		// var timeEnd = new Date((endDte).replace(/-/g, "/")).getTime().valueOf();
		var timeStart = new Date(startDte).getTime();
		var timeEnd = new Date(endDte).getTime();
		var hourDiff = timeEnd - timeStart; //in ms
		var secDiff = hourDiff / 1000; //in s
		var minDiff = hourDiff / 60 / 1000; //in minutes
		var hDiff = hourDiff / 3600 / 1000; //in hours
		var hours = Math.floor(hDiff);
		var minutes = minDiff - 60 * hours;
		if (from == 'break') {
			this.totalBreakHrs.push({
				hours: Math.floor(hDiff),
				minutes: minutes
			})
		} else {
			this.totalWorkHrs.push({
				hours: Math.floor(hDiff),
				minutes: minutes
			})
		}
		this.calculateTotalHrs();
	}
	calculateTotalHrs() {
		var totalWrkHrs = 0, totalWrkMnt = 0;
		var totalBrkHrs = 0, totalBrkMnt = 0;
		if (this.totalWorkHrs.length > 0) {
			this.totalWorkHrs.forEach((element) => {
				totalWrkHrs += element.hours;
				totalWrkMnt += element.minutes;
			});
		}
		if (this.totalBreakHrs.length > 0) {
			this.totalBreakHrs.forEach((element) => {
				totalBrkHrs += element.hours;
				totalBrkMnt += element.minutes;
			});
		}
		totalWrkHrs += totalWrkMnt / 60;
		this.shiftDetails.aproxhrsspend = totalWrkHrs.toFixed(4)
		totalWrkMnt = totalWrkMnt % 60;
		totalBrkHrs += totalBrkMnt / 60;
		totalBrkMnt = totalBrkMnt % 60;
		var workHrs = ('' + totalWrkHrs).substring(0, 2).replace('.', '');
		var workMin = ('' + totalWrkMnt).substring(0, 2).replace('.', '');
		if (workHrs.length == 1) {
			this.totalWorkTime = '0' + workHrs;
		} else {
			this.totalWorkTime = workHrs;
		}
		if (workMin.length == 1) {
			this.totalWorkTime = this.totalWorkTime + ':' + '0' + workMin;
		} else {
			this.totalWorkTime = this.totalWorkTime + ':' + workMin;
		}
		var breakHrs = ('' + totalBrkHrs).substring(0, 2).replace('.', '');
		var breakMin = ('' + totalBrkMnt).substring(0, 2).replace('.', '');
		if (breakHrs.length == 1) {
			this.totalBreakTime = '0' + breakHrs;
		} else {
			this.totalBreakTime = breakHrs;
		}
		if (breakMin.length == 1) {
			this.totalBreakTime = this.totalBreakTime + ':' + '0' + breakMin;
		} else {
			this.totalBreakTime = this.totalBreakTime + ':' + breakMin;
		}
	}

	getReasonId(n) {
		if (n.checked == true) {
			this.reasonId = n.id;
		}
	}
	startShift() {
		this.loader.start();
		this.processCalTotalHrs()
		let objShift = {};
		if (this.shiftDetails != null && this.shiftDetails.shift_id != undefined && this.shiftDetails.shift_id > 0) {
			objShift = {
				"id": this.shiftDetails.shift_id,
				"employee_id": this.userprofile.empid,
				"start_time": this.shiftDetails.start_time,
				"dow": this.datePipe.transform(this.shiftDetails.dow, this.userdata.postdatetimeformat),
				"end_time": this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
				"status": "submitted",
				"companyid": this.userprofile.companyid,
				"createby": this.shiftDetails.createby,
				"createdate": this.datePipe.transform(this.shiftDetails.createdate, this.userprofile.postdatetimeformat),
				"updateby": this.userprofile.email,
				"updatedate": this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
				"reasonid": this.reasonId ? this.reasonId : this.shiftDetails.reasonid,
				"other_reason": this.otherReason,
				"aproxhrsspend": this.shiftDetails.aproxhrsspend
			};
		} else {
			objShift = {
				// transporter_id: JSON.parse(localStorage.getItem(this.appName+'userprofile')).id,
				// transporter_vehicle_id: id,
				"companyid": this.userprofile.companyid,
				"start_time": this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
				"employee_id": this.userprofile.empid,
				"end_time": null,
				"status": 'submitted',
				"createby": this.userprofile.email,
				"createdate": this.datePipe.transform(new Date(), this.userprofile.postdatetimeformat),
				"reasonid": this.reasonId,
				"other_reason": this.otherReason,
			};
		}
		environment.production ? '' : console.log('Shift data!', JSON.stringify(objShift));
		this.service.toggleEmployeeShift(objShift).subscribe((resp: any) => {
			environment.production ? '' : console.log('Response from toggleshift start: ', resp);
			this.shiftDetails.shift_id == 0 ? Swal.fire('', 'Shift Started.', 'success') : Swal.fire('', 'Shift Ended.', 'success');
			this.loader.stop();
			this.getshiftDetails();
			environment.production ? '' : console.log('Shift started!');
			this.isToggleSwitch = !this.isToggleSwitch;
			this.otherReason = '';
			this.reasonId = null;
			this.modalService.dismissAll();
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('error : ', err);
			// this.presentAlert('Shift Updated.');
		});
	}
	getshiftReasons(type) {
		this.loader.start();
		this.service.getReasons(type).subscribe(res => {
			this.loader.stop();
			this.reasonsList = res
			environment.production ? '' : console.log('reasonsList', res)
		}, err => {
			this.loader.stop();
		})
	}
	pause() {
		if (this.brkStarted === true) {
			var objBreak = {
				reason: this.reason,
				id: this.timing[this.timing.length - 1].shifttime_id,
				employee_shift_id: this.shiftDetails.shift_id,
				start_time: this.timing[this.timing.length - 1].start_time,
				end_time: this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
				type: 'break-end',
				// deletedby: this.userprofile.email,
				updateby: this.userprofile.email,
				// status: 'submitted',
				companyid: this.userprofile.companyid,
				updatedate: this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
			};
			this.loader.start();
			this.service.shiftEmployeeBreak(objBreak).subscribe(resp => {
				this.loader.stop();
				this.modalService.dismissAll();
				environment.production ? '' : console.log('Add Break response : ', resp);
				Swal.fire('', 'Break Ended.', 'success');
				this.brkStarted = false;
				this.getshiftDetails();
			})
		} else {
			var objStart = {
				employee_shift_id: this.shiftDetails.shift_id,
				start_time: this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
				type: 'break-start',
				reasonid: this.reasonId,
				other_reason: this.otherReason,
				// deletedby: this.userprofile.email,
				createby: this.userprofile.email,
				companyid: this.userprofile.companyid,
				createdate: this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
			};
			this.loader.start();
			this.service.shiftEmployeeBreak(objStart).subscribe(resp => {
				this.loader.stop();
				this.brkStarted = true;
				this.modalService.dismissAll();
				Swal.fire('', 'Break Started.', 'success');
				environment.production ? '' : console.log('Add Break response : ', resp);
				this.getshiftDetails();
			}, err => {

			});
		}
	}
	closeCLockingModal() {
		this.brkStarted = false;
		this.shiftStarted = false
		this.getshiftDetails();
		this.modalService.dismissAll();
	}

	checkDate() {
		var startDate = this.datePipe.transform(this.empLeave.fromdate, ('dd/MM/yyyy'));
		var endDate = this.datePipe.transform(this.empLeave.todate, ('dd/MM/yyyy'));
		var regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
		if (parseInt(endDate.replace(regExp, "$3$2$1")) >= parseInt(startDate.replace(regExp, "$3$2$1"))) {
			environment.production ? '' : console.log('start date is greater than end date');
		} else {
			this.empLeave.todate = null
			this.totalLeaves = 0;
			this.toastr.Error("End date should be greater than start date")
			return false
		}
	}
	//Active Nave state
	setNavActive(item) {
	}

	// Click Toggle menu
	toggletNavActive(item) {
		if (!item.active) {
			this.menuItems.forEach(a => {
				if (this.menuItems.includes(item))
					a.active = false
				if (!a.children) return false
				a.children.forEach(b => {
					if (a.children.includes(item)) {
						b.active = false
					}
				})
			});
		}
		item.active = !item.active
	}

	//Fileupload
	readUrl(event: any) {
		if (event.target.files.length === 0)
			return;
		//Image upload validation
		var mimeType = event.target.files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			return;
		}
		// Image upload
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			this.url = reader.result;
		}
	}

	navigate(item) {
		if (item.type == 'link') {
			if (item.ang_root == '/login') {
				window.localStorage.clear();
				localStorage.clear();
				this.events.publish('event:logout', {
					data: {
						'from': 'header logout'
					}
				});
			}
			this.router.navigate([item.ang_root], { replaceUrl: true });
		}
	}
	plotSalesGraph() {
		this.salesTrend = [];
		var salesXArray = []
		var salesTrendArr = []
		this.loader.startLoader("loader-02")
		this.sales_trend ? salesXArray = Object.keys(this.sales_trend) : ''
		this.sales_trend ? salesTrendArr = Object.values(this.sales_trend) : ''

		if (salesTrendArr.length > 0) {
			salesTrendArr.forEach(element => {
				this.salesTrend.push(Number(element))
			});
		}

		setTimeout(() => {
			this.loader.stopLoader("loader-02")

			let chart = new Chart({
				chart: {
					type: 'column'
				},
				title: {
					text: ''
				},
				xAxis: {
					categories: salesXArray,
					crosshair: true
				},
				yAxis: {
					min: 0,
					title: {
						text: 'Amount'
					}
				},
				tooltip: {
					headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
					pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
						'<td style="padding:0">' + this.userdata.currency + '<b> {point.y:.2f}</b></td></tr>',
					footerFormat: '</table>',
					shared: true,
					useHTML: true
				},
				plotOptions: {
					column: {
						pointPadding: 0.2,
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							format: this.userdata.currency + ' ' + '{point.y:.2f}'
						}
					}
				},
				series: [{
					name: 'Sales',
					type: 'column',
					color: '#1e5598',
					data: this.salesTrend,

				}],
				responsive: {
					rules: [{
						condition: {
							maxHeight: 500
						},
						chartOptions: {
							legend: {
								enabled: false
							}
						}
					}]
				}
			});
			this.salesChart = chart;
		}, 1000);
	}
	plotPurchaseGraph() {
		this.purchaseTrend = [];
		var purchaseXArray = [];
		var purchaseTrendArr = []
		this.loader.startLoader("loader-02")
		this.purchase_trend ? purchaseXArray = Object.keys(this.purchase_trend) : ''
		this.purchase_trend ? purchaseTrendArr = Object.values(this.purchase_trend) : ''

		if (purchaseTrendArr.length > 0) {
			purchaseTrendArr.forEach(element => {
				this.purchaseTrend.push(Number(element))
			});
		}

		setTimeout(() => {
			this.loader.stopLoader("loader-02")
			let chart = new Chart({
				chart: {
					type: 'column'
				},
				title: {
					text: ''
				},
				xAxis: {
					categories: purchaseXArray,

					crosshair: true
				},
				yAxis: {
					min: 0,
					title: {
						text: 'Amount'
					}
				},
				tooltip: {
					headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
					pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
						'<td style="padding:0">' + this.userdata.currency + '<b> {point.y:.2f}</b></td></tr>',
					footerFormat: '</table>',
					shared: true,
					useHTML: true
				},
				plotOptions: {
					column: {
						pointPadding: 0.2,
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							format: this.userdata.currency + ' ' + '{point.y:.2f}'
						}
					}
				},
				series: [{
					name: 'Purchase',
					type: 'column',
					color: '#f44336',
					data: this.purchaseTrend,

				}],
				responsive: {
					rules: [{
						condition: {
							maxHeight: 500
						},
						chartOptions: {
							legend: {
								enabled: false
							}
						}
					}]
				}
			});
			this.purchaseChart = chart;
		}, 1000);

	}
	plotTicketChart() {
		this.loader.startLoader("loader-02");

		if (this.ticketList.length > 0) {
			this.ticketList.forEach(data => {
				data.name = data.status;
				data.y = data.count
			})
		}

		setTimeout(() => {
			let chart = new Chart({
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie'
				},
				title: {
					text: '',
					align: 'left'
				},
				tooltip: {
					pointFormat: '{series.name}: <b>{point.y}</b>'
				},
				accessibility: {
					point: {
						valueSuffix: '%'
					}
				},
				legend: {
					itemMarginBottom: 6,
					layout: 'vertical',
					align: 'right',
					verticalAlign: 'top',
					useHTML: true,
					labelFormatter: function () {
						const point = this as CustomPoint;
						return '<div class="point-name">' + point.name + '</div><div>' + point.y + '</div>';
					}
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: true,
							format: '<b>{point.name}</b>: {point.y}'
						},
						showInLegend: true
					}
				},
				series: [{
					type: 'pie',
					name: 'status',
					data: this.ticketList
				}]
			})
			this.ticketChart = chart;
		}, 1000)
	}
	plotTaskChart() {
		this.loader.startLoader("loader-02");
		if (this.taskList.length > 0) {
			this.taskList.forEach(data => {
				data.name = data.status;
				data.y = data.count
			})
		}
		setTimeout(() => {
			let chart = new Chart({
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie'
				},
				title: {
					text: '',
					align: 'left'
				},
				tooltip: {
					pointFormat: '{series.name}: <b>{point.y}</b>'
				},
				accessibility: {
					point: {
						valueSuffix: '%'
					}
				},
				legend: {
					itemMarginBottom: 6,
					layout: 'vertical',
					align: 'right',
					verticalAlign: 'top',
					useHTML: true,
					labelFormatter: function () {
						const point = this as CustomPoint;
						return '<div class="point-name">' + point.name + '</div><div>' + point.y + '</div>';
					}
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: true,
							format: '<b>{point.name}</b>: {point.y}'
						},
						showInLegend: true
					}
				},
				series: [{
					type: 'pie',
					name: 'status',
					data: this.taskList
				}]
			})
			this.taskChart = chart;
		}, 1000)
	}
	plotSnagChart() {
		this.loader.startLoader("loader-02")
		if (this.snagList.length > 0) {
			this.snagList.forEach(data => {
				data.name = data.status;
				data.y = data.count
			})
		}

		setTimeout(() => {
			let chart = new Chart({
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie'
				},
				title: {
					text: '',
					align: 'left'
				},
				tooltip: {
					pointFormat: '{series.name}: <b>{point.y}</b>'
				},
				accessibility: {
					point: {
						valueSuffix: '%'
					}
				},
				legend: {
					itemMarginBottom: 6,
					layout: 'vertical',
					align: 'right',
					verticalAlign: 'top',
					useHTML: true,
					labelFormatter: function () {
						const point = this as CustomPoint;
						return '<div class="point-name">' + point.name + '</div><div>' + point.y + '</div>';
					}
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: 'pointer',
						dataLabels: {
							enabled: true,
							format: '<b>{point.name}</b>: {point.y}'
						},
						showInLegend: true
					}
				},

				series: [{
					type: 'pie',
					name: 'status',
					data: this.snagList

				}]
			})
			this.snagChart = chart;
		}, 1000)
	}
	plotEzbCostingChart() {
		if (this.InvoiceTrendArr.length > 0) {
			this.InvoiceTrendArr.forEach(data => {
				data.y = data.data;
				data.currency = this.userdata.currency;
			})
		}
		setTimeout(() => {
			this.loader.stopLoader("loader-02")
			let chart = new Chart({
				chart: {
					type: 'pie',
				},
				title: {
					text: '',
					align: 'left'
				},
				subtitle: {
					text: '',
					align: 'left'
				},
				tooltip: {
				},
				// legend: {
				// 	itemMarginBottom: 6,
				// 	layout: 'vertical',
				// 	align: 'right',
				// 	verticalAlign: 'top',
				// 	useHTML: true,
				// 	labelFormatter: function () {
				// 		const point = this as CustomPoint;
				// 		return '<div class="point-name">' + point.name + '</div><div> ' + point.currency + point.y + '</div>';
				// 	}
				// },


				plotOptions: {
					pie: {
						innerSize: 100,
						depth: 45,
						size: '100%',
						// dataLabels: {
						// 	enabled: true,
						// 	distance: 20,
						// 	format: '{point.name}',
						// },

					},

				},

				series: [{
					showInLegend: false,
					dataLabels: {
						enabled: true,
						distance: -40,
						format: '{point.y}',
						style: {
							fontSize: '1em',
							textOutline: 'none',
							opacity: 0.7
						},
					},
					allowPointSelect: true,
					cursor: 'pointer',
					type: 'pie',
					name: this.userdata.currency,
					data: this.InvoiceTrendArr

				},
				{
					dataLabels: {
						enabled: true,
						distance: 20,
						format: '{point.name}',
					},
					allowPointSelect: true,
					cursor: 'pointer',
					type: 'pie',
					name: this.userdata.currency,
					data: this.InvoiceTrendArr
				}
				]
			});
			this.invoiceChart = chart;
		}, 1000);

	}
	gotoSubscriptions() {
		this.router.navigate(['/companyprofile'], { replaceUrl: true });
	}

	getDaysDifference(date1: Date, date2: Date): number {
		const timeDiff = Math.abs(date2.getTime() - date1.getTime());
		const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
		return daysDiff;
	}

	formatDate(date) {
		date = new Date(date);
		var day = date.getDate();
		// var monthIndex = date.getMonth();
		var monthName = date.toLocaleString('default', { month: 'long' });
		var year = date.getFullYear();
		return monthName + ' ' + year;
	}
	getWorldClockList() {
		this.service.getHomeClock().subscribe(res => {
			if (res) {
				this.timeZoneList = res;
				this.timerId = this.getTime();
			}
			environment.production ? '' : console.log('worlclocklist', res)
		}, err => {
			this.loader.stop();
		})
	}
	// getDashboardMenus() {
	// 	this.service.getHomeMenu().subscribe(res => {
	// 		this.menuItems = res;
	// 		environment.production ? '' : console.log('menu', this.menuItems);
	// 		localStorage.setItem(this.appname + 'menu', JSON.stringify(this.menuItems));
	// 		this.events.publish('dashboard:menu', {});
	// 		environment.production ? '' : console.log('homemenu', res)
	// 	}, err => {
	// 		this.loader.stop();
	// 	})
	// }
	getTransactionList() {
		this.service.getRecentTransaction().subscribe(res => {
			this.transactions = res;
			if (this.transactions) {
				this.transactions.forEach(element => {
					element.txndate = this.datePipe.transform(element.txndate, this.format);
					element.updatedate = this.datePipe.transform(element.updatedate, this.format);

					if (element.amt != null && element.amt != 0) {
						element.amt = element.amt.toFixed(this.userdata.decimals_allowed);
					}
					else {
						element.amt = parseFloat("0").toFixed(this.userdata.decimals_allowed);
					}
				});
			}
			this.resTransactions = this.transactions;
			environment.production ? '' : console.log('transaction list', res)
		}, err => {
			this.loader.stop();
		})
	}
	getAnnoucementList() {
		this.service.getHomeAnnouncements().subscribe(res => {
			if (res) {
				this.timeZoneList = res;
				this.timerId = this.getTime();
			}
			environment.production ? '' : console.log('worlclocklist', res)
		}, err => {
			this.loader.stop();
		})
	}
	getCountList() {
		this.service.getHomeTilelist().subscribe(res => {
			this.counts = res ? res : {};
			environment.production ? '' : console.log('count list', res)
		}, err => {
			this.loader.stop();
		})
	}
	getOfficeClockingList() {
		this.service.getHomeTimeLogs().subscribe(res => {
			this.officeClockings = res;
			if (this.officeClockings) {
				this.officeClockings.forEach(clock => {
					if (clock.start_time) {
						const arrValue = clock.start_time.split("T");
						clock.start_time = arrValue[1];
					}
					if (clock.end_time) {
						const arrValue = clock.end_time.split("T");
						clock.end_time = arrValue[1];
					}
				})
			}
			environment.production ? '' : console.log('count list', res)
		}, err => {
			this.loader.stop();
		})

	}
	getHomeEzbCosting() {
		var month = '';
		var year = '';
		if (this.ezbcostingFilter.monthyear) {
			var selectedValue = this.ezbcostingFilter.monthyear.split(' ');
			month = selectedValue[0];
			year = selectedValue[1];
		}
		this.service.getHomeEzbCosting(year, month).subscribe(res => {
			this.InvoiceTrendArr = res;
			setTimeout(() => {
				this.plotEzbCostingChart();
			}, 2000);
			environment.production ? '' : console.log('Invoice summary', res)
		}, err => {
			this.loader.stop();
		})
	}
	getSalesSummary() {
		this.service.getHomeSalesSummary().subscribe(res => {
			this.sales_trend = res[0];
			this.plotSalesGraph();
			environment.production ? '' : console.log('sales summary', res)
		}, err => {
			this.loader.stop();
		})
	}
	getPurchaseSummary() {
		this.service.getHomePurchaseSummary().subscribe(res => {
			this.purchase_trend = res[0];
			this.plotPurchaseGraph();
			environment.production ? '' : console.log('purchase list', res)
		}, err => {
			this.loader.stop();
		})

	}
	getSnagSummary() {
		this.service.getHomeSnagSummary().subscribe(res => {
			this.snagList = res
			this.plotSnagChart();
			environment.production ? '' : console.log('snag summary', res)
		}, err => {
			this.loader.stop();
		})
	}
	getTaskSummary() {
		this.service.getHomeTaskSummary().subscribe(res => {
			this.taskList = res
			this.plotTaskChart();
			environment.production ? '' : console.log('task summary', res)
		}, err => {
			this.loader.stop();
		})
	}
	getTicketSummary() {
		this.service.getHomeTicketSummary().subscribe(res => {
			this.ticketList = res
			this.plotTicketChart();
			environment.production ? '' : console.log('ticket summary', res)
		}, err => {
			this.loader.stop();
		})
	}
}

interface Menu {
	name: string;
	lvl: number;
}

interface MenuWithChildren extends Menu {
	children?: MenuWithChildren[];
}

function buildNestedStructure(data: Menu[]): MenuWithChildren[] {
	const result: MenuWithChildren[] = [];

	const map = new Map<number, MenuWithChildren>();

	data.forEach(menu => {
		const menuWithChildren: MenuWithChildren = { ...menu };
		map.set(menu.lvl, menuWithChildren);

		const parentLvl = menu.lvl - 1;
		if (map.has(parentLvl)) {
			const parent = map.get(parentLvl)!;
			if (!parent.children) {
				parent.children = [];
			}
			parent.children.push(menuWithChildren);
		} else {
			result.push(menuWithChildren);
		}
	});

	return result;
}

