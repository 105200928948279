import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { DatePipe } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class reportServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    userprofile: any;
    public projectDataForEdit: '';
    public expenseData: ''
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    // company finicial
    getTrialBal(fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_TrialBalance?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }

    getModules() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetModules?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getBalanceSheet(asondate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_BalanceSheet?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&asondate=' + asondate);
    }

    getEmpCostingSummary(name, code, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeesPaySummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&fromdate=' + fromdate + '&todate=' + todate);
    }

    getBalanceComparsion(asondate, period) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_BalanceSheetComparisonLike?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&asondate=' + asondate + '&period=' + period);
    }
    getBalanceComparsionByYear(asondate, period) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_BalanceSheetYealyComparison?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&asondate=' + asondate + '&period=' + period);
    }
    getPeriodList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBSPeriod?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    getProfitLoss(fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_PL?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    //account
    getAuditTrail(refno, module, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_AuditTrails?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&refno=' + refno + '&module=' + module + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getDeleteTransactions(type, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_DeletedTransactions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getJournls(refno, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_Journals?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&refno=' + refno + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getLedger(coa, refno, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_Ledgers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&coa=' + coa + '&refno=' + refno + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getCustomerWalletHistory(searchtxt, tranntype, fdate, edate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_CustomerWalletHistory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&tranntype=' + tranntype + '&fdate=' + fdate + '&edate=' + edate);
    }
    getCustomerWalletBalance(searchtxt) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_CustomerWalletBalance?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
    }

    getAccountDetails(id, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_Accountdetails?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getContractSummary(searchtxt, dtfilterapplyto, categoryid, status, fdate, edate, tpsync) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ContractSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&dtfilterapplyto=' + dtfilterapplyto + '&categoryid=' + categoryid + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate + '&tpsync=' + tpsync);
    }

    //customer receivable 
    getInvoiceAigningSummary(searchtxt, fromdate, todate, currency, status) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_InvoiceAgingSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate + '&currency=' + currency + '&status=' + status);
    }
    getArSigningSummary(searchtxt, currency, yr, mon) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ARAgingSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&currency=' + currency + '&yr=' + yr + '&mon=' + mon);
    }


    getCreditUseSummary(customerid, seldate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_CreditUseSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&seldate=' + seldate);
    }

    getinvoice(invno, customer, status, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_InvoicesHistory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&invno=' + invno + '&customer=' + customer + '&status=' + status + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getqoute(qteno, customer, status, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_QuotesHistory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&qteno=' + qteno + '&customer=' + customer + '&status=' + status + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getSalesByCategory(customer, status, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SalesByCategory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customer + '&status=' + status + '&fdate=' + fdate + '&tdate=' + tdate);
    }

    //vendor payables
    getAPsummary(searchtxt, currency, yr, mon) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_APAgingSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&currency=' + currency + '&yr=' + yr + '&mon=' + mon);
    }
    getBillAgingSummary(searchtxt, fromdate, todate, currency, status) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_BillAgingSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate + '&currency=' + currency + '&status=' + status);
    }
    getExpenseHistory(expno, submittedby, type, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ExpenseHistory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&expno=' + expno + '&submittedby=' + submittedby + '&type=' + type + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getExpenseSummary(createby, expno, type, supplierid, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ExpenseSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&createby=' + createby + '&expno=' + expno + '&type=' + type + '&supplierid=' + supplierid + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getExpenseType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetExpenseType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPurchaseOrderSummary(searchtxt, type, status, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_PurchaseOrderSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getPurchaseInvoiceSummary(searchtxt, type, status, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_PurchaseInvoiceSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    // Transactions
    getDailyTrList(searchtxt, status, ordertype, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_DailyTransactions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&status=' + status + '&ordertype=' + ordertype + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getSoTransaction(customer, reference, mop, status, ordertype, receivedby, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SoTransactions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customer=' + customer + '&reference=' + reference + '&mop=' + mop + '&status=' + status + '&ordertype=' + ordertype + '&receivedby=' + receivedby + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getThirdPatrySynch(tpsync, month, year, txttype) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_TransactionTpSync?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tpsync=' + tpsync + '&month=' + month + '&year=' + year + '&txttype=' + txttype);
    }
    getCountRefList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetInventoryCountRefs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getMaterialCostSummary(month, year) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_MaterialCosting?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&month=' + month + '&year=' + year);
    }
    getAssemblySummary(isAsc, assname, yr) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_AssemblySalesByMonth?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&isAsc=' + isAsc + '&assname=' + assname + '&yr=' + yr);
    }
    getBolCostingExport(bol, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_BolCostingExport?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&bol=' + bol + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getBolSummary(bol, po) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_BolSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&bol=' + bol + '&po=' + po);
    }
    getCatLogSummary(searchtxt, dept, categoryid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_CatalogSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&deptid=' + dept + '&categoryid=' + categoryid);
    }
    getInternalTransfer(reqno, ilc, desc, binid, status) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_InternalTransfers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&reqno=' + reqno + '&ilc=' + ilc + '&desc=' + desc + '&binid=' + binid + '&status=' + status);
    }
    getInvAdjustment(trantype, month, year) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_MaterialDj?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&trantype=' + trantype + '&month=' + month + '&year=' + year);
    }
    getInvBalance(asondate, onhandonly) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_InvBalance?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&asondate=' + asondate + '&onhandonly=' + onhandonly);
    }
    getInvByBin(ilc, desc, category) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_InventoryByBin?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&desc=' + desc + '&category=' + category);
    }
    getInvCountDisparancy(ilc, desc, binid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_InventoryCountDiscrepancy?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&desc=' + desc + '&binid=' + binid);
    }
    getInvCostComparison(searchtxt, deptid, catid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ProdCostingReview?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&deptid=' + deptid + '&categoryid=' + catid);
    }
    getInvMovmentHistory(searchtxt, trantype, deptid, categoryid, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ProdMovementHistory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&deptid=' + deptid + '&categoryid=' + categoryid + '&trantype=' + trantype + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getSalescountByMonth(searchtxt, category, deptid, year) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SalesByMonth?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&year=' + year + '&searchtxt=' + searchtxt + '&deptid=' + deptid + '&category=' + category);
    }
    getSalesCountByYear(searchtxt, category, deptid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SalesByYear?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&deptid=' + deptid + '&category=' + category);
    }
    getInvValueByInvoice(asondate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_InvValueByInvoice?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&asondate=' + asondate);
    }
    getLandedCostAudit(ilc, desc, pon, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_LandedCostaudit?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&desc=' + desc + '&pon=' + pon + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getLotExpireList(ilc, desc, binid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_LotExpiry?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&desc=' + desc + '&binid=' + binid);
    }
    getManualStockAudit(printcount, dept, cat) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ManualStockAudit?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&printcount=' + printcount + '&deptid=' + dept + '&categoryid=' + cat);
    }
    getInvOrderHistory(searchtxt, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ProdOrderHistory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getRecentTransactions(fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Get_RecentTransactions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getPickupHistory(ilc, status, orderno, orderref, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SopickStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&status=' + status + '&orderno=' + orderno + '&orderref=' + orderref + '&fromdate=' + fromdate + '&todate=' + todate);

    }
    getPriceAudting(ilc, desc, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ProductsPriceAuditing?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&desc=' + desc + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getProductSerialSummary(searchtxt, deptid, catid, status) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SerialSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&deptid=' + deptid + '&categoryid=' + catid + '&status=' + status);
    }
    getProdReplunishment(page, limit, minmax, van, searchtxt, dept, cat, supplierid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ProdReplunishment?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&page_number=' + page + '&grid_page_size=' + limit + '&minmax=' + minmax + '&van=' + van + '&searchtxt=' + searchtxt + '&categoryid=' + cat + '&deptid=' + dept + '&supplierid=' + supplierid);
    }
    getInvRevenueByProduct(searchtxt, fromdate, todate, dept, categoryid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_RevenueByProducts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate + '&deptid=' + dept + '&categoryid=' + categoryid);
    }
    getSalesVsCostSummary(ilc, desc, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SalesVsCostSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&desc=' + desc + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getStockCostSummary(ilc, desc, catid, deptid, type) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_StockCostReport?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&desc=' + desc + '&catid=' + catid + '&deptid=' + deptid + '&type=' + type);
    }
    getStockSummary(ilc, description, dept, categoryid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_StockSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&description=' + description + '&deptid=' + dept + '&categoryid=' + categoryid);
    }
    getWomaterials(vpsync, month, year) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_WomaterialSpending?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&vpsync=' + vpsync + '&month=' + month + '&year=' + year);
    }
    getNotCountedItems(countid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_NotCountedItems?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&countid=' + countid);
    }
    // getcustomer devices
    getCustomerDevices(searchtxt, type) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_CustomerDevices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type);
    }

    //Task & Tickets
    getTicketTimeLog() {

    }
    getTicketSummary(status, customer, proj, site, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_TicketSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&status=' + status + '&customer=' + customer + '&proj=' + proj + '&site=' + site + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getUnbilledTimeLogs(projid, customerid, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_UnbilledTimeLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projid=' + projid + '&customerid=' + customerid + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getTechTicketLogs(tech, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Get_TechTicketLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tech=' + tech + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getWorkLogSummary(fromdate, todate, empid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_WorkLogSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&empid=' + empid);
    }
    getEmployeeReminders() {
        return this.http.get(this.baseUrl + 'api/Reports/Get_EmployeeReminders?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getOrders(customerid, projid, orderno, status, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ProjectOrders?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&projid=' + projid + '&orderno=' + orderno + '&status=' + status + '&fdate=' + fdate + '&tdate=' + tdate);

    }
    getServiceContractTicketSummary(contractid, categoryid, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Get_ServiceContractTicketSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&contractid=' + contractid + '&categoryid=' + categoryid + '&fromdate=' + fdate + '&todate=' + tdate);
    }
    getServiceContract() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetContracts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getInvoices(projid, customerid, status, invno, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ProjectInvoices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projid=' + projid + '&customerid=' + customerid + '&invno=' + invno + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
    }

    getTicketInventory(month, year) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_TicketSinvCost?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&month=' + month + '&year=' + year);

    }
    getUnbilledTimeLog(projid, customerid, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_UnbilledTimeLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projid=' + projid + '&customerid=' + customerid + '&fdate=' + fdate + '&tdate=' + tdate);
    }


    // Reports HR Payroll

    getAccessCard(ename, code, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeAccessCards?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + ename + '&code=' + code + '&fromdate=' + fdate + '&todate=' + tdate);
    }
    getApprisal(name, code, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeAppraisals?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&fromdate=' + fromdate + '&todate=' + todate)
        // Reports/Gett_EmployeeAppraisals
    }
    getConcessionpasses(name, code, fromdate, todate) {
        // Gett_EmployeeConcessionPasses
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeConcessionPasses?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&fromdate=' + fromdate + '&todate=' + todate)
    }

    getDisplinaryLogList(code, name, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeDisciplinaryLog?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&code=' + code + '&name=' + name + '&fromdate=' + fromdate + '&todate=' + todate)
    }
    getEmpCosting() {

    }
    getEmpAnnivarsary(name, email, code, gender) {
        // Gett_EmployeeAnniversaries
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeAnniversaries?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&email=' + email + '&code=' + code + '&gender=' + gender)
    }
    getEmpBirthday(name, email, code, gender) {
        // Reports/Gett_EmployeeBirthdays
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeBirthdays?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&email=' + email + '&code=' + code + '&gender=' + gender)
    }
    getHurricaneContactList(name, code, fromdate, todate) {
        // Gett_EmployeeHurricanepocs
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeHurricanepocs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&fromdate=' + fromdate + '&todate=' + todate)
    }
    getInsuranceList(name, code, fromdate, todate) {
        // Gett_EmployeeInsurances
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeInsurances?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&fromdate=' + fromdate + '&todate=' + todate)
    }
    getJobApplicantsList(position, fromdate, todate) {
        // Gett_EmployeeJobApps
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeJobApps?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&position=' + position + '&fromdate=' + fromdate + '&todate=' + todate)
    }
    getEmpHurricanePOCsList(name, code, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeHurricanepocs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getEmployeeList(ename, email, phone, code, paytype, etype, gender) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeesHistory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + ename + '&email=' + email + '&phone=' + phone + '&code=' + code + '&paytype=' + paytype + '&etype=' + etype + '&gender=' + gender);
    }
    getEmpPensionsList(name, code, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeePensions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&fromdate=' + fromdate + '&todate=' + todate);
    }

    getWorkpermitList(name, code, fromdate, todate, status) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeeWorkPermits?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&fromdate=' + fromdate + '&todate=' + todate + '&status=' + status);
    }

    getEmployeePaySummary(name, code, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmployeesPaySummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&fromdate=' + fdate + '&todate=' + tdate);
    }

    // task & ticket
    getTechHrs(tech, fromdate, todate, status, designation, department) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_TechHours?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tech=' + tech + '&fromdate=' + fromdate + '&todate=' + todate + '&department=' + department + '&designation=' + designation + '&status=' + status);
    }
    // task & ticket
    getTechHourDetails(tech, doe) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_GetTechHourDetails?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tech=' + tech + '&doe=' + doe);
    }
    //timesheet
    getTimesheet(name, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_Timesheet?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    //timeSheetLog
    getTimesheetLogs(employeeid_id, fromdate, todate, project_id, type) {
        return this.http.get(this.baseUrl + 'api/Reports/Get_Timesheetlogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid_id=' + employeeid_id + '&projid=' + project_id + '&fromdate=' + fromdate + '&todate=' + todate + '&type=' + type);
    }

    getTimesheetHeader(employeeid_id, fromdate, todate, project_id, type) {
        return this.http.get(this.baseUrl + 'api/Reports/Get_TimesheetHeader?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid_id=' + employeeid_id + '&projid=' + project_id + '&fromdate=' + fromdate + '&todate=' + todate + '&type=' + type);
    }

    getsnagsummary(fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SnagSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getTaskummary(fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_TaskSummary?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }

    // ESIC Contribution
    get_ESIC_Contribution(payrollid, empid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_Payroll_ESIC_Contribution?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&payrollid=' + payrollid + '&empid=' + empid);
    }

    // EPF Contribution
    get_EPF_Contribution(payrollid, empid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_Payroll_EPF_Contribution?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&payrollid=' + payrollid + '&empid=' + empid);
    }

    // Employee Roster Details
    getRoasterDetails(fromdate, todate, empid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_Employee_Roaster_Details?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&empid=' + empid);
    }

    // Monthly attendance Component
    getAttendanceMonthly(yr, mon, empid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_AttendanceMonthly?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&yr=' + yr + '&mon=' + mon + '&empid=' + empid);
    }

    // Monthly attendance Component
    getPensionInsurContribution(payrollid, empid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_Payroll_Pension_Insur_Contribution?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&payrollid=' + payrollid + '&empid=' + empid);
    }

    // Monthly attendance Component
    getPaymentsPaid(supplierid, currency, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_PaymentsPaid?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid + '&currency=' + currency + '&fdate=' + fdate + '&tdate=' + tdate);
    }

    // CustomerPayments
    getCustomerPayments(searchtext, currency, mop, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_PaymentReceived?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtext=' + searchtext + '&currency=' + currency + '&mop=' + mop + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getChequesIssued(fromdate, todate, status) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ChequesIssued?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&status=' + status);
    }
    getChequesReceived(fromdate, todate, status) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ChequesReceived?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&status=' + status);
    }

    getSqlErrorLog(fromdate, todate, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SQLErrorLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&searchtxt=' + searchtxt)
    }
    getSqlJobLog(fromdate, todate, status) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_SQLJobLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&status=' + status);
    }

    getOnlinePayments(searchtxt, fromdate, todate, status) {
        return this.http.get(this.baseUrl + 'api/Reports/Get_OnlinePayments?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate + '&status=' + status);
    }

    getFSNAnalysis(searchtxt, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_FSNAnalysis?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getEmpEarlyGoingLateComing(searchtxt, fromdate, todate, empid) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_EmpEarlyGoingLateComing?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate + '&empid=' + empid);
    }
    getEmpshiftlog(searchtxt, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_Empshiftlog?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getItemCompatibility(prodid, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Reports/Gett_ItemCompatibility?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid + '&searchtxt=' + searchtxt);
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
