import { EventEmitter, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { DatePipe } from '@angular/common';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class SalesServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    isLoading: boolean;
    objLogin: modelLogin;
    objComp: modelComp;
    objCompId: any;
    userprofile: any;
    public hero: ''
    public expenseData: ''
    public customerIdForEdit: ''
    public editCustomerDetails: {}
    cartData = new EventEmitter<any>();
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }
    //get leads
    getLeads(name, status, salesperson, country) {
        return this.http.get(this.baseUrl + 'api/leads/Get_LeadIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&status=' + status + '&salesperson=' + salesperson + '&country=' + country);
    }
    getLeadActivityLogs(id) {
        return this.http.get(this.baseUrl + 'api/Leads/Get_LeadActivityLogs?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getLeadStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetLeadStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    getLeadsDetailsById(id) {
        return this.http.get(this.baseUrl + 'api/leads/Gett_LeadByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id);
    }
    postLead(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/leads/Postt_Leads', data);
    }
    postLeadContacts(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Leads/Post_SaveLeadContacts', data);
    }
    getLeadContactsById(id) {
        return this.http.get(this.baseUrl + 'api/Leads/Get_LeadContacts?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getLeadCommentsById(id) {
        return this.http.get(this.baseUrl + 'api/Leads/Get_LeadComments?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postLeadComments(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Leads/Post_SaveLeadComments', data);
    }
    //Sales Order List
    getSalesOrders(searchtxt, fdate, edate, type, txntype, tpsync, status) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Get_SalesOrderIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fdate=' + fdate + '&edate=' + edate + '&type=' + type + '&txntype=' + txntype + '&tpsync=' + tpsync + '&status=' + status);
    }
    //salseRequest
    getSalesRequest(type, status, fromdate, todate, searchtxt) {
        return this.http.get(this.baseUrl + 'api/SalesRequests/Get_SRIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type + '&searchtxt=' + searchtxt + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    postSalesRequests(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/SalesRequests/Postt_SalesRequest', data);
    }
    getSalesReqById(id) {
        return this.http.get(this.baseUrl + 'api/SalesRequests/Gett_SalesRequestItemByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Tracking
    postInvoiceTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/invoices/Post_TrackingNotes', data);
    }
    postEstimateTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Quotes/Post_TrackingNotes', data);
    }
    postSoTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/SalesOrders/Post_TrackingNotes', data);
    }
    //Invoice  List
    getInvoices(searchtxt, fdate, edate, status, tpsync) {
        return this.http.get(this.baseUrl + 'api/invoices/Get_InvoiceIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fdate=' + fdate + '&edate=' + edate + '&status=' + status + '&tpsync=' + tpsync);
    }

    //quote
    getQuoteList(searchtxt, fdate, edate, status, salesrep) {
        return this.http.get(this.baseUrl + 'api/Quotes/Get_QuoteIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fdate=' + fdate + '&edate=' + edate + '&status=' + status + '&salesrep=' + salesrep);
    }
    getQuoteDetails(id) {
        return this.http.get(this.baseUrl + 'api/Quotes/Gett_QuoteByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id);

    }
    //estimate
    getEstimates(searchtxt, fdate, edate, tpsync, status, salesrep) {
        return this.http.get(this.baseUrl + 'api/Estimates/Get_EstimateIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fdate=' + fdate + '&edate=' + edate + '&tpsync=' + tpsync + '&status=' + status + '&salesrep=' + salesrep);
    }
    getEstimateDetails(id) {
        return this.http.get(this.baseUrl + 'api/Estimates/Gett_EstimateByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id);

    }
    postEstimate(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Estimates/Postt_Estimate', data);

    }
    //get customer
    getCustomers(searchtxt, level, currency, type, status) {
        return this.http.get(this.baseUrl + 'api/Customers/Get_CustomerIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&level=' + level + '&currency=' + currency + '&type=' + type + '&status=' + status);
    }
    getCustomerList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    postcustomer(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Postt_Customer', data);
    }
    //post customer childs
    postContact(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Postt_CustomerContact', data);
    }
    postSites(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Postt_CustomerSite', data);
    }
    postDevice(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Postt_CustomerDevice', data);
    }
    postPayment(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Accounts/Post_ReceivedPayments', data);
    }
    getCheckListGrp() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetServiceChecklistGrp?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCustomerDetails(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCustomerContactByID(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerContactByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteCustomerContact(id) {
        return this.http.delete(this.baseUrl + 'api/Customers/Delete_CustomerContact?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //================Customer Tab APIs===================
    getChildCustomers(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_ChildCustomers?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomerContacts(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerContacts?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomerContracts(id, fromdate, todate, cno) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerContracts?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&cno=' + cno);
    }

    getCustomerCards(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerCards?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomerDevices(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerDevices?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomerDocuments(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerDocuments?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomerExpenses(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerExpenses?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomerPayments(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerPayments?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomerSites(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerSites?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomerWalletLogs(id, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerWalletLogs?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getCustomerWalletTranByID(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Get_CustomerWalletTranByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postWalletTransaction(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Post_CustomerWallet', data);
    }
    getCustomerProjects(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerProjects?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // getCustomerTickets(id) {
    //     return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerTickets?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    // }

    getCustomerInvoices(id, fromdate, todate, invno) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerInvoices?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&invno=' + invno);
    }

    getCustomerTasks(id, searchtxt, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerTasks?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&searchtxt=' + searchtxt);
    }

    getCustomerQuotes(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerQuotes?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomerSalesOrders(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerSalesOrders?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }


    //invoice
    getInvList(invno) {
        return this.http.get(this.baseUrl + 'api/WebInvoices/Gett_invhdr?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + this.userprofile.email + '&invno=' + invno);
    }
    getInvDetailsById(id) {
        return this.http.get(this.baseUrl + 'api/invoices/Gett_InvoiceByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCustTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomerType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    lateFeesTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/LatefeeTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDeviceDetials(customerid, id) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerDeviceByID?id=' + id + '&customerid=' + customerid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postInv(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/invoices/Postt_Invoice', data);
    }
    getInvStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetInvStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getInvTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPaymentTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postSalesOrder(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/SalesOrders/Postt_SalesOrder', data);
    }
    postQuote(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Quotes/Postt_Quote', data);
    }
    getSalesOrderDetails(id) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Gett_SalesOrderByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSOTrackingNotes(id) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Get_TrackingNotes?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getInvTrackingNotes(id) {
        return this.http.get(this.baseUrl + 'api/Invoices/Get_TrackingNotes?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //get standing order
    standingOrderList(searchtxt, fdate, edate, routeid, status) {
        return this.http.get(this.baseUrl + 'api/StandingOrders/Get_StandingOrderIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fdate=' + fdate + '&edate=' + edate + '&routeid=' + routeid + '&status=' + status)
    }

    postStandingOrders(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/StandingOrders/Postt_StandingOrder', data);
    }

    standingOrderById(id) {
        return this.http.get(this.baseUrl + 'api/StandingOrders/Gett_StandingOrderByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //receive payment 
    getReceivePaymentList(partyid, fdate, tdate, docid, party_type) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_PartyReceivables?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fdate=' + fdate + '&tdate=' + tdate + '&docid=' + docid + '&partyid=' + partyid + '&party_type=' + party_type);
    }
    getCustCreditBal(customerid) {
        return this.http.get(this.baseUrl + 'api/Customers/Get_CustomerCredits?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid);
    }

    postCustomerCredit(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Post_CreditPayments', data);
    }
    deleteCustomerCredit(id, avail_credit) {
        return this.http.delete(this.baseUrl + 'api/Customers/Delete_CustomerCredits?id=' + id + '?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&avail_credit=' + avail_credit)
    }
    getCreditInvList(partyid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPartyReceivables?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&partyid=' + partyid);
    }
    getfeedback(customerid, fromdate, todate, status) {
        // return this.http.get(this.baseUrl + 'api/Customers/Get_CustomerFeedbacks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid)
        return this.http.get(this.baseUrl + 'api/Customers/Get_CustomerFeedbacks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&fromdate=' + fromdate + '&todate=' + todate + '&status=' + status)
    }
    getfeedbackById(id, customerid) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerFeedbackByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid)
    }
    //create salesorder to invoice
    createSalesorder2Inv(id) {
        return this.http.get(this.baseUrl + 'api/invoices/Gett_SalesOrder2Invoice?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }
    //create qoute to invoice
    createQoute2Inv(id) {
        return this.http.get(this.baseUrl + 'api/invoices/Gett_Quote2Invoice?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }
    //create sales order from qoute
    createQoute2Salesorder(id) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Gett_Quote2SalesOrder?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }
    //create ticket from qoute
    createQoute2Ticket(id) {
        return this.http.get(this.baseUrl + 'api/Tickets/Gett_Quote2Ticket?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //standing orders
    postStandingOrder(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/StandingOrders/Postt_StandingOrder', data);
    }
    getRouteList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDeliveryRoutes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getMergeInvoice(customer) {
        return this.http.get(this.baseUrl + 'api/Invoices/Get_Invoices2Merge?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customer=' + customer);
    }
    getInvBulk(customer) {
        return this.http.get(this.baseUrl + 'api/Invoices/Get_DueInvoices2SendAsBulk?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customer=' + customer);
    }
    postMergeInvoice(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Invoices/Post_InvoicesMerge', data);
    }
    postBulkInv(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Emailer/Post_BulkInvoiceMailer', data);
    }
    getPickItem(refid, refno, customerid, prodid, qty2pick, module) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Get_Inventory2Pickup?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&refid=' + refid + '&refno=' + refno + '&customerid=' + customerid + '&prodid=' + prodid + '&qty2pick=' + qty2pick + '&module=' + module);
    }
    postPickItem(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/SalesOrders/Post_PickupInventory', data);
    }
    getPickupOrder(id) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Get_PickupOrder?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getUpdatePickupOrder(id, status, notes) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Get_UpdatePickupOrder?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&status=' + status + '&notes=' + notes);
    }
    getPurchaseRequest(id, flag) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Get_SalesOrder2PurchaseRequest?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&flag=' + flag);
    }

    //salesorder
    getPricingTypeList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPricingTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //temp
    getProfile() {

    }
    //delivery route
    getDeliveryRoute(description, code) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DeliveryRouteIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description + '&code=' + code)
    }
    postDeliveryRoute(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DeliveryRoute', data);
    }
    getDeliveryRoutByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeliveryRouteByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteDeliveryRoutByID(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeliveryRoute?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    siteMaterial(sideid) {
        return this.http.get(this.baseUrl + 'api/Customers/Get_SiteMaterials?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&siteid=' + sideid);
    }
    postMaterial(data) {
        return this.http.post(this.baseUrl + 'api/Customers/Postt_SiteMaterial', data);
    }

    getMaterialbyID(id) {
        return this.http.get(this.baseUrl + 'api/Customers/Get_SiteMaterialByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    deleteMaterial(id) {
        return this.http.delete(this.baseUrl + 'api/Customers/Delete_SiteMaterial?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    addFeedback(data) {
        return this.http.post(this.baseUrl + 'api/Customers/Postt_CustomerFeedback', data);
    }
    getCustomerSiteById(id, customerid) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerSiteByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid);
    }
    getFeedbackDetailsByid(id, customerid) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerFeedbackByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid);
    }
    deleteSalesOrderLineItem(id) {
        return this.http.delete(this.baseUrl + 'api/SalesOrders/Delete_SalesOrderLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteSalesOrder(id) {
        return this.http.delete(this.baseUrl + 'api/SalesOrders/Delete_SalesOrder?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteInvoiceLineItem(id) {
        return this.http.delete(this.baseUrl + 'api/Invoices/Delete_InvoiceLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteInvoice(id) {
        return this.http.delete(this.baseUrl + 'api/Invoices/Delete_Invoice?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteQouteLineItem(id) {
        return this.http.delete(this.baseUrl + 'api/Quotes/Delete_QuoteLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteCustomer(id) {
        return this.http.delete(this.baseUrl + 'api/Customers/Delete_Customer?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteEstimateLine(id) {
        return this.http.delete(this.baseUrl + 'api/Estimates/Delete_EstimateLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    deleteStandingOrderLine(id) {
        return this.http.delete(this.baseUrl + 'api/StandingOrders/Delete_StandingOrderLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    deleteCustomerSite(id) {
        return this.http.delete(this.baseUrl + 'api/Customers/Delete_CustomerSite?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteCustomerDevice(id) {
        return this.http.delete(this.baseUrl + 'api/Customers/Delete_CustomerDevice?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    createSalesRequest2SalesOrder(data) {
        return this.http.post(this.baseUrl + 'api/SalesOrders/Post_SalesRequest2SalesOrder', data);
    }
    getCustomerTicket(customerid, siteid, deviceid) {
        return this.http.get(this.baseUrl + 'api/Customers/Gett_CustomerTickets?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + this.userprofile.email + '&customerid=' + customerid + '&siteid=' + siteid + '&deviceid=' + deviceid);
    }
    getCustomerContactList(customerid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomerContacts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid);

    }
    getCustomerStatement(customerid, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Accounts/GetCustomerStatement?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    postSalesReqItem(data) {
        return this.http.post(this.baseUrl + 'api/SalesRequests/Postt_SalesRequest', data);

    }
    deleteSalesRequestItem(id) {
        return this.http.delete(this.baseUrl + 'api/SalesRequests/Delete_SalesRequestItem?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteSalesItem(id) {
        return this.http.delete(this.baseUrl + 'api/SalesRequests/Delete_SalesRequest?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSalesRequestById(id) {
        return this.http.get(this.baseUrl + 'api/SalesRequests/Gett_SalesRequestByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    approveSalesRequest(ids) {
        return this.http.get(this.baseUrl + 'api/PurchaseRequets/ApprovePRS?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ids=' + ids);
    }
    deleteQuote(id) {
        return this.http.delete(this.baseUrl + 'api/Quotes/Delete_Quote?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteEstimate(id) {
        return this.http.delete(this.baseUrl + 'api/Estimates/Delete_Estimate?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //dispute 
    getDisputeList(searchtext, status, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Disputes/Get_DisputeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtext + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate)
    }
    postDispute(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Disputes/Postt_Dispute', data);
    }
    postDisputeLog(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Disputes/Postt_DisputeLog', data);
    }
    getDisputeListByID(id) {
        return this.http.get(this.baseUrl + 'api/Disputes/Gett_DisputeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDisputeLog(id) {
        return this.http.get(this.baseUrl + 'api/Disputes/Gett_DisputeLogByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&dispute_id=' + id);
    }
    deleteDispute(id) {
        return this.http.delete(this.baseUrl + 'api/Disputes/Delete_CustomerDispute?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteDisputeLog(id) {
        return this.http.delete(this.baseUrl + 'api/Disputes/Delete_DisputeLog?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteLeadContact(id) {
        return this.http.delete(this.baseUrl + 'api/Leads/Delete_LeadContact?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPayNow(id) {
        return this.http.get(this.baseUrl + 'api/Disputes/Gett_DisputeLogByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&dispute_id=' + id);
    }
    getInvoicePayments(id) {
        return this.http.get(this.baseUrl + 'api/Invoices/Gett_InvoiceTransactions?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSOPayments(id) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Gett_SalesOrderTransactions?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
