import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';
import * as moment from 'moment';

@Component({
  selector: 'app-itemanalysis',
  templateUrl: './itemanalysis.component.html',
  styleUrls: ['./itemanalysis.component.scss']
})
export class ItemanalysisComponent implements OnInit {
  @Input() fromParent;
  fdate: any;
  edate: any;
  itemData: any = {}
  binList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  searchtext: any = '';
  itemDataList: any = [];
  fromdate: any;
  todate: any;
  constructor(private activeModal: NgbActiveModal,
    private service: InventoryServices,
    private datepipe: DatePipe,
    private commonService: CommonServices,
    private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.todate = moment().add(1, 'days').format('YYYY-MM-DD');
    this.fromdate = moment().subtract(180, 'days').format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    environment.production ? '' : console.log('item data', this.fromParent);
    this.getItemAnylsisList()
  }

  refresh() {
    sessionStorage.removeItem(this.constructor.name + '-FILTER_LIST')
    this.searchtext = '';
    this.todate = moment().add(1, 'days').format('YYYY-MM-DD');
    this.fromdate = moment().subtract(180, 'days').format('YYYY-MM-DD');
    this.getItemAnylsisList()
  }
  getItemAnylsisList() {
    this.loader.start()
    this.service.getItemAnalyses(this.fromParent.id, this.datepipe.transform(new Date(this.fromdate), this.userdata.postdateformat), this.datepipe.transform(new Date(this.todate), this.userdata.postdateformat), this.searchtext).subscribe((res) => {
      this.loader.stop();
      this.itemDataList = res;
      this.itemDataList.forEach(item => {
        item.pocost = item.pocost ? Number(item.pocost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        item.avgcost_weighted = item.avgcost_weighted ? Number(item.avgcost_weighted).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        item.avgcost_fifo = item.avgcost_fifo ? Number(item.avgcost_fifo).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        item.saleprice = item.saleprice ? Number(item.saleprice).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      })
      environment.production ? '' : console.log('itemdata list response ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post itemdata list ', err);
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
