<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Part & Material</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation">
      <div class="row">
        <div class="form-group col-lg-4 col-md-6 col-12">
          <label for="" class="mb-1">ItemCode</label>
          <input class="form-control" id="" [(ngModel)]="timenmaterial.ilc" name="ilc" type="text"
            placeholder="ItemCode" readonly>
        </div>
        <div class="form-group col-lg-4 col-md-6 col-12">
          <label for="" class="mb-1">Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="picker94" placeholder="Choose a date" [(ngModel)]="timenmaterial.bookingtime"
              name="date" required="">
            <mat-datepicker-toggle matSuffix [for]="picker94"></mat-datepicker-toggle>
            <mat-datepicker #picker94></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-group col-lg-4 col-md-6 col-12">
          <label for="" class="mb-1">Tech</label>
          <select class="form-control" required="" [(ngModel)]="timenmaterial.tech" name="tech">
            <option value="">Select Tech</option>
            <option *ngFor="let item of technicianList" [value]="item.id">{{item.title}}</option>
          </select>
        </div>
        <!-- <div class="form-group col-lg-2 col-md-6 col-12">
          <label for="" class="mb-1">Audited</label>
          <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
            <label class="d-block" for="audited">
              <input class="radio_animated" id="audited-yes" type="radio" [value]="1" name="audited"
                [(ngModel)]="timenmaterial.audited">
              Yes
            </label>
            <label class="d-block" for="audited">
              <input class="radio_animated" id="audited-no" [value]="0" [(ngModel)]="timenmaterial.audited" type="radio"
                name="audited">
              No
            </label>
          </div>
        </div> -->
        <div class="form-group col-lg-2 col-md-6 col-12">
          <label for="" class="mb-1">Qty</label>
          <input class="form-control text-right" id="validationCustom02" name="qty" [(ngModel)]="timenmaterial.qty"
            type="number" placeholder="Qty" readonly (change)="calculateTotal(timenmaterial)">
        </div>
        <div class="form-group col-lg-2 col-md-6 col-12">
          <label for="" class="mb-1">Rate</label>
          <input class="form-control text-right" id="" [(ngModel)]="timenmaterial.rate" type="number"
            (change)="calculateTotal(timenmaterial)" name="rate" placeholder="Rate">
        </div>
        <div class="form-group col-lg-2 col-md-6 col-12">
          <label for="" class="mb-1">Total</label>
          <input class="form-control text-right" id="validationCustom04" name="total" [(ngModel)]="timenmaterial.total"
            type="number" placeholder="Total" readonly>
        </div>
        <div class="form-group col-lg-12 col-md-12 col-12">
          <label for="" class="mb-1">Notes</label>
          <textarea class="form-control" id="" type="text" placeholder="Notes" cols="2" rows="2" name="notes"
            [(ngModel)]="timenmaterial.notes"></textarea>
        </div>
        <div class="form-group col-lg-12 col-md-12 col-12">
          <label for="" class="mb-1">Description</label>
          <textarea class="form-control" id="" type="text" placeholder="Description" cols="2" rows="2"
            name="description" [(ngModel)]="timenmaterial.description"></textarea>
        </div>
      </div>
      <div class="category-table custom-datatable mb-3">
        <div class="table-responsive2">
          <table class="custom-table">
            <thead>
              <tr class="custom-tr">
                <th class="custom-th text-center">Bin</th>
                <th class="custom-th text-center" style="width: 120px;">On hand</th>
                <th class="custom-th text-center" style="width: 120px;">Qty</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of timenmaterial.ticketItemLocations;let i = index;trackBy: track">
                  <tr class="custom-tr">
                    <td class="custom-td text-left" *ngIf="item.isserilized">
                      <i class="fa fa-chevron-circle-down" aria-hidden="true" *ngIf="!item.buttonclicked"
                        (click)="item.buttonclicked = !item.buttonclicked"></i>
                      <i class="fa fa-chevron-circle-up" aria-hidden="true" *ngIf="item.buttonclicked"
                        (click)="item.buttonclicked = !item.buttonclicked"></i>
                      {{item.binid}}-{{item.description}}
                    </td>
                    <td class="custom-td text-left" *ngIf="!item.isserilized">{{item.binid}}</td>
                    <td class="custom-td text-center">{{item.onhand}}</td>
                    <td class="custom-td text-center"><input type="number" class="form-control text-center"
                        name="qty-{{i}}" [(ngModel)]="item.qty" value="0" [disabled]="item.isserilized" min="0"
                        oninput="validity.valid||(value='');" (focusout)="checkEnterQty(item)"></td>
                  </tr>
                  <tr class="custom-tr disnone" [class.display]="item.buttonclicked == true">
                    <td class="custom-td" colspan="3">
                      <label><b>Select Serials :</b></label>
                      <div class="serial-list"
                        *ngFor="let element of item.serialList; let k = index;trackBy: trackByIndex">
                        <input type="checkbox" name="checked-{{k}}" (click)="onCheckboxClick(item,$event,element)"
                          [checked]="element.checked"> {{element.name}}
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-center">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" [disabled]="isView" (click)="saveSingleLineItem()">Save</button>
    </div>
  </div>
</resize-border>