import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';
import { purchaseServices } from 'src/app/components/purchase/purchase.service';
import { reportServices } from 'src/app/components/reports/reports.service';

@Component({
  selector: 'app-itemcompatibility',
  templateUrl: './itemcompatibility.component.html',
  styleUrls: ['./itemcompatibility.component.scss']
})
export class ItemCompatibilityComponent implements OnInit {
  @Input() fromParent;
  itemData: any = {}
  binList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  searchtxt: any = '';
  deviceList: any = []
  itemData1: any = {}
  constructor(private activeModal: NgbActiveModal,
    private service: reportServices,
    private datepipe: DatePipe,
    private commonService: CommonServices,
    private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    environment.production ? '' : console.log('item data', this.fromParent);
    this.getItemCompatibility()
  }
  getItemCompatibility() {
    this.loader.start()
    this.service.getItemCompatibility(this.fromParent.id, this.searchtxt).subscribe(res => {
      this.loader.stop();
      this.deviceList = res;
    }, err => {
      this.loader.stop();
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
}
