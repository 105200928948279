import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppServices } from 'src/app/shared/service/app.services';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { accountServices } from '../../accounts/account.service';

@Component({
  selector: 'app-singlebillpayment',
  templateUrl: './singlebillpayment.component.html',
  styleUrls: ['./singlebillpayment.component.scss']
})
export class SinglebillpaymentComponent implements OnInit {
  fullScreen = false;
  @Input('params') params;
  isShowAvailableCredit: any = false;
  userdata: any;
  appname = environment.APP_NAME;
  paymentMethods: any = [];
  selectedMOP: any;
  paying_amt: any = 0;
  payBillData: any = {
    payment_type: 'normal',
    mop: '',
    checkbookid: '',
    notify: '0',
    change: 0,
    leave_debit: false,
    amt: 0,
    memo: '',
    notes: '',
    trandate: new Date(),
    bankid: '',
    currency: '',
    roe: 0,
    tendered_amt: 0,
    fx_loss_gain: 0
  };
  supplier: any = {
    availbalance: 0
  };
  checkbookList: any = [];
  totalPayment: any = 0;
  isShowChequeFields = false;
  companyBanks: any = [];
  currencyList: any = [];
  baseTotal: any = 0;
  constructor(private activeModal: NgbActiveModal, private appService: AppServices, private toastr: NotificationService, private loader: NgxUiLoaderService, private service: accountServices, private commanService: CommonServices, private datepipe: DatePipe, private detectChange: ChangeDetectorRef, private modalService: NgbModal, private router: Router, private toaster: ToastrService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    this.payBillData.tendered_amt = this.params.amt;
    this.totalPayment = this.params.amt;
    this.paying_amt = this.params.amt;
    this.payBillData.currency = this.params.currency ? this.params.currency : '';
    this.payBillData.roe = this.params.roe ? this.params.roe : 0;
    this.baseTotal = (this.params.amt ? this.params.amt : 0) * (this.params.roe ? this.params.roe : 0);
    this.baseTotal = this.baseTotal ? Number(this.baseTotal).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    this.getpaymentMethods();
    this.appService.getCurrency().subscribe(res => {
      this.currencyList = res;
      environment.production ? '' : console.log(' currency list data ', this.currencyList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading Currency list ', err);
    });
    this.updatePayAmt();
  }

  getPaymentAccounts() {
    var type = '';
    this.paymentMethods.forEach(item => {
      if (item.Value == this.payBillData.mop) {
        type = item.Value1 ? item.Value1 : '';
      }
    })
    this.commanService.getCompBankList(type).subscribe(res => {
      this.companyBanks = res;
    }, err => {
    });
  }
  getpaymentMethods() {
    this.commanService.getPaymentMethods().subscribe(res => {
      this.paymentMethods = res;
      environment.production ? '' : console.log('payment method data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading payment method data', err);
    });
  }
  selectCheckBooks() {
    this.isShowChequeFields = false;
    this.selectedMOP = null;
    this.payBillData.checkbookid = '';
    this.payBillData.number = '';
    if (this.payBillData.mop == '') {
      return;
    }
    this.paymentMethods.forEach(mop => {
      if (mop.Value == this.payBillData.mop) {
        this.selectedMOP = mop;
      }
    })
    if (this.selectedMOP.Value1 == 'cheque') {
      this.isShowChequeFields = true;
      this.getCheckBooks();
    }
  }
  selectROE() {
    this.currencyList.forEach(element => {
      if (this.payBillData.currency == element.Value) {
        this.payBillData.roe = Number(element.Value2).toFixed(this.userdata.decimals_allowed) ? Number(element.Value2).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      }
      else if (!this.payBillData.currency) {
        this.payBillData.roe = '';
      }
    })
  }
  getCheckBooks() {
    this.commanService.getCheckBooks(this.payBillData.bankid).subscribe(res => {
      this.checkbookList = res;
      environment.production ? '' : console.log('checkbooks data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading checkbooks data', err);
    });
  }
  getCheckBookNos() {
    this.commanService.getCheckBookNos(this.payBillData.checkbookid).subscribe((res: any) => {
      this.payBillData.number = res.refid;
      environment.production ? '' : console.log('checkbookNos data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading checkbookNos data', err);
    });
  }
  updatePayAmt() {
    if (this.payBillData.tendered_amt == undefined || this.payBillData.tendered_amt == null || this.payBillData.tendered_amt == '') {
      this.payBillData.tendered_amt = Number(0).toFixed(this.userdata.decimals_allowed);
    }
    if (Number(this.payBillData.tendered_amt) > Number(this.totalPayment)) {
      this.payBillData.change = Number(this.payBillData.tendered_amt) - Number(this.totalPayment);
    } else {
      this.payBillData.change = 0;
    }
    this.paying_amt = (Number(this.payBillData.tendered_amt) - Number(this.payBillData.change)).toFixed(this.userdata.decimals_allowed);

    this.payBillData.change = Number(this.payBillData.change).toFixed(this.userdata.decimals_allowed);

    this.payBillData.receivedbaseamount = ((this.paying_amt ? Number(this.paying_amt) : 0) * Number(this.payBillData.roe ? this.payBillData.roe : 0));
    this.payBillData.receivedbaseamount = this.payBillData.receivedbaseamount ? Number(this.payBillData.receivedbaseamount).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);

    this.payBillData.fx_loss_gain = ((this.paying_amt ? Number(this.paying_amt) : 0) * Number(this.params.roe ? this.params.roe : 0)) - Number(this.payBillData.receivedbaseamount);
    this.payBillData.fx_loss_gain = this.payBillData.fx_loss_gain ? Number(this.payBillData.fx_loss_gain).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
  }

  billPayment() {
    var duebills = [];
    var dueBal = 0;
    if (this.payBillData.tendered_amt > 0) {
      dueBal = (this.params.amt ? Number(this.params.amt) : 0) - (this.payBillData.tendered_amt ? Number(this.payBillData.tendered_amt) : 0);
    } else {
      dueBal = Number(this.params.amt);
    }
    duebills.push({
      "companyname": this.params.companyname,
      "status": dueBal <= 0 ? 'paid' : 'partiallypaid',
      "refno": this.params.refno,
      "bal": dueBal <= 0 ? 0 : dueBal,
      "pay": dueBal <= 0 ? this.params.amt : this.payBillData.tendered_amt,
      "refid": this.params.refid,
      "ttype": this.params.ttype,
      "billref": this.payBillData.memo,
      "currency": this.params.currency,
      "billdate": this.params.billdate
    });
    if (!this.payBillData.mop) {
      this.toastr.Error("Please select method of payment")
      return;
    }
    if (this.selectedMOP.Value1 == 'cheque' && this.payBillData.number == '') {
      this.toastr.Error("Please select payment cheque");
      return;
    }
    if (this.payBillData.tendered_amt == undefined || this.payBillData.tendered_amt == null || this.payBillData.tendered_amt <= 0) {
      this.toastr.Error("Please enter amount");
      return;
    }
    if (!this.payBillData.currency) {
      this.toastr.Error('Please select currency');
      return;
    }
    if (this.payBillData.memo == undefined || this.payBillData.memo == null || this.payBillData.memo == '') {
      this.toastr.Error("Please enter memo");
      return;
    }
    var postData = {
      "id": null,
      "notes": this.payBillData.notes,
      "memo": this.payBillData.memo,
      "change": this.payBillData.leave_debit ? 0 : this.payBillData.change,
      "leave_debit": this.payBillData.leave_debit,
      "number": this.payBillData.number,
      "currency": this.payBillData.currency,
      "amt": this.payBillData.change > 0 ? Number(this.params.amt) : Number(this.payBillData.tendered_amt),
      "tendered_amt": Number(this.payBillData.tendered_amt),
      "availbalance": 0,
      "partyid": this.params.supplierid,
      "companyid": this.userdata.companyid,
      "checkbookid": this.payBillData.checkbookid,
      "supplier_name": this.payBillData.name,
      "notify": this.payBillData.notify,
      "mop": this.payBillData.mop,
      "bankid": this.payBillData.bankid,
      "payment_type": this.payBillData.payment_type,
      "trandate": this.datepipe.transform(new Date(this.payBillData.trandate), this.userdata.postdatetimeformat),
      "createby": this.userdata.email,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "companyname": this.userdata.compayname,
      "phone": this.userdata.phone,
      "duebills": duebills,
      // "paymentfiles": this.paymentFiles,
      "roe": this.payBillData.roe,
      "party_type": this.params.party_type,
      "party_name": this.params.party_name,
      "notify_party": false,
      "fx_loss_gain": this.payBillData.fx_loss_gain
    }
    environment.production ? '' : console.log(JSON.stringify(postData));
    this.loader.start();
    this.service.postBillPayment(postData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      this.closeModal();
    }, err => {
      this.loader.stop();
    })
  }
  closeModal() {
    this.activeModal.close();
  }
  getCurrentROE() {
    if (!this.payBillData.currency) {
      this.toaster.error('Please select currency');
      return;
    }
    this.loader.start();
    this.commanService.getExchangeRate(this.userdata.currency, this.payBillData.currency).subscribe(res => {
      this.loader.stop();
      this.payBillData.roe = res ? Number(res).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
    }, err => {
      this.loader.stop();
    });
  }

}
