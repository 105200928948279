import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppServices } from 'src/app/shared/service/app.services';
import { CommonServices } from 'src/app/shared/service/common.service';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { accountServices } from '../../accounts/account.service';
import { GenericprintComponent } from '../genericprint/genericprint.component';
import { MasterdataComponent } from '../masterdata/masterdata.component';

@Component({
  selector: 'app-vendorpayment',
  templateUrl: './vendorpayment.component.html',
  styleUrls: ['./vendorpayment.component.scss']
})
export class VendorpaymentComponent implements OnInit {
  fullScreen = false;
  payBillData: any = {
    payment_type: 'normal',
    mop: '',
    checkbookid: '',
    notify: '0',
    change: '0.00',
    amt: '0.00',
    leave_debit: false,
    bankid: ''
  };
  paybill: any = {
    payment: '',
    checkbook: '',
  };
  public arrowicon: boolean = true;
  totalPayment: any;
  public isShow: boolean = false;
  totalDue: any;
  supplierlist: any = []
  decimal: any;
  paymentTypes: any = [];
  paymentMethods: any = [];
  checkbookList: any = [];
  @Input('id') id;
  public projectData = []
  duepayments: any = [];
  isshowduepayments = false;
  userdata: any;
  appname = environment.APP_NAME;
  maincheckbox: any;
  selectedMOP: any;
  currencyList: any = [];
  companyBanks: any = [];
  isShowCheckBook = false;
  constructor(private activeModal: NgbActiveModal, private appService: AppServices, public toastr: NotificationService, private modalService: NgbModal, private loader: NgxUiLoaderService, private service: accountServices, private commanService: CommonServices, private datepipe: DatePipe,private toaster:ToastrService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    this.decimal = this.userdata.decimals_allowed;
    //date range by default 30 days added
    this.paybill.enddate = this.userdata.enddate;
    this.paybill.fromdate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    this.commanService.getComboEnums('paymenttype').subscribe(res => {
      this.paymentTypes = res;
    }, err => {
    });
    this.appService.getCurrency().subscribe(res => {
      this.currencyList = res;
    }, err => {
    });
    this.getpaymentMethods();
  }
  getPaymentAccounts() {
    var type = '';
    this.paymentMethods.forEach(item => {
      if (item.Value == this.payBillData.mop) {
        type = item.Value1 ? item.Value1 : '';
      }
    })
    this.isShowCheckBook = false;
    this.payBillData.checkbookid = '';
    this.payBillData.number = '';
    if (type == 'cheque') {
      this.isShowCheckBook = true;
    }
    this.commanService.getCompBankList(type).subscribe(res => {
      this.companyBanks = res;
    }, err => {
    });
  }
  //open Master Modal code
  openPaymentModal() {
    // var modalRef = this.modalService.open(MasterdataComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
    var modalRef = null;
    modalRef = this.modalService.open(MasterdataComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.paymentmethod = 'paymentmethod';
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  ngOnInit(): void {
    this.paybill.supplierid = this.id;
    var editDataForHeader = '';
    this.commanService.headerEditItemData.next(editDataForHeader); //end '
    this.getPayBillData();
  }
  searchPaybill() {
    this.paybill.fromdate = this.datepipe.transform(this.paybill.fromdate, this.userdata.postdatetimeformat);
    this.paybill.enddate = this.datepipe.transform(this.paybill.enddate, this.userdata.postdatetimeformat);
    this.getPayBillData();
  }

  getPayBillData() {
    this.loader.start();
    this.service.getPaybill('', this.paybill.supplierid, 'supplier', this.paybill.fromdate, this.paybill.enddate).subscribe(data => {
      this.loader.stop();
      this.payBillData = data;
      if (this.payBillData.currency) {
        this.selectROE()
      }
      this.payBillData.notify = '0';
      this.payBillData.mop = this.payBillData.mop ? this.payBillData.mop : '';
      this.payBillData.checkbookid = this.payBillData.checkbookid ? this.payBillData.checkbookid : '';
      this.payBillData.bankid = this.payBillData.bankid ? this.payBillData.bankid : '';
      this.payBillData.payment_type = 'normal';
      environment.production ? '' : console.log('paybill data', this.payBillData);
      if (this.payBillData.duebills) {
        this.totalPayment = 0
        this.duepayments = this.payBillData.duebills;
        this.duepayments.forEach(pay => {
          if (pay.ttype == 1) {
            pay.type = 'Purchase Order';
          } else if (pay.ttype == 2) {
            pay.type = 'Purchase Invoice';
          } else if (pay.ttype == 3) {
            pay.type = 'Expense';
          }
        })
        if (this.duepayments.length > 0) {
          this.isshowduepayments = true;
        }
      }
      this.calculateLineItemTotal();
    }, err => {
      this.loader.stop();
    })
  }
  dropdowntoggle() {
    this.isShow = !this.isShow;
  }
  clickEvent() {
    this.arrowicon = !this.arrowicon;
  }

  selectSupplier(item) {
    this.paybill.supplierid = Number(item.Value);
    this.paybill.fromdate = this.datepipe.transform(this.paybill.fromdate, this.userdata.postdatetimeformat);
    this.paybill.enddate = this.datepipe.transform(this.paybill.enddate, this.userdata.postdatetimeformat);
    this.getPayBillData();
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  getpaymentMethods() {
    this.commanService.getPaymentMethods().subscribe(res => {
      this.paymentMethods = res;
      environment.production ? '' : console.log('payment method data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading payment method data', err);
    });
  }
  getCheckBooks() {
    this.commanService.getCheckBooks(this.payBillData.bankid).subscribe(res => {
      this.checkbookList = res;
    }, err => {
    });
  }
  getCheckBookNos() {
    this.commanService.getCheckBookNos(this.payBillData.checkbookid).subscribe((res: any) => {
      this.payBillData.number = res.refid;
      environment.production ? '' : console.log('checkbookNos data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading checkbookNos data', err);
    });
  }

  selectCheckBooks() {
    this.selectedMOP = null;
    this.payBillData.checkbookid = '';
    this.payBillData.number = '';
    if (this.payBillData.mop == '') {
      return;
    }
    this.paymentMethods.forEach(mop => {
      if (mop.Value == this.payBillData.mop) {
        this.selectedMOP = mop;
      }
    })
    if (this.selectedMOP.Value1 == 'cheque') {
      this.getCheckBooks();
    }
  }

  onFocused($event) {
    // this.vendor.clear();
  }


  updatePayAmt() {
    if (this.payBillData.amt == undefined || this.payBillData.amt == null || this.payBillData.amt == '') {
      this.payBillData.amt = Number(0).toFixed(this.userdata.decimals_allowed);
    }
    if (Number(this.payBillData.amt) > Number(this.totalPayment)) {
      this.payBillData.change = Number(this.payBillData.amt) - Number(this.totalPayment);
    } else {
      this.payBillData.change = 0;
    }
    this.payBillData.change = Number(this.payBillData.change).toFixed(this.userdata.decimals_allowed);
  }

  calculateLineItemTotal() {
    this.totalPayment = 0;
    this.totalDue = 0;
    this.payBillData.amt = 0;
    this.payBillData.change = Number(0).toFixed(this.userdata.decimals_allowed);
    this.maincheckbox = true;
    this.duepayments.forEach(element => {
      if (element.pay > element.bal) {
        element.pay = Number(element.bal);
        element.checked = true;
      } else if (element.pay > 0) {
        element.checked = true;
      }
      if (element.checked) {
        element.pay = (element.pay > 0) ? Number(element.pay).toFixed(this.userdata.decimals_allowed) : Number(element.bal).toFixed(this.userdata.decimals_allowed);
      } else {
        this.maincheckbox = false;
        element.pay = Number(0).toFixed(this.userdata.decimals_allowed);
      }
      this.totalPayment += Number(element.pay);
      this.totalDue += Number(element.bal);
      element.bal = Number(element.bal).toFixed(this.userdata.decimals_allowed);
    });
    this.totalPayment = Number(this.totalPayment).toFixed(this.userdata.decimals_allowed);
    this.totalDue = Number(this.totalDue).toFixed(this.userdata.decimals_allowed);
    this.payBillData.amt = this.totalPayment;
  }
  billPayment() {
    var duebills = [];
    this.duepayments.forEach(element => {
      if (element.checked == true) {
        duebills.push(element);
      }
    });
    if (!this.payBillData.mop) {
      this.toastr.Error("Please select payment method");
      return false;
    }
   
    if (!this.payBillData.bankid) {
      this.toastr.Error("Please select Bank name");
      return false;
    }
    if (this.selectedMOP.Value1 == 'cheque' && this.payBillData.number == '') {
      this.toastr.Error("Please select payment cheque");
      return;
    }
   
    if (!this.payBillData.currency) {
      this.toastr.Error("Please select Currency");
      return ;
    }
   
    if (this.payBillData.amt == undefined || this.payBillData.amt == null || this.payBillData.amt <= 0) {
      this.toastr.Error("Please enter amount");
      return;
    }
    if (Number(this.payBillData.amt) < this.totalPayment) {
      this.toastr.Error('Please check payment amount, it can not be less than bill payment grand total');
      return;
    }
    if (!this.payBillData.currency) {
      this.toastr.Error('Please select currency');
      return;
    }
    if (this.payBillData.memo == undefined || this.payBillData.memo == null || this.payBillData.memo == '') {
      this.toastr.Error("Please enter memo");
      return;
    }
    var postData = {
      "id": null,
      "notes": this.payBillData.notes,
      "memo": this.payBillData.memo,
      "change": this.payBillData.change,
      "leave_debit": this.payBillData.leave_debit,
      "number": this.payBillData.number,
      "currency": this.userdata.currency,
      "amt": Number(this.payBillData.amt),
      "availbalance": this.payBillData.availbalance,
      "supplierid": this.paybill.supplierid,
      "companyid": this.userdata.companyid,
      "checkbookid": this.payBillData.checkbookid,
      "supplier_name": this.payBillData.name,
      "notify": this.payBillData.notify,
      "mop": Number(this.payBillData.mop),
      "payment_type": this.payBillData.payment_type,
      "trandate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "createby": this.userdata.email,
      "companyname": this.userdata.compayname,
      "phone": this.userdata.phone,
      "duebills": duebills,
      "roe": this.payBillData.roe,
      "bankid": this.payBillData.bankid
    }
    environment.production ? '' : console.log(JSON.stringify(postData));
    this.loader.start();
    this.service.postBillPayment(postData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      this.maincheckbox = false;
      this.getPayBillData();
    }, err => {
      this.loader.stop();
    })
  }
  onChange() {
    if (this.maincheckbox) {
      this.duepayments.forEach(element => {
        element.checked = true;
        element.pay = Number(element.bal);
      });
    } else {
      this.duepayments.forEach(element => {
        element.checked = false;
        element.pay = 0;
      });
    }
    this.calculateLineItemTotal();
  }
  onItemChange(item) {
    if (item.checked) {
      item.pay = Number(item.bal);
    } else {
      item.pay = 0;
    }
    this.calculateLineItemTotal();
  }
  print(item) {
    var printtype;
    if (item.ttype == 1) {
      printtype = 'printpo';
    } else if (item.ttype == 2) {
      printtype = 'printpi';
    } else if (item.ttype == 3) {
      printtype = 'printe';
    }
    var modalRef = null;
    modalRef = this.modalService.open(GenericprintComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
    modalRef.componentInstance.id = item.refid;
    modalRef.componentInstance.name = printtype;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }
  selectROE() {
    this.currencyList.forEach(element => {
      if (this.payBillData.currency == element.Value) {
        this.payBillData.roe = Number(element.Value2).toFixed(this.userdata.decimals_allowed) ? Number(element.Value2).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      }
      else if (!this.payBillData.currency) {
        this.payBillData.roe = '';
      }
    })
  }
  getCurrentROE() {
		if (!this.payBillData.currency) {
			this.toaster.error('Please select currency');
			return;
		}
		this.loader.start();
		this.commanService.getExchangeRate(this.userdata.currency, this.payBillData.currency).subscribe(res => {
			this.loader.stop();
			this.payBillData.roe = res ? Number(res).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
		}, err => {
			this.loader.stop();
		});
	}
}
