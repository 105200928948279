<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="exampleModalLabel">Customer Credits</h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="needs-validation">
                <h5>Customer Name: <span *ngIf="credit">{{credit.customer}}</span> </h5> <span class="custom-credit">Available Credit :
                    {{credtitotal}}</span>
                <div class="form row">
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="">Date of Payment</label>
                        <mat-form-field class="bg-white">
                            <input matInput [matDatepicker]="picker3" placeholder="Date of Payment"
                                [(ngModel)]="credit.trandate" name="trandate">
                            <mat-datepicker-toggle matSuffix [for]="picker3">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="">Amount</label>
                        <input type="text" class="form-control text-right" readonly name="amt" [(ngModel)]="credit.amt">
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="">Currency</label>
                        <select class="form-control" required="" name="currency" [(ngModel)]="credit.currency"
                            (change)="selectROE()" #currencyname="ngModel"
                            [ngClass]="{ 'is-invalid': currencyname.invalid && currencyname.errors , 'is-valid': currencyname.valid}">
                            <option value="">Select Currency</option>
                            <option *ngFor="let item of currencyList" [value]="item.Value">
                                {{item.Text}}</option>
                        </select>
                        <div *ngIf="currencyname.invalid && currencyname.errors" class="invalid-feedback d-block">
                            <div *ngIf="currencyname.errors.required">Currency is
                            required</div>
                        </div>
                       
                    </div>

                    <div class="form-group col-md-3 col-sm-12">
                        <label for="">XR</label>
                        <div class="input-group">
                        <input type="text" class="form-control text-right" placeholder="0" name="roe" [(ngModel)]="credit.roe" #roe="ngModel"required=""  #exchnagerate="ngModel"
                        [ngClass]="{ 'is-invalid': exchnagerate.invalid && exchnagerate.errors , 'is-valid': exchnagerate.valid}">
                        <div class="input-group-append">
                            <span class="input-group-text btn-primary cursor-pointer" id="basic-addon2"
                              (click)="getCurrentROE()"><i class="fa fa-refresh"></i>
                            </span>
                      </div>
                    </div>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <div class="form-group">
                            <label>Notify Customer</label>
                            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                <label class="d-block" for="showMultiple">
                                    <input class="radio_animated" [value]=true [(ngModel)]="credit.notify_customer"
                                        id="showMultiple33" type="radio" name="rdo-ani33">
                                    Yes
                                </label>
                                <label class="d-block" for="showMultiple1">
                                    <input class="radio_animated" [value]=false [(ngModel)]="credit.notify_customer"
                                        id="showMultiple44" type="radio" name="rdo-ani44">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="">Payment Type </label>
                        <select class="form-control" readonly required="" name="paymenttype" [(ngModel)]="credit.payment_type">
                            <option value="">Select Type</option>
                            <option *ngFor="let item of paymentTypeList" [value]="item.stringValue">
                                {{item.Text}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3 col-sm-12">
                        <label for="">Memo</label>
                        <input type="text" class="form-control" name="meno" [(ngModel)]="credit.memo"
                        #memo="ngModel"
                            [ngClass]="{ 'is-invalid': memo.invalid && memo.errors , 'is-valid': memo.valid}" required>
                        <div *ngIf="memo.invalid && memo.errors" class="invalid-feedback d-block">
                        <div *ngIf="memo.errors.required">Memo is required</div>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="">Notes</label>
                        <textarea class="form-control" rows="5" name="notes" [(ngModel)]="credit.notes"></textarea>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="">Due Invoices</label>
                        <div class="category-table custom-datatable">
                            <div class="table-responsive">
                                <table class="input-table">
                                    <tr class="custom-tr">
                                        <th class="custom-th text-center">
                                            <div class="">
                                                <label class="mb-0" for="flexCheckDefault">
                                                    Ref#
                                                </label>
                                            </div>
                                        </th>
                                        <th class="custom-th text-center">Inv Date</th>
                                        <th class="custom-th text-center">Payment Terms</th>
                                        <th class="custom-th text-center">Balance</th>
                                        <th class="custom-th text-center">Applied Amount</th>
                                        <th class="custom-th text-center">Actions</th>
                                    </tr>
                                    <ng-container *ngIf="receivePayment">
                                        <tr class="custom-tr" *ngFor="let item of credit.allDues; let j = index; ">
                                            <td class="custom-td text-center pl-2">
                                                <div class="d-flex align-items-cenetr">
                                                    <input class="mb-0 mx-1" type="checkbox" name="pay_id-{{j}}" value=""
                                                        id="flexCheckDefault" [(ngModel)]="item.checked"
                                                        (click)="selectCreditLineItemCheckBox($event,item)">
                                                    <label class="mb-0" for="flexCheckDefault">
                                                        {{item.refno}}
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="custom-td text-center">
                                                {{item.invdate}}
                                            </td>
                                            <td class="custom-td text-right">
                                                {{item.payment_terms}}
                                            </td>
                                            <td class="custom-td text-right">
                                                {{item.bal}}
                                            </td>
                                            <td class="custom-td text-right">
                                                <input class="form-control" value="0.00" name="pay_applied-{{j}}"
                                                    [(ngModel)]="item.pay_applied" (focusout)="calculatetotal(item)">
                                            </td>
                                            <td class="custom-td text-center">
                                                <div ngbDropdown class="d-inline-block ActionCell" container="body">
                                                    <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                                                    <ul ngbDropdownMenu class="ActionCell-List"
                                                        aria-labelledby="dropdownBasic1">
                                                        <li (click)="deleteLine(item)"><span ngbDropdownItem>Delete</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="custom-tr">
                                            <td class="custom-td text-right mr-2" colspan="3">Totals</td>
                                            <td class="custom-td text-right mr-2" colspan="1">{{balanceTotal}}</td>
                                            <td class="custom-td text-right mr-2" colspan="1" style="text-align: right !important;">{{appliedPayTotal}}</td>
                                        </tr>
                                    </ng-container>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
            <button type="button" class="btn btn-primary" (click)="postCredit()">Save</button>
        </div>
    </div>
</resize-border>