<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="Add Substitute Product"
                data-target="#exampleModal" [class.disabled]="fieldsetDisabled" (click)="open(content)"><i
                  class="fa fa-plus"></i> </a></th>
            <th class="custom-th text-center">ILC</th>
            <th class="custom-th text-center">AILC</th>
            <th class="custom-th text-center">Description</th>
            <th class="custom-th text-center">Supplier</th>
            <th class="custom-th text-center">Landed Cost</th>
            <th class="custom-th text-center">Price</th>
            <th class="custom-th text-center">PO Cost</th>
            <th class="custom-th text-center">CreateBy </th>
            <th class="custom-th text-center">CreateDate </th>
            <th class="custom-th text-center">UpdateBy </th>
            <th class="custom-th text-center"> UpdateDate </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of substituteItemList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled">Delete</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.ilc}}</td>
            <td class="custom-td text-left">{{item.alic}}</td>
            <td class="custom-td text-left">{{item.description}}</td>
            <td class="custom-td text-left">{{item.supplier}}</td>
            <td class="custom-td text-right">{{item.landedcost}}</td>
            <td class="custom-td text-right">{{item.price}}</td>
            <td class="custom-td text-right">{{item.pocost}}</td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.createdate | date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.updatedate | date:userdata.displaydatetimeformat}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Substitute product</h5>
      <button type="button" class="close" aria-label="Close" (click)="closemodal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation">
        <div class="form">
          <div class="row align-item-bottom-flex-end">
            <div class="form-group col-lg-2 ">
              <label for="" class="mb-1">Supplier List</label>
              <select class="form-control" required="" name="supplier" [(ngModel)]="subStitudeData.supplierid"
                (change)="getSearSubstitudeItems();">
                <option value="">Select Supplier</option>
                <option *ngFor="let item of supplierList" [value]="item.Value">
                  {{item.Text}}</option>
              </select>
            </div>
            <div class="form-group col-lg-2 pl-0">
              <label for="" class="mb-1">Department</label>
              <select class="form-control" required="" name="dept" [(ngModel)]="subStitudeData.dept"
                (change)="getCatUsingDept();getSearSubstitudeItems()">
                <option value="">Select Department</option>
                <option *ngFor="let item of deptList" [value]="item.Value">
                  {{item.Text}}</option>
              </select>
            </div>
            <div class="form-group col-lg-2 pl-0">
              <label for="" class="mb-1">Category</label>
              <select class="form-control" required="" name="category" [(ngModel)]="subStitudeData.cat"
                (change)="getSubCatUsingCategory();getSearSubstitudeItems()">
                <option value="">Select Category</option>
                <option *ngFor="let item of catList" [value]="item.Value">
                  {{item.Text}}</option>
              </select>
            </div>
            <div class="form-group col-lg-2 pl-0">
              <label for="" class="mb-1">Sub category</label>
              <select class="form-control" required="" name="subcat" [(ngModel)]="subStitudeData.subcat"
                (change)="getSearSubstitudeItems();">
                <option value="">Select Sub</option>
                <option *ngFor="let item of subCatlist" [value]="item.Value">
                  {{item.Text}}</option>
              </select>
            </div>
            <div class="form-group col-lg-3 pl-0">
              <label for="" class="mb-1">ILC/Name/Description</label>
              <input class="form-control" type="text" name="searchtxt" placeholder="ILC/Name/Description"
                [(ngModel)]="subStitudeData.searchtxt">
            </div>
            <div class="form-group col-lg-1 searchbutton">
              <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
                (click)="getSearSubstitudeItems()"><i class="fa fa-search"></i></button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="custom-table">
              <tr class="custom-tr">
                <th style="width: 5%;" class="custom-th text-center"></th>
                <th class="custom-th text-center">ILC</th>
                <th class="custom-th text-center">Description</th>
                <th class="custom-th text-center">PO Cost</th>
                <th class="custom-th text-center">Landed Cost</th>
                <th class="custom-th text-center">Donotorder</th>
                <th class="custom-th text-center">Milc</th>
                <th class="custom-th text-center">Pic</th>
                <th class="custom-th text-center">Pur. UOM</th>
                <th class="custom-th text-center">Qty</th>
                <th class="custom-th text-center">Qty Available</th>
                <th class="custom-th text-center">Qty OnHand</th>
                <th class="custom-th text-center">Qty OnOrder</th>
                <th class="custom-th text-center">Reorder Qty</th>
                <th class="custom-th text-center">Supplier</th>
                <th class="custom-th text-center">TP Code</th>

              </tr>
              <tr class="custom-tr" *ngFor="let item of substituteSearchItemList">
                <td class="custom-td text-center">
                  <div ngbDropdown class="d-inline-block ActionCell" container="body">
                    <!-- <input type="checkbox" name="selectItem" [(ngModel)]="item.isSelect" (click)="postSingleItem(item)">  -->
                    <input type="checkbox" name="test" (change)="postSingleItem($event,item)" />
                  </div>
                </td>
                <td class="custom-td text-left">{{item.ilc}}</td>
                <td class="custom-td text-left">{{item.desc}}</td>
                <td class="custom-td text-right">{{item.pocost}}</td>
                <td class="custom-td text-right">{{item.landedcost}}</td>
                <td class="custom-td text-left">{{item.donotorder}}</td>
                <td class="custom-td text-center">{{item.milc}}</td>
                <td class="custom-td text-left">{{item.pic}}</td>
                <td class="custom-td text-center">{{item.puom}}</td>
                <td class="custom-td text-center">{{item.qty}}</td>
                <td class="custom-td text-center">{{item.qtyavailable}}</td>
                <td class="custom-td text-center">{{item.qtyonhand}}</td>
                <td class="custom-td text-center">{{item.qtyonorder}}</td>
                <td class="custom-td text-center">{{item.reorderqty}}</td>
                <td class="custom-td text-left">{{item.supplier}}</td>
                <td class="custom-td text-center">{{item.tpcode}}</td>
              </tr>
            </table>
          </div>
        </div>
      </form>
    </div>
  </resize-border>
</ng-template>