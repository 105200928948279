import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'bintemplates',
    loadChildren: () => import('../../components/inventory/bintemplates/bintemplates.module').then(m => m.BintemplatesModule),
    data: {
      breadcrumb: 'Bin Templates'
    }
  },
  {
    path: 'inventoryadjustments',
    loadChildren: () => import('../../components/inventory/inventoryadjustments/inventoryadjustments.module').then(m => m.InventoryadjustmentsModule),
    data: {
      breadcrumb: 'Inventory Ajustments'
    }
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/inventory/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: 'Products'
    }
  },
  {
    path: 'emptimesheet',
    loadChildren: () => import('../../components/projectmanager/emptimesheet/emptimesheet.module').then(m => m.EmptimesheetModule),
    data: {
      breadcrumb: 'Timesheet'
    }
  },
  {
    path: 'projects',
    loadChildren: () => import('../../components/projectmanager/projects/projects.module').then(m => m.ProjectsModule),
    data: {
      breadcrumb: 'Projects'
    }
  },
  {
    path: 'projecttypes',
    loadChildren: () => import('../../components/projectmanager/projecttypes/projecttypes.module').then(m => m.ProjecttypesModule),
    data: {
      breadcrumb: 'Project Types'
    }
  },
  {
    path: 'tasktypes',
    loadChildren: () => import('../../components/projectmanager/tasktypes/tasktypes.module').then(m => m.TasktypesModule),
    data: {
      breadcrumb: 'Task Types'
    }
  },
  {
    path: 'projectmodules',
    loadChildren: () => import('../../components/projectmanager/projectmodules/projectmodules.module').then(m => m.ProjectmodulesModule),
    data: {
      breadcrumb: 'Project Module'
    }
  },
  {
    path: 'syncbatches',
    loadChildren: () => import('../../components/integrations/syncbatches/syncbatches.module').then(m => m.SyncbatchesModule),
    data: {
      breadcrumb: 'Sync batches'
    }
  },
  {
    path: 'pull',
    loadChildren: () => import('../../components/integrations/pull/pull.module').then(m => m.PullModule),
    data: {
      breadcrumb: 'Pull'
    }
  },
  {
    path: 'thirdpartyapps',
    loadChildren: () => import('../../components/integrations/thirdparty/thirdparty.module').then(m => m.ThirdpartyModule),
    data: {
      breadcrumb: 'Third Party App'
    }
  },
  {
    path: 'assemblies',
    loadChildren: () => import('../../components/inventory/assemblies/assemblies.module').then(m => m.AssembliesModule),
    data: {
      breadcrumb: 'Assemblies'
    }
  },
  {
    path: 'transferrequest',
    loadChildren: () => import('../../components/inventory/transfer-request/transfer-request.module').then(m => m.TransferRequestModule),
    data: {
      breadcrumb: 'Transfer Request'
    }
  },
  {
    path: 'categories',
    loadChildren: () => import('../../components/inventory/category/category.module').then(m => m.CategoryModule),
    data: {
      breadcrumb: 'Categories'
    }
  },
  {
    path: 'binlocations',
    loadChildren: () => import('../../components/inventory/binlocation/binlocation.module').then(m => m.BinlocationModule),
    data: {
      breadcrumb: 'Bin Locations'
    }
  },
  {
    path: 'pricings',
    loadChildren: () => import('../../components/inventory/pricings/pricings.module').then(m => m.PricingsModule),
    data: {
      breadcrumb: 'Pricings'
    }
  },
  {
    path: 'bulkpricingchange',
    loadChildren: () => import('../../components/inventory/bulkpricings/bulkpricings.module').then(m => m.BulkPricingsModule),
    data: {
      breadcrumb: 'Bulk Pricing Change'
    }
  },
  {
    path: 'employmentoffers',
    loadChildren: () => import('../../components/hr/offers/offers.module').then(m => m.OffersModule),
    data: {
      breadcrumb: 'Offers'
    }
  },
  {
    path: 'employees',
    loadChildren: () => import('../../components/hr/employees/employees.module').then(m => m.EmployeesModule),
    data: {
      breadcrumb: 'Employees'
    }
  },
  {
    path: 'companysalarycomponent',
    loadChildren: () => import('../../components/hr/salarycomponent/salarycomponent.module').then(m => m.SalarycomponentModule),
    data: {
      breadcrumb: 'Salary Components'
    }
  },
  {
    path: 'weeklyoff',
    loadChildren: () => import('../../components/hr/weeklyoff/weeklyoff.module').then(m => m.WeeklyoffModule),
    data: {
      breadcrumb: 'Weekly Off'
    }
  },
  {
    path: 'employeeassesment',
    loadChildren: () => import('../../components/hr/employeeassessments/employeeassessments.module').then(m => m.EmployeeassessmentsModule),
    data: {
      breadcrumb: 'Employee Assessments'
    }
  },
  {
    path: 'jobapplications',
    loadChildren: () => import('../../components/hr/jobapplications/jobapplications.module').then(m => m.JobapplicationsModule),
    data: {
      breadcrumb: 'Job Applications'
    }
  },
  {
    path: 'jobopenings',
    loadChildren: () => import('../../components/hr/jobopening/jobopening.module').then(m => m.JobopeningModule),
    data: {
      breadcrumb: 'Job Openings'
    }
  },
  {
    path: 'manualcount',
    loadChildren: () => import('../../components/inventory/manualcount/manualcount.module').then(m => m.ManualCountModule),
    data: {
      breadcrumb: 'Cycle Count'
    }
  },
  {
    path: 'projecttask',
    loadChildren: () => import('../../components/projectmanager/projecttask/projecttask.module').then(m => m.ProjecttaskModule),
    data: {
      breadcrumb: 'Project Task'
    }
  },
  {
    path: 'departments',
    loadChildren: () => import('../../components/inventory/departments/departments.module').then(m => m.DepartmentsModule),
    data: {
      breadcrumb: 'Service Manager'
    }
  },
  {
    path: 'itemrequests',
    loadChildren: () => import('../../components/inventory/itemrequests/itemrequests.module').then(m => m.ItemrequestsModule),
    data: {
      breadcrumb: 'Departments'
    }
  },
  {
    path: 'snagbugs',
    loadChildren: () => import('../../components/projectmanager/snags-bugs/snags-bugs.module').then(m => m.snagsbugsModule),
    data: {
      breadcrumb: 'List of Snags'
    }
  },
  {
    path: 'insights',
    loadChildren: () => import('../../components/insights/insights.module').then(m => m.InsightsModule),
    data: {
      breadcrumb: 'Insights'
    }
  },
  {
    path: 'loans',
    loadChildren: () => import('../../components/accounts/loans/loans.module').then(m => m.LoansModule),
    data: {
      breadcrumb: 'Loans'
    }
  },
  {
    path: 'payrollpaymenttypes',
    loadChildren: () => import('../../components/accounts/payrollpaymenttypes/payrollpaymenttypes.module').then(m => m.PayrollpaymenttypesModule),
    data: {
      breadcrumb: 'Payroll Payment Types'
    }
  },
  {
    path: 'paymentsreceived',
    loadChildren: () => import('../../components/accounts/paymentsreceived/paymentsreceived.module').then(m => m.PaymentsReceivedModule),
    data: {
      breadcrumb: 'Payments Received'
    }
  },
  {
    path: 'paymentpaid',
    loadChildren: () => import('../../components/accounts/paymentpaid/paymentpaid.module').then(m => m.PaymentPaidModule),
    data: {
      breadcrumb: 'Payments Paid'
    }
  },
  {
    path: 'fundtransferrequests',
    loadChildren: () => import('../../components/accounts/fundtransferrequests/fundtransferrequests.module').then(m => m.FundtransferrequestsModule),
    data: {
      breadcrumb: 'Fund Transfer Requests'
    }
  },
  {
    path: 'apstatement',
    loadChildren: () => import('../../components/accounts/apstatement/apstatement.module').then(m => m.ApstatementsModule),
    data: {
      breadcrumb: 'AP Statements'
    }
  },
  {
    path: 'arcustomerstatements',
    loadChildren: () => import('../../components/accounts/arcustomerstatements/arcustomerstatements.module').then(m => m.ArcustomerstatementsModule),
    data: {
      breadcrumb: 'AR Statements'
    }
  },
  {
    path: 'chartofaccounts',
    loadChildren: () => import('../../components/accounts/chartofaccounts/chartofaccounts.module').then(m => m.ChartofaccountsModule),
    data: {
      breadcrumb: 'Chart of Accounts'
    }
  },
  {
    path: 'banks',
    loadChildren: () => import('../../components/accounts/banks/banks.module').then(m => m.BanksModule),
    data: {
      breadcrumb: 'Banks'
    }
  },
  {
    path: 'financialyears',
    loadChildren: () => import('../../components/accounts/financialyears/financialyears.module').then(m => m.FinancialyearsModule),
    data: {
      breadcrumb: 'Financial Years'
    }
  },
  {
    path: 'taxconfiguration',
    loadChildren: () => import('../../components/accounts/taxconfiguration/taxconfiguration.module').then(m => m.TaxconfigurationModule),
    data: {
      breadcrumb: 'Tax Configuration'
    }
  },
  {
    path: 'currencies',
    loadChildren: () => import('../../components/accounts/currencies/currencies.module').then(m => m.CurrenciesModule),
    data: {
      breadcrumb: 'Currencies'
    }
  },
  {
    path: 'paymentmethod',
    loadChildren: () => import('../../components/accounts/paymentmethod/paymentmethod.module').then(m => m.PaymentmethodModule),
    data: {
      breadcrumb: 'Payment Method'
    }
  },
  {
    path: 'chequebooks',
    loadChildren: () => import('../../components/accounts/chequebooks/chequebooks.module').then(m => m.ChequebooksModule),
    data: {
      breadcrumb: 'Cheque Books'
    }
  },
  {
    path: 'customerdeposits',
    loadChildren: () => import('../../components/accounts/customerdeposits/customerdeposits.module').then(m => m.CustomerdepositsModule),
    data: {
      breadcrumb: 'Customer Deposits'
    }
  },
  {
    path: 'financialcharges',
    loadChildren: () => import('../../components/accounts/financialcharges/financialcharges.module').then(m => m.FinancialchargesModule),
    data: {
      breadcrumb: 'Financial Charges'
    }
  },
  {
    path: 'financialstatements',
    loadChildren: () => import('../../components/accounts/financialstatements/financialstatements.module').then(m => m.FinancialstatementsModule),
    data: {
      breadcrumb: 'Financial Statements'
    }
  },
  {
    path: 'journalentries',
    loadChildren: () => import('../../components/accounts/journalentries/journalentries.module').then(m => m.JournalentriesModule),
    data: {
      breadcrumb: 'Journal Entries'
    }
  },
  {
    path: 'ledgers',
    loadChildren: () => import('../../components/accounts/ledgers/ledgers.module').then(m => m.LedgersModule),
    data: {
      breadcrumb: 'Ledgers'
    }
  },
  {
    path: 'banking',
    loadChildren: () => import('../../components/accounts/banking/banking.module').then(m => m.BankingModule),
    data: {
      breadcrumb: 'Categorize Transactions'
    }
  },
  {
    path: 'creditnotes',
    loadChildren: () => import('../../components/accounts/creditnotes/creditnotes.module').then(m => m.CreditnotesModule),
    data: {
      breadcrumb: 'Accounts'
    }
  },
  {
    path: 'debitnotes',
    loadChildren: () => import('../../components/accounts/debitnotes/debitnotes.module').then(m => m.DebitnotesModule),
    data: {
      breadcrumb: 'Accounts'
    }
  },
  {
    path: 'writeoffs',
    loadChildren: () => import('../../components/accounts/writeoffs/writeoffs.module').then(m => m.WriteoffsModule),
    data: {
      breadcrumb: 'Write Offs'
    }
  },
  {
    path: 'testcases',
    loadChildren: () => import('../../components/projectmanager/testcases/testcases.module').then(m => m.testcasesModule),
    data: {
      breadcrumb: 'Testcases'
    }
  },
  {
    path: 'unbilledhours',
    loadChildren: () => import('../../components/projectmanager/unbilledhours/unbilledhours.module').then(m => m.UnbilledhoursModule),
    data: {
      breadcrumb: 'Unbilled Hours'
    }
  },
  {
    path: 'batchtransactions',
    loadChildren: () => import('../../components/accounts/batchtransactions/batchtransactions.module').then(m => m.BatchtransactionsModule),
    data: {
      breadcrumb: 'Batch Transactions'
    }
  },
  {
    path: 'vendorpayments',
    loadChildren: () => import('../../components/accounts/vendorpayments/vendorpayments.module').then(m => m.VendorpaymentsModule),
    data: {
      breadcrumb: 'Supplier Payments'
    }
  },
  {
    path: 'paybills',
    loadChildren: () => import('../../components/accounts/paybills/paybills.module').then(m => m.PaybillsModule),
    data: {
      breadcrumb: 'Bill Payments'
    }
  },
  {
    path: 'contraentries',
    loadChildren: () => import('../../components/accounts/contraentries/contraentries.module').then(m => m.ContraentriesModule),
    data: {
      breadcrumb: 'Contra Entries'
    }
  },
  {
    path: 'reconcile',
    loadChildren: () => import('../../components/accounts/reconcile/reconcile.module').then(m => m.ReconcileModule),
    data: {
      breadcrumb: 'Reconcile'
    }
  },
  {
    path: 'weeklytimesheets',
    loadChildren: () => import('../../components/servicemanager/weeklytimesheets/weeklytimesheets.module').then(m => m.WeeklytimesheetsModule),
    data: {
      breadcrumb: 'Service Manager',
    }
  },
  {
    path: 'techourdetail',
    loadChildren: () => import('../../components/servicemanager/techourdetail/techourdetail.module').then(m => m.TechourdetailModule),
    data: {
      breadcrumb: 'Service Manager',
    }
  },
  {
    path: 'techtimesheet',
    loadChildren: () => import('../../components/servicemanager/techtimesheet/techtimesheet.module').then(m => m.TechtimesheetModule),
    data: {
      breadcrumb: 'Service Manager',
    }
  },
  {
    path: 'ticketstatus',
    loadChildren: () => import('../../components/servicemanager/ticketstatus/ticketstatus.module').then(m => m.TicketstatusModule),
    data: {
      breadcrumb: 'Service Manager',
    }
  },
  {
    path: 'checklistgroup',
    loadChildren: () => import('../../components/servicemanager/checklistgroup/checklistgroup.module').then(m => m.ChecklistgroupModule),
    data: {
      breadcrumb: 'Service Manager'
    }
  },
  {
    path: 'checklists',
    loadChildren: () => import('../../components/servicemanager/checklists/checklists.module').then(m => m.ChecklistsModule),
    data: {
      breadcrumb: 'Service Manager'
    }
  },
  {
    path: 'noteslist',
    loadChildren: () => import('../../components/servicemanager/noteslist/noteslist.module').then(m => m.NoteslistModule),
    data: {
      breadcrumb: 'Service Manager'
    }
  },
  {
    path: 'servicecategories',
    loadChildren: () => import('../../components/servicemanager/category/category.module').then(m => m.CategoryModule),
    data: {
      breadcrumb: 'Service Manager'
    }
  },
  {
    path: 'contracttypes',
    loadChildren: () => import('../../components/servicemanager/contracttypes/contracttypes.module').then(m => m.ContracttypesModule),
    data: {
      breadcrumb: 'Contract Types'
    }
  },
  {
    path: 'customerdevicelist',
    loadChildren: () => import('../../components/servicemanager/customerdevicelist/customerdevicelist.module').then(m => m.CustomerdevicelistModule),
    data: {
      breadcrumb: 'Customer Device'
    }
  },
  {
    path: 'birdseye',
    loadChildren: () => import('../../components/servicemanager/birdseye/birdseye.module').then(m => m.BirdseyeModule),
    data: {
      breadcrumb: 'Birds Eye'
    }
  },
  {
    path: 'trackinghistory',
    loadChildren: () => import('../../components/locationtracking/trackinghistory/trackinghistory.module').then(m => m.TrackinghistoryModule),
    data: {
      breadcrumb: 'Tracking History'
    }
  },
  {
    path: 'bolsummary',
    loadChildren: () => import('../../components/purchase/bolsummary/bolsummary.module').then(m => m.BolsummaryModule),
    data: {
      breadcrumb: 'BOL Summary'
    }
  },
  {
    path: 'traders',
    loadChildren: () => import('../../components/purchase/traders/traders.module').then(m => m.TradersModule),
    data: {
      breadcrumb: 'Exporters/Importers'
    }
  },
  {
    path: 'customdeclarations',
    loadChildren: () => import('../../components/purchase/customdeclarations/customdeclarations.module').then(m => m.CustomdeclarationsModule),
    data: {
      breadcrumb: 'Custom Declarations'
    }
  },
  {
    path: 'expenses',
    loadChildren: () => import('../../components/purchase/expenses/expenses.module').then(m => m.ExpensesModule),
    data: {
      breadcrumb: 'Expenses'
    }
  },
  {
    path: 'purchaseinvoices',
    loadChildren: () => import('../../components/purchase/purchaseinvoices/purchaseinvoices.module').then(m => m.PurchaseinvoicesModule),
    data: {
      breadcrumb: 'Purchase Invoices'
    }
  },
  {
    path: 'purchaseorder',
    loadChildren: () => import('../../components/purchase/purchaseorder/purchaseorder.module').then(m => m.PurchaseorderModule),
    data: {
      breadcrumb: 'Purchase Order'
    }
  },
  {
    path: 'purchaserequests',
    loadChildren: () => import('../../components/purchase/purchaserequest/purchaserequest.module').then(m => m.PurchaserequestModule),
    data: {
      breadcrumb: 'Purchase Request'
    }
  },
  {
    path: 'receiveitembybol',
    loadChildren: () => import('../../components/purchase/receiveitembybol/receiveitembybol.module').then(m => m.ReceiveitembybolModule),
    data: {
      breadcrumb: 'Receive BOL Items'
    }
  },
  {
    path: 'rfqlist',
    loadChildren: () => import('../../components/purchase/rfqlist/rfqlist.module').then(m => m.RfqlistModule),
    data: {
      breadcrumb: 'Request for Quotes'
    }
  },
  {
    path: 'shipto',
    loadChildren: () => import('../../components/purchase/shipto/shipto.module').then(m => m.ShiptoModule),
    data: {
      breadcrumb: 'Shipto'
    }
  },
  {
    path: 'expensecategories',
    loadChildren: () => import('../../components/purchase/expensecategories/expensecategories.module').then(m => m.ExpenseCategoriesModule),
    data: {
      breadcrumb: 'Expense Categories'
    }
  },
  {
    path: 'suppliers',
    loadChildren: () => import('../../components/purchase/suppliers/suppliers.module').then(m => m.SuppliersModule),
    data: {
      breadcrumb: 'Suppliers'
    }
  }
  //Sales pages routes,
  ,
  {
    path: 'leads',
    loadChildren: () => import('../../components/Sales/leads/leads.module').then(m => m.LeadsModule),
    data: {
      breadcrumb: 'Leads'
    },

  },
  {
    path: 'customers',
    loadChildren: () => import('../../components/Sales/customers/customers.module').then(m => m.CustomersModule),
    data: {
      breadcrumb: 'Customers'
    },

  },
  {
    path: 'invoices',
    loadChildren: () => import('../../components/Sales/invoices/invoices.module').then(m => m.InvoicesModule),
    data: {
      breadcrumb: 'Invoices'
    },

  },
  {
    path: 'estimates',
    loadChildren: () => import('../../components/Sales/estimates/estimates.module').then(m => m.EstimatesModule),
    data: {
      breadcrumb: 'Estimates'
    },
  },
  {
    path: 'quotes',
    loadChildren: () => import('../../components/Sales/quote/quote.module').then(m => m.QuotesModule),
    data: {
      breadcrumb: 'Quotes'
    },
  },
  {
    path: 'salesorders',
    loadChildren: () => import('../../components/Sales/salesorders/salesorders.module').then(m => m.SalesordersModule),
    data: {
      breadcrumb: 'Salesorders'
    },

  },
  {
    path: 'standingorders',
    loadChildren: () => import('../../components/Sales/standingorders/standingorders.module').then(m => m.StandingordersModule),
    data: {
      breadcrumb: 'Standing Order'
    },
  },
  {
    path: 'salesreq',
    loadChildren: () => import('../../components/Sales/salesreq/salesreq.module').then(m => m.SalesreqModule),
    data: {
      breadcrumb: 'Sales Requests'
    },
  },
  {
    path: 'receivepayments',
    loadChildren: () => import('../../components/Sales/receivepay/receivepay.module').then(m => m.ReceivepayModule),
    data: {
      breadcrumb: 'Receive Payments'
    },
  },
  {
    path: 'feedbacks',
    loadChildren: () => import('../../components/Sales/feedback/feedback.module').then(m => m.FeedbackModule),
    data: {
      breadcrumb: 'User Feedback'
    },
  },
  {
    path: 'deliveryroutes',
    loadChildren: () => import('../../components/Sales/deliveryroutes/deliveryroutes.module').then(m => m.DeliveryroutesModule),
    data: {
      breadcrumb: 'Delivery Routes'
    },
  },
  //specmap links
  {
    path: 'mapview',
    loadChildren: () => import('../../components/specmap/mapview/mapview.module').then(m => m.MapviewModule),
    data: {
      breadcrumb: 'Specmap'
    }
  },
  {
    path: 'markers',
    loadChildren: () => import('../../components/specmap/markers/markers.module').then(m => m.MarkersModule),
    data: {
      breadcrumb: 'Markers'
    }
  },
  {
    path: 'markertypes',
    loadChildren: () => import('../../components/specmap/markertypes/markertypes.module').then(m => m.MarkertypesModule),
    data: {
      breadcrumb: 'Marker Types'
    }
  },

  //purchase links
  {
    path: 'purchaserequest',
    loadChildren: () => import('../../components/purchase/purchaserequest/purchaserequest.module').then(m => m.PurchaserequestModule),
    data: {
      breadcrumb: 'Purchase Requests'
    },
  },
  {
    path: 'assessmentparticulars',
    loadChildren: () => import('../../components/hr/assessmentparticulars/assessmentparticulars.module').then(m => m.AssessmentparticularsModule),
    data: {
      breadcrumb: 'Assessment Particulars'
    },
  },
  {
    path: 'clockings',
    loadChildren: () => import('../../components/hr/clockings/clockings.module').then(m => m.ClockingsModule),
    data: {
      breadcrumb: 'Job Site Clock-In/Out'
    },
  },
  {
    path: 'employees',
    loadChildren: () => import('../../components/hr/employees/employees.module').then(m => m.EmployeesModule),
    data: {
      breadcrumb: 'Employees'
    },
  },
  {
    path: 'leavecategories',
    loadChildren: () => import('../../components/hr/leavecategories/leavecategories.module').then(m => m.LeavecategoriesModule),
    data: {
      breadcrumb: 'Leave Type'
    }
  },
  {
    path: 'shiftbreakreasons',
    loadChildren: () => import('../../components/hr/shiftbreakreasons/shiftbreakreasons.module').then(m => m.ShiftbreakreasonsModule),
    data: {
      breadcrumb: 'Clocking Reasons'
    }
  },
  {
    path: 'leavesconfigurations',
    loadChildren: () => import('../../components/hr/leavesconfigurations/leavesconfigurations.module').then(m => m.LeavesconfigurationsModule),
    data: {
      breadcrumb: 'Leaves Configurations'
    },
  },
  {
    path: 'leavesvacations',
    loadChildren: () => import('../../components/hr/leavesvacations/leavesvacations.module').then(m => m.LeavesvacationsModule),
    data: {
      breadcrumb: 'Leaves/Vacations'
    },
  },
  {
    path: 'publicholidays',
    loadChildren: () => import('../../components/hr/publicholidays/publicholidays.module').then(m => m.PublicholidaysModule),
    data: {
      breadcrumb: 'Public Holidays'
    },
  },
  {
    path: 'salaryincreasestructure',
    loadChildren: () => import('../../components/hr/salaryincreasestructure/salaryincreasestructure.module').then(m => m.SalaryIncreaseStructureModule),
    data: {
      breadcrumb: 'Salary Increase Structure'
    },
  },
  {
    path: 'officeclockings',
    loadChildren: () => import('../../components/hr/officeclockings/officeclockings.module').then(m => m.OfficeclockingsModule),
    data: {
      breadcrumb: 'Office Clock-In'
    },
  },
  {
    path: 'sitelogs',
    loadChildren: () => import('../../components/hr/sitelogs/sitelogs.module').then(m => m.SitelogsModule),
    data: {
      breadcrumb: 'Site Logs'
    },
  },
  {
    path: 'designations',
    loadChildren: () => import('../../components/hr/designations/designations.module').then(m => m.DesignationsModule),
    data: {
      breadcrumb: 'Designations'
    },
  },
  {
    path: 'shift',
    loadChildren: () => import('../../components/hr/shift/shift.module').then(m => m.ShiftModule),
    data: {
      breadcrumb: 'Shift'
    },
  },
  {
    path: 'payroll',
    loadChildren: () => import('../../components/payroll/payroll/payroll.module').then(m => m.PayrollModule),
    data: {
      breadcrumb: 'Payroll'
    },
  },
  {
    path: 'payrollpayments',
    loadChildren: () => import('../../components/payroll/payments/payments.module').then(m => m.PaymentsModule),
    data: {
      breadcrumb: 'Payroll Payments'
    },
  },
  {
    path: 'timeline',
    loadChildren: () => import('../../components/scheduler/timeline/timeline.module').then(m => m.TimelineModule),
    data: {
      breadcrumb: 'Scheduler'
    },
  },
  {
    path: 'servicecontracts',
    loadChildren: () => import('../../components/servicemanager/servicecontracts/servicecontracts.module').then(m => m.ServicecontractsModule),
    data: {
      breadcrumb: 'Service Manager'
    },
  },
  {
    path: 'servicetickets',
    loadChildren: () => import('../../components/servicemanager/servicetickets/servicetickets.module').then(m => m.ServiceticketsModule),
    data: {
      breadcrumb: 'Service Manager'
    },
  },
  {
    path: 'servicelineitems',
    loadChildren: () => import('../../components/servicemanager/servicelineitems/servicelineitems.module').then(m => m.ServicelineitemsModule),
    data: {
      breadcrumb: 'Service Manager'
    },
  },
  {
    path: 'checklistgroup',
    loadChildren: () => import('../../components/servicemanager/checklistgroup/checklistgroup.module').then(m => m.ChecklistgroupModule),
    data: {
      breadcrumb: 'Service Manager'
    },
  },
  {
    path: 'checklists',
    loadChildren: () => import('../../components/servicemanager/checklists/checklists.module').then(m => m.ChecklistsModule),
    data: {
      breadcrumb: 'Service Manager'
    },
  },
  {
    path: 'noteslist',
    loadChildren: () => import('../../components/servicemanager/noteslist/noteslist.module').then(m => m.NoteslistModule),
    data: {
      breadcrumb: 'Service Manager'
    },
  },
  {
    path: 'techrecommendations',
    loadChildren: () => import('../../components/servicemanager/techrecommendations/techrecommendations.module').then(m => m.TechrecommendationsModule),
    data: {
      breadcrumb: 'Service Manager'
    },
  },
  {
    path: 'customerlist',
    loadChildren: () => import('../../components/loyalityprogram/customerlist/customerlist.module').then(m => m.CustomerlistModule),
    data: {
      breadcrumb: 'Customer List'
    },
  },
  {
    path: 'productslist',
    loadChildren: () => import('../../components/loyalityprogram/productslist/productslist.module').then(m => m.ProductslistModule),
    data: {
      breadcrumb: 'Products'
    },
  },
  {
    path: 'programslist',
    loadChildren: () => import('../../components/loyalityprogram/programslist/programslist.module').then(m => m.ProgramslistModule),
    data: {
      breadcrumb: 'Programs List'
    },
  },
  {
    path: 'transactions',
    loadChildren: () => import('../../components/loyalityprogram/transactions/transactions.module').then(m => m.TransactionsModule),
    data: {
      breadcrumb: 'Transactions'
    },
  },
  // documents
  {
    path: 'documentlist',
    loadChildren: () => import('../../components/documents/documentlist/documentlist.module').then(m => m.DocumentlistModule),
    data: {
      breadcrumb: 'Document List'
    },
  },
  {
    path: 'documenttypes',
    loadChildren: () => import('../../components/documents/documenttypes/documenttypes.module').then(m => m.DocumenttypesModule),
    data: {
      breadcrumb: 'Document Types'
    },
  },
  {
    path: 'dragdropfiles',
    loadChildren: () => import('../../components/documents/dragdropfiles/dragdropfiles.module').then(m => m.DragdropfilesModule),
    data: {
      breadcrumb: 'Drap Drop Files'
    },
  },
  {
    path: 'emailers',
    loadChildren: () => import('../../components/tools/emailers/emailers.module').then(m => m.EmailersModule),
    data: {
      breadcrumb: 'Emailers'
    },
  },
  {
    path: 'manualjobs',
    loadChildren: () => import('../../components/tools/manualjobs/manualjobs.module').then(m => m.ManualjobsModule),
    data: {
      breadcrumb: 'Manual Jobs'
    },
  },
  {
    path: 'printsequence',
    loadChildren: () => import('../../components/tools/printsequence/printsequence.module').then(m => m.PrintsequenceModule),
    data: {
      breadcrumb: 'Print Sequence'
    },
  },
  {
    path: 'empearlygoinglatecoming',
    loadChildren: () => import('../../components/reports/hrpayroll/empearlygoinglatecoming/empearlygoinglatecoming.module').then(m => m.EmpearlygoinglatecomingModule),
    data: {
      breadcrumb: 'Bank Transactions'
    },
  },
  {
    path: 'empshiftlog',
    loadChildren: () => import('../../components/reports/hrpayroll/empshiftlog/empshiftlog.module').then(m => m.EmpshiftlogModule),
    data: {
      breadcrumb: 'Emp Shift Log'
    },
  },
  {
    path: 'fsnanalysis',
    loadChildren: () => import('../../components/reports/inventory/fsnanalysis/fsnanalysis.module').then(m => m.FsnanalysisModule),
    data: {
      breadcrumb: 'fsnanalysis'
    },
  },
  {
    path: 'empearlygoinglatecoming',
    loadChildren: () => import('../../components/reports/hrpayroll/empearlygoinglatecoming/empearlygoinglatecoming.module').then(m => m.EmpearlygoinglatecomingModule),
    data: {
      breadcrumb: 'Bank Transactions'
    },
  },
  {
    path: 'banktransactions',
    loadChildren: () => import('../../components/reports/companyfinancials/banktransactions/banktransactions.module').then(m => m.BanktransactionsModule),
    data: {
      breadcrumb: 'Bank Transactions'
    },
  },
  {
    path: 'balancesheet',
    loadChildren: () => import('../../components/reports/companyfinancials/balancesheet/balancesheet.module').then(m => m.BalancesheetModule),
    data: {
      breadcrumb: 'Balance Sheet'
    },
  },
  {
    path: 'balancesheetlikecomparison',
    loadChildren: () => import('../../components/reports/companyfinancials/balancesheetlikecomparison/balancesheetlikecomparison.module').then(m => m.BalancesheetlikecomparisonModule),
    data: {
      breadcrumb: 'Balance Sheet Like COmparison'
    },
  },
  {
    path: 'balancesheetyearlycomparison',
    loadChildren: () => import('../../components/reports/companyfinancials/balancesheetyearlycomparison/balancesheetyearlycomparison.module').then(m => m.BalancesheetyearlycomparisonModule),
    data: {
      breadcrumb: 'Balance Sheet Yearly Comparison'
    },
  },
  {
    path: 'profitloss',
    loadChildren: () => import('../../components/reports/companyfinancials/profitloss/profitloss.module').then(m => m.ProfitlossModule),
    data: {
      breadcrumb: 'Profit Loss'
    },
  },
  {
    path: 'trailbalance',
    loadChildren: () => import('../../components/reports/companyfinancials/trailbalance/trailbalance.module').then(m => m.TrailbalanceModule),
    data: {
      breadcrumb: 'Trial Balance'
    },
  },
  {
    path: 'proformabalancesheet',
    loadChildren: () => import('../../components/reports/companyfinancials/proformabalancesheet/proformabalancesheet.module').then(m => m.ProformabalancesheetModule),
    data: {
      breadcrumb: 'Pro Forma Balancesheet'
    },
  },
  {
    path: 'acc-ledgers',
    loadChildren: () => import('../../components/reports/accounts/ledgers/ledgers.module').then(m => m.LedgersModule),
    data: {
      breadcrumb: 'Ledgers'
    },
  },
  {
    path: 'acc-audittrail',
    loadChildren: () => import('../../components/reports/accounts/audit-trail/audit-trail.module').then(m => m.AuditTrailModule),
    data: {
      breadcrumb: 'Audit Trails'
    },
  },
  {
    path: 'acc-journalentries',
    loadChildren: () => import('../../components/reports/accounts/journal-entries/journal-entries.module').then(m => m.JournalEntriesModule),
    data: {
      breadcrumb: 'Journal Entries'
    },
  },
  {
    path: 'acc-deletedtransactions',
    loadChildren: () => import('../../components/reports/accounts/deleted-transactions/deleted-transactions.module').then(m => m.DeletedTransactionsModule),
    data: {
      breadcrumb: 'Deleted Transactions'
    },
  },
  {
    path: 'chequeissued',
    loadChildren: () => import('../../components/reports/accounts/chequeissued/chequeissued.module').then(m => m.ChequeissuedModule),
    data: {
      breadcrumb: 'Cheque Issued'
    },
  },
  {
    path: 'chequereceived',
    loadChildren: () => import('../../components/reports/accounts/chequereceived/chequereceived.module').then(m => m.ChequereceivedModule),
    data: {
      breadcrumb: 'Cheque Received'
    },
  },
  // assets
  {
    path: 'assets',
    loadChildren: () => import('../../components/assets/assets/assets.module').then(m => m.AssetsModule),
    data: {
      breadcrumb: 'Assets'
    },
  },
  {
    path: 'assetrequests',
    loadChildren: () => import('../../components/assets/assetsrequest/assetsrequest.module').then(m => m.AssetsrequestModule),
    data: {
      breadcrumb: 'New Asset Requests'
    },
  },
  {
    path: 'assetcategories',
    loadChildren: () => import('../../components/assets/assetscategories/assetscategories.module').then(m => m.AssetscategoriesModule),
    data: {
      breadcrumb: 'Asset Categories'
    },
  },
  {
    path: 'assetusers',
    loadChildren: () => import('../../components/assets/assetusers/assetusers.module').then(m => m.AssetusersModule),
    data: {
      breadcrumb: 'Asset Users'
    },
  },

  {
    path: 'assetconditions',
    loadChildren: () => import('../../components/assets/assetconditions/assetconditions.module').then(m => m.AssetconditionsModule),
    data: {
      breadcrumb: 'Asset Conditions'
    },
  },
  {
    path: 'assetlocations',
    loadChildren: () => import('../../components/assets/assetlocations/assetlocations.module').then(m => m.AssetlocationsModule),
    data: {
      breadcrumb: 'Asset Locations'
    },
  },
  {
    path: 'assetactions',
    loadChildren: () => import('../../components/assets/assetactions/assetactions.module').then(m => m.AssetactionsModule),
    data: {
      breadcrumb: 'Asset Action'
    },
  }

  // Reports Customer Receivable
  ,
  {
    path: 'customeragingsummary',
    loadChildren: () => import('../../components/reports/customerreceivables/customeragingsummary/customeragingsummary.module').then(m => m.CustomeragingsummaryModule),
    data: {
      breadcrumb: 'Invoice Aging Summary'
    },
  },
  {
    path: 'aragingsummary',
    loadChildren: () => import('../../components/reports/customerreceivables/aragingsummary/aragingsummary.module').then(m => m.ArsummaryModule),
    data: {
      breadcrumb: 'A/R Aging Summary'
    },
  },
  {
    path: 'creditusesummary',
    loadChildren: () => import('../../components/reports/customerreceivables/creditusesummary/creditusesummary.module').then(m => m.CreditusesummaryModule),
    data: {
      breadcrumb: 'Credit Use Summary'
    },
  },
  {
    path: 'invoiceshistory',
    loadChildren: () => import('../../components/reports/customerreceivables/invoices/invoices.module').then(m => m.InvoicesModule),
    data: {
      breadcrumb: 'Invoices'
    },
  },
  {
    path: 'quotesestimates',
    loadChildren: () => import('../../components/reports/customerreceivables/quotesestimates/quotesestimates.module').then(m => m.QuotesestimatesModule),
    data: {
      breadcrumb: 'Quotes/Estimates'
    },
  },
  {
    path: 'salesbycategory',
    loadChildren: () => import('../../components/reports/customerreceivables/salesbycategory/salesbycategory.module').then(m => m.SalesbycategoryModule),
    data: {
      breadcrumb: 'Sales By Category'
    },
  },
  {
    path: 'customerpayments',
    loadChildren: () => import('../../components/reports/customerreceivables/customerpayments/customerpayments.module').then(m => m.CustomerpaymentsModule),
    data: {
      breadcrumb: 'Payments Received'
    },
  },

  // Reports Vendors payable
  {
    path: 'apagingsummary',
    loadChildren: () => import('../../components/reports/vendorspayables/apagingsummary/apagingsummary.module').then(m => m.ApagingsummaryModule),
    data: {
      breadcrumb: 'A/P Aging Summary'
    },
  },
  {
    path: 'billsagingsummary',
    loadChildren: () => import('../../components/reports/vendorspayables/billsagingsummary/billsagingsummary.module').then(m => m.BillsagingsummaryModule),
    data: {
      breadcrumb: 'Bills Aging Summary'
    },
  },
  {
    path: 'expenseshistory',
    loadChildren: () => import('../../components/reports/vendorspayables/expenseshistory/expenseshistory.module').then(m => m.ExpenseshistoryModule),
    data: {
      breadcrumb: 'Expenses History'
    },
  },
  {
    path: 'expensessummary',
    loadChildren: () => import('../../components/reports/vendorspayables/expensessummary/expensessummary.module').then(m => m.ExpensessummaryModule),
    data: {
      breadcrumb: 'Expenses Summary'
    },
  },
  {
    path: 'purchaseorders',
    loadChildren: () => import('../../components/reports/vendorspayables/purchaseorders/purchaseorders.module').then(m => m.PurchaseordersModule),
    data: {
      breadcrumb: 'Purchase Orders Summary'
    },
  },
  {
    path: 'purchaseinvoicesummary',
    loadChildren: () => import('../../components/reports/vendorspayables/purchaseinvoicesummary/purchaseinvoicesummary.module').then(m => m.PurchaseInvoiceSummaryModule),
    data: {
      breadcrumb: 'Purchase Invoice Summary'
    },
  },
  {
    path: 'paymentspaid',
    loadChildren: () => import('../../components/reports/vendorspayables/paymentspaid/paymentspaid.module').then(m => m.PaymentspaidModule),
    data: {
      breadcrumb: 'Payments Paid'
    },
  },


  // Reports transactions

  {
    path: 'dailytransactions',
    loadChildren: () => import('../../components/reports/transactions/dailytransactions/dailytransactions.module').then(m => m.DailytransactionsModule),
    data: {
      breadcrumb: 'Daily Transactions'
    },
  },
  {
    path: 'postransactions',
    loadChildren: () => import('../../components/reports/transactions/postransactions/postransactions.module').then(m => m.PostransactionsModule),
    data: {
      breadcrumb: 'POS Transactions'
    },
  },
  {
    path: 'transactionssyncreport',
    loadChildren: () => import('../../components/reports/transactions/transactionssyncreport/transactionssyncreport.module').then(m => m.TransactionssyncreportModule),
    data: {
      breadcrumb: 'Transactions Sync Report'
    },
  },
  {
    path: 'recenttransactions',
    loadChildren: () => import('../../components/reports/transactions/recenttransactions/recenttransactions.module').then(m => m.RecenttransactionsModule),
    data: {
      breadcrumb: 'Recent Transactions'
    },
  },
  {
    path: 'onlinepayments',
    loadChildren: () => import('../../components/reports/transactions/plugnpay/plugnpay.module').then(m => m.PlugnpayModule),
    data: {
      breadcrumb: 'Online Payments'
    },
  },

  // Reports Tasks Tickets

  {
    path: 'reportinvoices',
    loadChildren: () => import('../../components/reports/taskstickets/invoices/invoices.module').then(m => m.InvoicesModule),
    data: {
      breadcrumb: 'Invoices'
    },
  },
  {
    path: 'orders',
    loadChildren: () => import('../../components/reports/taskstickets/orders/orders.module').then(m => m.OrdersModule),
    data: {
      breadcrumb: 'Orders'
    },
  },
  {
    path: 'servicecontractticketsummary',
    loadChildren: () => import('../../components/reports/taskstickets/servicecontractticketsummary/servicecontractticketsummary.module').then(m => m.ServiceContractTicketsummaryComponentModule),
    data: {
      breadcrumb: 'Service Contract Ticket Summary'
    },
  },
  {
    path: 'taskstimelogs',
    loadChildren: () => import('../../components/reports/taskstickets/taskstimelogs/taskstimelogs.module').then(m => m.TaskstimelogsModule),
    data: {
      breadcrumb: 'Task Time Logs'
    },
  },
  {
    path: 'reportofficeclockings',
    loadChildren: () => import('../../components/reports/taskstickets/officeclockings/officeclockings.module').then(m => m.OfficeclockingsModule),
    data: {
      breadcrumb: 'Office Clockings'
    },
  },
  {
    path: 'techhours',
    loadChildren: () => import('../../components/reports/taskstickets/techhours/techhours.module').then(m => m.TechhoursModule),
    data: {
      breadcrumb: 'Tech Hours'
    },
  },
  {
    path: 'ticketsinventorycostexport',
    loadChildren: () => import('../../components/reports/taskstickets/ticketsinventorycostexport/ticketsinventorycostexport.module').then(m => m.TicketsinventorycostexportModule),
    data: {
      breadcrumb: 'Tickets Inventory Cost Export'
    },
  },
  {
    path: 'ticketstimelogs',
    loadChildren: () => import('../../components/reports/taskstickets/ticketstimelogs/ticketstimelogs.module').then(m => m.TicketstimelogsModule),
    data: {
      breadcrumb: 'Tickets Time Logs'
    },
  },
  {
    path: 'ticketsummary',
    loadChildren: () => import('../../components/reports/taskstickets/ticketsummary/ticketsummary.module').then(m => m.TicketsummaryModule),
    data: {
      breadcrumb: 'Ticket Summary'
    },
  },
  {
    path: 'unbilledtimelogs',
    loadChildren: () => import('../../components/reports/taskstickets/unbilledtimelogs/unbilledtimelogs.module').then(m => m.UnbilledtimelogsModule),
    data: {
      breadcrumb: 'Unbilled Time Logs'
    },
  },
  {
    path: 'employeereminders',
    loadChildren: () => import('../../components/reports/taskstickets/employeereminders/employeereminders.module').then(m => m.EmployeeremindersModule),
    data: {
      breadcrumb: 'Employee Reminders'
    },
  },
  {
    path: 'dailyworklog',
    loadChildren: () => import('../../components/reports/taskstickets/dailyworklog/dailyworklog.module').then(m => m.DailyworklogModule),
    data: {
      breadcrumb: 'Daily Work Logs'
    },
  },
  {
    path: 'employeewisereport',
    loadChildren: () => import('../../components/reports/taskstickets/employeewisereport/employeewisereport.module').then(m => m.EmployeewisereportModule),
    data: {
      breadcrumb: 'Employee Wise Reports'
    },
  },

  // Reports Inventory
  {
    path: 'customerdevices',
    loadChildren: () => import('../../components/reports/inventory/customerdevices/customerdevices.module').then(m => m.CustomerdevicesModule),
    data: {
      breadcrumb: 'Service Manager'
    },
  },
  {
    path: 'inventorymovementhistory',
    loadChildren: () => import('../../components/reports/inventory/inventorymovementhistory/inventorymovementhistory.module').then(m => m.InventorymovementhistoryModule),
    data: {
      breadcrumb: 'Inventory Mpvement History'
    },
  },
  {
    path: 'orderhistorybyproduct',
    loadChildren: () => import('../../components/reports/inventory/orderhistorybyproduct/orderhistorybyproduct.module').then(m => m.OrderhistorybyproductModule),
    data: {
      breadcrumb: 'Order History By Product'
    },
  },
  {
    path: 'replenishment',
    loadChildren: () => import('../../components/reports/inventory/replenishment/replenishment.module').then(m => m.ReplenishmentModule),
    data: {
      breadcrumb: 'Replenishment'
    },
  },
  {
    path: 'revenuebyproduct',
    loadChildren: () => import('../../components/reports/inventory/revenuebyproduct/revenuebyproduct.module').then(m => m.RevenuebyproductModule),
    data: {
      breadcrumb: 'Revenue by Product'
    },
  },
  {
    path: 'landedcostaudit',
    loadChildren: () => import('../../components/reports/inventory/landedcostaudit/landedcostaudit.module').then(m => m.LandedcostauditModule),
    data: {
      breadcrumb: 'Landed Cost Audit'
    },
  },
  {
    path: 'salesvslandedcostsummary',
    loadChildren: () => import('../../components/reports/inventory/salesvslandedcostsummary/salesvslandedcostsummary.module').then(m => m.SalesvslandedcostsummaryModule),
    data: {
      breadcrumb: 'Sales vs Landed Cost Summary'
    },
  },
  {
    path: 'stockcostsummary',
    loadChildren: () => import('../../components/reports/inventory/stockcostsummary/stockcostsummary.module').then(m => m.StockcostsummaryModule),
    data: {
      breadcrumb: 'Stock Cost Summary'
    },
  },
  {
    path: 'stocksummary',
    loadChildren: () => import('../../components/reports/inventory/stocksummary/stocksummary.module').then(m => m.StocksummaryModule),
    data: {
      breadcrumb: 'Stock Summary'
    },
  },
  {
    path: 'manualstockaudit',
    loadChildren: () => import('../../components/reports/inventory/manualstockaudit/manualstockaudit.module').then(m => m.ManualstockauditModule),
    data: {
      breadcrumb: 'Manual Stock Audit'
    },
  },
  {
    path: 'priceauditing',
    loadChildren: () => import('../../components/reports/inventory/priceauditing/priceauditing.module').then(m => m.PriceauditingModule),
    data: {
      breadcrumb: 'Price Auditing'
    },
  },
  {
    path: 'bolsummaryreport',
    loadChildren: () => import('../../components/reports/inventory/bolsummaryreport/bolsummaryreport.module').then(m => m.BolsummaryreportModule),
    data: {
      breadcrumb: 'BOL Summary'
    },
  },
  {
    path: 'productsserialsummary',
    loadChildren: () => import('../../components/reports/inventory/productserialsummary/productserialsummary.module').then(m => m.ProductserialsummaryModule),
    data: {
      breadcrumb: 'Products Serial Summary'
    },
  },
  {
    path: 'catalogsummary',
    loadChildren: () => import('../../components/reports/inventory/catalogsummary/catalogsummary.module').then(m => m.CatalogsummaryModule),
    data: {
      breadcrumb: 'Catalog Summary'
    },
  },
  {
    path: 'inventoryvaluebyinvoices',
    loadChildren: () => import('../../components/reports/inventory/inventoryvaluebyinvoices/inventoryvaluebyinvoices.module').then(m => m.InventoryvaluebyinvoicesModule),
    data: {
      breadcrumb: 'Inventory Value by Invoices'
    },
  },
  {
    path: 'inventoryadjustment',
    loadChildren: () => import('../../components/reports/inventory/inventoryadjustment/inventoryadjustment.module').then(m => m.InventoryadjustmentModule),
    data: {
      breadcrumb: 'Inventory Adjustment'
    },
  },
  {
    path: 'inventorysalescountbymonth',
    loadChildren: () => import('../../components/reports/inventory/inventorysalescountbymonth/inventorysalescountbymonth.module').then(m => m.InventorysalescountbymonthModule),
    data: {
      breadcrumb: 'Inventory Sales Count by Month'
    },
  },
  {
    path: 'inventorybybinlocations',
    loadChildren: () => import('../../components/reports/inventory/inventorybybinlocations/inventorybybinlocations.module').then(m => m.InventorybybinlocationsModule),
    data: {
      breadcrumb: 'Inventory by Bin Locations'
    },
  },
  {
    path: 'inventorysalescountbyyear',
    loadChildren: () => import('../../components/reports/inventory/inventorysalescountbyyear/inventorysalescountbyyear.module').then(m => m.InventorysalescountbyyearModule),
    data: {
      breadcrumb: 'Inventory Sales Count by Year'
    },
  },
  {
    path: 'pickuphistory',
    loadChildren: () => import('../../components/reports/inventory/pickuphistory/pickuphistory.module').then(m => m.PickuphistoryModule),
    data: {
      breadcrumb: 'Pickup history'
    },
  },
  {
    path: 'armaterialcostsummary',
    loadChildren: () => import('../../components/reports/inventory/armaterialcostsummary/armaterialcostsummary.module').then(m => m.ArmaterialcostsummaryModule),
    data: {
      breadcrumb: 'Inventory Sales Summary'
    },
  },
  {
    path: 'womaterials',
    loadChildren: () => import('../../components/reports/inventory/womaterials/womaterials.module').then(m => m.WomaterialsModule),
    data: {
      breadcrumb: 'WO Material'
    },
  },
  {
    path: 'inventorybalancereport',
    loadChildren: () => import('../../components/reports/inventory/inventorybalancereport/inventorybalancereport.module').then(m => m.InventorybalancereportModule),
    data: {
      breadcrumb: 'Inventory Balance Report'
    },
  },
  {
    path: 'bolcostingexport',
    loadChildren: () => import('../../components/reports/inventory/bolcostingexport/bolcostingexport.module').then(m => m.BolcostingexportModule),
    data: {
      breadcrumb: 'BOL Costing Export'
    },
  },
  {
    path: 'assemblysalesbymonth',
    loadChildren: () => import('../../components/reports/inventory/assemblysalesbymonth/assemblysalesbymonth.module').then(m => m.AssemblysalesbymonthModule),
    data: {
      breadcrumb: 'Assembly Sales by Month'
    },
  },
  {
    path: 'inventorycostcomparision',
    loadChildren: () => import('../../components/reports/inventory/inventorycostcomparison/inventorycostcomparison.module').then(m => m.InventorycostcomparisonModule),
    data: {
      breadcrumb: 'Assembly Sales by Month'
    },
  },
  {
    path: 'lotexpiry',
    loadChildren: () => import('../../components/reports/inventory/lotexpiry/lotexpiry.module').then(m => m.LotexpiryModule),
    data: {
      breadcrumb: 'Lot Expiry'
    },
  },
  {
    path: 'inventorycountdiscrepancy',
    loadChildren: () => import('../../components/reports/inventory/inventorycountdiscrepancy/inventorycountdiscrepancy.module').then(m => m.InventorycountdiscrepancyModule),
    data: {
      breadcrumb: ' Inventory Count Discrepancy'
    },
  },
  {
    path: 'internaltransfer',
    loadChildren: () => import('../../components/reports/inventory/internaltransfer/internaltransfer.module').then(m => m.InternaltransferModule),
    data: {
      breadcrumb: 'Internal Trasfer'
    },
  },
  {
    path: 'notcounteditems',
    loadChildren: () => import('../../components/reports/inventory/notcounteditems/notcounteditems.module').then(m => m.NotcounteditemsModule),
    data: {
      breadcrumb: 'Not Counted Items'
    },
  },

  // Reports HRPayroll
  {
    path: 'leavesbalance',
    loadChildren: () => import('../../components/reports/hrpayroll/leavesbalance/leavesbalance.module').then(m => m.LeavesbalanceModule),
    data: {
      breadcrumb: 'Leaves Balance'
    },
  },
  {
    path: 'listofemployees',
    loadChildren: () => import('../../components/reports/hrpayroll/listofemployees/listofemployees.module').then(m => m.ListofemployeesModule),
    data: {
      breadcrumb: 'List of Employees'
    },
  },
  {
    path: 'accesscards',
    loadChildren: () => import('../../components/reports/hrpayroll/accesscards/accesscards.module').then(m => m.AccesscardsModule),
    data: {
      breadcrumb: 'Access Cards'
    },
  },
  {
    path: 'appraisals',
    loadChildren: () => import('../../components/reports/hrpayroll/appraisals/appraisals.module').then(m => m.AppraisalsModule),
    data: {
      breadcrumb: 'Appraisals'
    },
  },
  {
    path: 'concessionpasses',
    loadChildren: () => import('../../components/reports/hrpayroll/concessionpasses/concessionpasses.module').then(m => m.ConcessionpassesModule),
    data: {
      breadcrumb: 'Conscession Passes'
    },
  },
  {
    path: 'disciplinarylogs',
    loadChildren: () => import('../../components/reports/hrpayroll/disciplinarylogs/disciplinarylogs.module').then(m => m.DisciplinarylogsModule),
    data: {
      breadcrumb: 'Disciplinary Logs'
    },
  },
  {
    path: 'employeecostingsummary',
    loadChildren: () => import('../../components/reports/hrpayroll/employeecostingsummary/employeecostingsummary.module').then(m => m.EmployeecostingsummaryModule),
    data: {
      breadcrumb: 'Employee Costing Summary'
    },
  },
  {
    path: 'employeesanniversary',
    loadChildren: () => import('../../components/reports/hrpayroll/employeesanniversary/employeesanniversary.module').then(m => m.EmployeesanniversaryModule),
    data: {
      breadcrumb: 'Employees Anniversary'
    },
  },
  {
    path: 'employeesbirthday',
    loadChildren: () => import('../../components/reports/hrpayroll/employeesbirthday/employeesbirthday.module').then(m => m.EmployeesbirthdayModule),
    data: {
      breadcrumb: 'Employees Birthday'
    },
  },
  {
    path: 'hurricanecontacts',
    loadChildren: () => import('../../components/reports/hrpayroll/hurricanecontacts/hurricanecontacts.module').then(m => m.HurricanecontactsModule),
    data: {
      breadcrumb: 'List of Employees'
    },
  },
  {
    path: 'insurance',
    loadChildren: () => import('../../components/reports/hrpayroll/insurance/insurance.module').then(m => m.InsuranceModule),
    data: {
      breadcrumb: 'Insurance'
    },
  },
  {
    path: 'jobapplications',
    loadChildren: () => import('../../components/reports/hrpayroll/jobapplications/jobapplications.module').then(m => m.JobapplicationsModule),
    data: {
      breadcrumb: 'List of Employees'
    },
  },
  {
    path: 'pensions',
    loadChildren: () => import('../../components/reports/hrpayroll/pensions/pensions.module').then(m => m.PensionsModule),
    data: {
      breadcrumb: 'Pensions'
    },
  },
  {
    path: 'workpermit',
    loadChildren: () => import('../../components/reports/hrpayroll/workpermit/workpermit.module').then(m => m.WorkpermitModule),
    data: {
      breadcrumb: 'Work Permit'
    },
  },
  {
    path: 'esiccontribution',
    loadChildren: () => import('../../components/reports/hrpayroll/esiccontribution/esiccontribution.module').then(m => m.EsiccontributionModule),
    data: {
      breadcrumb: 'ESIC Contribution'
    },
  },
  {
    path: 'epfcontribution',
    loadChildren: () => import('../../components/reports/hrpayroll/epfcontribution/epfcontribution.module').then(m => m.EpfcontributionModule),
    data: {
      breadcrumb: 'EPf Contribution'
    },
  },
  {
    path: 'emprosterdetails',
    loadChildren: () => import('../../components/reports/hrpayroll/emprosterdetails/emprosterdetails.module').then(m => m.EmprosterdetailsModule),
    data: {
      breadcrumb: 'Employee Roster Details'
    },
  },
  {
    path: 'monthlyattendance',
    loadChildren: () => import('../../components/reports/hrpayroll/monthlyattendance/monthlyattendance.module').then(m => m.MonthlyattendanceModule),
    data: {
      breadcrumb: 'Monthly Attendance'
    },
  },
  {
    path: 'pensioninsurancecontribution',
    loadChildren: () => import('../../components/reports/hrpayroll/pensioninsurancecontribution/pensioninsurancecontribution.module').then(m => m.PensioninsurancecontributionModule),
    data: {
      breadcrumb: 'Pension, Insurance Contribution'
    },
  },
  {
    path: 'sqlerrorlog',
    loadChildren: () => import('../../components/reports/support/sqlerrorlog/sqlerrorlog.module').then(m => m.SqlerrorlogModule),
    data: {
      breadcrumb: 'Sql Error Logs'
    },
  },
  {
    path: 'sqljoblog',
    loadChildren: () => import('../../components/reports/support/sqljoblog/sqljoblog.module').then(m => m.SqljoblogModule),
    data: {
      breadcrumb: 'Sql Job Logs'
    },
  },
  {
    path: 'timesheetreport',
    loadChildren: () => import('../../components/reports/employee/timesheetreport/timesheetreport.module').then(m => m.TimesheetreportModule),
    data: {
      breadcrumb: 'Timesheet Report'
    },
  },
  {
    path: 'snagsummary',
    loadChildren: () => import('../../components/reports/employee/snagsummary/snagsummary.module').then(m => m.SnagsummaryreportModule),
    data: {
      breadcrumb: 'Snag Summary'
    },
  },
  {
    path: 'tasksummary',
    loadChildren: () => import('../../components/reports/employee/tasksummary/tasksummary.module').then(m => m.TasksummaryModule),
    data: {
      breadcrumb: 'Task Summary'
    },
  },
  // Setting
  {
    path: 'ezbcostanalysis',
    loadChildren: () => import('../../components/settings/ezbcostanalysis/ezbcostanalysis.module').then(m => m.EzbcostanalysisModule),
    data: {
      breadcrumb: 'Ezb Cost Analysis'
    },
  },
  {
    path: 'companyprofile',
    loadChildren: () => import('../../components/settings/companyprofile/companyprofile.module').then(m => m.CompanyprofileModule),
    data: {
      breadcrumb: 'Company Profile'
    },
  },
  {
    path: 'countries',
    loadChildren: () => import('../../components/settings/countries/countries.module').then(m => m.CountriesModule),
    data: {
      breadcrumb: 'Countries'
    },
  },
  {
    path: 'sqljobs',
    loadChildren: () => import('../../components/settings/sqljobs/sqljobs.module').then(m => m.SqljobsModule),
    data: {
      breadcrumb: 'SQL Jobs'
    },
  },
  {
    path: 'emaillist',
    loadChildren: () => import('../../components/settings/emaillist/emaillist.module').then(m => m.EmaillistModule),
    data: {
      breadcrumb: 'Email List'
    },
  },
  {
    path: 'tagsmaster',
    loadChildren: () => import('../../components/settings/tagsmaster/tagsmaster.module').then(m => m.TagsmasterModule),
    data: {
      breadcrumb: 'Tags Master'
    },
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/settings/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: 'Users'
    },
  },
  {
    path: 'preferences',
    loadChildren: () => import('../../components/settings/preferences/preferences.module').then(m => m.PreferencesModule),
    data: {
      breadcrumb: 'Preferences'
    },
  },
  {
    path: 'emailhistory',
    loadChildren: () => import('../../components/settings/emailhistory/emailhistory.module').then(m => m.EmailhistoryModule),
    data: {
      breadcrumb: 'Email History'
    },
  },
  {
    path: 'companyemailtemplates',
    loadChildren: () => import('../../components/settings/emailtemplates/emailtemplates.module').then(m => m.EmailtemplatesModule),
    data: {
      breadcrumb: 'Email Templates'
    },
  },
  {
    path: 'printers',
    loadChildren: () => import('../../components/settings/printers/printers.module').then(m => m.PrintersModule),
    data: {
      breadcrumb: 'Printers'
    },
  },
  {
    path: 'reminderconfig',
    loadChildren: () => import('../../components/settings/reminderconfig/reminderconfig.module').then(m => m.ReminderconfigModule),
    data: {
      breadcrumb: 'Reminder Configs'
    },
  },
  {
    path: 'tariffs',
    loadChildren: () => import('../../components/settings/tariffs/tariffs.module').then(m => m.TariffsModule),
    data: {
      breadcrumb: 'Tariffs'
    },
  },
  {
    path: 'companymenu',
    loadChildren: () => import('../../components/settings/companymenu/companymenu.module').then(m => m.CompanymenuModule),
    data: {
      breadcrumb: 'Company Menu'
    },
  },
  {
    path: 'sitemaster',
    loadChildren: () => import('../../components/settings/sitemaster/sitemaster.module').then(m => m.SitemasterModule),
    data: {
      breadcrumb: 'Sites'
    },
  },
  {
    path: 'devicemaster',
    loadChildren: () => import('../../components/settings/devicemaster/devicemaster.module').then(m => m.DevicemasterModule),
    data: {
      breadcrumb: 'Devices'
    },
  },
  {
    path: 'contactmaster',
    loadChildren: () => import('../../components/settings/contactmaster/contactmaster.module').then(m => m.ContactmasterModule),
    data: {
      breadcrumb: 'Contacts'
    },
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/ebadmin/menu/menu.module').then(m => m.MenuModule),
    data: {
      breadcrumb: 'Menu'
    },
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('../../components/ebadmin/privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule),
    data: {
      breadcrumb: 'Privacy Policy'
    },
  },
  {
    path: 'termsofservice',
    loadChildren: () => import('../../components/ebadmin/termsofservice/termsofservice.module').then(m => m.TermsofserviceModule),
    data: {
      breadcrumb: 'Terms of Service'
    },
  },
  {
    path: 'mobileapps',
    loadChildren: () => import('../../components/ebadmin/mobileapps/mobileapps.module').then(m => m.MobileappsModule),
    data: {
      breadcrumb: 'Mobile Apps'
    },
  },
  {
    path: 'userguide',
    loadChildren: () => import('../../components/ebadmin/userguide/userguide.module').then(m => m.UserguideModule),
    data: {
      breadcrumb: 'User Guide'
    },
  },
  {
    path: 'emailtemplates',
    loadChildren: () => import('../../components/ebadmin/emailtemplates/emailtemplates.module').then(m => m.EmailTemplatesModule),
    data: {
      breadcrumb: 'Email Templates'
    },
  },

  //  Eb Admin

  {
    path: 'companies',
    loadChildren: () => import('../../components/ebadmin/companies/companies.module').then(m => m.CompaniesModule),
    data: {
      breadcrumb: 'Company Profile'
    },
  },
  {
    path: 'announcements',
    loadChildren: () => import('../../components/ebadmin/announcements/announcements.module').then(m => m.AnnouncementsModule),
    data: {
      breadcrumb: 'Company Profile'
    },
  },

  {
    path: 'subscriptions',
    loadChildren: () => import('../../components/ebadmin/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
    data: {
      breadcrumb: 'Subscriptions'
    },
  },
  {
    path: 'roles',
    loadChildren: () => import('../../components/ebadmin/roles/roles.module').then(m => m.RolesModule),
    data: {
      breadcrumb: 'Roles'
    },
  },
  {
    path: 'salarycomponent',
    loadChildren: () => import('../../components/ebadmin/salarycomponent/salarycomponent.module').then(m => m.SalarycomponentModule),
    data: {
      breadcrumb: 'Salary Components'
    },
  },
  {
    path: 'importdataoptions',
    loadChildren: () => import('../../components/ebadmin/importdataoptions/importdataoptions.module').then(m => m.ImportdataoptionsModule),
    data: {
      breadcrumb: 'Import Data Options'
    },
  },
  {
    path: 'exportdataoptions',
    loadChildren: () => import('../../components/ebadmin/exportdataoptions/exportdataoptions.module').then(m => m.ExportdataoptionsModule),
    data: {
      breadcrumb: 'Export Data Options'
    },
  },
  {
    path: 'exportdata',
    loadChildren: () => import('../../components/exportdata/exportdata.module').then(m => m.ExportdataModule),
    data: {
      breadcrumb: 'Export Data'
    },
  },
  // Customer Service
  {
    path: 'dispatcher',
    loadChildren: () => import('../../components/customerservice/dispatcher/dispatcher.module').then(m => m.DispatcherModule),
    data: {
      breadcrumb: 'Dispatcher'
    },
  },
  {
    path: 'distributionbatches',
    loadChildren: () => import('../../components/customerservice/distributionbatches/distributionbatches.module').then(m => m.DistributionbatchesModule),
    data: {
      breadcrumb: 'Distribution Batches'
    },
  },
  {
    path: 'customerfeedback',
    loadChildren: () => import('../../components/customerservice/feedbacks/feedbacks.module').then(m => m.FeedbacksModule),
    data: {
      breadcrumb: 'Customer Feedback'
    },
  },
  {
    path: 'customerchat',
    loadChildren: () => import('../../components/customerservice/chatsupport/chatsupport.module').then(m => m.ChatsupportModule),
    data: {
      breadcrumb: 'Chat Support'
    },
  },
  {
    path: 'disputes',
    loadChildren: () => import('../../components/customerservice/disputes/disputes.module').then(m => m.DisputesModule),
    data: {
      breadcrumb: 'Disputes'
    },
  },
  {
    path: 'customernotifications',
    loadChildren: () => import('../../components/customerservice/customernotifications/customernotifications.module').then(m => m.CustomernotificationsModule),
    data: {
      breadcrumb: 'Customer Notifications'
    },
  },
  // Help
  {
    path: 'help',
    loadChildren: () => import('../../components/help/help.module').then(m => m.HelpModule),
    data: {
      breadcrumb: 'Help'
    },
  },

  // profile
  {
    path: 'userprofile',
    loadChildren: () => import('../../components/editprofile/editprofile.module').then(m => m.EditprofileModule),
    data: {
      breadcrumb: 'Profile'
    },
  },
  // import data
  {
    path: 'importdata',
    loadChildren: () => import('../../components/importdata/importdata.module').then(m => m.ImportdataModule),
    data: {
      breadcrumb: 'Import Data'
    },
  },
  {
    path: 'importinvoice',
    loadChildren: () => import('../../components/importdetails/importdetails.module').then(m => m.ImportdetailsModule),
    data: {
      breadcrumb: 'Import'
    },
  },
  //changepassword
  {
    path: 'changepassword',
    loadChildren: () => import('../../components/changepassword/changepassword.module').then(m => m.ChangepasswordModule),
    data: {
      breadcrumb: 'Change Password'
    },
  },
  // Reminder
  {
    path: 'reminders',
    loadChildren: () => import('../../components/reminder/reminder.module').then(m => m.ReminderModule),
    data: {
      breadcrumb: 'Reminders'
    },
  },
  // Reminder
  {
    path: 'milestones',
    loadChildren: () => import('../../components/projectmanager/milestones/milestones.module').then(m => m.MilestonesModule),
    data: {
      breadcrumb: 'Milestones'
    },
  },
  {
    path: 'addbatch',
    loadChildren: () => import('../../components/accounts/addbatch/addbatch.module').then(m => m.AddBatchModule),
    data: {
      breadcrumb: 'Add Batch'
    },
  },
  {
    path: 'exportdata',
    loadChildren: () => import('../../components/exportdata/exportdata.module').then(m => m.ExportdataModule),
    data: {
      breadcrumb: 'Export Data'
    },
  },
  {
    path: 'errorlog',
    loadChildren: () => import('../../components/ebadmin/errorlog/errorlog.module').then(m => m.ErrorlogModule),
    data: {
      breadcrumb: 'Error Log'
    },
  },
  // {
  //   path: 'transactionint',
  //   loadChildren: () => import('../../components/settings/transactionintegration/transactionintegration.module').then(m => m.TransactionintegrationModule),
  //   data: {
  //     breadcrumb: 'transactionintegration'
  //   },
  // },
  {
    path: 'companyawards',
    loadChildren: () => import('../../components/settings/companyawards/companyawards.module').then(m => m.CompanyawardsModule),
    data: {
      breadcrumb: 'companyawards'
    },
  },
  {
    path: 'disputereasons',
    loadChildren: () => import('../../components/customerservice/disputereason/disputesreason.module').then(m => m.DisputesreasonModule),
    data: {
      breadcrumb: 'Disputes'
    },
  },
  {
    path: 'incomecategories',
    loadChildren: () => import('../../components/accounts/incomecategories/incomecategories.module').then(m => m.IncomecategoriesModule),
    data: {
      breadcrumb: 'Accounts'
    }
  },
  {
    path: 'otherincome',
    loadChildren: () => import('../../components/accounts/otherincome/otherincome.module').then(m => m.OtherincomeModule),
    data: {
      breadcrumb: 'Accounts'
    }
  },
  {
    path: 'projectinvoicebatch',
    loadChildren: () => import('../../components/projectmanager/batch/batch.module').then(m => m.Batchmodule),
    data: {
      breadcrumb: 'Add Batch'
    },
  },
  {
    path: 'wallethistory',
    loadChildren: () => import('../../components/reports/accounts/wallethistory/wallethistory.module').then(m => m.WallethistoryModule),
    data: {
      breadcrumb: 'Wallet History'
    },
  },
  {
    path: 'walletbalance',
    loadChildren: () => import('../../components/reports/accounts/walletbalance/walletbalance.module').then(m => m.WalletbalanceModule),
    data: {
      breadcrumb: 'Wallet Balance'
    },
  },
  {
    path: 'contractsummary',
    loadChildren: () => import('../../components/reports/accounts/contractsummary/contractsummary.module').then(m => m.ContractsummaryModule),
    data: {
      breadcrumb: 'Wallet Balance'
    },
  },
  {
    path: 'advloanrequest',
    loadChildren: () => import('../../components/hr/advanceloanrequest/advanceloanrequest.module').then(m => m.AdvanceloanrequestModule),
    data: {
      breadcrumb: 'Advance Loan'
    },
  },
  {
    path: 'legalcontract',
    loadChildren: () => import('../../components/hr/companylegalcontract/companylegalcontract.module').then(m => m.LegalcontractModule),
    data: {
      breadcrumb: 'Legal Contract'
    },
  },
  {
    path: 'bankingtransactions',
    loadChildren: () => import('../../components/accounts/banking/banktransactions/bankingtransactions.module').then(m => m.BankingTransactoinsModule),
    data: {
      breadcrumb: 'Bank Transactions'
    },
  },
  {
    path: 'businesspartners',
    loadChildren: () => import('../../components/accounts/businesspartners/businesspartners.module').then(m => m.BusinesspartnersModule),
    data: {
      breadcrumb: 'Business Partners'
    },
  },

  // Production 
  {
    path: 'productionorders',
    loadChildren: () => import('../../components/production/productionorders/productionorders.module').then(m => m.ProductionordersModule),
    data: {
      breadcrumb: 'Production Orders'
    },
  },
  {
    path: 'uommatrix',
    loadChildren: () => import('../../components/inventory/uommatrix/uommatrix.module').then(m => m.UOMMatrixModule),
    data: {
      breadcrumb: 'UOM Matrix'
    },
  },
  {
    path: 'thirdpartylinks',
    loadChildren: () => import('../../components/settings/thirdpartylinks/thirdpartylinks.module').then(m => m.TPILinksModule),
    data: {
      breadcrumb: 'Third Party Links'
    }
  },
  {
    path: 'loanapplications',
    loadChildren: () => import('../../components/accounts/loanapplications/loanapplications.module').then(m => m.LoanApplicationsModule),
    data: {
      breadcrumb: 'Loan Applications'
    }
  },
  {
    path: 'tipgroups',
    loadChildren: () => import('../../components/tips/tipgroups/tipgroups.module').then(m => m.TripgroupsModule),
    data: {
      breadcrumb: 'Tip Groups'
    },
  },
];