import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AddProductComponent } from '../add-product.component';

@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
	public closeResult: string;
	public categories = []
	product: any;
	detailsList: any = [];
	userdata: any;
	appname = environment.APP_NAME;
	detailsData: any = {
		"id": null,
		"prodid": '',
		"field_name": "",
		"field_value": "",
		"companyid": '',
		"createby": "",
		"createdate": "",
		"updateby": "",
		"updatedate": "",
	}
	isView: boolean = false;
	response: any;
	lastaction: any;
	fieldsetDisabled = false;
	constructor(private modalService: NgbModal,
		private activeModal: NgbActiveModal,
		private datepipe: DatePipe,
		private toastr: NotificationService,
		private route: ActivatedRoute,
		private maincomponent: AddProductComponent,
		private loader: NgxUiLoaderService,
		private service: InventoryServices) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.product = maincomponent.product;
		this.lastaction = this.route.snapshot.queryParamMap.get('action');
		if (this.lastaction == 'view') {
			this.fieldsetDisabled = true;
		} else if (this.lastaction == 'edit') {
			this.fieldsetDisabled = false;
		}
		this.getProductDetails();
	}
	ngOnInit(): void {

	}

	getProductDetails() {
		this.loader.start();
		this.service.getInventoryDetails(this.product.id).subscribe(res => {
			this.loader.stop();
			this.detailsList = res;
		}, err => {
			this.loader.stop();
		});
	}


	modalFunct(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			this.getProductDetails();
		}, (reason) => {
			//this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
	open(content, data, action) {
		if (data) {
			this.detailsData = {
				active: data.active,
				field_name: data.field_name,
				field_value: data.field_value,
				companyid: data.companyid,
				id: data.id,
				createby: data.createby,
				createdate: data.createdate
			};
		} else {
			this.detailsData = {
			};
		}
		this.isView = false;
		this.modalFunct(content);
		if (action == 'view') {
			this.isView = true;
			this.modalFunct(content);
		}
		if (action == 'edit') {
			this.isView = false;
			this.modalFunct(content);
		}
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	//#region Sorting Table Header
	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event,coloum) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr(coloum);
	}

	sortArr(colName: any) {
		this.detailsList.sort((a, b) => {
			let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
			let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

			if (!valueA && !valueB) {
				return 0;
			}
			if (!valueA) {
				return -this.sortDir;
			}
			if (!valueB) {
				return this.sortDir;
			}

			if (valueA > valueB) {
				return this.sortDir;
			}
			if (valueA < valueB) {
				return -this.sortDir;
			}
			return 0;
		});
	}
	//#endregion Sorting Table Header
	closeModal() {
		this.modalService.dismissAll();
	}
	postDetails() {
		this.detailsData.companyid = this.userdata.companyid;
		this.detailsData.prodid = this.product.id;
		if (this.detailsData.id) {
			this.detailsData.createby = this.detailsData.createby;
			this.detailsData.createdate = this.datepipe.transform(this.detailsData.createdate, this.userdata.postdatetimeformat);
			this.detailsData.updateby = this.userdata.email;
			this.detailsData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		}
		else {
			this.detailsData.createby = this.userdata.email;
			this.detailsData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		}
		this.loader.start();
		this.service.postInventoryDetails(this.detailsData).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
					this.detailsList = res.data;
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.modalService.dismissAll();
			}
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading post product ', err);
		});

	}
	delete(id) {
		Swal.fire({
			title: 'Are you sure want to delete?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.deleteInventoryDetails(id).subscribe((res: any) => {
					this.loader.stop();
					if (res != null) {
						if (res.status_code == "1") {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
							this.getProductDetails();
						} else {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
							return;
						}
					}
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While loading delete action ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}
}
