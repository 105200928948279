import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintElementService } from 'ngx-print-element';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { purchaseServices } from '../../purchase/purchase.service';
import { SpecmapServices } from '../../specmap/specmap.service';

@Component({
  selector: 'app-masterdata',
  templateUrl: './masterdata.component.html',
  styleUrls: ['./masterdata.component.scss']
})
export class MasterdataComponent implements OnInit {
  fullScreen = false;
  @Input('type') type;
  @Input('assetlocation') assetlocation;
  @Input('assetcondition') assetcondition;
  @Input('assetaction') assetaction;
  @Input('empdesignation') empdesignation;
  @Input('term') term;
  @Input('invoiceterm') invoiceterm;
  @Input('manufacturer') manufacturer;
  @Input('warehouse') warehouse;
  @Input('paymentmethod') paymentmethod;
  @Input('employeedepartment') employeedepartment;
  @Input('documenttype') documenttype;
  @Input('emplocation') emplocation;

  userdata: any;
  appname = environment.APP_NAME;
  printURL: any;
  itemList: any;
  modalTitle: any;
  master: any = {
    id: null,
    description: '',
    createby: null,
    createdate: null,
    updateby: null,
    updatedate: null,
    active: true
  }
  showtitle: boolean;
  isCodeFieldShow: boolean = false;
  isDataSave = false;
  markerTypes: any = [];
  constructor(private modalService: NgbModal, private specmapService: SpecmapServices, private toastr: NotificationService, private cdRef: ChangeDetectorRef, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: CommonServices, public print: NgxPrintElementService, private purchaseservice: purchaseServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }
  closeModal(action) {
    this.isCodeFieldShow = false;
    this.activeModal.close(this.isDataSave ? 'save' : action);
  }

  ngOnInit(): void {
    this.initializeValues();
    if (this.type == 'deliveryroutes') {
      this.isCodeFieldShow = true;
      this.modalTitle = 'Delivery Routes';
      this.getDeliveryRouteIndexValues();
    }
    if (this.type == 'cpc') {
      this.isCodeFieldShow = true;
      this.modalTitle = 'CPC List';
      this.getCpcIndexValues();
    }
    if (this.type == 'disputereason') {
      this.isCodeFieldShow = true;
      this.modalTitle = 'Dispute Reason';
      this.getDisputeReasonIndexValues();
    }
    if (this.type == 'projectype') {
      this.modalTitle = 'Project Types';
      this.getProjectTypeIndexValues();
    }
    else if (this.type == 'sotype') {
      this.modalTitle = 'Sales Order Types';
      this.getSOTypeIndexValues();
    }
    else if (this.type == 'contracttype') {
      this.modalTitle = 'Contract Types';
      this.getContractTypeIndexValues();
    }
    else if (this.type == 'tasktype') {
      this.modalTitle = 'Task Types';
      this.getTaskTypeIndexValues();
    }
    else if (this.type == 'expensetype') {
      this.modalTitle = 'Expense Types';
      this.getExpenseTypeIndexValues();
    }
    else if (this.type == 'salesrequesttype') {
      this.modalTitle = 'Sales Request Types';
      this.getSalesRequestTypeIndexValues();
    }
    else if (this.empdesignation == 'empdesignation') {
      this.modalTitle = 'Designation';
      this.getDesignationIndexValues();
    }
    else if (this.assetlocation == 'assetlocation') {
      this.isCodeFieldShow = true;
      this.modalTitle = 'Assets Location';
      this.getAssetsLocationIndexValues();
    }
    else if (this.assetcondition == 'assetcondition') {
      this.modalTitle = 'Assets Condition';
      this.getAssetsConditionIndexValues();
    }
    else if (this.assetaction == 'assetaction') {
      this.modalTitle = 'Assets Action';
      this.getAssetsActionIndexValues();
    }
    else if (this.invoiceterm == 'invoiceterm') {
      this.modalTitle = 'Invoice Terms';
      this.getInvoiceTermsIndexValues();
    }
    else if (this.term == 'quoteterm') {
      this.modalTitle = 'Quote Terms';
      this.getQuoteTermsIndexValues();
    }
    else if (this.term == 'estimateterm') {
      this.modalTitle = 'Estimate Terms';
      this.getEstimateTermsIndexValues();
    }
    else if (this.term == 'poterm') {
      this.modalTitle = 'Purchase Order Terms';
      this.getPOTermsIndexValues();
    }
    else if (this.term == 'soterm') {
      this.modalTitle = 'SO Terms';
      this.getSOTermsIndexValues();
    }
    else if (this.term == 'projecterm') {
      this.modalTitle = 'Payment Terms';
      this.getProjectTermsIndexValues();
    }
    else if (this.manufacturer == 'manufacturer') {
      this.modalTitle = 'Manufacturer';
      this.getManufacturerIndexValues();
    }
    else if (this.warehouse == 'warehouse') {
      this.modalTitle = 'Warehouse';
      this.getWarehouseIndexValues();
    }
    else if (this.paymentmethod == 'paymentmethod') {
      this.modalTitle = 'Payment Method';
      this.getPaymentMethodIndexValues();
    }
    else if (this.employeedepartment == 'employeedepartment') {
      this.modalTitle = 'Employee Department';
      this.getEmployeeDepartmentIndexValues();
    }
    else if (this.documenttype == 'documenttype') {
      this.isCodeFieldShow = true;
      this.modalTitle = 'Document Type';
      this.getDocumentTypeIndexValues();
    }
    else if (this.emplocation == 'emplocation') {
      this.isCodeFieldShow = false;
      this.modalTitle = 'Add Location';
      this.getLocationIndexValues();
    }
    else if (this.type == 'customertypes') {
      this.isCodeFieldShow = true;
      this.modalTitle = 'Customer Types';
      this.getCustomerTypeIndexValues();
    }
    else if (this.type == 'devicetypes') {
      this.isCodeFieldShow = false;
      this.modalTitle = 'Device Types';
      this.getMarkerTypes();
      this.getDeviceTypeIndexValues();
    }
    else if (this.type == 'piterms') {
      this.isCodeFieldShow = false;
      this.modalTitle = 'Purchase Invoice Terms';
      this.getPITermsIndexValues();
    }
    else if (this.type == 'disputereason') {
      this.isCodeFieldShow = false;
      this.modalTitle = 'Dispute Reason';
      this.getDisputeReasonIndexValues();
    }
    else if (this.type == 'writeoffreasons') {
      this.isCodeFieldShow = false;
      this.modalTitle = 'Writeoff Reason';
      this.getWriteoffReasonIndexValues();
    }

    environment.production ? '' : console.log('log for title', this.term);

    this.showtitle = true;
    this.cdRef.detectChanges();

  }
  initializeValues() {
    this.master = {
      id: null,
      code: null,
      description: '',
      marker_type_id: '',
      createby: null,
      createdate: null,
      updateby: null,
      active: true
    };
  }
  editMasterData(item) {
    this.master = item;
    this.master.active = true;
    if (this.type == 'deliveryroutes') {
      this.master.description = item.name;
    }
  }
  deleteMasterData(item) {
    this.isDataSave = true;
    if (this.type == 'projectype') {
      this.deleteProjectType(item);
    } else if (this.type == 'deliveryroutes') {
      this.deleteDeliveryRoute(item)
    } else if (this.type == 'salesrequesttype') {
      this.deleteSalesRequestType(item)
    } else if (this.type == 'cpc') {
      this.deleteCPC(item);
    } else if (this.type == 'contracttype') {
      this.deleteContractType(item);
    }
    else if (this.type == 'tasktype') {
      this.deleteTaskType(item);
    }
    else if (this.empdesignation == 'empdesignation') {
      this.deleteDesignation(item);
    }
    else if (this.assetlocation == 'assetlocation') {

      this.deleteAssetsLocation(item);
    }
    else if (this.assetcondition == 'assetcondition') {
      this.deleteAssetsCondition(item);
    }
    else if (this.assetaction == 'assetaction') {
      this.deleteAssetsAction(item);
    }
    else if (this.invoiceterm == 'invoiceterm') {
      this.deleteInvoiceTerms(item);
    }
    else if (this.term == 'quoteterm') {
      this.deleteQuoteTerms(item);
    }
    else if (this.term == 'estimateterm') {
      this.deleteEstimateTerms(item);
    }
    else if (this.term == 'poterm') {
      this.deletePOTerms(item);
    }
    else if (this.term == 'soterm') {
      this.deleteSOTerms(item);
    }
    else if (this.term == 'projecterm') {
      this.deleteProjectTerms(item);
    }
    else if (this.manufacturer == 'manufacturer') {
      this.deleteManufacturer(item);
    }
    else if (this.warehouse == 'warehouse') {
      this.deleteWarehouse(item);
    }
    else if (this.paymentmethod == 'paymentmethod') {
      this.deletePaymentMethod(item);
    }
    else if (this.employeedepartment == 'employeedepartment') {
      this.deleteEmployeeDepartment(item);
    }
    else if (this.documenttype == 'documenttype') {
      this.deleteDocumentType(item);
    }
    else if (this.emplocation == 'emplocation') {
      this.deleteLocation(item);
    }
    else if (this.type == 'sotype') {
      this.deleteSOType(item);
    } else if (this.type == 'customertypes') {
      this.deleteCustomerType(item);
    } else if (this.type == 'devicetypes') {
      this.deleteDeviceType(item);
    }
    else if (this.type == 'disputereason') {
      this.deleteDisputeReason(item);
    } else if (this.type == 'piterms') {
      this.deletePITerm(item);
    }

  }
  validatePostValues() {
    if (this.master.description == undefined || this.master.description == '') {
      this.toastr.Error('Please enter description')
      return;
    }
    this.isDataSave = true;
    if (this.master.id == undefined || this.master.id == null) {
      this.master.companyid = this.userdata.companyid;
      this.master.createby = this.userdata.email;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.master.updateby = this.userdata.email;
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    if (this.type == 'cpc') {
      this.postCPC();
    } else if (this.type == 'deliveryroutes') {
      this.postDeliveryRoute();
    } else if (this.type == 'salesrequesttype') {
      this.postSalesRequestType();
    } else if (this.type == 'projectype') {
      this.postProjectType();
    } else if (this.type == 'expensetype') {
      this.postExpenseType();
    } else if (this.type == 'sotype') {
      this.postSOType();
    } else if (this.type == 'contracttype') {
      this.postContractType();
    }
    else if (this.type == 'tasktype') {
      this.postTaskType();
    }
    else if (this.empdesignation == 'empdesignation') {
      this.postDesignation();
    }
    else if (this.assetlocation == 'assetlocation') {
      this.postAssetsLocation();
    }
    else if (this.assetcondition == 'assetcondition') {
      this.postAssetsCondition();
    }
    else if (this.assetaction == 'assetaction') {
      this.postAssetsAction();
    }
    else if (this.invoiceterm == 'invoiceterm') {
      this.postInvoiceTerms();
    }
    else if (this.term == 'quoteterm') {
      this.postQuoteTerms();
    }
    else if (this.term == 'estimateterm') {
      this.postEstimateTerms();
    }
    else if (this.term == 'poterm') {
      this.postPOTerms();
    }
    else if (this.term == 'soterm') {
      this.postSOTerms();
    }
    else if (this.term == 'projecterm') {
      this.postProjectTerms();
    }
    else if (this.manufacturer == 'manufacturer') {
      this.postManufacturer();
    }
    else if (this.warehouse == 'warehouse') {
      this.postWarehouse();
    }
    else if (this.paymentmethod == 'paymentmethod') {
      this.postPaymentMethod();
    }
    else if (this.employeedepartment == 'employeedepartment') {
      this.postEmployeeDepartment();
    }
    else if (this.documenttype == 'documenttype') {
      this.postDocumentType();
    }
    else if (this.emplocation == 'emplocation') {
      this.postLocation();
    }
    else if (this.type == 'customertypes') {
      this.postCustomerType();
    }
    else if (this.type == 'devicetypes') {
      this.postDeviceType();
    } else if (this.type == 'disputereason') {
      this.postDisputeReason();
    } else if (this.type == 'piterms') {
      this.postPITerms();
    } else if (this.type == 'writeoffreasons') {
      this.postWriteoffReason();
    }
  }
  // ***** sales request type begins ***********
  getSalesRequestTypeIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getSalesRequestType(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      this.itemList.forEach(item => {
        item.active = item.active == 1 ? true : false;
      })
    }, err => {
      this.loader.stop();
    })
  }
  postSalesRequestType() {
    this.master.active = this.master.active ? 1 : 0;
    this.loader.start();
    this.service.postSalesRequestType(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isDataSave = true;
        this.initializeValues();
        this.getSalesRequestTypeIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteSalesRequestType(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteSalesRequestType(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getSalesRequestTypeIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete cpc');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** sales request type ends ***********

  // ***** cpc begin ***********
  getCpcIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getCPCIndex(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      this.itemList.forEach(item => {
        item.active = item.active == 1 ? true : false;
      })
    }, err => {
      this.loader.stop();
    })
  }
  postCPC() {
    this.master.active = this.master.active ? 1 : 0;
    this.loader.start();
    this.service.postCPC(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isDataSave = true;
        this.initializeValues();
        this.getCpcIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }

  deleteCPC(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteCPC(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getCpcIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete cpc');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** cpc end ***********

  // ***** delivery route begin ***********
  getDeliveryRouteIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getDeliveryRouteIndex(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      this.itemList.forEach(item => {
        item.active = item.active == 1 ? true : false;
      })
    }, err => {
      this.loader.stop();
    })
  }
  postDeliveryRoute() {
    this.master.active = this.master.active ? 1 : 0;
    this.master.name = this.master.description ? this.master.description : ''
    this.loader.start();
    this.service.postDeliveryRoute(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isDataSave = true;
        this.initializeValues();
        this.getDeliveryRouteIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }

  deleteDeliveryRoute(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteDeliveryRoute(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getDeliveryRouteIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete cpc');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  //delivery route end
  // ***** project type begin ***********
  getProjectTypeIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getProjectTypeIndex(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postProjectType() {
    this.loader.start();
    this.service.postProjectType(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getProjectTypeIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteProjectType(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteProjectType(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getProjectTypeIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete project type');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** project type end ***********

  // ***** contract type begin ***********
  getContractTypeIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getContractTypeIndex(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postContractType() {
    this.loader.start();
    this.service.postContractType(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getContractTypeIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteContractType(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteContractType(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getContractTypeIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete contract type');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** contract type end ***********

  // ***** Task type begin ***********
  getTaskTypeIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getTaskTypeIndex(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postTaskType() {
    this.loader.start();
    this.service.postTaskType(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getTaskTypeIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteTaskType(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteTaskType(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getTaskTypeIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete task type');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  // ***** Task type end ***********
  // ***** Expense type begin ***********
  getExpenseTypeIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.purchaseservice.getExpensesCategory('').subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postExpenseType() {
    this.loader.start();
    this.purchaseservice.postExpensesCategory(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getExpenseTypeIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  // deleteExpenseType(master) {
  //   Swal.fire({
  //     title: 'Are you sure want to delete?',
  //     // text: 'You will not be able to recover this file!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes',
  //     cancelButtonText: 'No'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.loader.start();
  //       this.service.deleteTaskType(master.id).subscribe(res => {
  //         this.loader.stop();
  //         if (res != null) {
  //           this.toastr.Success('Record deleted successfully');
  //           this.initializeValues();
  //           this.getExpenseTypeIndexValues();
  //         } else {
  //           this.toastr.Error('Record not deleted');
  //         }
  //       }, err => {
  //         this.loader.stop();
  //         this.toastr.Error('Error occurred while delete task type');
  //       })
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //     }
  //   })
  // }

  // ***** Expense type end ***********

  // ***** Designation begin ***********
  getDesignationIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getDesignationIndex('').subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postDesignation() {
    this.loader.start();
    this.service.postDesignation(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getDesignationIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteDesignation(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteDesignation(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getDesignationIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete emp designation');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Designation end ***********

  // ***** Assets Location begin ***********
  getAssetsLocationIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getAssetsLocationIndex('').subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postAssetsLocation() {
    this.loader.start();
    this.service.postAssetsLocation(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getAssetsLocationIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteAssetsLocation(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteAssetsLocation(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getAssetsLocationIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete asset location');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Assets Location end ***********

  // ***** Assets Condition begin ***********
  getAssetsConditionIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getAssetsConditionIndex('').subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postAssetsCondition() {
    this.loader.start();
    this.service.postAssetsCondition(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getAssetsConditionIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteAssetsCondition(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteAssetsCondition(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getAssetsConditionIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while post service call');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Assets Condition end ***********

  // ***** Assets Action begin ***********
  getAssetsActionIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getAssetsActionIndex('').subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postAssetsAction() {
    this.loader.start();
    this.service.postAssetsAction(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getAssetsActionIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteAssetsAction(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteAssetsAction(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getAssetsActionIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while post service call');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Assets Action end ***********

  // ***** Invoice Terms begin ***********
  getInvoiceTermsIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getInvoiceTermsIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postInvoiceTerms() {
    this.loader.start();
    this.service.postInvoiceTerms(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getInvoiceTermsIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post invoice term');
    })
  }
  deleteInvoiceTerms(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteInvoiceTerms(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getInvoiceTermsIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting invoice term');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Invoice Terms end ***********

  // ***** Quote Terms begin ***********
  getQuoteTermsIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getQuoteTermsIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postQuoteTerms() {
    this.loader.start();
    this.service.postQuoteTerms(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList = res.data;
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post invoice term');
    })
  }
  deleteQuoteTerms(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteQuoteTerms(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getQuoteTermsIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting invoice term');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Quote Terms end ***********
  // ***** Estimate Terms begin ***********
  getEstimateTermsIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getEstimateTermsIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postEstimateTerms() {
    this.loader.start();
    this.service.postEstimateTerms(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList = res.data;
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post invoice term');
    })
  }
  deleteEstimateTerms(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteEstimateTerms(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getEstimateTermsIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting invoice term');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }

  // ***** Estimate Terms end ***********
  // ***** PO Terms begin ***********
  getPOTermsIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getPOTermsIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postPOTerms() {
    this.loader.start();
    this.service.postPOTerms(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList = res;
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post invoice term');
    })
  }
  deletePOTerms(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deletePOTerms(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getPOTermsIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting invoice term');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** PO Terms end ***********

  // ***** SO Terms begin ***********
  getSOTermsIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getSOTermsIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postSOTerms() {
    this.loader.start();
    this.service.postSOTerms(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList = res.data;
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post invoice term');
    })
  }
  deleteSOTerms(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteSOTerms(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getSOTermsIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting invoice term');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** SO Terms end ***********

  // ***** Project Terms begin ***********
  getProjectTermsIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getProjectTermsIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postProjectTerms() {
    this.loader.start();
    this.service.postProjectTerms(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getProjectTermsIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post invoice term');
    })
  }
  deleteProjectTerms(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteProjectTerms(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getProjectTermsIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting invoice term');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Project Terms end ***********

  // ***** Product Manufacturer begin ***********
  getManufacturerIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getManufacturerIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      this.itemList.forEach(element => {
        element.description = element.name;
      })
    }, err => {
      this.loader.stop();
    })
  }
  postManufacturer() {
    this.master.name = this.master.description;
    this.loader.start();
    this.service.postManufacturer(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getManufacturerIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post invoice term');
    })
  }
  deleteManufacturer(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteManufacturer(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getManufacturerIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting invoice term');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Product Manufacturer end ***********

  // ***** binLocation warehouse begin ***********
  getWarehouseIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getWarehouseIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postWarehouse() {
    this.master.name = this.master.description;
    this.loader.start();
    this.service.postWarehouse(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getWarehouseIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post invoice term');
    })
  }
  deleteWarehouse(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteWarehouse(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getWarehouseIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting invoice term');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** binLocation warehouse end ***********

  // ***** paybill payment method begin ***********
  getPaymentMethodIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getPaymentMethodIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }

  postPaymentMethod() {
    // this.master.name = this.master.description;
    this.loader.start();
    this.service.postPaymentMethod(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getPaymentMethodIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post invoice term');
    })
  }
  deletePaymentMethod(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deletePaymentMethod(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getPaymentMethodIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting invoice term');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** paybill payment method end ***********

  // ***** employee department begin ***********
  getEmployeeDepartmentIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getEmpDepartmentIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postEmployeeDepartment() {
    // this.master.name = this.master.description;
    this.loader.start();
    this.service.postEmpDepartment(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getEmployeeDepartmentIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post emp department');
    })
  }
  deleteEmployeeDepartment(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteEmpDepartment(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getEmployeeDepartmentIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting emp department');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** document type end ***********

  // ***** employee department begin ***********
  getDocumentTypeIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getDocumentTypeIndex('', '').subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      environment.production ? '' : console.log('document type list data ', res);
    }, err => {
      this.loader.stop();
    })
  }
  postDocumentType() {
    this.master.parentid = null;
    if (this.master.id == undefined || this.master.id == null) {
      this.master.roles = 'ADM';
    }
    this.loader.start();
    this.service.postDocumentType(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getDocumentTypeIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post document type');
    })
  }
  deleteDocumentType(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteDocumentType(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getDocumentTypeIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting document type');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** employee department end ***********


  // ***** product uom begin ***********

  // ***** employee department begin ***********
  getLocationIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getLocationIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      this.itemList.forEach(element => {
        element.description = element.Text;
        element.id = element.Value;
        element.active = 'true';
      })
      environment.production ? '' : console.log('location list data ', res);
    }, err => {
      this.loader.stop();
    })
  }
  postLocation() {
    var master = {
      id: this.master.id ? this.master.id : null,
      name: this.master.description,
      code: this.master.code,
      address: '',
      active: true,
      companyid: this.userdata.companyid,
      createby: this.master.createby ? this.master.createby : this.userdata.email,
      createdate: this.master.createdate ? this.datepipe.transform(new Date(this.master.createdate), this.userdata.postdatetimeformat) : this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      updateby: this.master.id ? this.userdata.email : null,
      updatedate: this.master.id ? this.datepipe.transform(new Date(), this.userdata.postdatetimeformat) : null
    };

    this.loader.start();
    this.service.postLocation(master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList = res.data;
        this.itemList.forEach(element => {
          element.description = element.name;
          element.id = element.id;
          element.active = 'true';
        })
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post location list');
    })
  }
  deleteLocation(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteLocation(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getLocationIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting location list');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** employee department end ***********
  // ***** sales order type begin ***********
  getSOTypeIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getSOTypeIndex(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postSOType() {
    this.loader.start();
    this.service.postSOType(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList = res.data;
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteSOType(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteSOType(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getSOTypeIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete contract type');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** contract type end ***********

  // ***** customer types begin ***********
  getCustomerTypeIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getCustomerTypeIndex(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      environment.production ? '' : console.log('location list data ', res);
    }, err => {
      this.loader.stop();
    })
  }
  postCustomerType() {
    if (this.master.id == undefined || this.master.id == null) {
      this.master.createby = this.userdata.email;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.master.id = this.master.id;
      this.master.updateby = this.userdata.email;
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.master.companyid = this.userdata.companyid;
    this.loader.start();
    this.service.postCustomerType(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getCustomerTypeIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post location list');
    })
  }
  deleteCustomerType(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteCustomerType(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getCustomerTypeIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting location list');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** customer type end ***********

  // *****Dispute Begin ***********
  getDisputeReasonIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getDisputeReasonIndex(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      environment.production ? '' : console.log('location list data ', res);
    }, err => {
      this.loader.stop();
    })
  }
  postDisputeReason() {
    if (this.master.id == undefined || this.master.id == null) {
      this.master.createby = this.userdata.email;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.master.id = this.master.id;
      this.master.updateby = this.userdata.email;
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.master.companyid = this.userdata.companyid;
    this.loader.start();
    this.service.postDisputeReason(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getDisputeReasonIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post location list');
    })
  }
  deleteDisputeReason(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteDisputeReason(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getCustomerTypeIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting location list');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Dispute reason end ***********
  // ***** device type start ***********
  getMarkerTypes() {
    this.markerTypes = [];
    this.loader.start();
    this.specmapService.getSpecMapCategories().subscribe(res => {
      this.loader.stop();
      this.markerTypes = res;
    }, err => {
      this.loader.stop();
    })
  }
  getDeviceTypeIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getDeviceTypeIndex(this.master.description).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      environment.production ? '' : console.log('location list data ', res);
    }, err => {
      this.loader.stop();
    })
  }
  postDeviceType() {
    if (this.master.id == undefined || this.master.id == null) {
      this.master.createby = this.userdata.email;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.master.id = this.master.id;
      this.master.updateby = this.userdata.email;
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.master.companyid = this.userdata.companyid;
    this.loader.start();
    this.service.postDeviceType(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getDeviceTypeIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post location list');
    })
  }
  deleteDeviceType(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteDeviceType(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getDeviceTypeIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting location list');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** device type end ***********

  // ***** purchase invoice terms start ***********
  getPITermsIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getPITermsIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      environment.production ? '' : console.log('location list data ', res);
    }, err => {
      this.loader.stop();
    })
  }
  postPITerms() {
    if (this.master.id == undefined || this.master.id == null) {
      this.master.createby = this.userdata.email;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.master.id = this.master.id;
      this.master.updateby = this.userdata.email;
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.master.companyid = this.userdata.companyid;
    this.loader.start();
    this.service.postPITerms(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getPITermsIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post location list');
    })
  }
  deletePITerm(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deletePITerms(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getPITermsIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting location list');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** device type end ***********
  // *****Writeoff Begin ***********
  getWriteoffReasonIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getWriteoffReasonIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      environment.production ? '' : console.log('location list data ', res);
    }, err => {
      this.loader.stop();
    })
  }
  postWriteoffReason() {
    if (this.master.id == undefined || this.master.id == null) {
      this.master.createby = this.userdata.email;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.master.id = this.master.id;
      this.master.updateby = this.userdata.email;
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    this.master.companyid = this.userdata.companyid;
    this.loader.start();
    this.service.postWriteoffReason(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.getWriteoffReasonIndexValues();
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post location list');
    })
  }
  deleteWriteoffReason(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteWriteoffReason(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getWriteoffReasonIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting location list');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Writeoff reason end ***********
}
