<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none">
            <div class="logo-wrapper">
                <a [routerLink]="'/dashboard/default'">
                    <!-- <img class="blur-up lazyloaded" src="assets/images/applogo.svg" alt=""> -->
                    <img class="blur-up lazyloaded" [src]="userdata.pic" alt="EzyBook"
                        onerror="this.src='assets/images/applogo.svg'">
                </a>
            </div>
        </div>
        <div class="mobile-sidebar">
            <div class="media-body text-right switch-sm">
                <label class="switch">
                    <i *ngIf="!this.open" id="sidebar-toggle" class="fa fa-chevron-left"
                        (click)="collapseSidebar()"></i>
                    <i *ngIf="this.open" id="sidebar-toggle" class="fa fa-chevron-right"
                        (click)="collapseSidebar()"></i>
                </label>
                <div class="page-header-left">
                    <h3 *ngIf="title != 'Dashboard'">{{title}} {{editData}}
                        <!-- <h3>{{title}} {{name}} {{refno1}} {{refno2}}</h3> -->

                    </h3>
                    <!-- <select class="custom-header" *ngIf="title == 'Dashboard'" [(ngModel)]="userdata.companyid" (change)="changeCompany()">
                        <option *ngFor="let company of userdata.companyids" [value]="company.companyid">{{company.displayname}}</option>
                    </select> -->
                    <div class="company-name-header" *ngIf="title == 'Dashboard'" (click)="toggledropdown()">
                        <div class="company-name">{{selectedcompanyData.displayname}}</div><i class="drop-icon"
                            class="fa fa-chevron-down"></i>
                        <div class="customdropdown" *ngIf="companyDropdown">
                            <button class="dropdown-button" *ngFor="let company of userdata.companyids"
                                (click)="selectedCompany(company)">{{company.displayname}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="announcement" *ngIf="title == 'Dashboard'">
            <div class="home-alert forSM">
                <div class="alert-count blink_me" (click)="gotoAnnouncements()">{{announcements.length}}</div><i
                    class="fa fa-bullhorn blink_me" aria-hidden="true" (click)="gotoAnnouncements()"></i>
            </div>

            <ngb-alert class="home-alert" [dismissible]="false" type="warning">
                <div class="alert-count blink_me" (click)="gotoAnnouncements()">{{announcements.length}}</div><i
                    class="fa fa-bullhorn blink_me" aria-hidden="true" (click)="gotoAnnouncements()"></i>&nbsp;
                <!-- <marquee scrollamount="10"></marquee> -->
                <ng-marquee speed="slow">
                    <mark><span class="description" *ngFor="let n of announcements; let i = index">{{i + 1}}.
                            {{n.description}}&nbsp;&nbsp;&nbsp;</span></mark>
                </ng-marquee>
            </ngb-alert>
        </div>
        <div class="nav-right col hidden-md">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li>
                    <a href="javascript:void(0)" class="text-dark" toggleFullscreen>
                        <app-feather-icons [icon]="'maximize-2'"></app-feather-icons>
                    </a>
                </li>

                <li class="onhover-dropdown">
                    <a class="txt-dark" href="javascript:void(0)">
                        <h6>{{Lang}}</h6>
                    </a>
                    <ul class="language-dropdown onhover-show-div p-20">
                        <li (click)="switchLanguage('en')"><a href="javascript:void(0)" data-lng="en"><i class="flag-icon flag-icon-is"></i>
                                English</a></li>
                        <li (click)="switchLanguage('es')"><a href="javascript:void(0)" data-lng="es"><i class="flag-icon flag-icon-um"></i>
                                Spanish</a></li>
                         <li (click)="switchLanguage('ar')"><a href="javascript:void(0)" data-lng="ar"><i class="flag-icon flag-icon-ae"></i>
                                Arabic</a></li>
                        <!--<li><a href="javascript:void(0)" data-lng="fr"><i class="flag-icon flag-icon-nz"></i> French</a>
                        </li> -->
                    </ul>
                </li>
                <!-- <li class="onhover-dropdown">
                    <app-feather-icons [icon]="'plus-circle'" (click)="right_side_bar()"></app-feather-icons> -->
                <!-- <ul class="quickAction-dropdown onhover-show-div p-20">
                        <li>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="icon">
                                        <i class="fa fa-user-plus"></i>
                                        <a href="javascript:void(0)">
                                            Add Client</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="icon">
                                        <i class="fa fa-file-text-o"></i>
                                        <a href="javascript:void(0)">
                                            New Invoice</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="icon">
                                        <i class="fa fa-users"></i>
                                        <a href="javascript:void(0)">
                                            Add Vendor</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="icon">
                                        <i class="fa fa-file-text"></i>
                                        <a href="javascript:void(0)">
                                            New Expense</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="icon">
                                        <i class="fa fa-file-text-o"></i>
                                        <a href="javascript:void(0)">
                                            Record Receipt</a>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="icon">
                                        <i class="fa fa-money"></i>
                                        <a href="javascript:void(0)">
                                            Record Payment</a>
                                    </div>
                                </div>
                            </div>

                        </li>
                    </ul> -->
                <!-- </li> -->
                <!-- <li class="onhover-dropdown">
                    <app-feather-icons [icon]="'settings'"></app-feather-icons>
                    <ul class="language-dropdown onhover-show-div p-20">
                        <li><a href="javascript:void(0)" [routerLink]="'/importdata'">
                                Import Data</a></li>
                        <li><a href="javascript:void(0)" [routerLink]="'/importdata'">
                                Export Data</a></li>
                    </ul>
                </li> -->
                <li class="onhover-dropdown">
                    <span class="badge badge-pill badge-primary pull-right notification-badge">{{reminderLength}}</span>
                    <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"></span>
                    <ul class="notification-dropdown onhover-show-div">
                        <li>Reminders <span class="badge badge-pill badge-primary pull-right">{{reminderLength}}</span>
                        </li>
                        <li *ngFor="let item of ReminderData| slice:0:3; let i=index,">
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0"><span>
                                            <!-- <app-feather-icons class="shopping-color" [icon]="'shopping-bag'">
                                            </app-feather-icons> -->
                                            <!-- <i [class]="iconClass"></i>  -->
                                            <i class="shopping-color eb-purchase"
                                                *ngIf="item.type == 'PO' || item.type == 'Purchase'"></i>
                                            <i class="shopping-color eb-sales-request"
                                                *ngIf="item.type == 'SO' || item.type == 'Sales'"></i>
                                            <i class="shopping-color eb-estimate-add"
                                                *ngIf="item.type == 'QTE' || item.type == 'Quote'"></i>
                                            <i class="shopping-color eb-invoices"
                                                *ngIf="item.type == 'INV' || item.type == 'Invoice'"></i>
                                            <i class="shopping-color  eb_ticket" *ngIf="item.type == 'Ticket'"></i>
                                            <i class="shopping-color eb-project" *ngIf="item.type == 'Project'"></i>
                                            <i class="shopping-color fa fa-bug" *ngIf="item.type == 'Snag'"></i>
                                            <i class="shopping-color eb-expenses" *ngIf="item.type == 'Bill'"></i>
                                            <i class="shopping-color eb-project-task" *ngIf="item.type == 'Task'"></i>
                                            <i class="shopping-color fa fa-ban"
                                                *ngIf="item.type == '' || item.type == null || item.type == 'Other'"></i>
                                        </span>{{item.title}}
                                    </h6>
                                    <p class="mb-0">{{item.notes}}</p>
                                </div>
                            </div>
                        </li>
                        <li class="txt-dark" routerLink="./reminders"><a href="javascript:void(0)">All</a> reminders
                        </li>
                    </ul>
                </li>
                <!-- <li><a>
                        <app-feather-icons class="right_side_toggle" [icon]="'message-square'" routerLink="./chatsupport">
                        </app-feather-icons><span class="dot"></span>
                    </a></li> -->
                <!-- <li class="onhover-dropdown">
                    <div class="media align-items-center userMenu">
                        <img class="align-self-center pull-right rounded-circle" src="assets/images/dashboard/man.png"
                            alt="header-user">
                        <div class="dotted-animation">
                            <span class="animate-circle"></span><span class="main-circle"></span>
                        </div>
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li><a [routerLink]="'/userprofile'">
                                <i class="fa fa-user"></i>Edit Profile
                            </a>
                        </li> -->
                <!-- <li><a [routerLink]="'/changepassword'">
                                <i class="fa fa-lock"></i>Change Password
                            </a>
                        </li> -->
                <!-- <li><a [routerLink]="'/userguide'">
                                <i class="fa fa-question-circle"></i>Help
                            </a></li>
                        <li><a (click)="goToLogout()">
                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                            </a></li>
                    </ul>
                </li> -->
                <li class="onhover-dropdown">
                    <a href="javascript:void(0)" class="text-dark" (click)="goToLogout()" title="logoff">
                        <app-feather-icons [icon]="'log-out'"></app-feather-icons>
                    </a>
                </li>
            </ul>
        </div>
        <div class="nav-right col visible-md hidden-lg">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li class="onhover-dropdown">
                    <i class="eb-dots" style="font-size: 20px;" aria-hidden="true"></i>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li>
                            <a href="javascript:void(0)" class="text-dark" toggleFullscreen>
                                <app-feather-icons [icon]="'maximize-2'"></app-feather-icons> Full Screen
                            </a>
                        </li>
                        <li><a (click)="goToLogout()">
                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                            </a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>