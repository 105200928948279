import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintElementService } from 'ngx-print-element';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { purchaseServices } from 'src/app/components/purchase/purchase.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-uom-ratio',
  templateUrl: './uom-ratio.component.html',
  styleUrls: ['./uom-ratio.component.scss']
})
export class UomRatioComponent implements OnInit {
  @Input('uom') uom;
  itemList: any;
  userdata: any;
  appname = environment.APP_NAME;
  master: any = {
    id: null,
    from_uom: null,
    to_uom: null,
    ratio: 0,
    createby: null,
    createdate: null,
    updateby: null,
    updatedate: null,
    active: true
  }
  uomList: any = [];
  produom: any;
  prodUOMList: any = [];
  constructor(private modalService: NgbModal, private cdRef: ChangeDetectorRef, private toastr: NotificationService, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: CommonServices, public print: NgxPrintElementService, private commoanService: CommonServices) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.commoanService.getProdUom().subscribe(res => {
      this.uomList = res;
    }, err => {
    });
  }

  ngOnInit(): void {
    this.produom = this.uom;
    this.master.from_uom = this.produom.description;
    this.getProductUOMIndexValues();
  }
  getProductUOMIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getProductUOMRatio(this.produom.id).subscribe(res => {
      this.loader.stop();
      this.prodUOMList = res;
    }, err => {
      this.loader.stop();
    })
  }
  editMasterData(item) {
    this.loader.start();
    this.service.getProductUOMRatioByID(item.id).subscribe(res => {
      this.loader.stop();
      this.master = res;
    }, err => {
      this.loader.stop();
    })
  }
  deleteProductUOM(item) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteProductUOMRatio(item.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.getProductUOMIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while deleting product uom ratio');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  postProductUOM() {
    if (this.master.id) {
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.master.updateby = this.userdata.email;
    } else {
      this.master.companyid = this.userdata.companyid;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      this.master.createby = this.userdata.email;
      this.master.from_uom_id = this.produom.id;
    }
    this.loader.start();
    this.service.postProductUOMRatio(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.master.id = null;
        this.master.ratio = 0;
        this.master.to_uom = null;
        this.getProductUOMIndexValues();
      } else {
        this.toastr.Error('Record not saved or updated');
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post product uom ratio');
    })
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
}
