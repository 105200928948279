<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <form (keydown.enter)="getItems()">
          <div class="custom-row">
            <div class="form-row">
              <div class="col-lg-6 pr-0">
                <div class="row">
                  <div class="col-lg-9 col-md-6 form-group">
                    <label for="">What are you looking for?</label>
                    <input type="search" class="form-control" [(ngModel)]="filter.searchtext" name="searchtext"
                      placeholder="Search by ILC, Description, Supplier" (change)="getItems()">
                  </div>
                  <div class="col-lg-3 col-md-6 form-group">
                    <label for="">Type </label>
                    <select class="form-control" required="" name="type" [(ngModel)]="filter.type"
                      (change)="getItems()">
                      <option value="">All</option>
                      <option *ngFor="let item of productType" [value]="item.stringValue">{{item.Text}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 p-0">
                <div class="row">
                  <div class="col-lg-3 col-md-6 form-group p-o">
                    <label for="">Department</label>
                    <ng-select placeholder="Search Department" required="" name="deptid" [(ngModel)]="filter.deptid"
                      (ngModelChange)="selectDepartment($event)" [clearable]="filter.deptid">
                      <ng-option *ngFor="let item of deptList" [value]="item.Value">
                        {{item.Text}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group pl-0">
                    <label for="">Category</label>
                    <select class="form-control" required="" name="cat" [(ngModel)]="filter.category"
                      (change)="getItems()">
                      <option value="">All</option>
                      <option *ngFor="let item of categoryList" [value]="item.Value">{{item.Text}}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group pl-0">
                    <label for="">Status</label>
                    <!-- <select class="form-control" required="" name="status" [(ngModel)]="filter.status"
                                        (change)="getItems()">
                                        <option value="">All</option>
                                        <option *ngFor="let item of statusList" [value]="item.stringValue">
                                            {{item.Text}}
                                        </option>
                                    </select> -->
                    <ng-multiselect-dropdown [placeholder]="'Status'" [settings]="dropdownSettings" [data]="statusList"
                      name="status" [(ngModel)]="selectedProductstatus" (onSelect)="onItemSelect($event)"
                      (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)">
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group p-0">
                    <label for="">Serilized</label>
                    <select class="form-control" required="" name="Serilized" [(ngModel)]="filter.serialized"
                      (change)="getItems()">
                      <option value="">All</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
              <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
                (click)="getItems()"><i class="refresh-btn fa fa-search"></i></button>
              <button type="button" container="body" ngbTooltip="Refresh"
                class="ml-2 btn btn-primary right-radius left-radius" (click)="refresh()"><i
                  class="refresh-btn fa fa-refresh"></i></button>
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="eb-dots toggleBtn" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="main-dropdown ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="goToAdd()">Add
                      Product</span></li>
                  <li><span ngbDropdownItem (click)="exportData('csv')"> Export to csv</span></li>
                  <li><span ngbDropdownItem (click)="exportData('excel')"> Export to excel</span></li>
                </ul>
              </div>
            </div>
          </div>
        </form>
        <div class="category-table custom-datatable product-list supplierproducttable">
          <div class="table-responsive">
            <ng2-smart-table [settings]="settings" [source]="products" (custom)="onCustomAction($event)">
            </ng2-smart-table>
          </div>
          <div class="total-count justify-content-between" *ngIf="totalRows">
            <span><b class="mr-1">Total rows : </b> {{totalRows}}</span>
            <div class="pagination">
              <span class="right-button arrow-btn" [class.disabled]="hideRightNav" (click)="gotoPage('first')"><i
                  class="fa fa-angle-double-left"></i></span>
              <span class="right-button arrow-btn" [class.disabled]="hideRightNav" (click)="gotoPage('prev')"><i
                  class="fa fa-angle-left"></i></span>
              <div aria-current="page" class="igx-page-nav__text">
                <span>{{currentPage}}</span><span>&nbsp;of&nbsp;</span><span>{{totalPages}}</span>
              </div>
              <span class="arrow-btn" [class.disabled]="hideLeftNav" (click)="gotoPage('next')"><i
                  class="fa fa-angle-right"></i></span>
              <span class="arrow-btn" [class.disabled]="hideLeftNav" (click)="gotoPage('last')"><i
                  class="fa fa-angle-double-right"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-ui-loader></ngx-ui-loader>