import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-receivedchequetracking',
  templateUrl: './receivedchequetracking.component.html',
  styleUrls: ['./receivedchequetracking.component.scss']
})
export class ReceivedchequetrackingComponent implements OnInit {
  fullScreen = false;
  tracking: any = {
    status: '',
    notes: ''
  };
  userdata: any;
  appname: any = environment.APP_NAME;
  statusList: any = [];
  constructor(private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private toastr: NotificationService, private service: CommonServices, private datepipe: DatePipe) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.service.getComboEnums('ReceivePaychequestatus').subscribe(res => {
      this.statusList = res;
      environment.production ? '' : console.log('status list', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading status list ', err);
    });
  }

  ngOnInit(): void {
  }
  closeModal(action) {
    this.activeModal.close(action);
  }

  saveTracking() {
    if (this.tracking.status == null && this.tracking.status == '') {
      this.toastr.Error('Please select status');
      return;
    }
    this.tracking.companyid = this.userdata.companyid;
    this.tracking.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.tracking.createby = this.userdata.email;
    this.loader.start();
    this.service.postReceivedTracking(this.tracking).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          this.tracking = res.data;
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal('close');
      }
    }, err => {
      this.loader.stop();
    })
  }

}
