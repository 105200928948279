<div class="row">
  <div class="col-sm-12">
    <!-- <div class="row">
			<div class="col-lg-9 col-md-9 col-12 form-group"></div>
			<div class="col-lg-3 col-md-3 col-12 form-group">
				<input type="search" class="form-control placehder" name="" placeholder="&#xF002; Search">
			</div>
		</div> -->
    <div class="clearfix"></div>
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="Add Details"
                [class.disabled]="fieldsetDisabled" data-target="#exampleModal" (click)="open(content,'','')"><i
                  class="fa fa-plus"></i>
              </a></th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Field Name <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)" style="width: 8%;">
              Field Value <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              CreateBy <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              CreateDate <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              UpdateBy <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              UpdateDate <i class="Sorting fa fa-chevron-up"></i>
            </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of detailsList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="open(content,item,'view')">View</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled"
                      (click)="open(content,item,'edit')">Edit</span></li>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled" (click)="delete(item.id)">Delete</span>
                  </li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.field_name}}</td>
            <td class="custom-td text-left">{{item.field_value}}</td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.createdate |
              date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.updatedate |
              date:userdata.displaydatetimeformat}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Details</h5>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation" name="from" #f="ngForm">
        <div class="form row">
          <div class="form-group col-md-6 col-sm-12">
            <label for="" class="mb-1">Field Name</label>
            <input class="form-control" id="" type="text" name="field_name" [(ngModel)]="detailsData.field_name"
              #field_name="ngModel"
              [ngClass]="{ 'is-invalid': field_name.invalid && field_name.errors , 'is-valid': field_name.valid}"
              required>
            <div *ngIf="field_name.invalid && field_name.errors" class="invalid-feedback d-block">
              <div *ngIf="field_name.errors.required">
                Please enter field name
              </div>
            </div>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="" class="mb-1">Field Value</label>
            <input class="form-control" id="" type="text" name="field_value" [(ngModel)]="detailsData.field_value"
              #field_value="ngModel"
              [ngClass]="{ 'is-invalid': field_value.invalid && field_value.errors , 'is-valid': field_value.valid}"
              required>
            <div *ngIf="field_value.invalid && field_value.errors" class="invalid-feedback d-block">
              <div *ngIf="field_value.errors.required">
                Please enter field value
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
      <button type="button" class="btn btn-primary" [disabled]="!f.valid || isView"
        (click)="postDetails()">Save</button>
    </div>
  </resize-border>
</ng-template>