<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <form class="needs-validation" name="form" #f="ngForm" (keydown.enter)="$event.preventDefault()">

            <div #header class="modal-header">
                <h5 class="modal-title f-w-600" id="productModalLabel">Offer Details# {{offerData.id}}
                </h5>
                <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                    <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                    <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
                </button>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="form row">
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Offer Type</label>
                            <select class="form-control" name="offertp" [(ngModel)]="offerData.offer_type"
                                #offertp="ngModel"
                                [ngClass]="{ 'is-invalid': offertp.invalid && offertp.errors , 'is-valid': offertp.valid}"
                                required="">
                                <option value="">Select Offer Type</option>
                                <option *ngFor="let type of offertype" [value]="type.stringValue">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="offertp.invalid && offertp.errors" class="invalid-feedback d-block">
                                <div *ngIf="offertp.errors.required">
                                    Offer type is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">Expected Date of Joining</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker260" placeholder="Choose a date" name="expected_doj"
                                [(ngModel)]="offerData.expected_doj" #doj="ngModel"
                                [ngClass]="{ 'is-invalid': doj.invalid && doj.errors , 'is-valid': doj.valid}"
                                required="">
                            <mat-datepicker-toggle matSuffix [for]="picker260"></mat-datepicker-toggle>
                            <mat-datepicker #picker260></mat-datepicker>
                        </mat-form-field>
                        <div *ngIf="doj.invalid && doj.errors" class="invalid-feedback d-block">
                            <div *ngIf="doj.errors.required">
                                Excepted date of joining is required
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Contract</label>
                            <select class="form-control" name="contractid" [(ngModel)]="offerData.contractid"
                                #contractid="ngModel"
                                [ngClass]="{ 'is-invalid': contractid.invalid && contractid.errors , 'is-valid': contractid.valid}"
                                required="">
                                <option value="">Select Contract</option>
                                <option *ngFor="let type of contractList" [value]="type.Value">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="contractid.invalid && contractid.errors" class="invalid-feedback d-block">
                                <div *ngIf="contractid.errors.required">
                                    Contract is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Pay Type</label>
                            <select class="form-control" name="paytype" [(ngModel)]="offerData.paytype"
                                #paytype="ngModel"
                                [ngClass]="{ 'is-invalid': paytype.invalid && paytype.errors , 'is-valid': paytype.valid}"
                                required="">
                                <option value="">Select Pay Type</option>
                                <option *ngFor="let type of paytypeList" [value]="type.stringValue">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="paytype.invalid && paytype.errors" class="invalid-feedback d-block">
                                <div *ngIf="paytype.errors.required">
                                    Pay type is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">Annual pay salary</label>
                        <input type="number" class="form-control" placeholder="Annual pay salary" name="salary"
                            [(ngModel)]="offerData.salary" #salary="ngModel"
                            [ngClass]="{ 'is-invalid': salary.invalid && salary.errors , 'is-valid': salary.valid}"
                            required="">
                        <div *ngIf="salary.invalid && salary.errors" class="invalid-feedback d-block">
                            <div *ngIf="salary.errors.required">
                                Annual pay Salary is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">Monthly basic Salary</label>
                        <input type="number" class="form-control" placeholder="Monthly basic Salary" name="basicsalary"
                            [(ngModel)]="offerData.basic_salary" #basicsalary="ngModel"
                            [ngClass]="{ 'is-invalid': basicsalary.invalid && basicsalary.errors , 'is-valid': basicsalary.valid}"
                            required="">
                        <div *ngIf="basicsalary.invalid && basicsalary.errors" class="invalid-feedback d-block">
                            <div *ngIf="basicsalary.errors.required">
                                Basic Salary is required
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Work Shift</label>
                            <select class="form-control" name="workshift" [(ngModel)]="offerData.workshift"
                                #workshift="ngModel"
                                [ngClass]="{ 'is-invalid': workshift.invalid && workshift.errors , 'is-valid': workshift.valid}"
                                required="">
                                <option value="">Select Work Shift</option>
                                <option *ngFor="let type of shiftList" [value]="type.stringValue">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="workshift.invalid && workshift.errors" class="invalid-feedback d-block">
                                <div *ngIf="workshift.errors.required">
                                    Work shift is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">Hours/week</label>
                        <input type="number" class="form-control" placeholder="Hours/week" name="hrsperweek"
                            [(ngModel)]="offerData.hrsperweek" #hrsperweek="ngModel"
                            [ngClass]="{ 'is-invalid': hrsperweek.invalid && hrsperweek.errors , 'is-valid': hrsperweek.valid}"
                            required="">
                        <div *ngIf="hrsperweek.invalid && hrsperweek.errors" class="invalid-feedback d-block">
                            <div *ngIf="hrsperweek.errors.required">
                                Hours/week is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">Paid off days/year</label>
                        <input type="number" class="form-control" placeholder="Paid off days/year"
                            name="paidoffdaysperyr" [(ngModel)]="offerData.paidoffdaysperyr" #paidoffdaysperyr="ngModel"
                            [ngClass]="{ 'is-invalid': paidoffdaysperyr.invalid && paidoffdaysperyr.errors , 'is-valid': paidoffdaysperyr.valid}"
                            required="">
                        <div *ngIf="paidoffdaysperyr.invalid && paidoffdaysperyr.errors"
                            class="invalid-feedback d-block">
                            <div *ngIf="paidoffdaysperyr.errors.required">
                                Paid off days/year is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-sm-6">
                        <label for="">Paid sick days/year</label>
                        <input type="number" class="form-control" placeholder="Paid sick days/year"
                            name="paidsickdaysperyr" [(ngModel)]="offerData.paidsickdaysperyr"
                            #paidsickdaysperyr="ngModel"
                            [ngClass]="{ 'is-invalid': paidsickdaysperyr.invalid && paidsickdaysperyr.errors , 'is-valid': paidsickdaysperyr.valid}"
                            required="">
                        <div *ngIf="paidsickdaysperyr.invalid && paidsickdaysperyr.errors"
                            class="invalid-feedback d-block">
                            <div *ngIf="paidsickdaysperyr.errors.required">
                                Paid sick days/year is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-sm-6">
                        <label for="">Regular pay Rate/Hour</label>
                        <input type="number" class="form-control" placeholder="Regular pay Rate/Hour"
                            name="payrateperhr" [(ngModel)]="offerData.payrateperhr" #payrateperhr="ngModel"
                            [ngClass]="{ 'is-invalid': payrateperhr.invalid && payrateperhr.errors , 'is-valid': payrateperhr.valid}"
                            required="">
                        <div *ngIf="payrateperhr.invalid && payrateperhr.errors" class="invalid-feedback d-block">
                            <div *ngIf="payrateperhr.errors.required">
                                Pay rate/hour is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-6  ">
                        <label for="">Work timezone</label>
                        <select class="form-control" required="" name="worktimezone"
                            [(ngModel)]="offerData.worktimezone" #worktimezone="ngModel"
                            [ngClass]="{ 'is-invalid': worktimezone.invalid && worktimezone.errors , 'is-valid': worktimezone.valid}"
                            required="">
                            <option value="">Select timezone</option>
                            <option *ngFor="let item of timezoneList" [value]="item.Value">
                                {{item.Text}}</option>
                        </select>
                        <div *ngIf="worktimezone.invalid && worktimezone.errors" class="invalid-feedback d-block">
                            <div *ngIf="worktimezone.errors.required">
                                Work timezone is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">Work shift time</label>
                        <input type="time" class="form-control" placeholder="Work shift time" name="workshifttime"
                            [(ngModel)]="offerData.workshifttime" #workshifttime="ngModel"
                            [ngClass]="{ 'is-invalid': workshifttime.invalid && workshifttime.errors , 'is-valid': workshifttime.valid}"
                            required="">
                        <div *ngIf="workshifttime.invalid && workshifttime.errors" class="invalid-feedback d-block">
                            <div *ngIf="workshifttime.errors.required">
                                Work shift time is required
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Cost center</label>
                            <select class="form-control" name="locationid" [(ngModel)]="offerData.locationid"
                                #locationid="ngModel"
                                [ngClass]="{ 'is-invalid': locationid.invalid && locationid.errors , 'is-valid': locationid.valid}"
                                required="">
                                <option value="">Select cost center</option>
                                <option *ngFor="let type of locationList" [value]="type.id">
                                    {{type.name}}</option>
                            </select>
                            <div *ngIf="locationid.invalid && locationid.errors" class="invalid-feedback d-block">
                                <div *ngIf="locationid.errors.required">
                                    Cost center is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Designation</label>
                            <select class="form-control" name="Designation" [(ngModel)]="offerData.designationid"
                                #designationid="ngModel"
                                [ngClass]="{ 'is-invalid': designationid.invalid && designationid.errors , 'is-valid': designationid.valid}"
                                required="">
                                <option value="">Select Designation</option>
                                <option *ngFor="let type of designationList" [value]="type.Value">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="designationid.invalid && designationid.errors" class="invalid-feedback d-block">
                                <div *ngIf="designationid.errors.required">
                                    Designation is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Status</label>
                            <select class="form-control" name="status" [(ngModel)]="offerData.status" #status="ngModel"
                                [ngClass]="{ 'is-invalid': status.invalid && status.errors , 'is-valid': status.valid}"
                                required="">
                                <option value="">Select Status</option>
                                <option *ngFor="let type of offerStatusList" [value]="type.stringValue">
                                    {{type.Text}}</option>
                            </select>
                            <div *ngIf="status.invalid && status.errors" class="invalid-feedback d-block">
                                <div *ngIf="status.errors.required">
                                    Status is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 col-md-12 col-sm-12">
                        <label for="">Date Accepted</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker261" placeholder="Choose a date" name="date_accepted"
                                [(ngModel)]="offerData.date_accepted">
                            <mat-datepicker-toggle matSuffix [for]="picker261"></mat-datepicker-toggle>
                            <mat-datepicker #picker261></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-xl-12 col-md-12 col-sm-12">
                        <label for="">Rejection Reason</label>
                        <textarea class="form-control" rows="4" name="Rejection reason" name="rejectionreason"
                            [(ngModel)]="offerData.rejection_reason"></textarea>
                    </div>
                    <div class="form-group col-xl-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Salary Components</label>
                            <ng-select placeholder="Select salary components" id="selectedComponent"
                                [(ngModel)]="offerData.selectedComponent" #selectedComponent="ngModel"
                                name="selectedComponent" [clearable]="offerData.selectedComponent"
                                (change)="selectComponent(offerData.selectedComponent)">
                                <ng-option *ngFor="let item of componentList" [value]="item">
                                    {{item.Text}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="custom-datatable col-sm-12  table-responsive2">
                    <table class="input-table">
                        <thead>
                            <tr class="custom-tr">
                                <th class="custom-th text-center">Actions</th>
                                <th class="custom-th text-center">Type#</th>
                                <th class="custom-th text-center">Description</th>
                                <th class="custom-th text-center">Fix Amount(Monthly)</th>
                                <th class="custom-th text-center">Fix Amount(Yearly)</th>
                                <th class="custom-th text-center">Max Amount(Monthly)</th>
                                <th class="custom-th text-center">Max Amount(Yearly)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="custom-tr" *ngFor="let data of selectedList; let i = index;">
                                <td class="custom-td text-center">

                                    <span (click)="deleteRow(i,data)" [class.isdisabled]="lastaction=='view'"
                                        class="fa fa-trash ml-2 cursor-pointer"></span>
                                </td>

                                <td class="custom-td text-left">
                                    <input type="text" class="form-control" name="type-{{i}}" [(ngModel)]="data.type"
                                        readonly>
                                </td>
                                <td class="custom-td text-left"><input type="text" class="form-control"
                                        name="description-{{i}}" [(ngModel)]="data.description">
                                </td>
                                <td class="custom-td text-right"><input type="number" class="form-control"
                                        name="fix_value-{{i}}" [(ngModel)]="data.fix_value"
                                        (focusout)="calculateInDecimal(data)"></td>
                                <td class="custom-td text-right"><input type="number" class="form-control"
                                        name="fix_yearly_value-{{i}}" [(ngModel)]="data.fix_yearly_value" readonly></td>
                                <td class="custom-td text-right"><input type="number" class="form-control"
                                        name="max_value-{{i}}" [(ngModel)]="data.max_value"
                                        (focusout)="calculateInDecimal(data)"></td>

                                <td class="custom-td text-right"><input type="number" class="form-control"
                                        name="max_yearly_value-{{i}}" [(ngModel)]="data.max_yearly_value" readonly></td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn btn-outline-secondary" (click)="closeModal()">Close</button>
                <button type="button" class="btn btn-primary" [disabled]="!f.valid || lastaction=='view'"
                    (click)="saveOffer()">Save</button>
            </div>
        </form>
    </div>
</resize-border>