<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="productModalLabel">Purchase Requests
    </h5>
    <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
      <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
      <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
    </button>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="custom-row">
      <div class="form-row">
        <div class="col-lg-4 col-md-6 form-group"> </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="">From Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="picker22" placeholder="Choose a date"
              [(ngModel)]="purchaseRequest.fromdate" name="fromdate">
            <mat-datepicker-toggle matSuffix [for]="picker22"></mat-datepicker-toggle>
            <mat-datepicker #picker22></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="">To Date</label>
          <mat-form-field>
            <input matInput [matDatepicker]="picker23" placeholder="Choose a date" [(ngModel)]="purchaseRequest.enddate"
              name="todate">
            <mat-datepicker-toggle matSuffix [for]="picker23"></mat-datepicker-toggle>
            <mat-datepicker #picker23></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
        <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
          (click)="getpurchaseData()">Search</button>
      </div>
    </div>
    <div class="category-table custom-datatable">
      <div class="table-responsive">
        <table class="custom-table">
          <thead class="sticky-tab-head">
            <tr class="custom-tr">
              <th class="custom-th text-center">Req#</th>
              <th class="custom-th text-center">Proj/Job Ref#</th>
              <th class="custom-th text-center">Supplier</th>
              <th class="custom-th text-center"> Customer/Site#</th>
              <th class="custom-th text-center"> RequestedBy</th>
              <th class="custom-th text-center"> Notes</th>
              <th class="custom-th text-center"> Status</th>
              <th class="custom-th text-center"> CreateBy</th>
              <th class="custom-th text-center"> CreateDate</th>
              <th class="custom-th text-center"> UpdateBy</th>
              <th class="custom-th text-center"> UpdateDate</th>
            </tr>
          </thead>
          <tbody>
            <ng-container>
              <tr class="custom-tr" *ngFor="let n of purchaseData;let i=index">
                <td class="custom-td"> {{n.req_no}}</td>
                <td class="custom-td" style="min-width: 310px;">{{n.descrorderrefiption}}</td>
                <td class="custom-td">{{n.supplier}}</td>
                <td class="custom-td">{{n.orderref_extra}}</td>
                <td class="custom-td">{{n.requestby}}</td>
                <td class="custom-td ">{{n.ponotes}}</td>
                <td class="custom-td text-center"><span class="badge" title="{{n.status}}">{{n.status}}</span></td>
                <td class="custom-td text-left">{{n.createby}}</td>
                <td class="custom-td text-center text-nowrap">{{n.createdate |
                  date:userdata.displaydatetimeformat}}</td>
                <td class="custom-td text-left">{{n.updateby}}</td>
                <td class="custom-td text-center text-nowrap">{{n.updatedate |
                  date:userdata.displaydatetimeformat}}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
  <ngx-ui-loader></ngx-ui-loader>
</resize-border>