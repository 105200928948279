<div id="main" class="main-content-wraper">
    <div class="main-content-inner">
        <div class="main-header">
            <div class="TopNav">
                <div class="container">
                    <ul>
                        <li><i class="fa fa-phone"></i><a href="tel:3453255469" title="call us">+1(345) 325 5469</a> &
                            <a href="tel:+13456406292" title="call us"> +1(345) 640 6292</a>
                        </li>
                        <li><a href="mailto:contactus@sanmolsoftware.com" title="mail us"><i class="fa fa-envelope-open"
                                    style="margin-right:10px;"></i>contactus@sanmolsoftware.com</a></li>
                    </ul>
                </div>
            </div>
            <header class="header">
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" routerLink="/public/index" title="ezyBooks">
                            <img src="assets/images/applogo.svg" alt="ezyBooks">
                        </a>
                        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                            <span class="" [ngClass]="isCollapsed?'fa fa-bars':'fa fa-times'"></span>
                        </button>
                        <div class="navbar-collapse" [ngbCollapse]="isCollapsed">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item active">
                                    <a class="nav-link" href="javascript: void(0);" title="Home"
                                        (click)="gotoHome()">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="javascript: void(0);" title="About"
                                        (click)="gotoAbout()">About</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="javascript: void(0);" (click)="gotoOverview()"
                                        title="Overview">Overview</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="javascript: void(0);" (click)="gotoFeature()"
                                        title="Features">Features</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="javascript: void(0);" (click)="gotoPricing()"
                                        title="Pricing">Pricing</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="javascript: void(0);" (click)="gotoContact()"
                                        title="Contact">Contact us</a>
                                </li>
                            </ul>
                            <ul class="nav navbar-nav navbar-right">
                                <li><a (click)="gotoPricing()" class="btn btn-outline-primary" title="Sign Up">Sign
                                        Up</a>
                                </li>
                                <li>
                                    <a (click)="gotoLogin()" class="btn btn-primary" title="Sign In">Sign In</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>

        <!-- --------------Main Page body html code start ---------------->
        <div class="wrapper" #home>
            <!--  OfferTime -->
            <div class="container">
                <div class="OfferDetails">
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-md-12">
                            <div class="total_offer">{{homePagePlan.discount}}<span
                                    *ngIf="homePagePlan.discount_type=='percent'">%</span><span
                                    *ngIf="homePagePlan.discount_type=='amount'">$</span></div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="offer_time">Limited time only. Ezybooks for 3 months.* </div>
                        </div>
                        <div class="col-lg-5 text-lg-right text-center col-md-12">
                            <a class="btn btn-primary" title="" (click)="goToCreateAccount(homePagePlan)">Try
                                free for {{30*homePagePlan.duration_in_months}}
                                days</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Banner Section -->
            <div class="banner" id="hero-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 content">
                            <h1>
                                <span>EzyBooks</span> Manage Your <br />
                                Business with our <br />
                                <span>Reliable Software</span>
                            </h1>
                            <p>
                                Feed your growing business with the right tools.
                                Inventory tracking, Purchase Orders, Sales Orders,
                                Invoices & Estimates/Quotes to reduce paperwork using
                                our developed robust EzyBooks with lot more features
                                with the latest technology.
                            </p>
                            <a class="btn btn-primary mt-3 mb-4" title="Try for 30 Days"
                                (click)="goToCreateAccount({})">Try free for 30 Days</a>
                        </div>
                        <div class="col-lg-6">
                            <img src="../../../assets/images/homepage/banner-img.png" class="img-fluid banner_img"
                                alt="banner" width="600" height="340" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- customers-section -->
            <div class="customers-section">
                <div class="container">
                    <div class="customers-content">
                        <owl-carousel-o [options]="imgSlider">
                            <ng-template carouselSlide><img src="assets/images/homepage/customer-logo-1.png"
                                    alt="CostWatch" title="CostWatch" loading="lazy">
                            </ng-template>
                            <ng-template carouselSlide><img src="assets/images/homepage/customer-logo-2.png"
                                    alt="Andro group" title="Andro group" loading="lazy">
                            </ng-template>
                            <ng-template carouselSlide><img src="assets/images/homepage/customer-logo-3.png"
                                    alt="Star-Mar Enterprises" title="Star-Mar Enterprises" loading="lazy">
                            </ng-template>
                            <ng-template carouselSlide><img src="assets/images/homepage/customer-logo-4.png"
                                    alt="Otisair" title="Otisair" loading="lazy">
                            </ng-template>
                            <ng-template carouselSlide><img src="assets/images/homepage/customer-logo-5.png"
                                    alt="Cay Tech Air Conditioning" title="Cay Tech Air Conditioning" loading="lazy">
                            </ng-template>
                            <ng-template carouselSlide><img src="assets/images/homepage/customer-logo-6.png"
                                    alt="Island Supply" title="Island Supply" loading="lazy">
                            </ng-template>
                            <ng-template carouselSlide><img src="assets/images/homepage/customer-logo-7.png"
                                    alt="Sanmol Software" title="Sanmol Software" loading="lazy">
                            </ng-template>
                            <ng-template carouselSlide><img src="assets/images/homepage/customer-logo-8.png"
                                    alt="Spectrum Ltd" title="Spectrum Ltd" loading="lazy">
                            </ng-template>
                            <ng-template carouselSlide><img src="assets/images/homepage/customer-logo-9.png"
                                    alt="Entech Ltd" title="Entech Ltd" loading="lazy">
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
            <!-- Why-Choos-us-section -->
            <section class="Why-Choos-us" #aboutus>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="why-us-img">
                                <img src="assets/images/homepage/choose-us-img.png" alt="Why EzyBooks"
                                    class="img-responsive" loading="lazy">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <h3 class="main-heading">Why choose us?</h3>
                            <p>
                                EzyBooks is perfect solution for any service based companies to handle there operations
                                end to end from customers to projects to billing to get paid. This system push all data
                                to quickbook, viewpoint, counterpoint,tally etc accounting systems smoothly on one click
                                of button or you can schedule data synchronization. This saves cost leak, time and
                                resources. EzyBooks system available in cloud web desktop, mobile apps android and iOS
                                versions as well so this give customers flexibility to use it from anywhere at anytime.
                                Again we have EzyTickets and EzyPOS androis and iOS tablet based applications very
                                useful for technicians or cashiers works in store or field or onsite.
                            </p>
                            <p>
                                EzyBooks application being developed for the management of the business & time-saving
                                plan. It is a single integrated system used by the organization to deal with the
                                totality of its procedures, so as to meet the association's targets and fairly fulfill
                                the partners. All Business units and Departments of the EzyBooks shall organize the
                                planning, tasks, monitoring, checks and continual improvement within the EzyBooks in
                                particular by utilizing the tools and methodology set up for it.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <!-- overview-section -->
            <section class="overview" #overview>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7">
                            <h3 class="main-heading">EzyBooks Overview</h3>
                            <p>
                                EzyBooks is a comprehensive, secured and compatible ERP solution for
                                an organization. This is a web based solution for inventory
                                tracking, purchasing, sales, billing, project management, time
                                management, service ticket management and HR & payroll and many
                                more.
                            </p>
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
                                <li ngbNavItem="top">
                                    <a ngbNavLink>
                                        <i class="eb-generate-Invoices"></i>
                                        <div class="overview-content">
                                            <h6>Generate Invoices direct from Time Records and
                                                project.</h6>
                                            <p>Create and send detailed Invoices quickly.</p>
                                        </div>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <img src="assets/images/homepage/overview-1.png" alt="overview"
                                            class="overview-image" width="555" height="699" loading="lazy">
                                    </ng-template>
                                </li>
                                <li ngbNavItem="middle">
                                    <a ngbNavLink>
                                        <i class="eb-maximiz-equality"></i>
                                        <div class="overview-content">
                                            <h6>Continually maximize quality experiences of Sales
                                            </h6>
                                            <p>
                                                Sales marketing and prospecting to ensure that
                                                business will continue to grow.
                                                Every modern Retail EzyBooks software should provide
                                                reporting, dashboards, and analytics in real time.
                                            </p>
                                        </div>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <img src="assets/images/homepage/overview-2.png" alt="overview"
                                            class="overview-image" width="555" height="699" loading="lazy">
                                    </ng-template>
                                </li>
                                <li ngbNavItem="bottom">
                                    <a ngbNavLink>
                                        <i class="eb-salesfore-casting"></i>
                                        <div class="overview-content">
                                            <h6>To improve your sales forecasting using Quotes</h6>
                                            <p>
                                                by projecting when a customer will place an order.
                                                Information like quality, payment terms, contract
                                                length, and specifications on items or services, are
                                                also included.
                                            </p>
                                        </div>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <img src="assets/images/homepage/overview-3.png" alt="overview"
                                            class="overview-image" width="555" height="699" loading="lazy">
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-5">
                            <div [ngbNavOutlet]="nav" class="ms-4"></div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- features-section -->
            <section class="features" #features>
                <div class="container">
                    <article class="text-center">
                        <h3 class="main-heading">EzyBooks Features</h3>
                        <p>Learn about your options for growing your business and how the right and appropriate software
                            can help.</p>
                    </article>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/leads.png" alt="Leads Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Leads Management</h4>
                                <p>Leads management bridges the gap between marketing and sales. It's a client obtaining
                                    process which distinguishes potential purchasers, instructs them, engages with them,
                                    and when the leads are viewed as qualified, get passed from marketing to sales.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/customer.png" alt="Customer Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Customer Management</h4>
                                <p>Customer Management module helps retailers overcome customer-related challenges, &
                                    achieve a truly integrated, comprehensive perspective of clients. It gives a unique
                                    ability to engage shoppers in a consistent and truly personalized manner across all
                                    sales channels and touch points.</p>
                            </div>
                            <span class="over-back blue"></span>
                        </div>
                    </div>
                    <div class="row-1">
                        <div class="row">
                            <div class="col-sm-5">
                                <img src="assets/images/homepage/purchase.png" alt="Purchase Management"
                                    class="img-fluid f-icon" width="200" height="200" loading="lazy">
                            </div>
                            <div class="col-sm-7">
                                <h4>Purchase Management</h4>
                                <p>The EzyBooks is business process the board programming that enables an association to
                                    utilize an arrangement of coordinated applications to deal with the business &
                                    office capacities identified with innovation, administrations, & HR. A productive &
                                    strong Purchasing module is key for all merchants.</p>
                            </div>
                            <span class="over-back"></span>
                        </div>
                    </div>
                    <div class="text-center text-lg-right">
                        <a href="" title="View features" class="btn btn-primary" routerLink="/public/features">View
                            features <i class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </section>
            <!-- pricingTable-section -->
            <div class="container">
                <section class="pricingTable">
                    <div class="text-center">
                        <h2 class="main-heading" #pricing>Choose plan</h2>
                        <p>
                            We have different type of pricing table to choose with your need with reasonable price. Any
                            one you can choose that you need.
                        </p>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-11">
                            <div class="row">
                                <div class="col-xl-3 col-lg-6 col-md-6" *ngFor="let item of signPlans">
                                    <div class="plan">
                                        <span class="disc">{{item.discount}}<span
                                                *ngIf="item.discount_type=='percent'">%</span><span
                                                *ngIf="item.discount_type=='amount'">$</span></span>
                                        <h2>{{item.name}}</h2>
                                        <div class="price" *ngIf="item.rate_type=='peruser'"><span>${{item.rate |
                                                number:'1.2-2'}}</span></div>
                                        <div *ngIf="item.rate_type!='peruser'"><span *ngIf="item.rate_type=='usage'">AS
                                                PER
                                                USAGE</span><span *ngIf="item.rate_type=='fixedamount'">FIXED</span>
                                        </div>
                                        <ul>
                                            <li *ngFor="let entry of item.ezbPlanDetails">{{entry.description
                                                }}</li>
                                        </ul>
                                        <a *ngIf="!item.contact_us" (click)="goToCreateAccount(item)" title=""
                                            class="btn btn-white mt-3">Try free for
                                            {{30*item.duration_in_months}}
                                            days</a>
                                        <a *ngIf="item.contact_us" (click)="gotoContact(item)" title="Contact US"
                                            class="btn btn-white mt-3">CONTACT US</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
            <!-- Integration with accounting-section -->
            <section class="Integration_section">
                <div class="container">
                    <article class="text-center">
                        <h3 class="main-heading">Integration with accounting & Bookkeeping Solutions</h3>
                        <p>EzyBooks integrates with populer solutions listed here and many more to help you automate
                            your business process across the board. Also customer migrated from booking solutions to
                            EzyBooks.</p>
                    </article>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="QuickBooks">
                                <img src="assets/images/homepage/integrate/qb.svg" width="300" height="170"
                                    alt="QuickBooks" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="View Point">
                                <img src="assets/images/homepage/integrate/vp.svg" width="300" height="170"
                                    alt="View Point" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="CounterPoint">
                                <img src="assets/images/homepage/integrate/cp.svg" width="300" height="170"
                                    alt="CounterPoint" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="Powerhouse">
                                <img src="assets/images/homepage/integrate/ph.svg" width="300" height="170"
                                    alt="Powerhouse" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="Sage">
                                <img src="assets/images/homepage/integrate/sg.svg" width="300" height="170" alt="Sage"
                                    loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="Medworxs">
                                <img src="assets/images/homepage/integrate/mw.png" width="300" height="170"
                                    style="height:170px !important" alt="Medworxs" loading="lazy">
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Integration with Payment-section -->
            <section class="Integration_section" id="paymentIntegration">
                <div class="container">
                    <article class="text-center bg-article">
                        <h3 class="main-heading">Integration with Payment Gateways</h3>
                        <p>We have ezybooks integrated with payment gateways list here that can help you process and
                            collect your receivables online where your customers can Bill Payments and invoices from any
                            device and using payments such as credit card, debit, or amex cards.</p>
                    </article>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="Plug n Pay">
                                <img src="assets/images/homepage/payment/plugnpay.svg" width="300" height="170"
                                    alt="Plug n Pay" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="First Atlantic Commerce">
                                <img src="assets/images/homepage/payment/fac.svg" width="300" height="170"
                                    alt="First Atlantic Commerce" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="Paypal">
                                <img src="assets/images/homepage/payment/paypal.svg" width="300" height="170"
                                    alt="Paypal" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="Stripe">
                                <img src="assets/images/homepage/payment/stripe.svg" width="300" height="170"
                                    alt="Stripe" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="RazorPay">
                                <img src="assets/images/homepage/payment/razorpay.svg" width="300" height="170"
                                    alt="RazorPay" loading="lazy">
                            </a>
                        </div>

                    </div>
                </div>
            </section>
            <!-- Integration with Shopping -section -->
            <section class="Integration_section" id="shoppingcart">
                <div class="container">
                    <article class="text-center">
                        <h3 class="main-heading">Integration with Shopping Cart</h3>
                        <p>We have inbuild ezycart where ezybooks inventory is integrated to own shopping cart where you
                            can deploy on your server to sell inventory online. Ezybooks can integrated with any website
                            you have in the place on request. Also we have ezybooks integrated with online shopping cart
                            frameworks list here and we are working on some shopping cart integrations...stay tune..</p>
                    </article>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="EzyCart">
                                <img src="assets/images/homepage/shoppingcart/ezycart.svg" width="300" height="170"
                                    alt="EzyCart" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="Plug n Pay">
                                <img src="assets/images/homepage/payment/plugnpay.svg" width="300" height="170"
                                    alt="Plug n Pay" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="Woo Commerce">
                                <img src="assets/images/homepage/shoppingcart/woocommerce.svg" width="300" height="170"
                                    alt="Woo Commerce" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="Megento">
                                <img src="assets/images/homepage/shoppingcart/magento.svg" width="300" height="170"
                                    alt="Megento" loading="lazy">
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a href="javascript:void(0);" title="shopify">
                                <img src="assets/images/homepage/shoppingcart/shopify.svg" width="300" height="170"
                                    alt="shopify" loading="lazy">
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Mobile Apps-section -->
            <section class="Mobile_Apps Integration_section">
                <div class="container">
                    <article class="text-center bg-article">
                        <h3 class="main-heading">Download our Mobile Apps</h3>
                        <p>EzyBooks system available in cloud web desktop as mobile apps android and iOS versions as
                            well so this give customers flexibility to use it from anywhere at anytime..</p>
                    </article>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="block">
                                <img src="assets/images/applogo.svg" class="AppIcon" width="220" height="70"
                                    title="EzyBooks" loading="lazy">
                                <div class="clearfix"></div>
                                <h3>EzyBooks</h3>
                                <div class="play-store-app">
                                    <a href="https://play.google.com/store/apps/details?id=com.ezyBooks.app"
                                        class="storeIcon" target="_blank" rel="noopener"
                                        title="Check on Google Play Store">
                                        <img src="assets/images/homepage/googlePlay.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                    <a href="https://apps.apple.com/us/app/ezybooks/id1457175179" class="storeIcon"
                                        target="_blank" rel="noopener" title="Check on Apple Store">
                                        <img src="assets/images/homepage/appstore.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="block">
                                <img src="assets/images/homepage/mobileApps/ezyPOS.svg" class="AppIcon" width="220"
                                    height="70" title="eziPOS" loading="lazy">
                                <div class="clearfix"></div>
                                <h3>EzyPOS</h3>
                                <div class="play-store-app">
                                    <a href="https://play.google.com/store/apps/details?id=com.ezypos.appin"
                                        class="storeIcon" target="_blank" rel="noopener"
                                        title="Check on Google Play Store">
                                        <img src="assets/images/homepage/googlePlay.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                    <a href="https://apps.apple.com/us/app/ezypos/id1446258511" class="storeIcon"
                                        target="_blank" rel="noopener" title="Check on Apple Store">
                                        <img src="assets/images/homepage/appstore.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="block">
                                <img src="assets/images/homepage/mobileApps/ezyTickets.svg" class="AppIcon" width="220"
                                    height="70" title="EzyTickets" loading="lazy">
                                <div class="clearfix"></div>
                                <h3>EzyTickets</h3>
                                <div class="play-store-app">
                                    <a href="https://play.google.com/store/apps/details?id=com.ezyticket.app"
                                        class="storeIcon" target="_blank" rel="noopener"
                                        title="Check on Google Play Store">
                                        <img src="assets/images/homepage/googlePlay.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                    <a href="https://apps.apple.com/us/app/ezytickets/id1493251814" class="storeIcon"
                                        target="_blank" rel="noopener" title="Check on Apple Store">
                                        <img src="assets/images/homepage/appstore.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="block">
                                <img src="assets/images/homepage/mobileApps/ezySales.svg" class="AppIcon" width="220"
                                    height="70" title="EzyTickets" loading="lazy">
                                <div class="clearfix"></div>
                                <h3>EzySales</h3>
                                <div class="play-store-app">
                                    <a href="https://play.google.com/store/apps/details?id=com.ezysales.app&hl=en_IN"
                                        class="storeIcon" target="_blank" rel="noopener"
                                        title="Check on Google Play Store">
                                        <img src="assets/images/homepage/googlePlay.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                    <a href="https://apps.apple.com/us/app/ezysales/id1495610717" class="storeIcon"
                                        target="_blank" rel="noopener" title="Check on Apple Store">
                                        <img src="assets/images/homepage/appstore.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="block">
                                <img src="assets/images/homepage/mobileApps/ezyDelivery.svg" class="AppIcon" width="220"
                                    height="70" title="EzyTickets" loading="lazy">
                                <div class="clearfix"></div>
                                <h3>EzyDelivery</h3>
                                <div class="play-store-app">
                                    <a href="https://play.google.com/store/apps/details?id=com.ezydelivery.app"
                                        class="storeIcon" target="_blank" rel="noopener"
                                        title="Check on Google Play Store">
                                        <img src="assets/images/homepage/googlePlay.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                    <a href="" class="storeIcon" target="_blank" rel="noopener"
                                        title="Check on Apple Store">
                                        <img src="assets/images/homepage/appstore.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="block">
                                <img src="assets/images/homepage/mobileApps/ezyClock.svg" class="AppIcon" width="220"
                                    height="70" title="EzyClock" loading="lazy">
                                <div class="clearfix"></div>
                                <h3>EzyClock</h3>
                                <div class="play-store-app">
                                    <a href="https://play.google.com/store/apps/details?id=com.ezyclock.app"
                                        class="storeIcon" target="_blank" rel="noopener"
                                        title="Check on Google Play Store">
                                        <img src="assets/images/homepage/googlePlay.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                    <a href="https://apps.apple.com/us/app/ezyclock/id1539129488" class="storeIcon"
                                        target="_blank" rel="noopener" title="Check on Apple Store">
                                        <img src="assets/images/homepage/appstore.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="block">
                                <img src="assets/images/homepage/mobileApps/ezyAssets.svg" class="AppIcon" width="220"
                                    height="70" title="EzyAssets" loading="lazy">
                                <div class="clearfix"></div>
                                <h3>EzyAssets</h3>
                                <div class="play-store-app">
                                    <a href="" class="storeIcon" target="_blank" rel="noopener"
                                        title="Check on Google Play Store">
                                        <img src="assets/images/homepage/googlePlay.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                    <a href="" class="storeIcon" target="_blank" rel="noopener"
                                        title="Check on Apple Store">
                                        <img src="assets/images/homepage/appstore.png" width="220" height="70"
                                            alt="EzyBooks" loading="lazy">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="block">
                                <img src="assets/images/homepage/mobileApps/ezyDiary.svg" class="AppIcon" width="220"
                                    height="70" title="EzyDiary" loading="lazy">
                                <div class="clearfix"></div>
                                <h3>EzyDiary</h3>
                                <span>Coming soon</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Testimonials-section -->
            <div class="container">
                <section class="Testimonials">
                    <article class="text-center">
                        <h2 class="main-heading">Testimonials</h2>
                        <p class="text-white">Streamlining different processes and workflows by using user access
                            control EzyBooks prompts efficiency and productivity levels of an organization, and reduces
                            operating costs.</p>
                    </article>
                    <div class="testimonial-section">
                        <div class="testimonial-slider">
                            <owl-carousel-o [options]="testimonialSlider">
                                <ng-template carouselSlide>
                                    <div class="testimonial-single bg-white">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="box"></div>
                                            </div>
                                            <div class="col-md-8 rightBlock">
                                                <h3>Marcus Cumber Island Air</h3>
                                                <p>The custom solution & web application service provided by Sanmol are
                                                    very best with simple, standard ui Design</p>
                                                <div class="testimonial-ratting">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="testimonial-single bg-white">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="box"></div>
                                            </div>
                                            <div class="col-md-8 rightBlock">
                                                <h3>Steve Walsh <a href="javascript:void(0);" target="_blank"
                                                        rel="noopener" title="Andro Group Ltd">Andro Group Ltd</a></h3>
                                                <p>I got the best quality product with strong functionalities, I would
                                                    always recommend sanmol for any web related services</p>
                                                <div class="testimonial-ratting">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>

                    </div>
                </section>
            </div>
            <!-- ContactUs-section -->
            <section class="ContactUs" #contact>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 contactbox">
                            <h2 class="main-heading">Contact with us</h2>
                            <p>Sanmol Softwares is helping thousands of people do handle there business and daily
                                operations with ease. If you have any questions about our products or services, please
                                call one of our phone number below or email Sanmol Software using the contact form
                                below.</p>
                            <p>
                                <a href="tel:3453255469" title="contact us">
                                    <i class="fa fa-phone"></i><span>+1(345) 325 5469</span>
                                </a>
                            </p>
                            <p>
                                <a href="mailto:contactus@sanmolsoftware.com" title="mail us">
                                    <i class="fa fa-envelope"></i><span>contactus@sanmolsoftware.com</span>
                                </a>
                            </p>
                            <p class="d-flex">
                                <i class="fa fa-map-marker" style="flex:none"></i><span>
                                    U15, Breezy Castle, 125 Owen Roberts Dr, PO Box 1221,
                                    Grand Cayman, KY1-1108, Cayman Islands
                                </span>
                            </p>
                            <div class="GoogleMap">
                                <iframe title="Sanmol Cayman Consulting"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.5713676720784!2d-81.36446156834563!3d19.29500826018964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f258650b3b80ea5%3A0x8f1c78e610e5baac!2sSanmol+Software+Consulting+Ltd!5e0!3m2!1sen!2sin!4v1550827909269"
                                    width="500" height="300" style="border:0;" allowfullscreen=""
                                    loading="lazy"></iframe>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 contactbox">
                            <form action="/public/contactus" method="post" class="">
                                <h3>Get in touch</h3>
                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input type="text" class="form-control" name="name" id="name"
                                        placeholder="Enter name" required="required">
                                </div>
                                <div class="form-group">
                                    <label for="email">Email ID</label>
                                    <input type="email" class="form-control" id="email" name="email"
                                        placeholder="Email ID" required="required">
                                </div>
                                <div class="form-group">
                                    <label for="phone">Phone no</label>
                                    <input type="number" name="phone" value="" class="form-control" id="phone"
                                        placeholder="Phone no">
                                </div>
                                <div class="form-group">
                                    <label for="company">Company name</label>
                                    <input type="text" name="company" value="" size="40" class="form-control"
                                        id="company" placeholder="Company name">
                                </div>
                                <div class="form-group">
                                    <label for="message">Message</label>
                                    <textarea name="message" id="message" class="form-control" rows="9" cols="25"
                                        placeholder="Message"></textarea>
                                </div>
                                <div class="text-center">
                                    <button type="submit" class="btn btn-primary" id="btnContactUs">
                                        <i class="loading fa fa-refresh fa-spin" style="display:none;"></i>
                                        <span>Send message</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <!-- footer -->
            <div class="container">
                <footer class="footer-section ptb-60">
                    <div class="row footer-wrap">
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-lg-3 col-md-6">
                                    <div class="footer-single-col">
                                        <h6>Company</h6>
                                        <ul class="footer-link-list">
                                            <li>
                                                <a href="https://sanmolsoftware.com/about-us" title="About us"
                                                    target="_blank" rel="noopener">About us</a>
                                            </li>
                                            <li>
                                                <a href="https://sanmolsoftware.com/manuals/ezybooks" title="User Guide"
                                                    target="_blank" rel="noopener">User guide</a>
                                            </li>
                                            <li>
                                                <a href="https://sanmolsoftware.com/privacy-policy"
                                                    title="Privacy Policy" target="_blank" rel="noopener">Privacy
                                                    policy</a>
                                            </li>
                                            <li>
                                                <a href="" title="Contact Us">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="footer-single-col">
                                        <h6>Features</h6>
                                        <ul class="footer-link-list">
                                            <li>
                                                <a href="" title="Accounts Reports &amp; Custom Reports">Accounts
                                                    Reports &amp; Custom Reports </a>
                                            </li>
                                            <li>
                                                <a href="" title="Payments Gateway">Payments Gateway</a>
                                            </li>
                                            <li>
                                                <a href="" title="Shopping Carts">Shopping Carts</a>
                                            </li>
                                            <li>
                                                <a href="" title="Payroll">Payroll</a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="footer-single-col">
                                        <h6>Explore</h6>
                                        <ul class="footer-link-list">
                                            <li>
                                                <a href="" title="Pricing">Pricing</a>
                                            </li>
                                            <li>
                                                <a href="" title="Demo" target="_blank">Free trials and demo</a>
                                            </li>
                                            <li>
                                                <a href="" title="Features">Features</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="footer-single-col">
                                        <h6>Learning</h6>
                                        <ul class="footer-link-list">
                                            <li>
                                                <a href="https://sanmolsoftware.com/custom-solutions/ezybooks-project-management-software"
                                                    title="Links" target="_blank" rel="noopener">Resources</a>
                                            </li>
                                            <!-- <li>
                                    <a href="https://eb.sanmolapps.com/help" title="Links" target="_blank" rel="noopener">API documentation</a>
                                    </li> -->
                                            <li>
                                                <a href="https://sanmolsoftware.com/faq" title="FAQ" target="_blank"
                                                    rel="noopener">FAQ</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-3">
                            <div class="footer-single-col Subscribe">
                                <h6>Subscribe our Newsletter</h6>
                                <span>Subscribe our newsletter</span>
                                <div class="Subscribe-input">
                                    <input type="text" class="form-control" placeholder="Enter the Email ID" />
                                    <button class="btn btn-primary">Subscribe</button>
                                </div>
                                <div class="socialIcons">
                                    <div>
                                        <a href="https://www.facebook.com/sanmolconsultancy" title="Facebook"
                                            aria-label="Facebook" rel="noopener" target="_blank">
                                            <img src="assets/images/homepage/facebook.png"
                                                onerror="this.onerror=null; this.src='../Images/facebook.png'"
                                                class="img-fluid" alt="facebook" height="50" width="50" />
                                        </a>
                                        <a href="https://twitter.com/Sanmolsoftware" title="Twitter"
                                            aria-label="Twitter" rel="noopener" target="_blank">
                                            <img src="assets/images/homepage/twitter.png"
                                                onerror="this.onerror=null; this.src='../Images/facebook.png'"
                                                class="img-fluid" alt="facebook" height="50" width="50" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/sanmol-software-solutions"
                                            title="LinkedIn" aria-label="LinkedIn" rel="noopener" target="_blank">
                                            <img src="assets/images/homepage/instagram.png"
                                                onerror="this.onerror=null; this.src='../Images/facebook.png'"
                                                class="img-fluid" alt="facebook" height="50" width="50" />
                                        </a>
                                        <a href="https://www.facebook.com/sanmolconsultancy" title="Facebook"
                                            aria-label="Facebook" rel="noopener" target="_blank">
                                            <img src="assets/images/homepage/youtube.png"
                                                onerror="this.onerror=null; this.src='../Images/facebook.png'"
                                                class="img-fluid" alt="facebook" height="50" width="50" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="clearfix"></div>
                                <div class="col-sm-9">
                                    <p class="text-center">Powered by:</p>
                                </div>
                                <div class="col-sm-3">
                                    <span class="secure-text">Feel Secure Feel Safe as we are Secured with:</span><br />
                                </div>
                                <div class="col-sm-9 secureCard">
                                    <img src="assets/images/homepage/visa.svg" alt="visa" loading="lazy"
                                        class="col-sm-3">
                                    <img src="assets/images/homepage/master.svg" alt="master" loading="lazy"
                                        class="col-sm-3">
                                    <img src="assets/images/homepage/FAC.svg" alt="FAC" loading="lazy" class="col-sm-3">
                                    <img src="assets/images/homepage/PNP.svg" alt="PNP" loading="lazy" class="col-sm-3">
                                </div>
                                <div class="col-sm-3 secureCard">
                                    <span>Feel Secure Feel Safe as we are Secured with:</span><br />
                                    <img src="assets/images/homepage/SSL.svg" alt="SSL" loading="lazy">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="develop-by">
                        <a href="" target="_blank"> Developed by <span>Sanmol Software </span></a>
                    </div>
                </footer>
            </div>
        </div>
    </div>
    <div class="mainChat" [ngClass]="chaticon ? 'open' : ''">
        <div class="chatHeader" (click)="toggleChat()">
            <i class="eb-supportchat"></i>
            <h3>Chat Support</h3>
            <i class="closeChat" [ngClass]="chaticon ? 'fa fa-times' : 'fa fa-chevron-down'"></i>
        </div>
        <div class="chatBody">
            <form name="basicChatFrom" #f="ngForm" class="formUser" *ngIf="formUser">
                <div class="form-group">
                    <input type="text" name="firstname" #firstname="ngModel" class="form-control"
                        placeholder="First Name" [(ngModel)]="startchatform.firstname"
                        [ngClass]="{ 'is-invalid': firstname.invalid && firstname.errors , 'is-valid': firstname.valid}"
                        required="" />
                    <div *ngIf="firstname.invalid && firstname.errors" class="invalid-feedback d-block">
                        <div *ngIf="firstname.errors.required">
                            First name is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" name="lastname" #lastname="ngModel" class="form-control" placeholder="Last Name"
                        [(ngModel)]="startchatform.lastname"
                        [ngClass]="{ 'is-invalid': lastname.invalid && lastname.errors , 'is-valid': lastname.valid}"
                        required="" />
                    <div *ngIf="lastname.invalid && lastname.errors" class="invalid-feedback d-block">
                        <div *ngIf="lastname.errors.required">
                            Last name is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" name="email" #email="ngModel" class="form-control" placeholder="Email"
                        [(ngModel)]="startchatform.email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        [ngClass]="{ 'is-invalid': email.invalid && email?.errors , 'is-valid': email.valid}"
                        required="" />
                    <div *ngIf="email.invalid && email.errors" class="invalid-feedback d-block">
                        <div *ngIf="email.errors.required">Email is required</div>
                        <div *ngIf="email.errors?.pattern">Valid Email is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" name="phone" #phone="ngModel" class="form-control" placeholder="Phone Number"
                        [(ngModel)]="startchatform.phone"
                        [ngClass]="{ 'is-invalid': phone.invalid && phone?.errors , 'is-valid': phone.valid}"
                        required="" />
                    <div *ngIf="phone.invalid && phone.errors" class="invalid-feedback d-block">
                        <div *ngIf="phone.errors.required">Phone is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <select class="form-control" class="form-control" name="department" #department="ngModel"
                        (change)="changeDept($event)" [(ngModel)]="startchatform.department" required="">
                        <option value="">Select Department--</option>
                        <option value="Accounting">Accounting</option>
                        <option value="Tracking">Tracking</option>
                    </select>
                    <div *ngIf="department.invalid && department.errors" class="invalid-feedback d-block">
                        <div *ngIf="department.errors.required">
                            Please select Department
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-primary text-white" [disabled]="!f.valid" id="btnStartChat"
                        (click)="startSupport()">
                        Next
                    </button>
                </div>
            </form>
            <!-- <div class="verifyOTP" *ngIf="isverifyOTP">
                <div class="w-100">
                    <div class="form-group">
                        <input type="number" class="form-control" placeholder="Enter OTP" id="otp">
                    </div>
                    <div class="form-group text-center">
                        <button type="button" class="btn btn-primary text-white" id="" (click)="gotoConversation()">
                            Verify OTP
                        </button>
                    </div>
                </div>
            </div> -->
            <div class="conversation" *ngIf="chatstart">
                <div class="conList">
                    <div class="personname">Welcome {{joinedUser.DisplayName}}</div>
                    <span *ngFor="let message of messageList">
                        <div class="chat-bubble receiveMsg" *ngIf="!message.isMine">
                            <div class="msg receive">
                                {{message.Content}}
                            </div>
                            <span>{{message.FromName}} | {{joinedUser.SentTimeStamp}}</span>
                        </div>
                        <div class="chat-bubble" *ngIf="message.isMine">
                            <div class="msg send">
                                {{message.Content}}
                            </div>
                            <span>{{joinedUser.DisplayName}} | {{message.SentTimeStamp}}</span>
                        </div>
                    </span>
                </div>
                <div class="textArea">
                    <form name="chatMsg" #f="ngForm" (ngSubmit)="startChat()" class="inpubtBox">
                        <input type="text" name="msg" #msg="" class="form-control" placeholder="Type......"
                            [ngClass]="{ 'is-invalid': submitted && f['msg']?.errors , 'is-valid': submitted && f['msg']?.valid}"
                            [(ngModel)]="chatMsg.msg" required />
                        <div *ngIf="submitted && f['msg']?.errors" class="invalid-feedback">
                            <div *ngIf="f['msg']?.errors['required']">Message is required</div>
                        </div>
                        <button type="submit" class="btn btn-primary">Send</button>
                    </form>
                </div>

            </div>


        </div>

    </div>
</div>