<resize-border  [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Add Contact</h5>
    <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
      <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
      <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
    </button>
    <button type="button" class="close" aria-label="Close" (click)="closeModal('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="needs-validation" name="form" #f="ngForm">
      <fieldset>
        <div class="form row">
          <div class="form-group col-lg-4 col-sm-12">
            <label for="name" class="required">Name </label>
            <div class="input-group">
              <input type="text" name="name" id="name" class="form-control" [(ngModel)]="contact.name" #name="ngModel"
                placeholder="Name" [ngClass]="{ 'is-invalid': name.invalid && name.errors , 'is-valid': name.valid}"
                required="">
              <div class="input-group-append">
                <span class="input-group-text btn-primary cursor-pointer" data-toggle="modal" data-original-title="test"
                  data-target="#exampleModal" (click)="openContact()"><i class="fa fa-search"></i></span>
              </div>
            </div>
            <div *ngIf="name.invalid && name.errors" class="invalid-feedback d-block">
              <div *ngIf="name.errors.required">
                Name is required
              </div>
            </div>
          </div>
          <div class="form-group col-lg-4 col-sm-12">
            <label for="email" class="required">Email </label>
            <input class="form-control" id="email" type="email" required="" [(ngModel)]="contact.email" #email="ngModel"
              name="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              [ngClass]="{ 'is-invalid': email.invalid && email?.errors , 'is-valid': email.valid}" />
            <div *ngIf="email.touched && email.errors" class="invalid-feedback d-block">
              <div *ngIf="email.errors.required">Email is required</div>
              <div *ngIf="email.errors?.pattern">Valid Email is required</div>
            </div>
          </div>
          <div class="form-group col-lg-4 col-sm-12">
            <label for="phone" class="required">Phone </label>
            <input type="number" name="phone" id="phone" class="form-control" [(ngModel)]="contact.phone"
              #phone="ngModel" placeholder="Phone" pattern="[- +()0-9]+" minlength="8"
              [ngClass]="{ 'is-invalid': phone.invalid && phone.errors , 'is-valid': phone.valid}" required="">
            <div *ngIf="phone.invalid && phone.errors" class="invalid-feedback d-block">
              <div *ngIf="phone.errors.required">
                Phone is required
              </div>
              <div *ngIf="phone.errors.minlength">
                Must have 8 digits
              </div>
              <div *ngIf="phone.errors.pattern">
                Please insert a valid phone number like "+1345232323"
              </div>
            </div>
          </div>
          <div class="form-group col-md-4 col-sm-12">
            <label for="">Primary </label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="">
                <input class="radio_animated" [value]="true" [(ngModel)]="contact.isprimary"
                  id="" type="radio" name="isprimary" #isprimary="ngModel">
                Yes
              </label>
              <label class="d-block" for="">
                <input class="radio_animated" [value]="false" [(ngModel)]="contact.isprimary"
                  id="" type="radio" name="isprimary" #isprimary="ngModel">
                No
              </label>
            </div>
          </div>
          <div class="form-group col-md-4 col-sm-12">
            <label for="">SOA(Signature of Authority)</label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="">
                <input class="radio_animated" [value]="true" [(ngModel)]="contact.soa" id=""
                  type="radio" name="soa" #soa="ngModel">
                Yes
              </label>
              <label class="d-block" for="">
                <input class="radio_animated" [value]="false" [(ngModel)]="contact.soa" id=""
                  type="radio" name="soa" #soa="ngModel">
                No
              </label>
            </div>
          </div>
          <div class="form-group col-md-4 col-sm-12">
            <label for="">Notify Me&nbsp;<i class="fa fa-info-circle InfoBlock" container="body"
                ngbTooltip="Followup Emails, Updates etc"></i></label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="">
                <input class="radio_animated" [value]="true" [(ngModel)]="contact.notifyme"
                  id="" type="radio" name="notifyme" #notifyme="ngModel">
                Yes
              </label>
              <label class="d-block" for="">
                <input class="radio_animated" [value]="false" [(ngModel)]="contact.notifyme"
                  id="" type="radio" name="notifyme" #notifyme="ngModel">
                No
              </label>
            </div>
          </div>
          <div class="form-group col-md-4 col-sm-12">
            <label for="">Technician </label>
            <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label class="d-block" for="">
                <input class="radio_animated" [value]="true" [(ngModel)]="contact.istech"
                  id="" type="radio" name="istech" #istech="ngModel">
                Yes
              </label>
              <label class="d-block" for="">
                <input class="radio_animated" [value]="false" [(ngModel)]="contact.istech"
                  id="" type="radio" name="istech" #istech="ngModel">
                No
              </label>
            </div>
          </div>
          <div class="form-group col-lg-4 col-sm-12">
            <label for="loyalitypoints">Loyalty Points</label>
            <input type="number" name="loyalitypoints" id="loyalitypoints" class="form-control"
              [(ngModel)]="contact.loyalitypoints" #loyalitypoints="ngModel" placeholder="Loyalty Points">
          </div>
          <div class="form-group col-sm-12">
            <label for="notes">Notes</label>
            <textarea class="form-control" rows="5" debounce="0" name="notes" id="notes"
              #notes="ngModel" [(ngModel)]="contact.notes"></textarea>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    <button type="button" class="btn btn-primary" (click)="addContact()" [disabled]="!f.valid">Save</button>
  </div>
</div>
</resize-border>