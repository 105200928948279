import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServices } from 'src/app/shared/service/app.services';
import { environment } from 'src/environments/environment';
import { settingServices } from '../../settings/setting.service';

@Component({
	selector: 'app-chooseindustry',
	templateUrl: './chooseindustry.component.html',
	styleUrls: ['./chooseindustry.component.scss']
})

export class ChooseIndustryComponent implements OnInit {
	public isCollapsed = true;
	industry: any;

	id: any;
	otp: any;

	listofIndustry = [];

	myindustry: NgForm;
	IndustryForm: any = {
		"id": null,
		"planinterested": null,
		"company_name": null,
		"phone": null,
		"email": null,
		"country": "",
		"signupServiceData": null,
		"point_of_contact": null,
		"address": null,
		"finyearend": null,
		"finyearstart": null,
		"industry": "",
	}
	submitted = false;
	countryList: any;
	otpdetails: any;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private service: AppServices,
		private settingService: settingServices,
		private datepipe: DatePipe) {
		this.otpdetails = JSON.parse(atob(this.route.snapshot.queryParamMap.get('otpdetails')));
		this.IndustryForm.id = this.otpdetails.id;
		this.IndustryForm.email = this.otpdetails.email;
		this.IndustryForm.otp = this.otpdetails.otp;
		this.IndustryForm.companyid = this.otpdetails.companyid;
		this.IndustryForm.planinterested = this.otpdetails.planinterested;
		this.settingService.getCountryList().subscribe(data => {
			this.countryList = data;
			environment.production ? '' : console.log('Country List data ', this.countryList);
		}, err => {
			environment.production ? '' : console.log('Error : While loading Country List data ', err);
		});
		this.service.getTradeTypes().subscribe((res: any) => {
			if (res != null) {
				this.listofIndustry = res;
				environment.production ? '' : console.log('Industry List :', JSON.stringify(this.listofIndustry));
			}
		}, err => {
			environment.production ? '' : console.log('Industry List Error', err);
		});
	}

	ngOnInit(): void {
	}

	SubmitIndustry(): void {
		this.IndustryForm.finyearstart = this.datepipe.transform(new Date(this.IndustryForm.finyearstart), 'yyy-MM-dd');
		this.IndustryForm.finyearend = this.datepipe.transform(new Date(this.IndustryForm.finyearend), 'yyy-MM-dd');
		this.router.navigate(['/public/systemmigration'], { queryParams: { companydetails: btoa(JSON.stringify(this.IndustryForm)), otpdetails: btoa(JSON.stringify(this.otpdetails)) }, replaceUrl: true });
	}

	back() {
		this.router.navigate(['/public/createaccount'], { queryParams: { otpdetails: btoa(JSON.stringify(this.otpdetails)) }, replaceUrl: true });
	}

	//#region Navigation Link Redirection
	gotoAbout() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'aboutus' } });
	}
	gotoHome() {
		this.router.navigate(['/public/index']);
	}
	gotoOverview() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'overview' } });
	}
	gotoFeature() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'feature' } });
	}
	gotoPricing() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'pricing' } });
	}
	gotoContact() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'contact' } });
	}
	//#endregion Navigation Link Redirection
}
