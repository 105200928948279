<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="exampleModalLabel">Contacts</h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form class="needs-validation" name="form" #f="ngForm">
                <div class="form row">
                    <div class="form-group col-md-6 col-sm-12">
                        <label for="">Name</label>
                        <input type="text" class="form-control" name="name" [(ngModel)]="contact.name" #name="ngModel"
                            [ngClass]="{ 'is-invalid': name.invalid && name.errors , 'is-valid': name.valid}" required>
                        <div *ngIf="name.invalid && name.errors" class="invalid-feedback d-block">
                            <div *ngIf="name.errors.required">
                                Name is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <label for="">Email</label>
                        <input type="text" class="form-control" name="email" [(ngModel)]="contact.email"
                            [(ngModel)]="contact.email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            #email="ngModel"
                            [ngClass]="{ 'is-invalid': email.invalid && email?.errors , 'is-valid': email.valid}"
                            required>
                        <div *ngIf="email.invalid && email.errors" class="invalid-feedback d-block">
                            <div *ngIf="email.errors.required">Email is required</div>
                            <div *ngIf="email.errors?.pattern">Valid Email is required</div>
                        </div>
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <label for="">Phone</label>
                        <input type="text" name="phone" id="phone" class="form-control" [(ngModel)]="contact.phone"
                            #phone="ngModel">
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <label for="">Skype</label>
                        <input type="text" class="form-control" name="skype" [(ngModel)]="contact.skype">
                    </div>
                    <div class="form-group col-md-12 col-sm-12">
                        <label for="">Designation</label>
                        <input type="text" class="form-control" name="designation" [(ngModel)]="contact.designation">
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <label for="">Active</label>
                        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label class="d-block" for="active1">
                                <input class="radio_animated" [value]="true" id="active1" type="radio" name="active"
                                    [(ngModel)]="contact.active">
                                Yes
                            </label>
                            <label class="d-block" for="active2">
                                <input class="radio_animated" [value]="false" id="active2" type="radio" name="active"
                                    [(ngModel)]="contact.active">
                                No
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                        <label for="">Notify Me(Follow-up Emails, updates etc)</label>
                        <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                            <label class="d-block" for="notifyme">
                                <input class="radio_animated" [value]="true" id="notifyme" type="radio" name="notifyme"
                                    [(ngModel)]="contact.notifyme">
                                Yes
                            </label>
                            <label class="d-block" for="notifyme1">
                                <input class="radio_animated" [value]="false" id="notifyme1" type="radio"
                                    name="notifyme" [(ngModel)]="contact.notifyme">
                                No
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <label for="">Notes</label>
                        <textarea name="notes" class="form-control" [(ngModel)]="contact.notes" cols=""
                            rows="2"></textarea>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
            <button type="button" class="btn btn-primary" (click)="saveContact()" [disabled]="!f.valid">Save</button>
        </div>
    </div>
</resize-border>