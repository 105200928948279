<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="productModalLabel">Quotes/Estimates
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="custom-row">
                <div class="form-row">
                    <div class="col-lg-4 col-md-6 form-group">
                        <label for="">What are you looking for?</label>
                        <input type="text" class="form-control" name="looking" placeholder="Search by quote#, customer"
                            [(ngModel)]="qoute.searchtxt">
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <label for="">From Date</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker1" placeholder="Choose a date"
                                [(ngModel)]="qoute.fromdate" name="fromdate">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <label for="">To Date</label>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker2" placeholder="Choose a date"
                                [(ngModel)]="qoute.enddate" name="enddate">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-md-6 form-group">
                        <label for="">Status</label>
                        <ng-multiselect-dropdown [placeholder]="'Status'" [settings]="dropdownSettings"
                            [data]="statusList" name="status" [(ngModel)]="selectedQuotestatus"
                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                            (onDeSelect)="onItemDeSelect($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
                    <button type="button" container="body" ngbTooltip="Search" (click)="getEstimateList()"
                        class="btn btn-primary right-radius">Search</button>
                </div>
            </div>
            <div class="category-table custom-datatable">
                <div class="row">
                    <div class="table-responsive2 col-lg-12 form-group">
                        <table class="custom-table">
                            <tr class="custom-tr">
                                <th class="custom-th text-center">Action</th>
                                <th class="custom-th text-center">Qte/Est#</th>
                                <th class="custom-th text-center">Customer Name</th>
                                <th class="custom-th text-center">Project Ref#</th>
                                <th class="custom-th text-center">Currency</th>
                                <th class="custom-th text-center">QuoteTotal</th>
                                <th class="custom-th text-center">Date</th>
                                <th class="custom-th text-center">ExpiryDate</th>
                                <th class="custom-th text-center">Status</th>
                                <th class="custom-th text-center">TPSync</th>
                                <th class="custom-th text-center">CreateBy</th>
                                <th class="custom-th text-center">CreateDate</th>
                                <th class="custom-th text-center">UpdateBy</th>
                                <th class="custom-th text-center">UpdateDate</th>
                            </tr>
                            <ng-container *ngFor="let item of estimateList">
                                <tr class="custom-tr">
                                    <td class="custom-td text-center">
                                        <a type="button" title="Convert to sales order"
                                            (click)="quote2SalesOrder(item.id)"><i
                                                class="fa fa-cart-arrow-down table-icon"></i></a>
                                        &nbsp;
                                        &nbsp;
                                        <a type="button" title="Print" (click)="printQuote(item.id)"><i
                                                class="fa fa-print table-icon"></i></a>
                                    </td>
                                    <td class="custom-td text-center">{{item.estno}}</td>
                                    <td class="custom-td text-left">{{item.customer}}</td>
                                    <td class="custom-td text-left">{{item.projref}}</td>
                                    <td class="custom-td text-center">{{item.currency}}</td>
                                    <td class="custom-td text-right">{{item.amt}}</td>
                                    <td class="custom-td text-center text-nowrap">{{item.estimatedate}}</td>
                                    <td class="custom-td text-center text-nowrap">{{item.expirydate}}</td>
                                    <td class="custom-td text-center"><span class="badge"
                                            title="{{item.status}}">{{item.status}}</span> </td>
                                    <td class="custom-td text-center">{{item.tpsync}}</td>
                                    <td class="custom-td text-left">{{item.createby}}</td>
                                    <td class="custom-td text-center text-nowrap">{{item.createdate |
                                        date:userdata.displaydatetimeformat}}
                                    <td class="custom-td text-left">{{item.updateby}}</td>
                                    <td class="custom-td text-center text-nowrap">{{item.updatedate |
                                        date:userdata.displaydatetimeformat}}
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
        </div>
    </div>
</resize-border>