<resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="productModalLabel">Serials
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="custom-row">
      <div class="form-row ">
        <div class="col-lg-4 col-md-12 form-group">
          <input type="text" class="form-control" placeholder="Search by serial#" name="serialno"
            [(ngModel)]="filter.serialno">
        </div>
        <div class="col-lg-4 col-md-12 form-group">
          <input type="text" class="form-control" placeholder="Search by bincode" name="bincode"
            [(ngModel)]="filter.bincode">
        </div>
        <div class="col-lg-3 col-md-12 form-group">
          <select class="form-control high-25" id="status" name="status" [(ngModel)]="filter.status">
            <option value="">Select status</option>
            <option *ngFor="let item of serialStatus" [value]="item.stringValue">
              {{item.Text}}</option>
          </select>
        </div>
        <div class="col-lg-1 col-md-12 form-group">
          <button type="button" container="body" ngbTooltip="Search" class="btn btn-primary right-radius"
            (click)="getProductSerials()"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </div>
    <div class="table-responsive2">
      <table class="custom-table">
        <thead>
          <tr class="custom-tr">
            <!-- <th class="custom-th text-center">Action</th> -->
            <th class="custom-th text-center"(click)="onSortClick($event,'serial')">Serial<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'binlocation')">BIN<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'status')">Status<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'lastupdated')">Last Updated<i class="Sorting fa fa-chevron-up"></i></th>
          </tr>
        </thead>
        <tbody>
          <!-- <ng-container *ngFor="let item of serialList"> -->
          <tr class="custom-tr" *ngFor="let item of filteredItems">
            <!-- <td class="custom-td">
            <div class="modalActionBtn">
              <i class="fa fa-eye"></i>
              <i class="fa fa-trash"></i>
            </div>
          </td> -->
            <td class="custom-td text-left">{{item.serial}} </td>
            <td class="custom-td text-left">{{item.binlocation}} </td>
            <td class="custom-td text-left">{{item.status}}</td>
            <td class="custom-td text-left">{{item.lastupdated | date:userdata.displaydatetimeformat}}</td>
          </tr>
          <!-- </ng-container> -->

        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
  </div>
</resize-border>