import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InventoryServices } from 'src/app/components/inventory/inventory.service';
import { reportServices } from 'src/app/components/reports/reports.service';
import { settingServices } from 'src/app/components/settings/setting.service';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AddProductComponent } from '../add-product.component';

@Component({
	selector: 'app-compatibility',
	templateUrl: './compatibility.component.html',
	styleUrls: ['./compatibility.component.scss']
})
export class CompatibilityComponent implements OnInit {
	public closeResult: string;
	public categories = []
	product: any;
	detailsList: any = [];
	userdata: any;
	appname = environment.APP_NAME;
	detailsData: any = {
		"id": null,
		"prodid": '',
		"field_name": "",
		"field_value": "",
		"companyid": '',
		"createby": "",
		"createdate": "",
		"updateby": "",
		"updatedate": "",
	}
	isView: boolean = false;
	response: any;
	lastaction: any;
	fieldsetDisabled = false;
	searchtxt: any = '';
	deviceList: any = [];
	filter: any = {
		name: '',
		ilc: '',
		type: '',
		active: '',
	}
	companyDevices: any = [];
	deviceTypes: any = [];
	maincheckbox: any = false;
	isSaved = false;
	constructor(private modalService: NgbModal,
		private activeModal: NgbActiveModal,
		private datepipe: DatePipe,
		private toastr: NotificationService,
		private settingService: settingServices,
		private route: ActivatedRoute,
		private reportService: reportServices,
		private maincomponent: AddProductComponent,
		private commonService: CommonServices,
		private loader: NgxUiLoaderService,
		private service: InventoryServices) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.product = maincomponent.product;
		this.lastaction = this.route.snapshot.queryParamMap.get('action');
		if (this.lastaction == 'view') {
			this.fieldsetDisabled = true;
		} else if (this.lastaction == 'edit') {
			this.fieldsetDisabled = false;
		}
		this.getProductCompatibility();

		this.commonService.getDeviceTypeList().subscribe(res => {
			this.deviceTypes = res;
			this.loader.stop();
		}, err => {
			this.loader.stop();
		})
	}
	ngOnInit(): void {

	}

	getProductCompatibility() {
		this.loader.start();
		this.reportService.getItemCompatibility(this.product.id, this.searchtxt).subscribe(res => {
			this.loader.stop();
			this.deviceList = res;
		}, err => {
			this.loader.stop();
		});
	}

	getCompanyDevices() {
		this.loader.start();
		this.settingService.getCompanyDevice(this.filter.name, this.filter.ilc, this.filter.type, this.filter.active).subscribe(res => {
			this.loader.stop();
			this.companyDevices = res;
			this.companyDevices.forEach(device => {
				device.isactive = device.active ? 'Yes' : 'No';
			})
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading customer devices data', err);
		});
	}

	open(content) {
		this.filter = {
			name: '',
			ilc: '',
			type: '',
			active: '',
		}
		this.companyDevices = [];
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			if (this.isSaved) {
				this.getProductCompatibility();
			}
		}, (reason) => {
			//this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	//#region Sorting Table Header
	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event,column) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr(column);
	}

	sortArr(colName: any) {
		this.deviceList.sort((a, b) => {
		  let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
		  let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];
	  
	  if (!valueA && !valueB) {
		return 0; 
	  }
	  if (!valueA) {
		return -this.sortDir; 
	  }
	  if (!valueB) {
		return this.sortDir; 
	  }
	  
	  if (valueA > valueB) {
		return this.sortDir;
	  }
	  if (valueA < valueB) {
		return -this.sortDir;
	  }
	  return 0;
	  });
	  }
	  
	  //end
	 
	//#endregion Sorting Table Header

	closeModal() {
		this.modalService.dismissAll();
		if (this.isSaved) {
			this.getProductCompatibility();
		}
	}
	postDevice(device) {
		if (!device.checked) {
			return;
		}
		var postOb: any = {
			"id": null,
			"prodid": this.product.id,
			"ilc": this.product.ilc,
			"description": this.product.description,
			"createby": null,
			"createdate": null,
			"deviceid": null
		}
		this.detailsData.companyid = this.userdata.companyid;
		this.detailsData.prodid = this.product.id;
		if (this.detailsData.id) {
			this.detailsData.createby = this.detailsData.createby;
			this.detailsData.createdate = this.datepipe.transform(this.detailsData.createdate, this.userdata.postdatetimeformat);
			this.detailsData.updateby = this.userdata.email;
			this.detailsData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		}
		else {
			this.detailsData.createby = this.userdata.email;
			this.detailsData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		}
		postOb.companyid = this.userdata.companyid;
		postOb.createby = this.userdata.email;
		postOb.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		postOb.deviceid = device.id
		this.loader.start();
		this.commonService.postDeviceMaterial(postOb).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.isSaved = true;
			}
		}, err => {
			this.loader.stop();
			this.toastr.Error('Error occurred while post device material');
		})

	}

	delete(id) {
		Swal.fire({
			title: 'Are you sure want to delete?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.commonService.deleteDeviceMaterial(id).subscribe((res: any) => {
					this.loader.stop();
					if (res != null) {
						if (res.status_code == "1") {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
						} else {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
							return;
						}
						this.isSaved = true;
						this.getProductCompatibility();
					}
				}, err => {
					this.loader.stop();
					this.toastr.Error('Error occurred while delete device inventory');
				})
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}
}
