import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { DatePipe } from '@angular/common';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class accountServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    userprofile: any;

    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    //account data
    getChartOfAccountData(searchtxt, account_type, status) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_COAIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&account_type=' + account_type + '&status=' + status)
    }

    getAccountType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAccountTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAccountGroups(acctype) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAccountGroups?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&acctype=' + acctype)
    }
    getAccountDetailTypes(acctype) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAccountDetailTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&acctype=' + acctype)
    }

    getSubAccount(acctype) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSubAccounts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&acctype=' + acctype)
    }

    getAccountCode(acctype, dtype) {
        return this.http.get(this.baseUrl + 'api/Comman/GetAccountCode?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + acctype + '&dtype=' + dtype)
    }
    getCOADetails(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_COAByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCoa(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Accounts/Postt_COA', data)
    }

    getfinancialstatements(fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_FinancialStatements?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fdate + '&todate=' + tdate)
    }

    getJournals(status, jno, journaltype, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Journals/Get_JournalIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&status=' + status + '&jno=' + jno + '&journaltype=' + journaltype + '&fromdate=' + fromdate + '&todate=' + todate)
    }
    getJournalsById(id, type) {
        return this.http.get(this.baseUrl + 'api/Journals/Get_JournalByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type)
    }
    deleteJournalsById(id) {
        return this.http.delete(this.baseUrl + 'api/Journals/Delete_Journal?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getBatchTrans(fromdate, todate, batchno) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_BatchIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&batchno=' + batchno)
    }

    getBatchById(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_BatchByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getLoanDocuments(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_LoanDocuments?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postBatch(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Accounts/Post_Batch', data)
    }
    undoBatch(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Undo_Batch?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteBatchEntry(id) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_BatchEntry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    undoReceivedPayment(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Undo_PaymentsReceived?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    undoPaidPayment(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Undo_PaymentsPaid?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    approveBatch(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Approve_Batch?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postBatchTransactions(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Accounts/Batch_Transactions', data)
    }

    getReviewTrans(fromdate, todate, txtype, narr) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_PendingJournals?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&txtype=' + txtype + '&narr=' + narr)
    }

    postJournal(data) {
        return this.http.post(this.baseUrl + 'api/Journals/Postt_SaveJournal', data);
    }
    postTranscationClass(data) {
        return this.http.post(this.baseUrl + 'api/Master/Postt_AccountTranscationClass', data);
    }
    getJournltype() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetJournalType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getJournalstatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetJournalStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postARstatement(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Post_ARStatements', data);
    }
    postAPstatement(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Post_APStatements', data);
    }


    getLedgers(coa, name, memo, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Ledgers/Get_Ledgers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&coa=' + coa + '&name=' + name + '&memo=' + memo + '&fromdate=' + fromdate + '&todate=' + todate);
    }

    postLedgers(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Ledgers/Post_Ledgers', data);
    }

    getCustomerDeposits(fromdate, todate, number, status) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_Deposits?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&number=' + number + '&status=' + status);
    }
    getCustomerDepositById(id, fdate, tdate, customerid, d_type) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_DepositByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fdate=' + fdate + '&tdate=' + tdate + '&customerid=' + customerid + '&d_type=' + d_type)
    }

    getdepositType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDepositType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postCustomerDeposit(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Accounts/Post_Deposit', data);
    }

    getloanList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetLoans?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getDepositStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDepositStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getChequeBooks(bankid, name, number, status) {
        return this.http.get(this.baseUrl + 'api/Cheques/Gett_ChequeBooks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&bankid=' + bankid + '&name=' + name + '&number=' + number + '&status=' + status)
    }

    getChequeBooksById(id) {
        return this.http.get(this.baseUrl + 'api/Cheques/Gett_ChequeBookById?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getChequeStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetChequeStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getBanks() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBanks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getChequeIssueById(id, cbid) {
        return this.http.get(this.baseUrl + 'api/Cheques/Gett_ChequeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&chqbokid=' + cbid)
    }
    getIssuedCheques(id) {
        return this.http.get(this.baseUrl + 'api/Cheques/Get_IssuedCheques?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&chqbokid=' + id)
    }

    postChequeBooks(data) {
        return this.http.post(this.baseUrl + 'api/Cheques/Post_ChequeBook', data);
    }

    postChequeIssue(data) {
        return this.http.post(this.baseUrl + 'api/Cheques/Post_ChequeIssued', data);
    }

    getPayments(fromdate, todate, number, status) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_Payments?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate + '&number=' + number + '&status=' + status);
    }

    getFinancialCharges(customerid, asondate) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_FinancialCharges?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&asondate=' + asondate);
    }
    postFinancialCharges(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Post_FinancialCharges', data);
    }
    getGLbanks() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetGLBanks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getpaymenttype(fieldname) {
        return this.http.get(this.baseUrl + 'api/Comman/GetComboEnums?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name_of_field=' + fieldname);
    }

    getSupplierName() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSuppliers() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliersName?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getPaybill(party_name, partyid, party_type, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_PayBills?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&party_type=' + party_type + '&party_name=' + party_name + '&partyid=' + partyid + '&fdate=' + fdate + '&tdate=' + tdate)
    }

    postBillPayment(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Accounts/Post_BillPayments', val);
    }

    getBankTransaction(bankid) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_BankTranFiles?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&bankid=' + bankid);
    }
    getExcludeTran(id) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_ExcludeTran?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&banktxnid=' + id);
    }
    getUndoTran(id, type) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_UndoTran?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type)
    }
    deleteTran(id, tab) {
        return this.http.delete(this.baseUrl + 'api/Bankings/Delete_Tran?banktxnid=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tab=' + tab)
    }
    getFindTranMatch(banktxnid, banktxntype) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_FindTranMatches?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&banktxnid=' + banktxnid + '&banktxntype=' + banktxntype);
    }
    getBankTranMapped(banktranhdrid, banktxnid) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_GetBankTranMapped?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&banktranhdrid=' + banktranhdrid + '&banktxnid=' + banktxnid);
    }

    getBankTrSplitData(banktxnid) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_SplitTranData?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&banktxnid=' + banktxnid);

    }
    getBankTransMatches(bankid, banktxntype, banktxnamt) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_BankTranMatches?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&bankid=' + bankid + '&banktxntype=' + banktxntype + '&banktxnamt=' + banktxnamt)
    }
    postMatchTransactions(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Post_MatchTransactions', data)
    }
    postSplitResolve(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_SplitResolve', data)
    }

    getbankStatement(type, banktranhdrid, status) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_BankStatements?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&banktranhdrid=' + banktranhdrid + '&type=' + type + '&status=' + status)
    }

    postBankStatement(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_BankStatement', data)
    }
    getBankTransactions(type, bankid, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_BankTranIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type + '&bankid=' + bankid + '&searchtxt=' + searchtxt)
    }
    postUploadBankTransactions(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_UploadBankTransactions', data)
    }
    getBankReconcileData(banktranhdrid) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_BankReconcileData?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&banktranhdrid=' + banktranhdrid);
    }
    postUpdateReconcile(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_UpdateTranReconcile', data)
    }
    postUpdateBankUploadHdr(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_UpdateBankUploadHdr', data)
    }
    deleteUploadedTranFile(id) {
        return this.http.delete(this.baseUrl + 'api/Bankings/Delete_BankUpload?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postBankFiles(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_BankFiles', data)
    }

    getTabGridData(bank, type, fdate, tdate, tab) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_TabGridData?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&bankid=' + bank + '&banktxtype=' + type + '&fdate=' + fdate + '&tdate=' + tdate + '&tab=' + tab)
    }
    getTrasanctionTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTxTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postMatchSingleTransaction(banktxnid, txnid, banktxntype) {
        return this.http.get(this.baseUrl + 'api/Accounts/Match_Transacton?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&banktxnid=' + banktxnid + '&txnid=' + txnid + '&banktxntype=' + banktxntype);
    }
    getSplitTransaction(banktxnid) {
        return this.http.get(this.baseUrl + 'api/Bankings/splittran?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&banktxnid=' + banktxnid);
    }
    postSplitTransaction(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_SaveSplitTran', data);
    }
    getUndoTransaction(banktxnid, type) {
        return this.http.get(this.baseUrl + 'api/Bankings/Get_UndoTran?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&banktxnid=' + banktxnid + '&type=' + type);
    }
    getGLAccount() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetGLAccounts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getVendorPaymentbyId(id, fdate, tdate, supplierid, p_type) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_VendorPaymentByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fdate=' + fdate + '&tdate=' + tdate + '&supplierid=' + supplierid + '&p_type=' + p_type)
    }

    postVendPayment(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Post_VendorPayment', data)
    }
    getTransactionClasses() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTranClasses?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getBatchbyId(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_BatchByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    getARStatements(customerid) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_ARStatements?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid)
    }

    getPaymentsPaid(searchtxt, supplierid, currency, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_PaymentsPaid?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&supplierid=' + supplierid + '&currency=' + currency + '&fdate=' + fdate + '&tdate=' + tdate);
    }

    // PaymentReceived
    getPaymentReceived(searchtext, currency, mop, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_PaymentReceived?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtext=' + searchtext + '&currency=' + currency + '&mop=' + mop + '&fdate=' + fdate + '&tdate=' + tdate);
    }
    getCreditNotesList(cnno, type, status, fdate, edate, tpsync) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Accounts/Get_CreditNoteIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + cnno + '&type=' + type + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate + '&tpsync=' + tpsync);
    }
    getCreditNotesbyId(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_CreditNoteByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    postCrNotes(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Postt_CreditNote', data);
    }
    deleteCrNote(id) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_CreditNote?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteCrNoteLine(id, cnid) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_CreditNoteLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&cnid=' + cnid);
    }
    deleteCrNoteTransaction(id, cnid) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_CreditNoteTransaction?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&cnid=' + cnid);
    }
    getDebitNotesList(searchtxt, type, status, fdate, edate, tpsync) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Accounts/Get_DebitNoteIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate + '&tpsync=' + tpsync);
    }
    getDebitNotesbyId(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_DebitNoteByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    postDrNotes(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Postt_DebitNote', data);
    }
    deleteDrNote(id) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_DebitNote?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteDrNoteLine(id, dnid) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_DebitNoteLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&dnid=' + dnid);
    }
    deleteDrNoteTransaction(id, dnid) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_DebitNoteTransaction?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&dnid=' + dnid);
    }
    createIncome(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_CreateIncome', data);
    }
    createInvoice(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_CreateInvoice', data);
    }
    createExpense(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_CreateExpense', data);
    }
    createLoan(data) {
        return this.http.post(this.baseUrl + 'api/Bankings/Post_LoanTransaction', data);
    }

    getIncomeType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetIncomeType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //other income
    getOtherIncomeList(searchtxt, type, status, fdate, edate, tpsync) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Income/Get_IncomeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate + '&tpsync=' + tpsync);
    }
    getOtherIncomebyId(id) {
        return this.http.get(this.baseUrl + 'api/Income/Gett_IncomeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    postOtherIncome(data) {
        return this.http.post(this.baseUrl + 'api/Income/Postt_Income', data);
    }
    deleteOtherIncome(id) {
        return this.http.delete(this.baseUrl + 'api/Income/Delete_Income?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteIncomeLineItem(id) {
        return this.http.delete(this.baseUrl + 'api/Income/Delete_IncomeLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //loan
    getLoanList(searchtxt, type, status, fdate, edate) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Loans/Get_LoanIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate);
    }
    getLoanbyId(id) {
        return this.http.get(this.baseUrl + 'api/Loans/Gett_LoanByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    postLoan(data) {
        return this.http.post(this.baseUrl + 'api/Loans/Postt_Loan', data);
    }
    deleteLoan(id) {
        return this.http.delete(this.baseUrl + 'api/Loans/Delete_Loan?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getLoanStatement(loanid) {
        return this.http.get(this.baseUrl + 'api/Loans/Gett_LoanStatement?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss')+'&loanid='+loanid);
    }
    //Loan application
    getLoanApplicationIndex(searchtxt, type, status, fdate, edate) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Loans/Get_LoanAppIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate);
    }
    getLoanApplicationById(id) {
        return this.http.get(this.baseUrl + 'api/Loans/Gett_LoanAppByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    postLoanApplication(data) {
        return this.http.post(this.baseUrl + 'api/Loans/Postt_LoanApp', data);
    }
    deleteLoanApplication(id) {
        return this.http.delete(this.baseUrl + 'api/Loans/Delete_LoanApp?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBankList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBanks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //loan config
    getLoanConfigList(id) {
        return this.http.get(this.baseUrl + 'api/Loans/Get_LoanConfigs/?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))

    }
    postLoanConfig(data) {
        return this.http.post(this.baseUrl + 'api/Loans/Postt_LoanConfig', data);
    }
    deleteLoanConfig(id) {
        return this.http.delete(this.baseUrl + 'api/Loans/Delete_LoanConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //loan transaction
    getLoanTransactionList(id) {
        return this.http.get(this.baseUrl + 'api/Loans/Get_LoanTransactions?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    postLoanTransaction(data) {
        return this.http.post(this.baseUrl + 'api/Loans/Postt_LoanTransaction', data);
    }
    deleteLoanTransaction(id) {
        return this.http.delete(this.baseUrl + 'api/Loans/Delete_LoanTransaction?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getWriteoffIndex(searchtxt, type, status, fdate, edate, tpsync) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_WriteoffIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate + '&tpsync=' + tpsync)
    }
    getWriteOffDetails(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_WriteoffByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteWriteOff(id) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_Writeoff?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteWriteOffLine(id, wiid) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_Writeoff?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&wiid=' + wiid);
    }
    postWriteoff(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Postt_Writeoff', data);
    }
    getWriteOffTrans(partytype, partyid, partyname, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTransaction4Writeoff?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&partytype=' + partytype + '&partyid=' + partyid + '&partyname=' + partyname + '&searchtxt=' + searchtxt);
    }
    getContraEntriesIndex(searchtxt, type, status, fdate, edate) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_ContraIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate)
    }
    getContraEntryDetails(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_ContraEntryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteContraEntry(id) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_ContraEntry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postContraEntry(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Postt_ContraEntry', data);
    }
    getBusinessPartners(searchtxt, type) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_BusinessPartnerIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type)
    }
    postBusinessPartner(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Postt_BusinessPartner', data);
    }
    getBusinessPartnerById(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_BusinessPartnerByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteBusinessPartner(id) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_BusinessPartner?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBusinessPartnerRecords(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_BusinessPartnerRecords?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&business_partner_id=' + id);
    }
    postBusinessPartenerRecord(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Postt_BusinessPartnerRecord', data);
    }
    getBusinessPartnerRecordById(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_BusinessPartnerRecordByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteBusinessPartnerRecord(id) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_BusinessPartnerRecord?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBusinessPartnerRecordTransactions(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_BusinessPartnerRecordTransactions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&bprid=' + id);
    }
    postBusinessPartenerRecordTran(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Postt_BusinessPartnerRecordTran', data);
    }
    deleteBusinessPartnerRecordTran(id) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_BusinessPartnerRecordTran?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Fund Transfer
    getFundTransferReqIndex(searchtxt, type, status, fdate, edate) {
        return this.http.get(this.baseUrl + 'api/Accounts/Get_FundTransferIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate)
    }
    getFundTransferReqDetails(id) {
        return this.http.get(this.baseUrl + 'api/Accounts/Gett_FundTransferByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteFundTransferReq(id) {
        return this.http.delete(this.baseUrl + 'api/Accounts/Delete_FundTransfer?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postFundTransferReq(data) {
        return this.http.post(this.baseUrl + 'api/Accounts/Postt_FundTransfer', data);
    }
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
