<form>
  <div class="custom-row">
    <div class="form-row">
      <div class="row">
        <div class="col-lg-3 col-md-6 form-group"></div>
        <div class="col-lg-3 col-md-6 form-group">
          <label for="">Serials</label>
          <input type="search" class="form-control" [(ngModel)]="filter.serialno" name="serials"
            placeholder="Search by serials">
        </div>
        <div class="col-lg-3 col-md-6 form-group">
          <label for="">Bincode</label>
          <input type="search" class="form-control" [(ngModel)]="filter.bincode" name="bincode"
            placeholder="Search by Bincode">
        </div>
        <div class="col-lg-3 col-md-6 form-group p-0">
          <label for="">Status</label>
          <ng-select placeholder="Select status" name="status" [(ngModel)]="filter.status" [multiple]="true">
            <ng-option *ngFor="let item of serialStatus" [value]="item.stringValue">
              {{item.Text}}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-lg-3 col-md-6 form-group">

        </div>
      </div>
    </div>
    <div class=" btn-group CsearchBtn1 CsearchBtn" role="group">
      <button type="button" container="body" (click)="getProductSerials()" ngbTooltip="Search"
        class="btn btn-primary right-radius"><i class="refresh-btn fa fa-search"></i></button>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-sm-12">

    <div class="category-table custom-datatable">
      <div class="table-responsive-5">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" data-target="#exampleModal"
                (click)="open('','add')"><i class="fa fa-plus"></i> </a></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'serial')">Serial#<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'binlocation')">BIN<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'status')">Status<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'warrantyexpirydate')">Warranty Expiry Date<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'lastupdated')">Last Updated<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'purchasedate')">Create Date<i class="Sorting fa fa-chevron-up"></i></th>
            <th class="custom-th text-center"(click)="onSortClick($event,'updateby')">Updated By<i class="Sorting fa fa-chevron-up"></i></th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of serialList">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem data-toggle="modal" data-original-title="View" data-target="#exampleModal"
                      (click)="open(item,'view')">View</span></li>
                  <li><span ngbDropdownItem data-toggle="modal" data-original-title="View" data-target="#exampleModal"
                      (click)="open(item,'edit')">Edit</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.serial}}</td>
            <td class="custom-td text-left">{{item.binlocation}}</td>
            <td class="custom-td text-center">{{item.status}}</td>
            <td class="custom-td text-left">{{item.warrantyexpirydate | date:userdata.date_format}}</td>
            <td class="custom-td text-left">{{item.lastupdated | date:userdata.date_format}}</td>
            <td class="custom-td text-left">{{item.purchasedate | date:userdata.date_format}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <resize-border [dragHolder]="header">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">Serials</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="needs-validation">
        <div class="form">
          <div class="form-group">
            <label for="" class="mb-1">Supplier</label>
            <input class="form-control" type="text">
          </div>
          <div class="form-group">
            <label for="" class="mb-1">Item Code</label>
            <input class="form-control" type="text">
          </div>
          <div class="form-group">
            <label for="" class="mb-1">Cost</label>
            <input class="form-control" type="text">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
        (click)="modal.dismiss('Cross click')">Close</button>
      <button type="button" class="btn btn-primary">Save</button>
    </div>
  </resize-border>
</ng-template>