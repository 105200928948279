import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-openingbalance',
  templateUrl: './openingbalance.component.html',
  styleUrls: ['./openingbalance.component.scss']
})
export class OpeningbalanceComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private loader: NgxUiLoaderService,) { }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  ngOnInit(): void {
  }

}
