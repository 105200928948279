import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { DashboardServices } from '../../dashboard/dashboard.service';

@Component({
  selector: 'app-customerannouncements',
  templateUrl: './customerannouncements.component.html',
  styleUrls: ['./customerannouncements.component.scss']
})
export class CustomerannouncementsComponent implements OnInit {
  announcements: any = [];
  fullScreen = false;
  userdata: any;
  appname = environment.APP_NAME
  constructor(private activeModal: NgbActiveModal, private service: DashboardServices) { }

  ngOnInit(): void {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.getDashBoardData()
  }

  getDashBoardData() {
    this.service.getHomeAnnouncements().subscribe(res => {
      // var dashboardData = res;
      this.announcements = res;
      environment.production ? '' : console.log(res);
    }, err => {

    });
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  //#region Sorting Table Header
  sortDir = 1;//1= 'ASE' -1= DSC
  onSortClick(event) {
    let target = event.currentTarget,
      childtarget = target.querySelector('i'),
      classList = childtarget.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      target.classList.add('Sorted');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      target.classList.remove('Sorted');
      this.sortDir = 1;
    }
    this.sortArr('title');
  }

  sortArr(colName: any) {
    this.announcements.announcements.sort((a, b) => {
      if (a[colName] != null) {
        a = a[colName].toLowerCase();
      }
      if (b[colName] != null) {
        b = b[colName].toLowerCase();
      }
      return a.localeCompare(b) * this.sortDir;
    });
  }
  //#endregion Sorting Table Header
}
