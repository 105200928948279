import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from './events.service';
import { DatePipe } from '@angular/common';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class CommonServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    isLoading: boolean;
    objLogin: modelLogin;
    objComp: modelComp;
    objCompId: any;
    userprofile: any;
    public hero: ''
    public expenseData: ''
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    public headerEditItemData = new BehaviorSubject('');

    @Output() aClickedEvent = new EventEmitter<string>();

    islocalClicked(msg: string) {
        this.aClickedEvent.emit(msg);
    }
    //Ticket Category List && sub cat same api need to use -- updated by mayur 4 sept 2022
    getSalesReqItems(binid, deptid, categoryid, subcategoryid, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetSalesReqItems?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&binid=' + binid + '&deptid=' + deptid + '&categoryid=' + categoryid + '&subcategoryid=' + subcategoryid + '&searchtxt=' + searchtxt);
    }

    getTicketCategories(parentid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetServiceCategory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&parentid=' + parentid);
    }

    getAuditLogs(id, pg) {
        return this.http.get(this.baseUrl + 'api/Comman/Gett_AuditLogs?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg);
    }

    getTaxList(type) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTaxList?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type);
    }

    getClockingCodes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetClockingCodes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }


    //Ticket Priorities List
    getTicketPriorities() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketPriority?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTicketStatuses() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBusinessPartnerRecordType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBusinessPartnerRecordType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //enum list
    getComboEnums(name_of_field) {
        return this.http.get(this.baseUrl + 'api/Comman/GetComboEnums?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name_of_field=' + name_of_field);
    }
    //get audit log

    //get party list
    getPartyList(ptype, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetParties?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&partytype=' + ptype + '&searchtxt=' + searchtxt);
    }
    //get document list 
    getDocList(refno) {
        return this.http.get(this.baseUrl + 'api/Comman/GetDocumnets?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&refno=' + refno);

    }
    getDocIndexList(refno, description, type) {
        return this.http.get(this.baseUrl + 'api/Documents/Get_DocumentIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&refno=' + refno + '&description=' + description + '&type=' + type);

    }
    getEmailTemplateCodes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmailTemplateCodes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmailLists() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmailLists?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmailTemplateLists() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmailTemplates?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // getdoc type{
    getDocType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDocumentType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPayrollPaymentType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPayrollPaymentType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //getBoolean type list as common
    getBooleanList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/yesNoBoolean?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // getticketlist
    getTicketsUsingCustomer(customer) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTickets?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customer);
    }
    //Ticket Devices List
    getCustomerDevices(customerid, siteid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerDevices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&siteid=' + siteid);
    }

    //Customer info
    getCustomerInfo(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerInfo?customerid=' + customerid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSiteInfo(siteid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetSiteInfo?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&siteid=' + siteid);
    }

    getProjJobslist(searchtxt, customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_ProjJobslist?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&customerid=' + customerid);
    }

    getInvOrderslist(searchtxt, customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_InvOrderslist?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&customerid=' + customerid);
    }

    getCustomerList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getPaymentTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPaymentTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomers(name, email, phone) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerList?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&email=' + email + '&phone=' + phone);
    }

    //project
    getProjectList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjects?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&username=' + this.userprofile.email);
    }

    getProjectTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjectTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&username=' + this.userprofile.email);
    }
    getPOSPaymentMethods() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPOSPaymentMethods?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&username=' + this.userprofile.email);
    }
    getCustomerTicketList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTickets?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSOTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSOTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCustomerTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomerTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //project
    getCustomerProjectList(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProjects?customerid=' + customerid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCustomerSiteList(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerSites?customerid=' + customerid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDeliveryEmployees() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDeliveryEmployees?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCustomerSites(customerid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomerSites?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid);
    }
    getSubCategory(cat) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProductSubCategory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&categoryid=' + cat);
    }
    getReceivedVia() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketReceivedVia?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTicketPriority() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketPriority?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getQouteStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetInvStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getQuoteTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetQuoteTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCurrency() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyCurrencies?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getWriteoffReasons() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetWriteoffReasons?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getProCategory() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetGoodsCategories?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=')
    }

    // service manager
    getServiceContractPeriods() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetServicePeriod?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // binlocation type
    getGetBinType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBinTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getWareHouse() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetWarehouses?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getsuppliers(tpcode, accno, shortname, name, status) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Get_SupplierIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tpcode=' + tpcode + '&accno=' + accno + '&shortname=' + shortname + '&name=' + name + '&status=' + status);
    }
    getsuppliersList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSupplierStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetInvStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getSalesOrerTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSOTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSalesOrderTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSOTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSalesOrderStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSOStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSalesOrderPricingTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetOrderPricingTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getProjectListByCustomer(custoerid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjects?username=' + this.userprofile.email + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAllProList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjects?username=' + this.userprofile.email + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getProductCatalog(ptype, pricingtype, searchtxt, deptid, categoryid, subcatid, supplierid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductCatalog4Sales?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ptype=' + ptype + '&pricingtype=' + pricingtype + '&searchtxt=' + searchtxt + '&deptid=' + deptid + '&categoryid=' + categoryid + '&subcatid=' + subcatid + '&supplierid=' + supplierid);
    }
    getServiceItems(searchtxt: string, deptid: string, empid: any, tid: any) {
        return this.http.get(this.baseUrl + 'api/comman/Gett_ServiceItems?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&deptid=' + deptid + '&searchtxt=' + searchtxt + '&tid=' + tid + '&empid=' + empid);
    }
    getBins(searchtxt, type) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_Binlist?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type);
    }
    getProductCatalogforPO(searchtxt, supplierid, categoryid, deptid, subcatid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductCatalog4Purchase?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&supplierid=' + supplierid + '&categoryid=' + categoryid + '&deptid=' + deptid + '&subcatid=' + subcatid);
    }

    getAssembliesCatalog(searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetAssemblyCatalog?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
    }

    getProductDetailByILC(ilc, searchby, pricingtype) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProdInfo?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&searchby=' + searchby + '&pricingtype=' + pricingtype);
    }
    getPoStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPostatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCountries() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCountries?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAllCountries() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyCountries?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }
    getJobList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetJobOpenings?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }
    getDeviceTypeList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDeviceTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }
    getEmployees() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmployees?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getTaxConfig(type) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTaxConfigs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type);
    }
    getCustomerContracts(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerContracts?customerid=' + customerid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPaymentMethods() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPaymentMethods?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCompBankList(type) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyBanks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type);
    }
    getCheckBooks(bankid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCheckBooks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&bankid=' + bankid);
    }
    getCheckBookNos(chqbokid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCheckNo?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&chqbokid=' + chqbokid);
    }
    getPaymentTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPaymentType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getTicketsByCustomer(custid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTickets?customerid=' + custid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCustomerCreditAvailable(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/Gett_CustomerCreditAvailable?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid);
    }

    getTicketsByDevice(deviceid, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeviceTickets?id=' + deviceid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }

    getWorkTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetWorkType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }

    getCustomerDepartmentList(customerid, siteid) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomerDepartments?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&siteid=' + siteid);
    }

    //product
    getDepartmentList(isservice) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProductDept?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&isservice=' + isservice);
    }
    getCategory(dept, isservice) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProductCategory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&dept=' + dept + '&isservice=' + isservice);
    }
    getCheckListGroup() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetChecklistGroupTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPoTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPOTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getAssignTo() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssetUsers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    getPoPriority() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPOPriority?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getPoTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPoTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getSupplierName() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliersName?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSupplierList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSuppliersList(local, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetSupplierList?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&local=' + local + '&searchtxt=' + searchtxt);
    }
    getSchedulerResources() {
        return this.http.get(this.baseUrl + 'api/Scheduler/Gett_Resources?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssigntoForTask() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetResources?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAssigntoForProjTasks() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjResources?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //notes
    getNotesType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTechNotesType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBOLList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBOLList?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getDepositStatusList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDepositStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAllTicket() {
        return this.http.get(this.baseUrl + 'api/tickets/Gett_AllTickets?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTagIndex(desc, pg) {
        return this.http.get(this.baseUrl + 'api/Tags/Get_TagIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + desc + '&pg=' + pg);
    }
    getTagByID(id) {
        return this.http.get(this.baseUrl + 'api/Tags/Get_TagByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postTags(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Tags/Post_Tags', data);
    }
    getTags(pg) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTags?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg);
    }
    getComposeEmail(id, template_code) {
        return this.http.get(this.baseUrl + 'api/Emailer/GetComposeEmail?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&template_code=' + template_code);
    }
    postEmail(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Emailer/Post_Email', data);
    }
    getPrintType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPrintType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPlans(current_plan) {
        return this.http.get(this.baseUrl + 'api/Comman/GetPlans?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&current_plan=' + current_plan);
    }
    //getbin list
    getBinLocation() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBinLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBSPeriod() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBSPeriod?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For projecttype index
    getProjectTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_ProjectTypeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getProjectTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ProjectTypeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postProjectType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ProjectType', data);
    }

    deleteProjectType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ProjectType?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }



    // For contract type index
    getContractTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_ContractTypeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getContractTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ContractTypeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postContractType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ContractType', data);
    }

    deleteContractType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ContractType?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Task type index
    getTaskTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_TaskTypeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getTaskTypeIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_TaskTypeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postTaskType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_TaskType', data);
    }

    deleteTaskType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_TaskType?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Designation type index
    getDesignationIndex(desc) {
        return this.http.get(this.baseUrl + 'api/Master/Get_EmployeeDesignationIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + desc);
    }

    getDesignationIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_EmployeeDesignationByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postDesignation(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_EmployeeDesignation', data);
    }

    deleteDesignation(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_EmployeeDesignation?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    deleteResponsibilities(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DesignationResponsibilities?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Assets Location index
    getAssetsLocationIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_AssetLocationIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getAssetsLocationIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_AssetLocationByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postAssetsLocation(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_AssetLocation', data);
    }

    deleteAssetsLocation(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_AssetLocation?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Assets Condition index
    getAssetsConditionIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_AssetConditionIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getAssetsConditionIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_AssetConditionByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postAssetsCondition(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_AssetCondition', data);
    }

    deleteAssetsCondition(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_AssetCondition?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    deleteDocument(id) {
        return this.http.delete(this.baseUrl + 'api/Documents/Delete_Document?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Assets Action index
    getAssetsActionIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_AssetActionIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getAssetsActionIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_AssetActionByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postAssetsAction(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_AssetAction', data);
    }

    deleteAssetsAction(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_AssetAction?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getMakeProductActive(id) {
        return this.http.get(this.baseUrl + 'api/Comman/Make_ProductActive?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Invoice Terms=============
    getInvoiceTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_InvoiceTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getInvoiceTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetInvoiceTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getEstimateTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEstimateTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getPrinters() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPrinters?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getInvoiceTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_InvoiceTermsByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postInvoiceTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_InvoiceTerms', data);
    }

    deleteInvoiceTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_InvoiceTerms?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Quote Terms=============
    getQuoteTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_QuoteTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getQuoteTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_QuoteTermsByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postQuoteTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_QuoteTerms', data);
    }

    deleteQuoteTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_QuoteTerms?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // forExtimateExpirterms
    getEstimateTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_EstimateTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getEstimateTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_EstimateTermsByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postEstimateTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_EstimateTerms', data);
    }

    deleteEstimateTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_EstimateTerms?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For PO Terms=============
    getPOTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_POTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getPOTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_POTermsByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postPOTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_POTerms', data);
    }
    deletePOTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_POTerms?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For PI Terms=============
    getPITermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_PITerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getPITermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_PITermsByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postPITerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_PITerms', data);
    }
    deletePITerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_PITerms?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Writeoff Reasons=============
    getWriteoffReasonIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_WriteoffReasons?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getWriteoffReasonIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_WriteoffReasonByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postWriteoffReason(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_WriteoffReasons', data);
    }
    deleteWriteoffReason(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_WriteoffReasons?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Ticket Status=============
    getTicketStatusIndex(desc, code) {
        return this.http.get(this.baseUrl + 'api/Master/Get_TicketStatusIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + desc + '&code=' + code);
    }
    getTicketStatusByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_TicketStatusByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postTicketStatus(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_TicketStatus', data);
    }
    deleteTicketStatus(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_TicketStatus?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For SO Terms=============
    getSOTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_SOTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getSOTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_SOTermsByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postSOTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_SOTerms', data);
    }
    deleteSOTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_SOTerms?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Project Terms=============
    getProjectTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_ProjectTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getProjectTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ProjectTermsByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postProjectTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ProjectTerms', data);
    }
    deleteProjectTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ProjectTerms?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Product Manufacturer=============
    getManufacturerIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_Manufacturers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getManufacturerIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ManufacturerByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postManufacturer(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_Manufacturer', data);
    }
    deleteManufacturer(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Manufacturer?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For binLocation warehouse=============
    getWarehouseIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_Warehouses?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getWarehouseLst() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetWarehouses?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getWarehouseIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_WarehouseByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postWarehouse(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_Warehouse', data);
    }
    deleteWarehouse(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Warehouse?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For paybill payment=============
    getPaymentMethodIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_CompanyPaymentMethods?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getPaymentMethodIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyPaymentMethods?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postPaymentMethod(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyPaymentMethod', data);
    }
    deletePaymentMethod(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyPaymentMethod?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for employee department
    getEmpDepartmentIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_EmployeeDepartmentsIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getEmpDepartmentIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_EmployeeDepartmentByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postEmpDepartment(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_EmployeeDepartment', data);
    }
    deleteEmpDepartment(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_EmployeeDepartment?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for document type
    getDocumentTypeIndex(desc, code) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DocumentTypeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&desc=' + desc + '&code=' + code);
    }
    getDocumentTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DocTypeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postDocumentType(val) {
        // var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Post_DocType', val);
    }
    deleteDocumentType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DocType?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for delivery route
    getDeliveryRouteIndex(code) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DeliveryRouteIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&code=' + code);
    }
    getDeliveryRouteByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeliveryRouteByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postDeliveryRoute(val) {
        // var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DeliveryRoute', val);
    }
    deleteDeliveryRoute(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeliveryRoute?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for cpc type
    getCPCIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_CPC?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }
    getCPCByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_CPCByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCPC(val) {
        // var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_CPC', val);
    }
    deleteCPC(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_CPC?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for customeerdept
    getCustomerDeptIndex(customerid, name) {
        return this.http.get(this.baseUrl + 'api/Master/Get_CustomerDepartments?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&name=' + name);
    }
    getCustomerDeptByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_CustomerDepartmentByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCustomerDept(val) {
        // var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_CustomerDepartment', val);
    }
    deleteCustomerDept(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_CustomerDepartment?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for sales reqyest type
    getDisputeReasonList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDisputeReasons?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSalesRequestType(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_SalesRequestTypeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }
    getSalesRequestTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_SalesRequestTypeByID/' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postSalesRequestType(val) {
        // var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_SalesRequestType', val);
    }
    deleteSalesRequestType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_SalesRequestType?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSalesRequestTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSalesRequestTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //add location
    getLocationIndex() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postLocation(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanySite', val);
    }
    deleteLocation(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanySite?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for Product UOM
    getProductUOMIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_ProductUOM?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getProductUOMByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ProductUOMByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postProductUOM(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ProductUOM', data);
    }
    deleteProductUOM(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ProductUOM?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Shipping port=============
    getShippingPortIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_ShippingPort?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getShippingPortIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ShippingPortByI?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postShippingPort(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ShippingPort', data);
    }
    deleteShippingPort(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ShippingPort?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Shipping Agent=============
    getShippingAgentIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_ShippingAgents?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getShippingAgentIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ShippingAgentByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postShippingAgent(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ShippingAgent', data);
    }
    deleteShippingAgent(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ShippingAgent?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Vessels=============
    getVesselsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_Vessels?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getVesselsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_VesselByI?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postVessels(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_Vessel', data);
    }
    deleteVessels(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Vessel?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For delivery terms=============
    getTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_DeliveryTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeliveryTermByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postDeliveryTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DeliveryTerm', data);
    }
    deleteDeliveryTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeliveryTerm?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //get user profile
    getUserProfileInfo() {
        return this.http.get(this.baseUrl + 'api/Employees/Gett_ProfileByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid=' + this.userprofile.empid);
    }
    //Complete signup
    postLead2Company(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/EbSettings/Post_Lead2Company', data);
    }
    postLead2Customer(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Post_Lead2Customer', data);
    }
    getTarrifList(searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTariffList?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
    }
    postAddCard(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Post_Card', data);
    }
    getChangeSubscriptionDate(customerid, planid, rate) {
        return this.http.get(this.baseUrl + 'api/Settings/Change_Subscription?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&planid=' + planid + '&rate=' + rate);
    }
    deleteEzyBooksSubscription(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CancelEzbSubscription', data);
    }
    getCompanySubscriptions() {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanySubscriptions?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postSubscriptionPayment(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_UpgradeEzbSubscription', data);
    }
    updateCost(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Post_UpdatePOCost', data);
    }
    deleteLineItem(id) {
        return this.http.delete(this.baseUrl + 'api/InventoryTransfers/DeleteInventoryTransferEntry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDueInvList(customer) {
        return this.http.get(this.baseUrl + 'api/Invoices/Get_CustomerInvList?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customer);
    }
    getActiveInactiveStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/ActiveInActiveFilter?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getProductBinDetails(prodid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProdBinsData?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
    }
    getProductPictures(prodid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductPictures?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
    }
    getProductBin4Adj(prodid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductBin4Adj?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
    }
    getContact(name) {
        return this.http.get(this.baseUrl + 'api/Comman/GetContacts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name);
    }
    getDevice(name, ilc, type) {
        return this.http.get(this.baseUrl + 'api/Comman/GetDevices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&ilc=' + ilc + '&type=' + type);
    }
    getSite(name) {
        return this.http.get(this.baseUrl + 'api/Comman/GetSites?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name);
    }
    getParticularHdr() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssesmentHdr?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    validateIlc(ilc) {
        return this.http.get(this.baseUrl + 'api/Comman/Check_LookupCodeExist?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc);
    }
    getProdUom() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProdUom?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For sales order type index
    getSOTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_SOTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getSOTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_SOTypeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postSOType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_SOType', data);
    }

    deleteSOType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_SOTtype?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getTrackingNotes(ref_id, pg) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_TrackingNotes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ref_id=' + ref_id + '&pg=' + pg);
    }
    getSupplierContactList(supplierid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSupplierContacts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid);

    }
    postTrackingNotes(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Comman/Post_TrackingNotes', data);
    }
    // For customer type index
    getCustomerTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_CustomerTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getCustomerTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_CustomerTypeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postCustomerType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_CustomerType', data);
    }

    deleteCustomerType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_CustomerType?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Dispute Reason 
    getDisputeReasonIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DisputeReasons?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getDisputeReasonByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DisputeReasonByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postDisputeReason(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DisputeReason', data);
    }

    deleteDisputeReason(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DisputeReason?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For device type index
    getDeviceTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DeviceTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getDeviceTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeviceTypeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postDeviceType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DeviceType', data);
    }

    deleteDeviceType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeviceType?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getComponentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSalaryComponent?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getUserGuideParentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetUserGuideParent?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //get guide list
    getGuideList() {
        return this.http.get(this.baseUrl + 'api/Comman/Get_UserGuide?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postReceivedTracking(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Accounts/Post_TrackingChequeReceived', data);
    }

    postIssuedTracking(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Accounts/Post_TrackingChequeIssued', data);
    }

    getPoliciesParentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPrivacyPolicies?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getServiceParentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTermsofService?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCompLocation() {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDesignationList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmployeeDesignations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSoftwareList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyTPIS?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getIlcInformation4Sales(pricingtype, ilc) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductInfo4Sales?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pricingtype=' + pricingtype + '&ilc=' + ilc);
    }
    getIlcInformation4Purchase(supplierid, ilc) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductInfo4Purchase?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid + '&ilc=' + ilc);
    }
    getLocationTracking(userid, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/LocationTracking/GetUserLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&userid=' + userid + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getProdBins(prodid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductBins?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
    }
    getFundTransferReq() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetFundTransferReq?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCreditNoteType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCreditNoteType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDebitNoteType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDebitNoteType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCheckProductSerialExists(prodid, serialno) {
        return this.http.get(this.baseUrl + 'api/InventoryItems/Check_ProductSerialExists?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid + '&serialno=' + serialno);
    }
    exportDataToFormat(data_option, type, fromdate, todate, reno, refid, asondate) {
        var exportURL = environment.API_URL + 'public/Export_Data?' + 'companyid=' + this.userprofile.companyid + '&un=' + this.userprofile.company_web_token + '&logo=' + this.userprofile.logo + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&data_option=' + data_option + '&file_type=' + type + '&fromdate=' + fromdate + '&todate=' + todate + '&reno=' + reno + '&refid=' + refid + '&asondate=' + asondate;
        // var exportURL = environment.API_URL + 'public/Export_Data?' + 'companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&logo=' + this.userdata.logo + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&data_option=' + data_option + '&file_type=excel' + '&rfqid=' + this.rowData.id + '&refno=' + this.rowData.rfqno;
        window.open(exportURL, '_blank');
    }

    // For uom ratio index
    getProductUOMRatioIndex(searchtxt) {
        return this.http.get(this.baseUrl + 'api/Master/Get_ProductUOMMatrix?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
    }
    getProductUOMRatio(uomid) {
        return this.http.get(this.baseUrl + 'api/Master/Get_ProductUOMRatio?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&uomid=' + uomid);
    }

    getProductUOMRatioByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ProductUOMRatioByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postProductUOMRatio(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ProductUOMRatio', data);
    }

    deleteProductUOMRatio(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ProductUOMRatio?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getExchangeRate(base_currency, foreign_currency) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_ExchangeRate?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&base_currency=' + base_currency + '&foreign_currency=' + foreign_currency);
    }
    getGetSalesApprovers() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSalesApprovers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getGetPurchaseApprovers() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPurchaseApprovers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Device Inventory=============
    getDeviceMaterialsByDeviceID(deviceid) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DeviceMaterials?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&deviceid=' + deviceid);
    }
    postDeviceMaterial(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DeviceMaterial', data);
    }
    deleteDeviceMaterial(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeviceMaterial?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Master TPI Link =============
    getThirdPartyLinkIndex(searchtxt) {
        return this.http.get(this.baseUrl + 'api/Master/Get_ThirdPartyLinkIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
    }
    postThirdPartyLink(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ThirdPartyLink', data);
    }
    getThirdPartyLinkByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ThirdPartyLinkByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteThirdPartyLink(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ThirdPartyLink?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Device TPI Link =============
    getDeviceThirdPartyLinkIndex(deviceid) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DeviceThirdPartyLinks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&deviceid=' + deviceid);
    }
    postDeviceThirdPartyLink(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DeviceThirdPartyLink', data);
    }
    deleteDeviceThirdPartyLink(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeviceThirdPartyLink?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTPILinks() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTPILinks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getLoanType(category) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetLoanType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&category=' + category);
    }
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }


}
