import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { DatePipe } from '@angular/common';



@Injectable({
    providedIn: 'root'
})
export class hrServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    userprofile: any;

    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }
    getDepartmentList(isservice) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProductDept?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&isservice=' + isservice);
    }
    getGenderList() {

        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetGender?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpType() {

        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmpType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpDesignation() {

        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmployeeDesignations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postEmergencyContact(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpemergencyContact', data);
    }
    getEmergencyContactById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpemergencyContactByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // 

    //============Employee tab services=================
    getEmpBadges(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeBadges?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpBanks(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeBanks?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpDocuments(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Gett_EmployeeDocuments?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpConsessionPasses(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeConsessionPasses?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpCostings(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeCostings?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpLeaves(id, fromdate, todate) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeLeaves?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getEmpDisciplinaryLog(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeDisciplinaryLogs?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpEducationHistory(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeEducationHistories?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpEmergencyContacts(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeEmergencyContacts?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpFamilyHistory(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeFamilyHistories?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpInsurance(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeInsurances?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpLeaveTrackers(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeLeaveTrackers?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpLoans(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeLoans?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpLoanById(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpLoansByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteEmpLoan(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpLoans?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }


    getEmpPensions(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeePensions?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpAward(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeAwards?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpAwardById(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpAwardsByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postEmpAward(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpAwards', data);
    }

    getEmpPermits(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeePermits?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpReferences(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeReferences?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpReleases(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeReleases?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpWorkHistories(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeWorkHistories?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpWorkLeaves(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeLeaves?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpPays(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeePays?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpLicenses(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeLicenses?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpTrainings(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeTrainings?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpPayrolls(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeePayrolls?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }


    getEmpLeaveConfigs(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeLeaveConfigs?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpOfficeClockings(id, startdate, endate) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeOfficeClockings?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&startdate=' + startdate + '&endate=' + endate);
    }

    getEmpSalaryComponents(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeSalaryComponents?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpShifts(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeShifts?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //============Employee tab services end=================

    getGetPayType() {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPayTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpStatus() {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmpStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getManager() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmpManagers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmployeeDeptList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmployeeDepartments?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getApprisalCycle() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAppraisalCycles?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postEmployee(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Employees/Postt_Employee', data);
    }
    getEmpById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Gett_EmployeeByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getLeaves(name, leaveType, status, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Leaves/Get_LeaveIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + name + '&leavetype=' + leaveType + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    deleteLeaveById(id) {
        return this.http.delete(this.baseUrl + 'api/Leaves/Delete_Leave?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmployees() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmployees?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Banks api
    postEmpBanks(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpBanks', data);
    }
    getEmpBanksbyId(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpBanksByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Company shift api
    postCompShift(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpShiftConfig', data);
    }
    getCompShiftById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpShiftConfigByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteCompShift(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpShiftConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getcompShiftDrop() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyShifts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Pension api
    postPension(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_PensionHistory', data);
    }
    getPensionbyId(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_PensionHistoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //EmpInsurance api
    postEmpInsurance(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpInsurances', data);
    }

    getEmpInsurancebyId(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpInsurancesByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //SalaryStructure api
    postSalaryStructure(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_SalaryStructure', data);
    }
    getSalaryStructurebyId(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_SalaryStructure?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //emp Edu History
    postEduHistory(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EduHistory', data);
    }
    getEmpEduById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EduHistoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }


    //badge history
    postBadgeHistory(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_BadgesHistory', data);
    }
    //emp training
    postTraining(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_TrainingHistory', data);
    }
    getTrainingById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_TrainingHistoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //emp leaves
    postEmpLeaves(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpLeaves', data);
    }
    leaveType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetLeaveType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //emp contact 
    postEmpcontact(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_ECHistory', data);
    }
    //emp loans
    empLoans(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpLoans', data);
    }
    getRePaymentMethod() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetRePaymentModes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getContractLegalType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyLegalContracts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //emp cost center
    empCostCenter(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpCostCenter', data);
    }
    getCompanyLocation() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //emp ref
    postEmpRef(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpRef', data);
    }
    getEmpRefById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpRefByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //emp reference release 
    postEmpRelease(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpRelease', data);
    }
    //emp salary post
    postEmpCosting(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpCosting', data);
    }
    getEmpCostingById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpCostingByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteEmpCostingById(pid) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpCosting?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pid=' + pid);
    }
    //emp family info
    postEmpFamily(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_FamilyHistory', data);
    }
    getEmpFamilyById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_FamilyHistoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //emp salary component
    postSalaryComp(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpSalaryComponent', data);
    }
    getSalaryCompById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpSalaryComponentByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteSalaryCompById(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpSalaryComponent?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Company salary component
    postSalaryComponent(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/HR/Post_SalaryComponent', data);
    }
    postSalaryComponentSlab(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/HR/Post_SalaryComponentSlab', data);
    }
    deleteSalaryComponentSlab(id) {
        return this.http.delete(this.baseUrl + 'api/HR/Delete_SalaryComponentSlab?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteSalaryComponentSlabUpto(id) {
        return this.http.delete(this.baseUrl + 'api/HR/Delete_SalaryComponentSlabUpto?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSalaryComponentIndex(type, description, status) {
        return this.http.get(this.baseUrl + 'api/HR/Get_SalaryComponentIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type + '&description=' + description + '&status=' + status)
    }
    getSalaryComponentById(id) {
        return this.http.get(this.baseUrl + 'api/HR/Get_SalaryComponentByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteSalaryComponentById(id) {
        return this.http.delete(this.baseUrl + 'api/HR/Delete_SalaryComponent?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Public Holiday
    postPublicHoliday(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/HR/Postt_PublicHoliday', data);
    }
    getPublicHolidayIndex(searchtxt, type, finyear) {
        return this.http.get(this.baseUrl + 'api/HR/Get_PublicHolidayIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&finyear=' + finyear)
    }
    getPublicHolidayById(id) {
        return this.http.get(this.baseUrl + 'api/HR/Gett_PublicHolidayByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deletePublicHolidayById(id) {
        return this.http.delete(this.baseUrl + 'api/HR/Delete_PublicHoliday?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //emp leave config
    postEmpLeaveConfig(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpLeaveConfig', data);
    }
    getEmpLeaveConfigById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpLeaveConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getLeaveConfig(finyear) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetLeaveConfig?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&finyear=' + finyear)
    }

    deleteEmpLeaveConfigById(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpLeaveConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Weekly off 
    //emp leave config
    postWeeklyOff(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/HR/Postt_WeeklyOff', data);
    }
    getWeeklyOffById(id) {
        return this.http.get(this.baseUrl + 'api/HR/Gett_WeeklyOffByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getWeeklyOffIndex(departmentid, type) {
        return this.http.get(this.baseUrl + 'api/HR/Get_WeeklyOffIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&departmentid=' + departmentid + '&type=' + type)
    }
    deleteWeeklyOffById(id) {
        return this.http.delete(this.baseUrl + 'api/HR/Delete_WeeklyOff?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //Master Leave Config
    postLeaveConfig(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/HR/Postt_LeaveConfig', data);
    }
    getLeaveConfigById(id) {
        return this.http.get(this.baseUrl + 'api/HR/Gett_LeaveConfigByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getLeaveConfigIndex(description, type) {
        return this.http.get(this.baseUrl + 'api/HR/Get_LeaveConfigIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description + '&type=' + type);
    }
    deleteLeaveConfigById(id) {
        return this.http.delete(this.baseUrl + 'api/HR/Delete_LeaveConfig?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //emp work history
    postEmpWorkHistory(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_WorkHistory', data);
    }
    // 
    getEmpWorkById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_WorkHistoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }




    postWpHistoryData(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_WorkPermit', data);
    }

    postDisLogs(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpDisLog', data);
    }
    getLeaveType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetLeaveType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postLeaves(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Leaves/Postt_Leaves', data);
    }

    postLeaveItem(data) {
        return this.http.post(this.baseUrl + 'api/Leaves/Postt_LeaveItem', data);
    }

    getleavesStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetLeaveStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getLeavesbyId(id) {
        return this.http.get(this.baseUrl + 'api/Leaves/Gett_LeaveByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getOfficeCLocking(empid, status, fdate, tdate) {
        return this.http.get(this.baseUrl + 'api/OfficeClockings/Get_OfficeClockings?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid_id=' + empid + '&status=' + status + '&fromdate=' + fdate + '&todate=' + tdate)
    }

    getSiteLogs(fdate, tdate, locid) {
        return this.http.get(this.baseUrl + 'api/CompanySites/Get_SiteLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fdate=' + fdate + '&tdate=' + tdate + '&locid=' + locid)
    }

    getSiteClockingSites() {
        return this.http.get(this.baseUrl + 'api/CompanySites/Get_CompanySites?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getEmpList(name, code, status, type, paytype, designation_id, department_id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmployeeIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&code=' + code + '&status=' + status + '&type=' + type + '&paytype=' + paytype + '&designation_id=' + designation_id + '&department_id=' + department_id);
    }


    //site logs
    getCustomerLocations() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyLocations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getLeavesCategories(description) {
        return this.http.get(this.baseUrl + 'api/Leaves/Get_LeaveCategoryIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description)
    }
    getLeavesCategoriesById(id) {
        return this.http.get(this.baseUrl + 'api/Leaves/Gett_LeaveCategoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postLeaveCategory(data) {
        return this.http.post(this.baseUrl + 'api/Leaves/Postt_LeaveCategory', data)
    }
    deleteLeaveCategory(id) {
        return this.http.delete(this.baseUrl + 'api/Leaves/Delete_LeaveCategory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    getshiftReasons(description) {
        return this.http.get(this.baseUrl + 'api/OfficeClockings/Get_ClockingReasonIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description)
    }
    getShiftreasonById(id) {
        return this.http.get(this.baseUrl + 'api/OfficeClockings/Gett_ClockingReasonByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    deleteClockingReason(id) {
        return this.http.delete(this.baseUrl + 'api/OfficeClockings/Delete_ClockingReason?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }

    postshiftReason(data) {
        return this.http.post(this.baseUrl + 'api/OfficeClockings/Postt_ClockingReason', data);
    }

    getShiftBreaksByID(shift_id) {
        return this.http.get(this.baseUrl + 'api/OfficeClockings/Get_ShiftByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&shift_id=' + shift_id)
    }
    deleteShift(id) {
        return this.http.delete(this.baseUrl + 'api/OfficeClockings/Delete_EmployeeShift?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    deleteShiftBreak(id) {
        return this.http.delete(this.baseUrl + 'api/OfficeClockings/Delete_ShiftBreak?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    //license
    postLicense(data) {
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpLicense', data);

    }
    getLicenseById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpLicense?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    //consession passes
    postConssionPasses(data) {
        return this.http.post(this.baseUrl + 'api/Employees/Post_EmpPasses', data);

    }
    getConssionPassesById(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpLicense?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getContactList() {

        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyContacts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getAssessmentList(type, desc) {
        return this.http.get(this.baseUrl + 'api/Master/Get_AssesmentParticulars?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type + '&description=' + desc)
    }

    getAssessmentbyId(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_AssesmentParticularByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }

    postAssessment(data) {
        return this.http.post(this.baseUrl + 'api/Master/Postt_AssesmentParticular', data);
    }

    deleteAssessment(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_AssesmentParticular?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    getEmpAssesmentList(employeeid, fromdate, todate, status) {
        return this.http.get(this.baseUrl + 'api/HR/Get_EmployeeAssesmentIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid=' + employeeid + '&fromdate=' + fromdate + '&todate=' + todate + '&status=' + status);
    }
    getEmpAssessmentbyId(id) {
        return this.http.get(this.baseUrl + 'api/HR/Get_EmployeeAssesmentById?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    postEmpAssesment(data) {
        return this.http.post(this.baseUrl + 'api/HR/Postt_EmployeeAssesment', data);
    }
    deleteEmpAssessment(id) {
        return this.http.delete(this.baseUrl + 'api/HR/Delete_EmployeeAssesment?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postEntry(data) {
        return this.http.post(this.baseUrl + 'api/Master/Postt_AssesmentParticularEntry', data);
    }

    deleteEntryChoice(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_AssesmentParticularEntryChoice?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    deleteEntry(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_AssesmentParticularEntry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    deleteEmpBank(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpBanks?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))

    }
    deleteBadgesHistory(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_BadgesHistory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))

    }



    deleteEmpInsurance(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpInsurances?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    deleteEmpPensionHistry(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_PensionHistory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    deleteEmpWorkPermit(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_WorkPermit?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteEmpemergencyContact(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpemergencyContact?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteTrainingHistory(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_TrainingHistory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteEmpRelease(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpRelease?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteEmpRef(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpRef?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteEmpCosting(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpCosting?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteWorkHistory(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_WorkHistory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteFamilyHistory(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_FamilyHistory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteEmpPasses(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpPasses?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteEmpLeaves(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpLeaves?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteEduHistory(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EduHistory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteEmployee(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_Employee?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    DeleteEmpLincense(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpLincense?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    deleteEmpAward(id) {
        return this.http.delete(this.baseUrl + 'api/Employees/Delete_EmpAwards?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    getJobOpeningList(type) {
        return this.http.get(this.baseUrl + 'api/Recruitment/Get_JobOpeningIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type)
    }
    getJobOpeningById(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Recruitment/Gett_JobOpeningByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postJobOpening(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Recruitment/Postt_JobOpening', data);
    }
    deleteJobOpening(id) {
        return this.http.delete(this.baseUrl + 'api/Recruitment/Delete_JobOpening?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }

    getJobApplicationList(skillset, name, email, status) {
        return this.http.get(this.baseUrl + 'api/Recruitment/Get_JobAplicationIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&skillset=' + skillset + '&name=' + name + '&email=' + email + '&status=' + status)
    }
    getJobApplicationById(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Recruitment/Gett_JobAplicationByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postJobApplication(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Recruitment/Postt_JobApplication', data);
    }
    deleteJobApplication(id) {
        return this.http.delete(this.baseUrl + 'api/Recruitment/Delete_JobApplication?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    getJobOfferList(id) {
        return this.http.get(this.baseUrl + 'api/Recruitment/Gett_OffersByApplicant?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&applicant_id=' + id)
    }
    getJobOfferById(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Recruitment/Gett_JobOfferByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postJobOffer(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Recruitment/Postt_JobOffer', data);
    }
    deleteJobOffer(id) {
        return this.http.delete(this.baseUrl + 'api/Recruitment/Delete_JobOffer?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    getApplicantsByJobopening(jobid) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Recruitment/Gett_AppsByJob?jobid=' + jobid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getOfferByJobOpening(jobid) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Recruitment/Gett_OffersByJob?jobid=' + jobid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //Advnace loan request
    getAdvLoanList(searchtxt, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/HR/Get_EmpAdvanceIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate)
    }
    getAdvLoanById(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/HR/Gett_EmpAdvanceByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postAdvLoan(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/HR/Postt_EmpAdvance', data);
    }
    deleteAdvLoan(id) {
        return this.http.delete(this.baseUrl + 'api/HR/Delete_EmpAdvance?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }

    //Advnace loan request
    getLegalContractList(name) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyLegalContracts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name)
    }
    getLegalContractById(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyLegalContractByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postLegalContract(val) {
        const data = JSON.stringify(val)
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyLegalContracts', data);
    }
    // deleteLegalContract(id) {
    //     return this.http.delete(this.baseUrl + 'api/HR/Delete_EmpAdvance?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    // }
    updateEmployeeAssesmentStatus(id, status) {
        return this.http.get(this.baseUrl + 'api/HR/EmployeeAssesment_UpdateStatus?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&status=' + status);
    }
    submitParticular4Review(id, status) {
        return this.http.get(this.baseUrl + 'api/Employees/EmployeeAssesment_Submit4Review?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&status=' + status);
    }
    createOffer(id) {
        return this.http.get(this.baseUrl + 'api/Employees/EmployeeAssesment_Submit4Review?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&status=' + status);
    }
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}