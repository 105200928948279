import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class POSServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    userprofile: any;
    public projectDataForEdit: '';
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    getPOSItems(pricingtype, qtyonhand, deptid, categoryid, subcategoryid, searchtext) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/POS/GetPOSItems?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pricingtype=' + pricingtype + '&qtyonhand=' + qtyonhand + '&deptid=' + deptid + '&categoryid=' + categoryid + '&subcategoryid=' + subcategoryid + '&searchtxt=' + searchtext + '&saleenddate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd'));
    }

    getPOSRecentOrders(searchtxt, type, status, fdate, edate) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/POS/Get_RecentOrders?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fdate=' + fdate + '&edate=' + edate + '&type=' + type + '&status=' + status + '&searchtxt=' + searchtxt);
    }

    getPOSOrderByID(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/POS/Gett_POSOrderByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postPOSOrder(data) {
        return this.http.post(this.baseUrl + 'api/POS/Postt_POSOrder', data);
    }

    deletePOSOrderEntry(id) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.delete(this.baseUrl + 'api/POS/Delete_POSOrderLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getXReport() {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/POS/GetXReport?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&register_id=');
    }

    getZReport() {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/POS/GetZReport?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&register_id=');
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
