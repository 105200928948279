import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { categoryDB } from '../../../../../shared/tables/category';
import { InventoryServices } from '../../../inventory.service';
import { AddProductComponent } from '../add-product.component';

@Component({
  selector: 'app-substituteproduct',
  templateUrl: './substituteproduct.component.html',
  styleUrls: ['./substituteproduct.component.scss']
})
export class SubstituteproductComponent implements OnInit {
  public closeResult: string;
  public categories = []
  product: any;
  substituteItemList: any = [];
  substituteSearchItemList: any = [];
  supplierList: any = [];
  catList: any = [];
  subCatlist: any = [];
  binData: any = {}
  deptList: any = []
  subStitudeData: any = {
    supplierid: '',
    dept: '',
    cat: '',
    subcat: '',
    searchtxt: ''
  }
  singleItemData: any = {}
  userdata: any;
  appname = environment.APP_NAME;
  lastaction: any;
  fieldsetDisabled = false;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private commonService: CommonServices,
    private datepipe: DatePipe, private loader: NgxUiLoaderService,
    private service: InventoryServices,
    private toastr: NotificationService,
    private maincomponent: AddProductComponent) {
    this.categories = categoryDB.category;
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.product = maincomponent.product;
    this.lastaction = this.route.snapshot.queryParamMap.get('action');
    if (this.lastaction == 'view') {
      this.fieldsetDisabled = true;
    } else if (this.lastaction == 'edit') {
      this.fieldsetDisabled = false;
    }
    var isservice = false;
    this.service.getParentCat(isservice).subscribe(res => {
      this.deptList = res;
      environment.production ? '' : console.log('dept list list  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading dept list ', err);
    });


    this.service.getProductSupplier().subscribe(res => {
      this.supplierList = res;
      environment.production ? '' : console.log('supplierList list  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading supplierList data', err);
    });
    this.getProductSubstitute();
  }

  getSearSubstitudeItems() {
    this.loader.start();
    this.service.getSubtitudeCatlog(this.subStitudeData.supplierid, this.subStitudeData.dept, this.subStitudeData.cat, this.subStitudeData.subcat, this.subStitudeData.searchtxt).subscribe(res => {
      this.loader.stop();
      this.substituteSearchItemList = res;
      this.substituteSearchItemList.forEach(element => {
        element.landedcost = element.landedcost ? Number(element.landedcost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        element.price = element.price ? Number(element.price).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        element.pocost = element.pocost ? Number(element.pocost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      });
    }, err => {
      this.loader.stop();
    })
  }
  //#region Sorting Table Header
	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event,coloum) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr(coloum);
	}

	sortArr(colName: any) {
		this.substituteItemList.sort((a, b) => {
			let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
			let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

			if (!valueA && !valueB) {
				return 0;
			}
			if (!valueA) {
				return -this.sortDir;
			}
			if (!valueB) {
				return this.sortDir;
			}

			if (valueA > valueB) {
				return this.sortDir;
			}
			if (valueA < valueB) {
				return -this.sortDir;
			}
			return 0;
		});
	}
	//#endregion Sorting Table Header

  getProductSubstitute() {
    this.loader.start();
    this.service.getProductSubstitutes(this.product.id).subscribe(res => {
      this.loader.stop();
      this.substituteItemList = res;
      this.substituteItemList.forEach(element => {
        element.landedcost = Number(element.landedcost).toFixed(this.userdata.decimals_allowed) ? Number(element.landedcost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        element.price = Number(element.price).toFixed(this.userdata.decimals_allowed) ? Number(element.price).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
        element.pocost = Number(element.pocost).toFixed(this.userdata.decimals_allowed) ? Number(element.pocost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
      });
    }, err => {
      this.loader.stop();
    })
  }

  getCatUsingDept() {
    this.service.getProductCategory(this.subStitudeData.dept, false).subscribe(res => {
      this.catList = res;
      environment.production ? '' : console.log('catList list  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading catList data', err);
    });
  }
  getSubCatUsingCategory() {
    this.commonService.getSubCategory(this.subStitudeData.cat).subscribe(res => {
      this.subCatlist = res;
      environment.production ? '' : console.log('SubcatList list  ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading catList data', err);
    });
  }


  ngOnInit(): void {
  }
  postSingleItem(event, item) {
    environment.production ? '' : console.log('selected item', item);
    if (event.target.checked) {
      var data = {
        "createby": this.userdata.email,
        "companyid": this.userdata.companyid,
        "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
        "prodid": this.product.id,
        "sub_prodid": item.id
      }
      environment.production ? '' : console.log('post review data', data);
      this.loader.start();
      this.service.postSingleSubstitudeData(data).subscribe((res: any) => {
        this.loader.stop();
        if (res != null) {
          if (res.status_code == "1") {
            res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
          } else {
            res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
            return;
          }
          this.substituteItemList = res.data;
          this.substituteItemList.forEach(element => {
            element.landedcost = Number(element.landedcost).toFixed(this.userdata.decimals_allowed) ? Number(element.landedcost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
            element.price = Number(element.price).toFixed(this.userdata.decimals_allowed) ? Number(element.price).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
            element.pocost = Number(element.pocost).toFixed(this.userdata.decimals_allowed) ? Number(element.pocost).toFixed(this.userdata.decimals_allowed) : Number(0).toFixed(this.userdata.decimals_allowed);
          })
        }
      }, err => {
        this.loader.stop();
        environment.production ? '' : console.log('Error : While loading single item post ', err);
      });
    }
  }
  eventCheck(event) {
    if (event.target.checked) {

    }
  }
  closemodal() {
    this.substituteSearchItemList = [];
    this.subStitudeData = {
      supplierid: '',
      dept: '',
      cat: '',
      subcat: '',
      searchtxt: ''
    }
    this.modalService.dismissAll()
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  addItem() {

  }


}
