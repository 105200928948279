<resize-border [dragHolder]="header">
  <div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="productModalLabel">Item Analysis
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <div class="col-lg-5 input-group mb-2 SearchBox">
        <input type="text" class="form-control small" id="chat-search" name="searchValue"
          placeholder="Search by ILC, Customer Name" aria-label="Search" aria-describedby="basic-addon2"
          [(ngModel)]="searchtext" [ngModelOptions]="{standalone: true}">
      </div>
      <div class="col-lg-3 col-md-6 form-group">
        <mat-form-field>
          <input matInput [matDatepicker]="picker6" [(ngModel)]="fromdate" name="fromdate"
            placeholder="Select fromdate">
          <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
          <mat-datepicker #picker6></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-lg-3 col-md-6 form-group">
        <mat-form-field>
          <input matInput [matDatepicker]="picker5" [(ngModel)]="todate" name="todate" placeholder="Select todate">
          <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
          <mat-datepicker #picker5></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-lg-1 d-flex form-group">
        <button type="button" container="body" ngbTooltip="Search"
          class="ml-2 btn btn-primary right-radius left-radius flexy-button" (click)="getItemAnylsisList()"><i
            class="refresh-btn fa fa-search"></i></button>
        <button type="button" container="body" ngbTooltip="Refresh"
          class="ml-2 btn btn-primary right-radius left-radius flexy-button" (click)="refresh()"><i
            class="refresh-btn fa fa-refresh"></i></button>
      </div>
    </div>
    <div class="category-table custom-datatablemb-3 productitemanalysistable">
      <div class="table-responsive2">
        <table class="custom-table">
          <tr class="custom-tr">
            <th class="custom-th text-center">TxnID</th>
            <th class="custom-th text-center">TxnDate</th>
            <th class="custom-th text-center">From Location</th>
            <th class="custom-th text-center">To Location</th>
            <th class="custom-th text-center">TxnQty</th>
            <!-- <th class="custom-th text-center">Balance</th> -->
            <th class="custom-th text-center">UOM</th>
            <th class="custom-th text-center">PO Cost</th>
            <th class="custom-th text-center">AVG Cost(Weighted)</th>
            <th class="custom-th text-center">AVG Cost(FIFO)</th>
            <th class="custom-th text-center">Price</th>
            <th class="custom-th text-center">TxnType</th>
            <th class="custom-th text-center">Serial</th>
            <th class="custom-th text-center">TxnBy</th>
            <th class="custom-th text-center">Customer</th>
            <th class="custom-th text-center">Receipt</th>
            <th class="custom-th text-center">Refno#</th>
            <th class="custom-th text-center">Ref Details</th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of itemDataList">
            <td class="custom-td text-left">{{item.id}}</td>
            <td class="custom-td text-center">{{item.trandate| date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.from_bin_location}}</td>
            <td class="custom-td text-left">{{item.to_bin_location}}</td>
            <td class="custom-td text-right">{{item.txnqty}}</td>
            <!-- <td class="custom-td text-right">{{item.balance}}</td> -->
            <td class="custom-td text-center">{{item.uom}}</td>
            <td class="custom-td text-right">{{item.pocost}}</td>
            <td class="custom-td text-right">{{item.avgcost_weighted}}</td>
            <td class="custom-td text-right">{{item.avgcost_fifo}}</td>
            <td class="custom-td text-right">{{item.saleprice}}</td>
            <td class="custom-td text-left">{{item.trantype}}</td>
            <td class="custom-td text-left">{{item.serial}}</td>
            <td class="custom-td text-left">{{item.tranby}}</td>
            <td class="custom-td text-left">{{item.customer}}</td>
            <td class="custom-td text-left">{{item.receipt}}</td>
            <td class="custom-td text-left">{{item.refno}}</td>
            <td class="custom-td text-left">{{item.ref_details}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
  </div>
</resize-border>
<!-- <ngx-ui-loader></ngx-ui-loader> -->
