

import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountToModule } from 'angular-count-to';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPrintModule } from 'ngx-print';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxDropFileImageModule } from 'ngx-drop-file-image';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { PosRoutingModule } from './pos-routing.module';
import { PosComponent } from './pos.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OpeningbalanceComponent } from './openingbalance/openingbalance.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ResizeModule } from '../modal/resize/resize-border.module';
import { CustomerListModule } from '../servicemanager/servicecontracts/customerlist/customerlist.module';
import { ProjjoblistModule } from '../modal/projjoblistmodal/projjoblistmodal.module';
@NgModule({
  declarations: [PosComponent, OpeningbalanceComponent],
  imports: [
    CommonModule,
    PosRoutingModule,
    CountToModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    Ng2SmartTableModule,
    NgbModule,
    
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    NgxUiLoaderModule,
    CKEditorModule,
    NgxDropFileImageModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    AutocompleteLibModule,
    MatAutocompleteModule,
    CarouselModule,
    ResizeModule,
    CustomerListModule,
    ProjjoblistModule,
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    NgbActiveModal
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class PosModule { }
