import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { purchaseServices } from '../../purchase/purchase.service';
import { DatePipe } from '@angular/common';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-purchaserequest',
  templateUrl: './purchaserequest.component.html',
  styleUrls: ['./purchaserequest.component.scss']
})
export class PurchaserequestComponent implements OnInit {
  fullScreen = false;
  appname = environment.APP_NAME;
  purchaseRequest: any = {
    searchtxt: '',
    fromdate: '',
    todate: '',
    status: '',
    type: ''
  }
  supplierListData: any = [];
  purchaseRequestData: any;
  userdata: any;
  purchaseData: any;
  constructor(
    private loader: NgxUiLoaderService, private activeModal: NgbActiveModal, private commonService: CommonServices, private service: purchaseServices, private datepipe: DatePipe
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    this.purchaseRequest.fromdate = this.userdata.fromdate;
    this.purchaseRequest.enddate = this.userdata.enddate;
    environment.production ? '' : console.log('from date', this.purchaseRequest.fromdate);
    environment.production ? '' : console.log('end date', this.purchaseRequest.enddate);

    this.getpurchaseData();
  }

  getpurchaseData() {
    this.loader.start();
    this.service.getPurchaseRequest(this.purchaseRequest.type,this.purchaseRequest.status, this.datepipe.transform(this.purchaseRequest.fromdate, this.userdata.postdateformat),
      this.datepipe.transform(this.purchaseRequest.enddate, this.userdata.postdateformat),this.purchaseRequest.searchtxt).subscribe(res => {
      environment.production ? '' : console.log("purchase req", res);
      this.loader.stop();
      this.purchaseData = res;
      this.purchaseData.forEach(item => {
        item.ischecked = false;
      })
    })
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  ngOnInit(): void {
  }

}
