import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
    private languageSubject = new BehaviorSubject<string>(localStorage.getItem('language') || 'en');

    constructor(private translate: TranslateService) {
      this.languageSubject.subscribe((lang) => {
        this.translate.use(lang);
      });
    }
  
    changeLanguage(language: string) {
      this.languageSubject.next(language);
      localStorage.setItem('language', language);
    }
  
    getCurrentLanguage() {
      return this.languageSubject.asObservable();
    }
}