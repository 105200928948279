import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';

@Component({
  selector: 'app-serials',
  templateUrl: './serials.component.html',
  styleUrls: ['./serials.component.scss']
})
export class SerialsListComponent implements OnInit {
  @ViewChild('itemdata') itemdata;
  serialList: any = []
  filteredItems: any = []
  searchText: any;
  userdata: any;
  appName = environment.APP_NAME;
  serialStatus: any = [];
  filter: any = {
    prodid: '',
    bincode: '',
    serialno: '',
    status: ''
  }
  constructor(private activeModal: NgbActiveModal, private commonService: CommonServices, private service: InventoryServices, private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appName + 'userprofile'));
    commonService.getComboEnums('serialnostatus').subscribe(res => {
      this.serialStatus = res;
    })
  }
  ngOnInit(): void {
    this.filter = this.itemdata;
    this.getProductSerials();
  }
  onSearchChange() {
    this.filterItems();
  }

  getProductSerials() {
    this.loader.start();
    this.service.getProductSerials(this.filter.prodid, this.filter.serialno ? this.filter.serialno : '', this.filter.bincode ? this.filter.bincode : '', this.filter.status ? this.filter.status : '').subscribe(res => {
      this.loader.stop();
      this.serialList = res;
      this.filteredItems = this.serialList
    }, err => {
      this.loader.stop();
    })
  }
  //#region Sorting Table Header
  sortDir = 1;//1= 'ASE' -1= DSC
  onSortClick(event,colum) {
    let target = event.currentTarget,
      childtarget = target.querySelector('i'),
      classList = childtarget.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      target.classList.add('Sorted');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      target.classList.remove('Sorted');
      this.sortDir = 1;
    }
    this.sortArr(colum);
  }
  sortArr(colName: any) {
    this.filteredItems.sort((a, b) => {
        let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
        let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];
  
        if (!valueA && !valueB) {
          return 0;
        }
        if (!valueA) {
          return -this.sortDir;
        }
        if (!valueB) {
          return this.sortDir;
        }
  
        if (valueA > valueB) {
          return this.sortDir;
        }
        if (valueA < valueB) {
          return -this.sortDir;
        }
        return 0;
      });
  }
  //#endregion Sorting Table Heade

  filterItems() {
    this.filteredItems = this.serialList.filter(item => item.serial.toLowerCase().includes(this.searchText.toLowerCase()));
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
}