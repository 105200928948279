import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { assetServices } from '../../assets/asset.service';
import { AddassetsComponent } from '../../assets/assets/addassets/addassets.component';
import * as moment from 'moment';
import { hrServices } from '../../hr/hr.services';

@Component({
  selector: 'app-assetassignment',
  templateUrl: './assetassignment.component.html',
  styleUrls: ['./assetassignment.component.scss']
})
export class AssetassignmentComponent implements OnInit {
  fullScreen = false;
  @Input('lastaction') lastaction;
  @Input('id') id;
  @ViewChild('Location') Location;
  pageTitel: any;
  appname = environment.APP_NAME;
  userdata: any;
  asset: any = {
    locationid: '',
    assignto: '',
    duedate: '',
    condition: '',
  }
  assignToList: any = []
  locationList: any = [];
  conditionList: any = [];

  keywordLocationSearch: any = 'Text';
  editSetLocation: any;
  location_validation: any;

  totalLeaves: any;
  isSingleClicked: boolean = true;
  legalTypeList: any = []
  constructor(private loader: NgxUiLoaderService,

    private datepipe: DatePipe,
    private toastr: NotificationService,
    private commonservice: CommonServices,
    private service: assetServices,
    private hrservice: hrServices,
    private modalService: NgbModal) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));

    this.service.getAssetLocation().subscribe(res => {
      this.locationList = res;
      environment.production ? '' : console.log('locationList list data ', this.locationList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading locationList list ', err);
    });
    this.commonservice.getAssignTo().subscribe(res => {
      this.assignToList = res;
      environment.production ? '' : console.log('assignto list', this.assignToList);
      environment.production ? '' : console.log(' bug newassignToList list data ', this.assignToList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading newassignToList list ', err);
    });
    this.hrservice.getContractLegalType().subscribe(res => {
      this.legalTypeList = res;
      environment.production ? '' : console.log(' payment method list data ', this.legalTypeList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading status list ', err);
    });
    this.commonservice.getComboEnums('assetconditions').subscribe(res => {
      this.conditionList = res;
      environment.production ? '' : console.log('conditionList list data ', this.conditionList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading conditionList list ', err);
    });
  }
  //====== Location dropodown
  selectLocation(item) {
    this.asset.locationid = item.Value;
  }
  inputLocationCleared() {
    this.asset.locationid = null;
  }
  onFocusedLocation(item) {
    this.Location ? this.Location.clear() : '';
  }
  onAnySearchChange(item) {

  }
  setLocation() {
    if (this.asset.locationid != undefined && this.asset.locationid != null) {
      this.locationList.forEach(element => {
        if (element.Value == this.asset.locationid) {
          this.editSetLocation = element;
        }
      });
    }
  }
  //====== Location dropodown


  checkDate() {
    var startDate = this.datepipe.transform(new Date(), ('dd/MM/yyyy'));
    var endDate = this.datepipe.transform(this.asset.duedate, ('dd/MM/yyyy'));
    var regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
    if (parseInt(endDate.replace(regExp, "$3$2$1")) >= parseInt(startDate.replace(regExp, "$3$2$1"))) {
      environment.production ? '' : console.log('start date is greater than end date');
    } else {
      this.asset.duedate = null
      this.toastr.Error("Due date should be greater than current date")
      return false
    }
  }
  ngOnInit(): void {
  }
  closeModal() {
    this.modalService.dismissAll()
  }
  postAssignment() {
    var obj = {
      "assid": this.id,
      "assignto": this.asset.assignto,
      "locationid": this.asset.locationid,
      "doa": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "actionby": this.userdata.email,
      // "contractid": 1,
      "duedate": this.datepipe.transform(this.asset.duedate, this.userdata.postdateformat),
      "notes": this.asset.notes,
      "lastaction": this.lastaction,
      "companyid": this.userdata.companyid,
      "condition": this.asset.condition,
      "createby": this.userdata.email,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdateformat),
    }
    this.loader.start();
    this.service.postAssetAssignment(obj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isSingleClicked = true;
        this.modalService.dismissAll(res);
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error occurred while post asset assignment', err);
    })
  }

}
