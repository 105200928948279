<div class="row">
  <div class="col-lg-6 col-md-6 col-12 form-group"></div>
  <div class="col-lg-1 col-md-1 col-12 form-group">
    <label class="form-group">For Sale</label>
  </div>
  <div class="col-lg-2 col-md-2 col-12 form-group">
    <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
      <label class="d-block" for="">
        <input class="radio_animated" id="" type="radio" [value]="true" name="forsale" [(ngModel)]="forsale"
          (change)="getProductBins()">
        Yes
      </label>
      <label class="d-block" for="">
        <input class="radio_animated" id="" type="radio" [value]="false" name="forsale" [(ngModel)]="forsale"
          (change)="getProductBins()">
        No
      </label>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-12 form-group">
    <input type="search" class="form-control placehder" name="searchText" placeholder="&#xF002; Search"
      [(ngModel)]="searchText">
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="category-table custom-datatable productaddprocutbintable">
      <div class="table-responsive">
        <table class="custom-table">
          <tr class="custom-tr">
            <th style="width: 5%;" class="custom-th text-center"> <a class="add-table-button cursor-pointer"
                data-toggle="modal" data-original-title="Add" container="body" ngbTooltip="Add Bin Location"
                data-target="#exampleModal" [class.disabled]="fieldsetDisabled" (click)="openProductBin('','add')"><i
                  class="fa fa-plus"></i>
              </a></th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Code <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              BinID <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Name <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              OnHand <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              OnHold <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Avail <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <!-- <th class="custom-th text-center" (click)="onSortClick($event)">
              Sold <i class="Sorting fa fa-chevron-up"></i>
            </th> -->
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Min <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Max <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Reorder Point <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Reorder Qty <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              Notes <i class="Sorting fa fa-chevron-up"></i>
            </th>

            <th class="custom-th text-center" (click)="onSortClick($event)">
              Active <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              CreateBy <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              CreateDate <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              UpdateBy <i class="Sorting fa fa-chevron-up"></i>
            </th>
            <th class="custom-th text-center" (click)="onSortClick($event)">
              UpdateDate <i class="Sorting fa fa-chevron-up"></i>
            </th>
          </tr>
          <tr class="custom-tr" *ngFor="let item of binLocationList | binLocatFilter: searchText">
            <td class="custom-td text-center">
              <div ngbDropdown class="d-inline-block ActionCell" container="body">
                <i class="fa fa-th-list" id="dropdownBasic1" ngbDropdownToggle></i>
                <ul ngbDropdownMenu class="ActionCell-List" aria-labelledby="dropdownBasic1">
                  <li><span ngbDropdownItem (click)="openProductBin(item.id,'view')">View</span>
                  <li><span ngbDropdownItem [class.disabled]="fieldsetDisabled"
                      (click)="openProductBin(item.id,'edit')">Edit</span>
                  </li>
                  <li><span ngbDropdownItem (click)="adjustQty(item)">Adjust</span></li>
                  <li><span ngbDropdownItem (click)="changeForsale(item)">Update forsale</span></li>
                  <li><span ngbDropdownItem (click)="deleteProdBin(item)" [class.disabled]="fieldsetDisabled">Delete</span></li>
                </ul>
              </div>
            </td>
            <td class="custom-td text-left">{{item.code}}</td>
            <td class="custom-td text-left">{{item.binid}}</td>
            <td class="custom-td text-left">{{item.description}}</td>
            <td class="custom-td text-center">{{item.cnt}}</td>
            <td class="custom-td text-center">{{item.onhold}}</td>
            <td class="custom-td text-center">{{item.availableqty}}</td>
            <!-- <td class="custom-td text-center">{{item.sold}}</td> -->
            <td class="custom-td text-center">{{item.minqty}}</td>
            <td class="custom-td text-center">{{item.maxqty}}</td>
            <td class="custom-td text-center">{{item.reorderpoint}}</td>
            <td class="custom-td text-center">{{item.reorderqty}}</td>
            <td class="custom-td text-left"> <span innerHTML="{{item.notes}}"></span></td>
            <td class="custom-td text-center"><span class="badge" title="{{item.active}}">{{item.active}}</span></td>
            <td class="custom-td text-left">{{item.createby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.createdate |
              date:userdata.displaydatetimeformat}}</td>
            <td class="custom-td text-left">{{item.updateby}}</td>
            <td class="custom-td text-left text-nowrap">{{item.updatedate |
              date:userdata.displaydatetimeformat}}</td>
          </tr>
          <tr class="custom-tr">
            <th class="custom-td text-right" colspan="4">
              Total
            </th>
            <th class="custom-td text-center">
              {{totalOnhandQty}}
            </th>
            <th class="custom-td text-center">
              {{totalOnHold}}
            </th>
            <th class="custom-td text-center">
              {{totalAvail}}
            </th>
            <th class="custom-td text-center" colspan="6">
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>