import { I } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { purchaseServices } from '../../purchase/purchase.service';
import { ReceiveserilizeditemsComponent } from '../receiveserilizeditems/receiveserilizeditems.component';
import { element } from 'screenfull';

@Component({
  selector: 'app-receivepo',
  templateUrl: './receivepo.component.html',
  styleUrls: ['./receivepo.component.scss']
})
export class ReceivepoComponent implements OnInit {
  fullScreen = false;
  buttonclicked = false;
  @Input('poid') poid;
  @Input('prodid') prodid;
  binlocation: any;
  poDetails: any = {
    lstItems: [{
      itemBins: [{
        code: ''
      }]
    }],
    status: '',
    msg: ''
  };
  keyword2 = 'Value';
  poStatusList: any = [];
  postatus: any;
  appname = environment.APP_NAME;
  userdata: any;
  binLocationList: any;
  constructor(private activeModal: NgbActiveModal,
    private toastr: NotificationService,
    private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: purchaseServices, private commonService: CommonServices, private modalService: NgbModal) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    this.getBINLocations();
    this.getPOStatus();
    this.getPODetails();
  }
  selectBin(item) {
    this.binLocationList.forEach(element => {
      if (element.Value == item.newbin) {
        item.newbincode = element.Text;
      }
    })
  }
  getBINLocations() {
    this.commonService.getBinLocation().subscribe(res => {
      this.binLocationList = res;
      environment.production ? '' : console.log('binlocation data ', res);
    }, err => {
      environment.production ? '' : console.log('Error : While loading binlocation data', err);
    });
  }
  getPOStatus() {
    this.commonService.getComboEnums('postatus').subscribe(res => {
      this.poStatusList = res;
      environment.production ? '' : console.log('PO Status List', this.poStatusList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  getPODetails() {
    this.loader.start();
    this.service.getReceivePO(this.poid, this.prodid?this.prodid:'').subscribe(res => {
      this.loader.stop();
      this.poDetails = res;
      if (this.poDetails.lstItems.length > 0) {
        this.poDetails.lstItems.forEach(po => {
          po.newbin = null;
        })
      }
      environment.production ? '' : console.log(' supplier po data ', this.poDetails);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }
  checkReceivedTotal(item, lineitem) {
    var receivedcnt = 0;
    if (item.itemBins.length > 0) {
      item.itemBins.forEach(element => {
        receivedcnt += Number(element.receivedcnt);
      })
    }
    if (item.newbin && item.newbincnt) {
      receivedcnt += item.newbincnt;
    }
    if (receivedcnt > (item.qty - item.receivedqty)) {
      this.toastr.Error("Receive quantity shouldn't be greater than the order quantity or pending quantity");
      setTimeout(() => {
        lineitem.receivedcnt = 0;

      }, 100);
    }

    if(item.newbincnt > (item.qty - item.receivedqty)) {
      setTimeout(() => {
        item.newbincnt = 0;
      }, 100);
    }
    
  }
  postReceivePO() {
    var lstItems = [];
    if (this.poDetails != null) {
      if (this.poDetails.lstItems != null && this.poDetails.lstItems != undefined) {
        this.poDetails.lstItems.forEach(item => {
          if (item.itemBins != null && item.itemBins != undefined) {
            var binLoc = [];
            item.itemBins.forEach(bin => {
              if (bin.receivedcnt > 0) {
                binLoc.push({
                  binid: bin.binid,
                  code: bin.code,
                  name: bin.name,
                  receivedcnt: bin.receivedcnt
                })
              }
            })
            if (item.newbin != undefined && item.newbin != null && item.newbincnt != undefined && item.newbincnt != null && item.newbincnt > 0) {
              var newBin;
              this.binLocationList.forEach(bin => {
                if (bin.Value == item.newbin) {
                  newBin = bin;
                }
              })
              binLoc.push({
                binid: parseFloat(item.newbin),
                code: newBin.Value1,
                name: newBin.Text,
                receivedcnt: item.newbincnt
              })
            }
            if (binLoc.length == 0) {
              return;
            }
            item.itemBins = binLoc;
            lstItems.push(item);
          }
        })
      }
    }

    if (lstItems.length == 0) {

      this.toastr.Error('Please enter received qty')
      return;
    }

    var postObj = {
      "poid": this.poDetails.poid,
      "pon": this.poDetails.pon,
      "shippingfees": 0,
      "adjustment": 0,
      "msg": this.poDetails.msg,
      "status": this.poDetails.status,
      "companyid": this.userdata.companyid,
      "createby": this.userdata.email,
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "lstItems": lstItems
    }
    console.log('check received qty', lstItems);

    this.loader.start();
    this.service.postReceivePO(postObj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
    // window.location.reload();
  }

  onSortClick(event) {

  }

  selectEvent($event, item) {
    item.newbin = $event.Value;
    this.binLocationList.forEach(element => {
      if (element.Value == item.newbin) {
        item.newbincode = element.Text;
      }
    })
    item.newbin
  }

  serilizepo(item) {
    var modalRef = this.modalService.open(ReceiveserilizeditemsComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.po = this.poDetails;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
      this.getPODetails();
    }).catch((error) => {
      environment.production ? '' : console.log(error);
      this.getPODetails();
    });
  }

}
