import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SerialComponent } from '../../modal/serial/serial.component';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';
import { AddProductComponent } from '../add-product.component';
import { CommonServices } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-serial',
  templateUrl: './serials.component.html',
  styleUrls: ['./serials.component.scss']
})
export class SerialsComponent implements OnInit {
  filter: any = {
    bincode: '',
    serialno: '',
    status: '',
  }
  public closeResult: string;
  public categories = []
  product: any;
  serialList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  serialStatus: any = [];
  status: string;
  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private datepipe: DatePipe,
    private loader: NgxUiLoaderService,
    private service: InventoryServices,
    private maincomponent: AddProductComponent,
    private commonService: CommonServices
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.product = maincomponent.product;
    commonService.getComboEnums('serialnostatus').subscribe(res => {
      this.serialStatus = res;
      console.log('statuslist', res);
    })
    this.getProductSerials();
  }
  ngOnInit(): void {

  }

  getProductSerials() {
    this.loader.start();
    this.status = this.filter.status.toString()
    this.service.getProductSerials(this.product.id, this.filter.serialno ? this.filter.serialno : '', this.filter.bincode ? this.filter.bincode : '', this.status ? this.status : '').subscribe(res => {
      this.loader.stop();
      this.serialList = res;
    }, err => {
      this.loader.stop();
    })
  }


  open(item, action) {
    var modalRef = null;
    modalRef = this.modalService.open(SerialComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
    modalRef.componentInstance.fromParent = this.product;
    modalRef.componentInstance.editData = item;
    modalRef.componentInstance.action = action;
    environment.production ? '' : console.log('id', modalRef.componentInstance.id);
    modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.getProductSerials();
      environment.production ? '' : console.log('closed');
    }, (reason) => {

    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public settings = {
    actions: {
      position: 'left',
    },
    columns: {
      serialno: {
        title: 'Serial No.',
      },
      lastupdated: {
        title: 'Last Updated',
        width: '30%',
      },
      updatedby: {
        title: 'Updated By',
      },
      islocal: {
        title: 'Status',
        type: 'html',
      },
    },
  };
}
