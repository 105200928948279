<resize-border [dragHolder]="header">
    <div class="whole-modal" [class.full-screen]="fullScreen">
        <div #header class="modal-header">
            <h5 class="modal-title f-w-600" id="productModalLabel">Customer List
            </h5>
            <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
                <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
                <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
            </button>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form (keydown.enter)="getCustomers()">
                <div class="form-row">
                    <div class="col-lg-3 col-md-6 form-group">
                        <input type="text" class="form-control" name="customername" placeholder="Customer"
                            [(ngModel)]="customer.name">
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <input type="text" class="form-control" name="email" placeholder="Email"
                            [(ngModel)]="customer.email">
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <input type="number" class="form-control" name="primaryphone" placeholder="Phone"
                            [(ngModel)]="customer.primaryphone">
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <button class="btn btn-primary" (click)="getCustomers()">Show</button>
                        <button class="btn btn-primary ml-2" (click)="addCustomer()">Add Customer</button>
                    </div>
                </div>
            </form>
            <div class="table-responsive2">
                <table class="custom-table">
                    <thead>
                        <tr class="custom-tr">
                            <th class="custom-th text-center">Code</th>
                            <th class="custom-th text-center">Name</th>
                            <th class="custom-th text-center">ShortName</th>
                            <th class="custom-th text-center">TPAcc#</th>
                            <th class="custom-th text-center">Auth Required?</th>
                            <th class="custom-th text-center">Phone</th>
                            <th class="custom-th text-center">Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="custom-tr" *ngFor="let item of customerList">
                            <td class="custom-td text-center"><span class="hyperlink"
                                    (click)="selectCustomer(item)">{{item.accountno}}</span></td>
                            <td class="custom-td text-left"><span class="hyperlink"
                                    (click)="selectCustomer(item)">{{item.name}}</span></td>
                            <td class="custom-td text-left"><span class="hyperlink"
                                    (click)="selectCustomer(item)">{{item.shortname}}</span>
                            </td>
                            <td class="custom-td text-left"><span class="hyperlink"
                                    (click)="selectCustomer(item)">{{item.tpaccno}}</span></td>
                            <td class="custom-td text-center"><span *ngIf="item.authorizationreq">Yes</span><span
                                    *ngIf="!item.authorizationreq">No</span></td>
                            <td class="custom-td text-left">{{item.primaryphone}}</td>
                            <td class="custom-td text-left">{{item.mainemail}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
                (click)="closeModal()">Close</button>
        </div>
    </div>
</resize-border>