import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { element } from 'screenfull';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dsccode',
  templateUrl: './dsccode.component.html',
  styleUrls: ['./dsccode.component.scss']
})
export class DSCcodeComponent implements OnInit {
  fullScreen = false;
  @Input('shippingagent') shippingagent;
  @Input('vessel') vessel;

  userdata: any;
  appname = environment.APP_NAME;
  // printURL: any;
  itemList: any;
  modalTitle: any;
  master: any = {
    id: null,
    code: '',
    description: '',
    createby: null,
    createdate: null,
    updateby: null,
    updatedate: null,
    active: true
  }
  showtitle: boolean;

  constructor(private cdRef: ChangeDetectorRef, private toastr: NotificationService, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: CommonServices,) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  ngOnInit(): void {
    if (this.shippingagent == 'shippingagent') {
      this.modalTitle = 'Shipping Agent';
      this.getShippingAgentIndexValues();
    }
    else if (this.vessel == 'vessel') {
      this.modalTitle = 'Vessels';
      this.getVesselsIndexValues();
    }
    else if (this.vessel == 'terms') {
      this.modalTitle = 'Terms';
      this.getTermsIndexValues();
    }
    // console.log('log for title', this.term);

    this.showtitle = true;
    this.cdRef.detectChanges();

  }
  initializeValues() {
    this.master = {
      id: null,
      code: '',
      description: '',
      createby: null,
      createdate: null,
      updateby: null,
    };
  }
  editMasterData(item) {
    this.master = item;
  }
  deleteMasterData(item) {
    if (this.shippingagent == 'shippingagent') {
      this.deleteShippingAgent(item);
    }
    else if (this.vessel == 'vessel') {
      this.deleteVessels(item);
    }
  }
  validatePostValues() {
    if (this.master.description == undefined || this.master.description == '') {
      Swal.fire('', 'Please enter name', 'warning');
      return;
    }
    if (this.master.code == undefined || this.master.code == '') {
      Swal.fire('', 'Please enter code', 'warning');
      return;
    }
    if (this.master.id == undefined || this.master.id == null) {
      this.master.companyid = this.userdata.companyid;
      this.master.createby = this.userdata.email;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.master.updateby = this.userdata.email;
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
    if (this.shippingagent == 'shippingagent') {
      this.postShippingAgent();
    }
    else if (this.vessel == 'vessel') {
      this.postVessels();
    }
    else if (this.vessel == 'terms') {
      this.postTerms();
    }
  }

  // ***** shipping agent begin ***********
  getShippingAgentIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getShippingAgentIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
      this.itemList.forEach(element => {
        element.code = element.CODE;
        element.description = element.NAME
      })
      environment.production ? '' : console.log('shipping agent', this.itemList)
    }, err => {
      this.loader.stop();
    })
  }
  postShippingAgent() {
    var postobj = {
      id: this.master.id,
      CODE: this.master.code,
      NAME: this.master.description,
      companyid: this.userdata.companyid,
      createby: this.master.id ? this.master.createby : this.userdata.email,
      createdate: this.master.id ? this.master.createdate : this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      updateby: this.master.id ? this.userdata.email : null,
      updatedate: this.master.id ? this.datepipe.transform(new Date(), this.userdata.postdatetimeformat) : null

    }
    this.loader.start();
    this.service.postShippingAgent(postobj).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList = res.data;
        this.itemList.forEach(element => {
          element.code = element.CODE;
          element.description = element.NAME
        })
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteShippingAgent(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteShippingAgent(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getShippingAgentIndexValues();
          } else {
            this.toastr.Error('Record not deleted');
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete shipping agent');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** shipping agent end ***********

  // ***** Vessels begin ***********
  getVesselsIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getVesselsIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postVessels() {
    this.loader.start();
    this.service.postVessels(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList = res.data;
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteVessels(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteVessels(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getVesselsIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete Vessels');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Vessels end ***********
  //*****Terms start code  ********/
  getTermsIndexValues() {
    this.itemList = [];
    this.loader.start();
    this.service.getTermsIndex().subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postTerms() {
    this.loader.start();
    this.master.active = 1;
    this.service.postDeliveryTerms(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.initializeValues();
        this.itemList = res.data;
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteTerms(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteVessels(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.initializeValues();
            this.getTermsIndexValues();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete Vessels');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ******terms code end ******

}
