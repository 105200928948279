import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { environment } from 'src/environments/environment';
import { BinholddetailsComponent } from '../binholddetails/binholddetails.component';
import { InventoryServices } from '../../../inventory.service';
import { CommonServices } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-serials',
  templateUrl: './serials.component.html',
  styleUrls: ['./serials.component.scss']
})
export class SerialsListComponent implements OnInit {
  @ViewChild('itemdata') itemdata;
  serialList: any = []
  filteredItems: any = []
  searchText: any;
  userdata: any;
  appName = environment.APP_NAME;
  serialStatus: any = [];
  filter: any = {
    prodid: '',
    bincode: '',
    serialno: '',
    status: ''
  }
  constructor(private activeModal: NgbActiveModal, private commonService: CommonServices, private service: InventoryServices, private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appName + 'userprofile'));
    commonService.getComboEnums('serialnostatus').subscribe(res => {
      this.serialStatus = res;
    })
  }
  ngOnInit(): void {
    this.filter = this.itemdata;
    this.getProductSerials();
  }
  onSearchChange() {
    this.filterItems();
  }

  getProductSerials() {
    this.loader.start();
    this.service.getProductSerials(this.filter.prodid, this.filter.serialno ? this.filter.serialno : '', this.filter.bincode ? this.filter.bincode : '', this.filter.status ? this.filter.status : '').subscribe(res => {
      this.loader.stop();
      this.serialList = res;
      this.filteredItems = this.serialList
    }, err => {
      this.loader.stop();
    })
  }

  filterItems() {
    this.filteredItems = this.serialList.filter(item => item.serial.toLowerCase().includes(this.searchText.toLowerCase()));
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
}