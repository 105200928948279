import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-deviceinventory',
  templateUrl: './deviceinventory.component.html',
  styleUrls: ['./deviceinventory.component.scss']
})
export class DeviceInventoryComponent implements OnInit {
  fullScreen = false;
  @ViewChild('Product') Product;
  @Input('device') device;
  userdata: any;
  appname = environment.APP_NAME;
  // printURL: any;
  itemList: any;
  modalTitle: any;
  productList: any = [];
  master: any = {
    "id": null,
    "prodid": null,
    "ilc": "",
    "description": "",
    "createby": "",
    "createdate": "",
    "deviceid": null
  }
  showtitle: boolean;
  countryList: any = [];
  deviceDetails: any;
  filter: any = {
    categoryid: null,
    supplierid: '',
    subcategoryid: null,
    deptid: null,
    productsearchby: 0,
    pricingtype: 'retail',
    ptype: 'inventory',
  }
  subCategoryList: any = [];
  categoryList: any = [];
  searchitem: any;
  deptList: any = [];
  keyword2 = 'ilc';
  isSaved = false;
  constructor(private cdRef: ChangeDetectorRef,
    private toastr: NotificationService,
    private commonService: CommonServices,
    private activeModal: NgbActiveModal,
    private loader: NgxUiLoaderService,
    private datepipe: DatePipe,
    private service: CommonServices,) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.getDepartmentList();
  }
  closeModal() {
    this.activeModal.close(this.isSaved ? 'save' : 'Modal Closed');
  }
  getDepartmentList() {
    this.commonService.getDepartmentList(false).subscribe(res => {
      this.deptList = res
    }, err => {
      this.loader.stop();
    });
  }
  ngOnInit(): void {
    if (this.device) {
      this.deviceDetails = this.device;
    }
    this.getDeviceMaterials();
    this.showtitle = true;
    this.cdRef.detectChanges();
  }
  initializeValues() {
    this.master = {
      "id": null,
      "prodid": null,
      "ilc": "",
      "description": "",
      "createby": "",
      "createdate": "",
      "deviceid": null
    };
  }
  editMasterData(item) {
    this.master = item;
  }
  validatePostValues() {
    if (this.master.name == undefined || this.master.name == '') {
      Swal.fire('', 'Please enter description', 'warning');
      return;
    }
    if (this.master.id == undefined || this.master.id == null) {
      this.master.companyid = this.userdata.companyid;
      this.master.createby = this.userdata.email;
      this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    } else {
      this.master.updateby = this.userdata.email;
      this.master.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    }
  }

  selectCategory() {
    this.filter.subcategoryid = null;
    this.filter.supplierid = '';
    this.searchitem = '';
    this.commonService.getSubCategory(this.filter.categoryid).subscribe(res => {
      this.subCategoryList = res
      this.searchProduct();
    }, err => {
      this.loader.stop();
    });
  }
  selectDept() {
    this.filter.categoryid = null;
    this.filter.subcategoryid = null;
    this.filter.supplierid = null;
    this.commonService.getCategory(this.filter.deptid, false).subscribe(res => {
      this.categoryList = res
      this.searchProduct();
    }, err => {
      this.loader.stop();
    });
  }
  // ***** Device Inventory begin ***********
  getDeviceMaterials() {
    this.itemList = [];
    this.loader.start();
    this.service.getDeviceMaterialsByDeviceID(this.deviceDetails.id).subscribe(res => {
      this.loader.stop();
      this.itemList = res;
    }, err => {
      this.loader.stop();
    })
  }
  postDeviceMaterials() {
    this.master.companyid = this.userdata.companyid;
    this.master.createby = this.userdata.email;
    this.master.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
    this.master.deviceid = this.device.id
    this.loader.start();
    this.service.postDeviceMaterial(this.master).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isSaved = true;
        this.Product ? this.Product.clear() : '';
        this.Product.close()
        this.initializeValues();
        this.itemList = res.data;
      }
    }, err => {
      this.loader.stop();
      this.toastr.Error('Error occurred while post service call');
    })
  }
  deleteDeviceMaterial(master) {
    Swal.fire({
      title: 'Are you sure want to delete?',
      // text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.loader.start();
        this.service.deleteDeviceMaterial(master.id).subscribe((res: any) => {
          this.loader.stop();
          if (res != null) {
            if (res.status_code == "1") {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
            } else {
              res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
              return;
            }
            this.isSaved = true;
            this.getDeviceMaterials();
          }
        }, err => {
          this.loader.stop();
          this.toastr.Error('Error occurred while delete device inventory');
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    })
  }
  // ***** Device Inventory end ***********
  searchProduct() {

  }

  selectEvent(item) {
    // do something with selected item
    // this.toastr.Success("Item added into list")
    if (item) {
      this.master.prodid = item.id;
      this.master.ilc = item.ilc;
      this.master.description = item.desc;
      this.postDeviceMaterials();
    }
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.commonService.getProductCatalog(this.filter.ptype, this.filter.pricingtype, this.searchitem, this.filter.deptid, this.filter.categoryid, this.filter.subcategoryid, '').subscribe(res => {
      this.productList = res;
      this.productList.forEach(p => {
        p.qty = 0;
        p.name = p.ilc;
      })
      this.productList.map(item => item.ilc = `${item.desc ? item.desc : ''} ${item.ilc}`);
      environment.production ? '' : console.log('product list data ', this.productList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading expType list ', err);
    });

  }

  onFocused(e) {
    this.Product.clear();
    // do something when input is focused
  }
}
