import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { NgbActiveModal, NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { Events } from 'src/app/shared/service/events.service';
import { environment } from 'src/environments/environment';
import { SalesServices } from '../../Sales/sales.service';
import { AddcustomersComponent } from '../../Sales/customers/addcustomers/addcustomers.component';
import Swal from 'sweetalert2';
import { MapsAPILoader } from '@agm/core';
import { ActivatedRoute } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { POSServices } from 'src/app/components/pos/pos.service';
import { SiteComponent } from '../site/site.component';
import { settingServices } from '../../settings/setting.service';

@Component({
	selector: 'app-sitesmodal',
	templateUrl: './sitesmodal.component.html',
	styleUrls: ['./sitesmodal.component.scss']
})
export class SitesmodalComponent implements OnInit {
	fullScreen = false;
	@ViewChild('content') Content: ElementRef;
	@ViewChild('product') product: any;
	@Input('params') params;
	@ViewChild('search')
	public searchElementRef: ElementRef;
	appname = environment.APP_NAME;
	searchText: string = '';
	isEdit: boolean = false;
	isView: boolean = false;
	site: any = {}
	keyword2: any = 'ilc';
	materialsearch = 'desc';
	customerSiteList: any = []
	userdata: any;
	submitted = false;
	productList: any = [];
	form: NgForm;
	fieldsetDisabled = false;
	materialList: any = [];
	deptList: any;
	categoryList: any;
	categoryid: any = '';
	siteid: any;
	material: any;
	deptid: any = '';
	searchtext: any;
	positem: any = [];
	subCategoryList: any;
	materialData: any = {};
	response: any;
	lastaction: string;
	customer: any;
	filename: any;
	uploader: FileUploader;
	picURL: any;
	subcatid: any = '';
	format: any;
	geoCoder: any;
	zoom: any;
	customerDevideTickets: any = [];
	action: any;
	tabTitle: any = '';
	typeList: any = [];
	constructor(
		private modalService: NgbModal,
		private activeModal: NgbActiveModal,
		private toastr: NotificationService,
		private datepipe: DatePipe,
		private loader: NgxUiLoaderService,
		private service: SalesServices,
		private settingService: settingServices,
		private route: ActivatedRoute,
		private commanservice: CommonServices,
		private toaster: NotificationService,
		private posService: POSServices,
		private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone,
	) {
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
		this.format = this.userdata.date_format;
		this.lastaction = this.route.snapshot.queryParamMap.get('action');

		this.getDeptData();
	}


	ngOnInit(): void {

		this.mapsAPILoader.load().then(() => {
			this.geoCoder = new google.maps.Geocoder;
			let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
			autocomplete.addListener("place_changed", () => {
				this.ngZone.run(() => {
					//get the place result
					let place: google.maps.places.PlaceResult = autocomplete.getPlace();
					this.site.directions = place.formatted_address;
					//verify result
					if (place.geometry === undefined || place.geometry === null) {
						return;
					}
					//set latitude, longitude and zoom
					this.site.lat = place.geometry.location.lat();
					this.site.lng = place.geometry.location.lng();
					this.zoom = 12;
				});
			});
		});
		if (this.params) {
			this.action = this.params.action;
			if (this.action == 'view') {
				this.isView = true;
				this.fieldsetDisabled = true;
			} else if (this.action == 'edit') {
				this.isView = false;
				this.isEdit = true;
				this.fieldsetDisabled = false;
			} else if (this.action == 'new') {
				this.isView = false;
				this.isEdit = false;
				this.site = {
					specmap: false,
					isprimary: false
				};
				this.fieldsetDisabled = false;
			}
			if (this.params.from == 'sitemaster') {
				this.getSiteTypes();
				this.tabTitle = 'Site Details'
				this.params.id ? this.getMasterSiteById(this.params.id) : '';
			} else {
				this.tabTitle = 'Basic info';
				this.params.id ? this.getCustomerTicket(this.params.customerid, this.params.id, '') : ''
				this.params.id ? this.getSiteById(this.params.id) : '';
			}
		}
		this.initializeFileUploader();
	}
	getSiteTypes() {
		this.commanservice.getComboEnums('sitetype').subscribe(res => {
			this.typeList = res;
		}, err => {
		});
	}
	openSite() {
		var modalRef = this.modalService.open(SiteComponent, { size: 'xl', });
		modalRef.result.then((result) => {
			if (result != undefined && result != null) {
				this.site.name = result.name;
				this.site.poc = result.poc;
				this.site.email = result.email;
				this.site.phone = result.phone;
				this.site.zip = result.zip;
				this.site.directions = result.directions;
				this.site.address1 = result.address1;
				this.site.address2 = result.address2;
				this.site.address3 = result.address3;
				this.site.address4 = result.address4;
				this.site.shortname = result.shortname;
				this.site.lat = result.lat;
				this.site.lng = result.lng;
				this.site.pic = result.pic;
				this.site.south = result.south;
				this.site.west = result.west;
				this.site.east = result.east;
				this.site.north = result.north;
				this.site.specmap = result.specmap;
				this.site.siteid = result.id;
			}
		}).catch((error) => {
			environment.production ? '' : console.log(error);
		});
	}

	getSiteById(id) {
		this.loader.start();
		this.service.getCustomerSiteById(id, this.params.customerid).subscribe(res => {
			this.loader.stop();
			this.site = res;
			this.getMaterials();
			this.picURL = this.site.pic ? environment.DOC_URL + '/' + this.userdata.companyid + '/' + this.site.pic : '';
		}, err => {
			this.loader.stop();
		})
	}

	delete(id) {
		Swal.fire({
			title: 'Are you sure want to delete?',
			// text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				this.loader.start();
				this.service.deleteCustomerSite(id).subscribe((res: any) => {
					this.loader.stop();
					if (res != null) {
						if (res.status_code == "1") {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
						} else {
							res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
							return;
						}
					}
				}, err => {
					this.loader.stop();
					environment.production ? '' : console.log('Error : While loading delete action ', err);
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) {
			}
		})
	}

	get f(): { [key: string]: AbstractControl } {
		return this.site.controls;
	}

	onChangeSearch(val: string) {
		this.commanservice.getProductCatalog('', '', this.material, '', '', '', '').subscribe(res => {
			environment.production ? '' : console.log(res);
			this.productList = res;
		})
	}

	onFocused($event) {
		this.product ? this.product.clear() : '';
	}

	deleteRow(item) {
	}

	createNewRow() {
	}

	selectEvent(item) {
		this.materialData.siteid = Number(this.site.id);
		this.materialData.prodid = Number(item.id),
			this.materialData.ilc = item.ilc,
			this.materialData.description = item.desc,
			this.materialData.qty = 1,
			this.materialData.createby = this.userdata.email;
		this.materialData.companyid = this.userdata.companyid;
		this.materialData.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		environment.production ? '' : console.log(this.materialData)
		this.service.postMaterial(this.materialData).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.getMaterials();
			}
		}, err => {
			console.log(err);
			this.toaster.Error('Error while added material')
		})
	}

	getMaterials() {
		this.service.siteMaterial(this.site.id).subscribe(res => {
			this.materialList = res;
			environment.production ? '' : console.log(res);
		})
	}

	getPosItems() {
		this.posService.getPOSItems('', 0, this.deptid, this.categoryid, this.subcatid, '').subscribe(res => {
			console.log(res);
			this.productList = res
		})
	}

	getMaterialById(item) {
		item.companyid = this.userdata.companyid;
		item.updateby = this.userdata.email;
		item.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		this.loader.start();
		this.service.postMaterial(item).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.getMaterials();
			}
		})
	}

	getDeptData() {
		this.commanservice.getDepartmentList(false).subscribe(res => {
			this.deptList = res;
			environment.production ? '' : console.log('department data ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading department data', err);
		});
	}

	getCatData() {
		this.subcatid = ''
		this.loader.start();
		this.commanservice.getCategory(this.deptid, false).subscribe(res => {
			this.categoryList = res;
			this.loader.stop();
			environment.production ? '' : console.log('Category data ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading category data', err);
		});
	}

	getSubCatdata() {
		this.loader.start();
		this.commanservice.getSubCategory(this.categoryid).subscribe(res => {
			this.loader.stop();
			this.subCategoryList = res;
			environment.production ? '' : console.log('sub category data ', res);
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While loading Sub category data', err);
		});
	}


	deleteMaterial(id) {
		this.loader.start();
		this.service.deleteMaterial(id).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
					this.getMaterials();
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
			}
		}, err => {
			this.loader.stop();
		})
	}

	getCustomerTicket(customerid, siteid, deviceid) {
		this.service.getCustomerTicket(customerid, siteid, deviceid).subscribe(res => {
			this.customerDevideTickets = res;
			environment.production ? '' : console.log('ticket history details data ', res);
		}, err => {
			environment.production ? '' : console.log('Error : While loading ticket history data', err);
		});
	}

	addSites(): void {
		if (this.params.from == 'sitemaster') {
			this.addMasterSite();
			return;
		}
		this.submitted = true;
		if (this.isEdit) {
			this.site.updateby = this.userdata.email;
			this.site.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		} else {
			this.site.customerid = this.params.customerid;
			this.site.createby = this.userdata.email;
			this.site.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
			this.site.active = true;
		}
		this.site.companyid = this.userdata.companyid;
		this.site.pic = this.filename ? this.filename : (this.site.pic ? this.site.pic : '');
		this.site.siteid ? this.site.siteid : null;
		this.loader.start();
		this.service.postSites(this.site).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
					if (this.filename) {
						this.uploadFile();
					}
					this.modalService.dismissAll();
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
			}
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('Error : While post customer sites ', err);
		});
	}


	//#region Sorting Table Header
	sortDir = 1;//1= 'ASE' -1= DSC
	onSortClick(event) {
		let target = event.currentTarget,
			childtarget = target.querySelector('i'),
			classList = childtarget.classList;

		if (classList.contains('fa-chevron-up')) {
			classList.remove('fa-chevron-up');
			classList.add('fa-chevron-down');
			target.classList.add('Sorted');
			this.sortDir = -1;
		} else {
			classList.add('fa-chevron-up');
			classList.remove('fa-chevron-down');
			target.classList.remove('Sorted');
			this.sortDir = 1;
		}
		this.sortArr('name');
	}

	sortArr(colName: any) {
		this.customerSiteList.sort((a, b) => {
			let valueA = (a[colName] && typeof a[colName] === 'string') ? a[colName].toLowerCase() : a[colName];
			let valueB = (b[colName] && typeof b[colName] === 'string') ? b[colName].toLowerCase() : b[colName];

			if (!valueA && !valueB) {
				return 0;
			}
			if (!valueA) {
				return -this.sortDir;
			}
			if (!valueB) {
				return this.sortDir;
			}

			if (valueA > valueB) {
				return this.sortDir;
			}
			if (valueA < valueB) {
				return -this.sortDir;
			}
			return 0;
		});
	}
	//#endregion Sorting Table Header


	selectedFileOnChanged(event: any) {
		environment.production ? '' : console.log(event.target.value);
		this.filename = event.target.value.split(/(\\|\/)/g).pop()
	}

	initializeFileUploader() {
		this.uploader = new FileUploader({
			authTokenHeader: 'authorization',
			authToken: 'Bearer ' + this.userdata.token,
			url: environment.API_URL + 'api/Documents/UploadFiles',
			method: 'POST',
			removeAfterUpload: true,
			queueLimit: 100
		})
		this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
			form.append('companyid', this.userdata.companyid);
			form.append('createby', this.userdata.email);
			form.append('refno', this.params.customerid);
			form.append('createdate', this.datepipe.transform(new Date(), this.userdata.postdatetimeformat));
			form.append('dtype', 'cust-site-file');
		};
		this.response = '';
		this.uploader.response.subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
				this.response = res.data;
				this.closeModal()
			}
		});
		this.uploader.onAfterAddingFile = (file) => {
			file.withCredentials = false;
		};
		// this.uploader.onAfterAddingAll = (files: any) => {
		// 	files.forEach(fileItem => {
		// 		// fileItem.file.name = fileItem.file.name.replace(/\.[^/.]+$/, "");
		// 		environment.production ? '' : console.log('file name: ' + fileItem.file.name);
		// 	});
		// };
	}
	uploadFile() {
		this.uploader.queue[0].onSuccess = function (response, status, headers) {
			if (status == 200) {
				let tempRes = JSON.parse(response);
			} else {
				this.toaster.Success('File uploaded')
			}
		};
		this.uploader.queue[0].upload();
	}


	closeModal() {
		this.activeModal.close('Modal Closed');
	}


	//Site Master Code
	getMasterSiteById(id) {
		this.loader.start();
		this.settingService.getCompanySiteByID(id).subscribe(res => {
			this.loader.stop();
			this.site = res;
			this.picURL = this.site.pic ? environment.DOC_URL + '/' + this.userdata.companyid + '/' + this.site.pic : '';
		}, err => {
			this.loader.stop();
		})
	}

	addMasterSite(): void {
		this.submitted = true;
		if (this.isEdit) {
			this.site.updateby = this.userdata.email;
			this.site.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
		} else {
			this.site.createby = this.userdata.email;
			this.site.createdate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
			this.site.active = true;
		}
		this.site.companyid = this.userdata.companyid;
		this.site.pic = this.filename ? this.filename : (this.site.pic ? this.site.pic : '');
		this.loader.start();
		this.settingService.postCompanySites(this.site).subscribe((res: any) => {
			this.loader.stop();
			if (res != null) {
				if (res.status_code == "1") {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
					if (this.filename) {
						this.uploadFile();
					}
					this.closeModal();
				} else {
					res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
					return;
				}
			}
		}, err => {
			this.loader.stop();
		});
	}

}
