import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../../inventory.service';
import { SerialComponent } from '../serial/serial.component';

@Component({
  selector: 'app-adjust-qty',
  templateUrl: './adjust-qty.component.html',
  styleUrls: ['./adjust-qty.component.scss']
})
export class AdjustQtyComponent implements OnInit {
  @Input() fromParent;

  itemData: any = {}
  binList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  ownItemProdBinList: any = [];
  actionType: any = []
  closeResult: string;
  serialList: any = [];
  isSaved = false;
  setReadonly = false;
  constructor(private activeModal: NgbActiveModal,
    private service: InventoryServices,
    private datepipe: DatePipe,
    private modalService: NgbModal,
    private commonService: CommonServices,
    private toastr: NotificationService,
    private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    environment.production ? '' : console.log('item data', this.fromParent);
    // this.loader.start();
    this.service.getAdjustQty(this.fromParent.id).subscribe(res => {
      this.loader.stop();
      this.itemData = res;
      this.getProdBins();
      if (this.itemData.isserilized) {
        this.itemData.adjustqty = 1;
      }
      this.itemData.action = '';
      environment.production ? '' : console.log('adjust qty response ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post adjust qty ', err);
    });
    this.service.getActionType().subscribe(res => {
      this.actionType = res;
      environment.production ? '' : console.log(' bin list data ', this.ownItemProdBinList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  getProdBins() {
    this.service.getProductBin(this.fromParent.id).subscribe(res => {
      this.ownItemProdBinList = res;
      this.itemData.frombinlocation = this.fromParent.frombinlocation ? this.fromParent.frombinlocation : null;
      if (this.fromParent.frombinlocation) {
        this.setReadonly = true;
        this.selectOwnBinLocation(this.fromParent.frombinlocation);
      }
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  selectOwnBinLocation(id) {
    environment.production ? '' : console.log(id);
    let obj = this.ownItemProdBinList.find(x => x.Value == id);
    this.itemData.total = obj.Value2;
    environment.production ? '' : console.log(obj);
    this.getSerial()
  }
  closemodal() {
    this.activeModal.close(this.isSaved ? 'save' : '');
  }
  getSerial() {
    this.service.getSerialListUsingBin(this.itemData.frombinlocation, this.fromParent.id).subscribe(res => {
      this.serialList = res;
      environment.production ? '' : console.log('serial list data ', this.serialList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
  }
  serial(id: any, frombinlocation: any, serial: any) {
    throw new Error('Method not implemented.');
  }
  postTransferRequest() {
    if (this.itemData.isserilized) {
      if (!this.itemData.serial || this.itemData.serial == undefined) {
        this.toastr.Error('Please select serial no')
        return false
      }
    }
    var data = {
      "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      "companyid": this.userdata.companyid,
      "prodid": this.fromParent.id,
      "tiid": this.itemData.tiid,
      "module": this.itemData.module,
      "username": this.userdata.username,
      "createby": this.userdata.email,
      "tobinlocation": this.itemData.frombinlocation,
      "frombinlocation": this.itemData.frombinlocation,
      "qty": this.itemData.qty,
      "qtycount": this.itemData.adjustqty ? this.itemData.adjustqty : 1,
      "action": this.itemData.action,
      "msg": this.itemData.msg,
      "ttype": 3,//it means adjust
      "adjustqty": this.itemData.adjustqty,
      "refid": this.itemData.refid,
      "serial": this.itemData.serial,
      "ilc": this.itemData.ilc,
      "description": this.itemData.description,
      "isserilized": this.itemData.isserilized,
      "customerid": this.itemData.customerid,
      "adjusttype": this.itemData.action,
      "qty2pick": this.itemData.qty2pick,
      "uom":this.itemData.uom
    }
    this.loader.start();
    this.service.postTransferHoldAdjust(data).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.isSaved = true;
        this.closemodal()
      }
      environment.production ? '' : console.log('post product response ', res);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });

  }
  open() {
    var modalRef = null;
    this.fromParent.binid = this.itemData.frombinlocation;
    modalRef = this.modalService.open(SerialComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
    modalRef.componentInstance.fromParent = this.fromParent;
    modalRef.componentInstance.action = 'add';
    environment.production ? '' : console.log('id', modalRef.componentInstance.id);
    modalRef.result.then((result) => {
      this.getSerial();
      environment.production ? '' : console.log('closed');
    }, (reason) => {

    });
  }
}
