<resize-border [dragHolder]="header">
  <div class="whole-modal" [class.full-screen]="fullScreen">
    <div #header class="modal-header">
      <h5 class="modal-title f-w-600" id="productModalLabel">PO Costing
      </h5>
      <button type="button" class="fullscreen-btn" (click)="fullScreen = !fullScreen">
        <app-feather-icons [icon]="'maximize-2'" *ngIf="!fullScreen"></app-feather-icons>
        <app-feather-icons [icon]="'minimize-2'" *ngIf="fullScreen"></app-feather-icons>
      </button>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <h5 class="text-center text-red mb-2">
          PS: Click Submit to create costing or pricing batch to approve
        </h5>
        <button class="btn btn-primary mb-2" (click)="submitPOCosting()">Submit Costing for Approval</button>
      </div>
      <div class="form">
        <div class="form row">
          <div class="form-group col-lg-2 col-6">
            <label for="" class="mb-1">PON</label>
            <input class="form-control" id="pon" name="pon" type="text" readonly [(ngModel)]="poDetails.pon"
              placeholder="PON">
          </div>
          <div class="form-group col-lg-2 col-6">
            <label for="" class="mb-1">PO Date</label>
            <input class="form-control" id="podate" name="podate" type="date" readonly [(ngModel)]="poDetails.podate"
              placeholder="PON">
          </div>
          <div class="form-group col-lg-2 col-6">
            <label for="" class="mb-1">PO Total</label>
            <input class="form-control" id="invtotal" name="invtotal" type="text" readonly
              [(ngModel)]="poDetails.invtotal" placeholder="0.00">
          </div>
          <div class="form-group col-lg-2 col-6">
            <label for="" class="mb-1">Shipping Fee</label>
            <input class="form-control" id="shippingfees" name="shippingfees" type="text" readonly
              [(ngModel)]="poDetails.shippingfees" placeholder="0.00">
          </div>
          <div class="form-group col-lg-2 col-6">
            <label for="" class="mb-1">Other Fee</label>
            <input class="form-control" id="otherfees" name="otherfees" type="text" readonly
              [(ngModel)]="poDetails.otherfees" placeholder="0.00">
          </div>
          <div class="form-group col-lg-2 col-6">
            <label for="" class="mb-1">Adjustments</label>
            <input class="form-control" id="adjustment" name="adjustment" type="text" readonly
              [(ngModel)]="poDetails.adjustment" placeholder="0.00">
          </div>
        </div>
        <div class="category-table ">
          <div class="table-responsive">
            <table class="custom-table">
              <tr class="custom-tr">
                <th class="custom-th text-center w-150" (click)="onSortClick($event)">
                  ILC <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Description <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Category <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Type <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  pur UOM <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center w-90" (click)="onSortClick($event)">
                  OnOrder <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  ReceivedQty <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  PO cost per item <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  CurrLandedCost <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  NewLandedCost <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  CurrAvgCost <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  NewAvgCost <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  SalesMargin% <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Sales/Retail <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Service <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  FlatPrice <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  UOM <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  SellQty <i class="Sorting fa fa-chevron-up"></i>
                </th>
                <th class="custom-th text-center" (click)="onSortClick($event)">
                  Costing Applied <i class="Sorting fa fa-chevron-up"></i>
                </th>
              </tr>
              <tr class="custom-tr" *ngFor="let item of poDetails.costingItems">
                <td class="custom-td text-left w-150">
                  <a class="link-text" (click)="ProdctView(item)">{{item.ilc}}</a>
                </td>
                <td class="custom-td text-left">
                  {{item.description}}
                </td>
                <td class="custom-td text-center">
                  {{item.category}}
                </td>
                <td class="custom-td text-left">
                  {{item.ptype}}
                </td>
                <td class="custom-td text-center">
                  {{item.puom}}
                </td>
                <td class="custom-td text-right">
                  {{item.qty}}
                </td>
                <td class="custom-td text-center">
                  {{item.receivedqty}}
                </td>
                <td class="custom-td text-center">
                  {{item.pocost}}
                </td>
                <td class="custom-td text-center">
                  {{item.landedcost}}
                </td>
                <td class="custom-td text-center">
                  {{item.newlandedcost}}
                </td>
                <td class="custom-td text-center">
                  {{item.avgcost}}
                </td>
                <td class="custom-td text-center">
                  {{item.newavgcost}}
                </td>
                <td class="custom-td text-center">
                  {{item.salepricepercentage}}
                </td>
                <td class="custom-td text-center">
                  {{item.store}}
                </td>
                <td class="custom-td text-center">
                  {{item.service}}
                </td>
                <td class="custom-td text-center">
                  {{item.flatprice}}
                </td>
                <td class="custom-td text-center">
                  {{item.uom}}
                </td>
                <td class="custom-td text-center">
                  {{item.actualqtytosell}}
                </td>
                <td class="custom-td text-center">
                  {{item.ca}}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</resize-border>