import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { purchaseServices } from '../../purchase/purchase.service';
import { ProductviewComponent } from '../productview/productview.component';

@Component({
  selector: 'app-pocosting',
  templateUrl: './pocosting.component.html',
  styleUrls: ['./pocosting.component.scss']
})
export class PocostingComponent implements OnInit {
  fullScreen = false;
  @Input('poid') poid;
  poDetails: any = {
    costingItems: []
  };
  userdata: any;
  appname = environment.APP_NAME;
  constructor(private activeModal: NgbActiveModal,
    private toastr: NotificationService, private datepipe: DatePipe, private loader: NgxUiLoaderService, private service: purchaseServices, private modalService: NgbModal, private router: Router) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }

  ngOnInit(): void {
    this.getPOItems();
  }
  getPOItems() {
    this.loader.start();
    this.service.getPOCosting(this.poid).subscribe(res => {
      this.loader.stop();
      this.poDetails = res;
      if (this.poDetails != null) {
        this.poDetails.podate = this.datepipe.transform(new Date(this.poDetails.podate), this.userdata.postdateformat);
      }
      environment.production ? '' : console.log(' supplier po data ', this.poDetails);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  onSortClick(event) {

  }

  ProdctView(value) {
    var modalRef = this.modalService.open(ProductviewComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modalRef.componentInstance.item = value;
    modalRef.result.then((result) => {
      environment.production ? '' : console.log(result);
      this.getPOItems();
    }).catch((error) => {
      environment.production ? '' : console.log(error);
    });
  }

  submitPOCosting() {
    if (this.poDetails.costingItems.length == 0) {
      this.toastr.Error('Please check PO if received & try again')
      return;
    }
    // var postObj = {

    //   "poid": this.poid,
    //   "pon": this.poDetails.pon,
    //   "companyid": this.userdata.companyid,
    //   "podate": this.poDetails.podate,
    //   "invtotal": this.poDetails.invtotal,
    //   "shippingfees": this.poDetails.shippingfees,
    //   "otherfees": this.poDetails.otherfees,
    //   "adjustment": this.poDetails.adjustment,
    //   "costing": this.poDetails.costing,
    //   "createby": this.userdata.email,
    //   "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
    //   "authorizedby": this.poDetails.authorizedby,
    //   "scheduledate": this.poDetails.scheduledate,
    //   "costingItems": this.poDetails.costingItems
    // }
    this.loader.start();
    this.service.postApprovePOCosting(this.poid).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.redirectAction();
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading po data ', err);
    });
  }

  redirectAction() {
    Swal.fire({
      title: 'PO costing submitted, Do you want to review pricing?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.closeModal();
        this.router.navigate(['/pricings'], { replaceUrl: true });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.closeModal();
      }
    })
  }
}
