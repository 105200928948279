import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Events } from 'src/app/shared/service/events.service';
import { DatePipe } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class projectmanagerServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    userprofile: any;
    public projectDataForEdit: '';
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }
    postProject(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Projects/Postt_Project', data);
    }
    getProjectDetails(id) {
        return this.http.get(this.baseUrl + 'api/Projects/Gett_ProjectByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getProjects(searchtxt, type, status, fdate, edate) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&type=' + type + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate);
    }

    getProjectTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjectTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }


    //get task
    taskList(name, projid, customerid, status, assignto, moduleid, fdate, edate, priority) {
        return this.http.get(this.baseUrl + 'api/Tasks/Get_TaskIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&projid=' + projid + '&customerid=' + customerid + '&status=' + status + '&assignto=' + assignto + '&moduleid=' + moduleid + '&fdate=' + fdate + '&edate=' + edate + '&priority=' + priority);
    }
    getReviwer() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmpManagers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getTaskTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTaskTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getComplexity() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetComplexity?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postTask(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Tasks/Postt_Task', data);
    }
    getTaskDetails(id) {
        return this.http.get(this.baseUrl + 'api/Tasks/Gett_TaskByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id);
    }
    getPendingReviewTaskLogs(id) {
        return this.http.get(this.baseUrl + 'api/Tasks/Gett_PendingReviewTaskLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&taskid=' + id);
    }
    postReviewTaskLogs(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Tasks/Post_ReviewTaskLogs', data);
    }
    postMilestoneTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Milestones/Post_MilestoneTrackingNotes', data);
    }
    //ticket - team member
    postTeamMember(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Tasks/Post_TaskEmployees', data);
    }
    //get task status list
    getTaskStatusList() {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTaskStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getEmpList() {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmployees?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //getProjectStatus
    getProjectStatus() {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjectStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }

    //Get Snags Bugs

    getSnagsList(projid, moduleid, assignto, name, customerid, status, taskid, fdate, edate, priority) {
        return this.http.get(this.baseUrl + 'api/Snags/Get_SnagIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projid=' + projid + '&module_id=' + moduleid + '&assignto=' + assignto + '&name=' + name + '&customerid=' + customerid + '&status=' + status + '&taskid=' + taskid + '&fdate=' + fdate + '&edate=' + edate + '&priority=' + priority);
    }
    getSnagDetails(id) {
        return this.http.get(this.baseUrl + 'api/Snags/Gett_SnagByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id);
    }
    getProjectList() {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjects?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&username=' + this.userprofile.username);
    }
    postBug(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Snags/Postt_Snag', data);
    }
    getTaskListByProject(proj) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTasks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projid=' + proj);
    }
    getMilestoneListByProject(proj) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProjectMilestones?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projid=' + proj);
    }
    deleteBug() {
        return this.http.delete(this.baseUrl + 'api/bugs/Deletet_bugs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBugType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSnagType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssignTo() {
        return this.http.get(this.baseUrl + 'api/Scheduler/Gett_Resources?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteTaskLogs(id) {
        return this.http.delete(this.baseUrl + 'api/Tasks/DeleteTaskLog?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTaskLogs(id) {
        return this.http.get(this.baseUrl + 'api/Tasks/Gett_TaskLogByTaskID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTaskSnags(id) {
        return this.http.get(this.baseUrl + 'api/Tasks/Gett_TaskSnagByTaskID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTaskEmployees(id) {
        return this.http.get(this.baseUrl + 'api/Tasks/Gett_TaskEmployeesByTaskID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTaskTestCases(id) {
        return this.http.get(this.baseUrl + 'api/Tasks/Gett_TaskTestCasesByTaskID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteSnagLogs(id) {
        return this.http.delete(this.baseUrl + 'api/Snags/DeleteSnagLog?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSnagLogs(id) {
        return this.http.get(this.baseUrl + 'api/Snags/Gett_SnagLogBySnagID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteChecklistLogs(id) {
        return this.http.delete(this.baseUrl + 'api/TestCase/DeleteTestCaseLog?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Get Testcase 
    testcaseList(name, projid, status, fdate, edate, customerid) {
        return this.http.get(this.baseUrl + 'api/TestCase/Get_TestCaseIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&projid=' + projid + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate + '&customerid=' + customerid)
    }

    //test cases

    //Get Milestones
    getMilestoneList(projid, msno, name, customerid, status, fdate, edate) {
        return this.http.get(this.baseUrl + 'api/Milestones/Get_MilestoneIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projid=' + projid + '&msno=' + msno + '&name=' + name + '&customerid=' + customerid + '&status=' + status + '&fdate=' + fdate + '&edate=' + edate)
    }
    postTestCase(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/TestCase/Postt_TestCase', data);
    }

    //project tab
    postProjectEmp(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Projects/Postt_ProjectEmployees', data);
    }
    getTeamsResources() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetResources?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postMileStone(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Milestones/Postt_Milestone', data);
    }
    postProjectMileStone(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Projects/Postt_ProjectMilestone', data);
    }
    //Resource tab
    postMilestoneEmp(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Milestones/Postt_MilestoneEmployees', data);
    }

    //get milestone details 
    getMileStoneDetails(id) {
        return this.http.get(this.baseUrl + 'api/Milestones/Gett_MilestoneByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id);
    }
    getTestCaseDetails(id) {
        return this.http.get(this.baseUrl + 'api/TestCase/Gett_TestCaseByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    postReleaseData(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Projects/Postt_ProjectReleaseNotes', data);
    }
    //milestone tab
    postMilestoneReleaseData(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Milestones/Postt_MilestoneReleaseNotes', data);
    }
    //task time log
    postTaskTimeLog(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Tasks/Postt_TaskLogs', data);
    }
    //task time log
    postMilestoneTimeLog(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Projects/Postt_ProjectReleaseNotes', data);
    }
    //test case time log
    postTestcaseTimelog(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/TestCase/Postt_TestCaseLogs', data);
    }
    //snag time log
    postSnagTimelog(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Snags/Postt_SnagLogs', data);
    }
    //project module
    getModuleList(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_ProjectModulesIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description)
    }
    postModule(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ProjectModule', data);
    }
    getParentModule(projid, parent_module_id) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjectModule?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projid=' + projid + '&parent_module_id=' + parent_module_id)
    }
    getProjectModule(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectModules?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getModuleByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ProjectModuleByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // Project tab index service
    getProjectTestCases(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectTestCases?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectReleaseNotes(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectReleaseNotes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectExpenses(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectExpenses?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectInvoices(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectInvoices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectMilestones(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectMilestones?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectOrders(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectOrders?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectQuotes(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectQuotes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectSalesOrders(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectSalesOrders?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectSnags(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectSnags?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectTasks(projectid, fromdate, todate, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectTasks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid + '&fromdate=' + fromdate + '&todate=' + todate + '&searchtxt=' + searchtxt);
    }
    getProjectEmployees(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectEmployees?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }
    getProjectTrackings(projectid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjectTrackings?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&projectid=' + projectid);
    }

    //Pricing Method 
    getPricingMethod() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjectPricingMethods?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //tracking notes
    postTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Projects/Post_ProjectTrackingNotes', data);
    }
    //created for create unbilled hrs invoice.
    createUnbilledHrsInv(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Projects/Post_ProjectsBilling', val);
    }
    deleteProject(id) {
        return this.http.delete(this.baseUrl + 'api/Projects/Delete_Project?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteProjectModule(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ProjectModule?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteProjectResource(id) {
        return this.http.delete(this.baseUrl + 'api/Projects/Delete_ProjectEmployee?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteSnag(id) {
        return this.http.delete(this.baseUrl + 'api/Projects/Delete_Project?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //Proj Invoice Batch
    postProjInvBatch(val) {
        return this.http.post(this.baseUrl + 'api/Projects/Post_ProjInvBatch', val);
    }
    getProjInvBatchDetails(id) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjInvBatchByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postProjInvBatchEntries(val) {
        return this.http.post(this.baseUrl + 'api/Projects/Post_ProjInvBatchEntries', val);
    }
    getProjInvBatchEntries(batchid) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_GetProjInvBatchEntries?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&batchid=' + batchid);
    }
    getProjUnbilledHrs(employeeid, projectid, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_UnBilledProjTaskLogs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid=' + employeeid + '&projectid=' + projectid + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    deleteProjInvBatch(id) {
        return this.http.delete(this.baseUrl + 'api/Projects/Delete_ProjInvBatch?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Get invoice batch index
    getInvBatchList(searchtxt, projectid, fdate, edate, status) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjInvBatches?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&projectid=' + projectid + '&fdate=' + fdate + '&edate=' + edate + '&status=' + status)
    }
    getProjInvBatch2Invoice(id) {
        return this.http.get(this.baseUrl + 'api/Projects/Get_ProjInvBatch2Invoice?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteProjInvBatchEntry(id) {
        return this.http.delete(this.baseUrl + 'api/Projects/Delete_ProjInvBatchEntry?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
