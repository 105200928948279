import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

import { DatePipe } from '@angular/common';
import { Events } from 'src/app/shared/service/events.service';


@Injectable({
    providedIn: 'root'
})
export class purchaseServices {
    getJournals(status: string, jno: any, journaltype: any, fdate: any, tdate: any) {
        throw new Error('Method not implemented.');
    }
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    userprofile: any;
    public projectDataForEdit: '';
    public expenseData: ''
    public hero: ''
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    getRfqList(searchtxt, fdate, edate, status) {
        return this.http.get(this.baseUrl + 'api/RFQ/Get_RFQIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fdate=' + fdate + '&edate=' + edate + '&status=' + status);
    }
    getShipToList(name, status) {
        return this.http.get(this.baseUrl + 'api/Shipto/Get_ShiptoIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&status=' + status);
    }
    //expense
    getExpenseList(searchtxt, fdate, edate, type, tpsync, status) {
        return this.http.get(this.baseUrl + 'api/Expenses/Get_ExpenseIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fdate=' + fdate + '&edate=' + edate + '&type=' + type + '&tpsync=' + tpsync + '&status=' + status);
    }
    postExpense(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Expenses/Postt_Expense', data);

    }
    deleteExpenseByID(id) {
        return this.http.delete(this.baseUrl + 'api/Expenses/Delete_Expense?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getsuppliers(type, islocal, searchtxt, status) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Get_SupplierIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type + '&islocal=' + islocal + '&searchtxt=' + searchtxt + '&status=' + status);
    }
    getRfqStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetRFQStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPriorityList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetGenericPirorities?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getRfqToPO(id) {
        return this.http.get(this.baseUrl + 'api/PurchaseOrders/Get_RFQ2PO?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&rfqid=' + id);
    }
    postRfqToPO(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Post_RFQ2PO', data);
    }
    getProdUom() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProdUom?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postRfq(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/RFQ/Postt_RFQ', data);
    }
    postSupplier(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Suppliers/Postt_Supplier', data);
    }
    //supplier post tab
    postSupplierContact(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Suppliers/Postt_SupplierContact', data);
    }
    getSupplierContacts(supplierid) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierContacts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid);
    }
    getSupplierOrderList(supplierid) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierOrders?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid);
    }
    getSupplierStatement(supplierid, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Accounts/GetSupplierStatement?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    getSupplierRfqList(supplierid) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierRFQs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid);
    }
    getSupplierProductList(supplierid) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierItems?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid);
    }
    getSupplierInvoiceList(supplierid) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierInvoices?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid);
    }
    getSupplierexpesneList(supplierid) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierExpenses?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid);
    }

    getRfqDetails(id) {
        return this.http.get(this.baseUrl + 'api/RFQ/Gett_RFQByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSupplierDetails(id) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSupplierInfo(supplierid,) {
        return this.http.get(this.baseUrl + 'api/Comman/GetSupplierInfo?supplierid=' + supplierid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSupplierStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSupplierStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //Tracking
    postExpTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Expenses/Post_TrackingNotes', data);
    }
    postrfqTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/RFQ/Post_TrackingNotes', data);
    }
    postPoTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Post_TrackingNotes', data);
    }

    postPiTracking(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseInvoices/Post_TrackingNotes', data);
    }
    //purchase invoice
    getPurchaseInvoiceList(searchtxt, status, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/PurchaseInvoices/Get_PIIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate);
    }

    getCustomDeclarationIndex(searchtxt, fromdate, todate, status) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_CustomDeclarationIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fdate=' + fromdate + '&edate=' + todate + '&status=' + status);
    }
    getSupplierName() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPIstatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPiSearchBy() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPIIndexSearchBy?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPiTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPITypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPOTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getPITerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPITerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getCurrency() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyCurrencies?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postInvoice(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseInvoices/Postt_PurchaseInvoice', data);
    }
    getInvoiceDetailsById(id) {
        return this.http.get(this.baseUrl + 'api/PurchaseInvoices/Gett_PurchaseInvoiceByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }
    //
    getPurchaseCatalog(supplierid, searchText) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductCatalog4Purchase?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid + '&searchtxt=' + searchText)
    }
    //receive item 
    getReceiveBOLItemList(bol, pon, ilc) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_ReceiveBOL?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&bol=' + bol + '&pon=' + pon + '&ilc=' + ilc);

    }
    //ship to
    getCountryList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCountries?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getStatusList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetShip2Status?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postShipToData(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Shipto/Postt_Shipto', data);
    }
    getShipToDetailsById(id) {
        return this.http.get(this.baseUrl + 'api/Shipto/Gett_ShiptoByID?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }

    //purchase order
    getPurchaseOrderList(status, type, fdate, edate, tpsync, searchtxt, localpo) {
        return this.http.get(this.baseUrl + 'api/PurchaseOrders/Get_POIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&status=' + status + '&type=' + type + '&fdate=' + fdate + '&edate=' + edate + '&localpo=' + localpo + '&tpsync=' + tpsync);
    }
    postPurchaseOrder(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Postt_PurchaseOrder', data);

    }
    getPurchaseOrderDetailsById(id) {
        return this.http.get(this.baseUrl + 'api/PurchaseOrders/Gett_PurchaseOrderByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getPurchaseRequest(type, status, fromdate, todate, searchtxt) {
        return this.http.get(this.baseUrl + 'api/PurchaseRequests/Get_PRIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type + '&searchtxt=' + searchtxt + '&status=' + status + '&fromdate=' + fromdate + '&todate=' + todate)
    }
    getPurchaseReqByID(id) {
        return this.http.get(this.baseUrl + 'api/PurchaseRequests/Gett_PurchaseRequestByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDeletePurchaseReq(id) {
        return this.http.delete(this.baseUrl + 'api/PurchaseRequests/Delete_PurchaseRequest?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDeletePurchaseReqItem(id) {
        return this.http.delete(this.baseUrl + 'api/PurchaseRequests/Delete_PurchaseRequestItem?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPurchaseRequestDetailsById(id) {
        return this.http.get(this.baseUrl + 'api/PurchaseRequests/Gett_PurchaseRequestItemByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getExpStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetExpenseStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getExpType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetExpenseType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getExpsenseDetails(id) {
        return this.http.get(this.baseUrl + 'api/Expenses/Gett_ExpenseByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //create sales order from purchase order
    createQoute2Salesorder(id) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Gett_Purchase2SalesOrder?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&id=' + id)
    }
    createPO2Salesorder(id, customerid, refid, reftype, projref, ordertype) {
        return this.http.get(this.baseUrl + 'api/SalesOrders/Gett_Purchase2SalesOrder?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&refid=' + refid + '&reftype=' + reftype + '&projref=' + projref + '&ordertype=' + ordertype)
    }

    getExpensesCategory(description) {
        return this.http.get(this.baseUrl + 'api/Expenses/Get_ExpenseCategoryIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description)
    }
    getExpenseCategorybyID(id) {
        return this.http.get(this.baseUrl + 'api/Expenses/Gett_ExpenseCategoryByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteExpenseCategorybyID(id) {
        return this.http.delete(this.baseUrl + 'api/Expenses/Delete_ExpenseCategory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postExpensesCategory(data) {
        return this.http.post(this.baseUrl + 'api/Expenses/Postt_ExpenseCategory', data);
    }
    //custom declration
    postDeclration(data) {
        return this.http.post(this.baseUrl + 'api/Shipping/Postt_Declaration', data);
    }

    getRegemeList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetRegime?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getMoneyDeclrationList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetMoneyDeclaredFlag?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDeclrationDetailsByID(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Gett_DeclarationByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postBOLItem(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Shipping/Post_BOLItem', data);
    }
    deleteBOLItem(id, prodid) {
        return this.http.delete(this.baseUrl + 'api/Shipping/Delete_BOLItems?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
    }
    deletePO(sid, poid) {
        return this.http.delete(this.baseUrl + 'api/Shipping/Delete_POItems?id=' + sid + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&poid=' + poid);
    }
    deleteContainer(conid) {
        return this.http.delete(this.baseUrl + 'api/Shipping/Delete_Containers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&conid=' + conid);
    }
    deletPO(id) {
        return this.http.delete(this.baseUrl + 'api/PurchaseOrders/Delete_PurchaseOrder?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postPORestockManually(id) {
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Get_RestockManually?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&poid=' + id, '');
    }
    postApprovePO(ids) {

        return this.http.post(this.baseUrl + 'api/PurchaseOrders/ApprovePO?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ids=' + ids, '');
    }

    postNotifyPO(id) {
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Notify_PurchaseOrder?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'), '');
    }
    getReceivePO(poid, prodid) {
        return this.http.get(this.baseUrl + 'api/PurchaseOrders/Get_ReceivePO?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&poid=' + poid + '&prodid=' + prodid);
    }

    getPO2PurchaseInvoice(id) {
        return this.http.get(this.baseUrl + 'api/PurchaseInvoices/Gett_PO2PurchaseInvoice?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postReceivePO(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Post_ReceivePO', data);
    }

    getShortPOItems(id) {
        return this.http.get(this.baseUrl + 'api/PurchaseOrders/Get_ShortPOItems?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postPOShortItems(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Post_ShortPOItems', data);
    }

    getPOCosting(id) {
        return this.http.get(this.baseUrl + 'api/PurchaseOrders/Get_POCosting?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postPOProdUpdate(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Post_ProdUpdate', data);
    }

    postApprovePOCosting(poid) {
        return this.http.get(this.baseUrl + 'api/PurchaseOrders/Approve_POCosting?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&poid=' + poid);
    }
    getTrackingNotes(id) {
        return this.http.get(this.baseUrl + 'api/PurchaseOrders/Get_TrackingNotes?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postTrackingNotes(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Post_TrackingNotes', data);
    }
    postPRtoPO(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Post_PR2PO', data);
    }
    postPRtoRFQ(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/RFQ/Post_PR2RFQ', data);
    }
    postPurchaseRequest(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseRequests/Postt_PurchaseRequest', data);
    }
    postPurchaseRequestItem(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseRequests/Postt_PurchaseRequestItem', data);
    }
    //tab custom declration tab
    getShippingPort() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetShipingPort?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getContainerType(val) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetContainerType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=' + val)
    }
    getPkgType(val) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPkgType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=' + val)
    }
    getGoodsCategory(val) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetGoodsCategories?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=' + val)
    }
    getGauranteeType(val) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetGuaranteeType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=' + val)
    }
    getShippingAgentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetShippingAgent?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'))
    }
    termsOfDeliveryList(val) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTermsOfDelivery?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=' + val)
    }
    getFinancePartyList(val) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetFinanceParty?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=' + val)
    }
    getCodes(ser) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_ComodityCodes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ser=' + ser)
    }
    getPref() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPref?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCPC(val) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCPC?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=' + val)
    }
    // Custom declaration
    getBolCosting(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_BOLcostingByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBOLsInfo(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_BOLsInfo?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBOLContainers(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_ContainersInfo?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postBOlCosting(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Shipping/Post_BOLCosting', data);
    }
    postApproveBolCosting(sid) {
        return this.http.post(this.baseUrl + 'api/Shipping/Approve_BOLCosting?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&sid=' + sid, '')
    }
    postRecalculateItem(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Shipping/Post_SavePOItems', data);
    }
    getStockReconciliation(id, pon, ilc) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_StockReconciliation?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pon=' + pon + '&ilc=' + ilc)
    }
    getPOListForBol(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_POList?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getOpenPOs() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetOpenPOs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPOItems(pon) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_ImportPOItems?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pon=' + pon);
    }
    postPOItems(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Shipping/Post_MapPOItems', data);
    }
    getVessals() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetVessals?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=')
    }
    postRestockManually(id) {
        return this.http.post(this.baseUrl + 'api/Shipping/Restock_Manual?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'), '');
    }
    postRestockItemManually(poid, prodid) {
        return this.http.post(this.baseUrl + 'api/Shipping/Restock_Item?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&poid=' + poid + '&prodid=' + prodid, '');
    }
    getTradersInfo(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_TradersInfo?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBOLItemList(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_Itemslist?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&sid=' + id);
    }
    getBOLItems(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_BOLItems?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBOLItem(id) {
        return this.http.delete(this.baseUrl + 'api/Shipping/Delete_BOLItems?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&itemid=' + id);
    }
    getContainers(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_ContainerByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postContainer(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Shipping/Post_Container', data);
    }
    deletePOMapping(id) {
        return this.http.delete(this.baseUrl + 'api/Shipping/Delete_POMapping?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTrader(id) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_TraderByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postTrader(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Shipping/Post_Trader', data);
    }

    getShippingVendor(number, type) {
        return this.http.get(this.baseUrl + 'api/Shipping/Get_ShippingVendors?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&number=' + number + '&type=' + type);
    }

    getPurchaseRequestItemHistory(id, searchtxt, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/InventoryItems/Gett_ItemPurchaseHistory?prodid=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&fromdate=' + fromdate + '&todate=' + todate);
    }
    deletePurchaseInvLineITem(id) {
        return this.http.delete(this.baseUrl + 'api/PurchaseInvoices/Delete_PurchaseInvoiceLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deletePurchaseInv(id) {
        return this.http.delete(this.baseUrl + 'api/PurchaseInvoices/Delete_PurchaseInvoice?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteRfqLineItem(id) {
        return this.http.delete(this.baseUrl + 'api/RFQ/Delete_RFQLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteRfq(id) {
        return this.http.delete(this.baseUrl + 'api/RFQ/Delete_RFQ?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deletePoLineItem(id) {
        return this.http.delete(this.baseUrl + 'api/PurchaseOrders/Delete_PurchaseOrderLine?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteExpLines(id) {
        return this.http.delete(this.baseUrl + 'api/Expenses/Delete_Expense?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getRfqDues(ids) {
        return this.http.get(this.baseUrl + 'api/RFQ/Approve_RFQs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ids=' + ids);
    }
    approvePurchaseRequest(ids) {
        return this.http.get(this.baseUrl + 'api/PurchaseRequests/ApprovePRS?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ids=' + ids);
    }
    sendForApprovalPurchaseRequest(ids) {
        return this.http.get(this.baseUrl + 'api/PurchaseRequests/SendforApproval?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ids=' + ids);
    }
    getSupplierCards(id) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierCards?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + id);
    }
    getSupplierBanks(id) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierBanks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + id);
    }
    getSupplierPayout(id) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierPayoutRules?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + id);
    }

    getEmpBanksbyId(id) {
        return this.http.get(this.baseUrl + 'api/Employees/Get_EmpBanksByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postBankData(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Suppliers/Postt_SupplierBank', data);
    }
    deleteSupplier(id) {
        return this.http.delete(this.baseUrl + 'api/Suppliers/Delete_Supplier?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteSupplierBank(id) {
        return this.http.delete(this.baseUrl + 'api/Suppliers/Delete_SupplierBank?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postsupplierPayout(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Suppliers/Postt_SupplierPayoutRule', data);
    }
    deleteSupplierPayout(id) {
        return this.http.delete(this.baseUrl + 'api/Suppliers/Delete_SupplierPayoutRule?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteSupplierCard(id) {
        return this.http.delete(this.baseUrl + 'api/Suppliers/Delete_SupplierCard?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deletExpenseCategory(id) {
        return this.http.delete(this.baseUrl + 'api/Expenses/Delete_ExpenseCategory?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    deleteShipTo(id) {
        return this.http.delete(this.baseUrl + 'api/Shipto/Delete_ShipTo?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getSupplierWalletLogs(id) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Gett_SupplierWalletLogs?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postWalletTransaction(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Suppliers/Post_SupplierWallet', data);
    }
    getSupplierWalletTranByID(id) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Get_SupplierWalletTranByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }

}
