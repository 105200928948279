import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { InventoryServices } from '../../../inventory.service';

@Component({
  selector: 'app-serial',
  templateUrl: './serial.component.html',
  styleUrls: ['./serial.component.scss']
})

export class SerialComponent implements OnInit {
  serialdata: any = {
    status: '',
    pon: '',
    binid: ''
  }
  @Input() fromParent;
  @Input() editData;
  @Input() action;
  binList: any = []
  userdata: any;
  appname = environment.APP_NAME;
  ownItemProdBinList: any = [];
  actionType: any = []
  closeResult: string;
  allBinList: any = [];
  statusList: any = [];
  constructor(private activeModal: NgbActiveModal,
    private service: InventoryServices,
    private datepipe: DatePipe,
    private modalService: NgbModal,
    private commonService: CommonServices,
    private toastr: NotificationService,
    private loader: NgxUiLoaderService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.service.getBinLocationList().subscribe(res => {
      this.allBinList = res;
      this.serialdata.binid = this.fromParent.binid;
      environment.production ? '' : console.log(' bin list data ', this.allBinList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading bin list ', err);
    });
    this.getStatusList();
  }
  getStatusList() {
    this.commonService.getComboEnums('serialnostatus').subscribe(res => {
      this.statusList = res;
      environment.production ? '' : console.log(' status list data ', this.statusList);
    }, err => {
      environment.production ? '' : console.log('Error : While loading status list ', err);
    });
  }
  ngOnInit(): void {
    setTimeout(() => {
      if (this.editData != null && this.editData != '') {
        this.serialdata = this.editData;
        this.serialdata.binid = this.editData.binid.toString();
      }
    }, 1000)
  }
  closemodal() {
    this.activeModal.close('Modal Closed');
  }
  postSerial() {
    var data;
    if (this.serialdata.id) {
      this.serialdata.createdate = this.serialdata.createdate ? this.datepipe.transform(new Date(this.serialdata.createdate), this.userdata.postdatetimeformat) : null;
      this.serialdata.updateby = this.userdata.email;
      this.serialdata.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat);
      data = this.serialdata;
    } else {
      data = {
        "createdate": this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
        "createby": this.userdata.email,
        "companyid": this.userdata.companyid,
        "lastupdated": this.datepipe.transform(this.serialdata.lastupdated, this.userdata.postdatetimeformat),
        "serial": this.serialdata.serial,
        "updateby": this.serialdata.updateby,
        "id": this.serialdata.id,
        "prodid": this.fromParent.id,
        "pon": this.serialdata.pon,
        "purchasefrom": this.serialdata.purchasefrom,
        "purchasedate": this.datepipe.transform(this.serialdata.purchasedate, this.userdata.postdatetimeformat),
        "manufacturer": this.serialdata.manufacturer,
        "saledate": this.datepipe.transform(this.serialdata.saledate, this.userdata.postdatetimeformat),
        "orderno": this.serialdata.orderno,
        "notes": this.serialdata.notes,
        "warrantyexpirydate": this.datepipe.transform(this.serialdata.saledate, this.userdata.postdatetimeformat),
        "status": this.serialdata.status,
        "binid": this.serialdata.binid
      }
    }
    environment.production ? '' : console.log('post transfer datais', data);
    this.loader.start();
    this.service.postSerial(data).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
        this.closemodal()
      }
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post product ', err);
    });

  }

}
