import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import { SalesServices } from '../../Sales/sales.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/shared/service/notification.service';

@Component({
  selector: 'app-customerlist',
  templateUrl: './customerlist.component.html',
  styleUrls: ['./customerlist.component.scss']
})
export class CustomerlistComponent implements OnInit {
  fullScreen = false;
  @ViewChild('custname') custname;
  pos: any = {};
  userdata: any;
  appname = environment.APP_NAME;
  setCustomerValue: any;
  keyword = 'name';
  validatecustomer: any;
  customerList: any = []
  customer: any = {
    name: '',
    email: '',
    primaryphone: '',
    status: 'active'
  }
  selectedCustomer: any = {};
  constructor(private activeModal: NgbActiveModal,
    private commonServices: CommonServices,
    private loader: NgxUiLoaderService,
    private datePipe: DatePipe,
    private toastr: NotificationService,
    private service: SalesServices,
  ) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }
  getCustomers() {
    if (this.customer.name == '') {
      return;
    }
    this.loader.start();
    this.commonServices.getCustomers(this.customer.name, this.customer.email, this.customer.primaryphone).subscribe(res => {
      this.loader.stop();
      this.customerList = res;
      environment.production ? '' : console.log(' customer list data ', this.customerList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading customer list ', err);
    });
  }
  selectCustomer(item) {
    this.selectedCustomer = item;
    this.closeModal();
  }
  addCustomer() {
    if (this.customer.name == null || this.customer.name == undefined || this.customer.name == '') {
      this.toastr.Error('Please enter customer name');
      return;
    }
    if (this.customer.primaryphone == null || this.customer.primaryphone == undefined || this.customer.primaryphone == '') {
      this.toastr.Error('Please enter phone number');
      return;
    }
    var obj = {
      id: null,
      name: this.customer.name,
      mainemail: this.customer.email,
      companyid: this.userdata.companyid,
      primaryphone: this.customer.primaryphone,
      createby: this.userdata.email,
      status: this.customer.status,
      createdate: this.datePipe.transform(new Date(), this.userdata.postdatetimeformat),
      updateby: null,
      updatedate: null
    }
    this.loader.start();
    this.service.postcustomer(obj).subscribe(res => {
      this.loader.stop();
      // this.customerList = res;
      if (res != null) {
        this.customer.name = '';
        this.customer.email = '';
        this.customer.primaryphone = '';
        this.getCustomers();
      }
      environment.production ? '' : console.log('post customer response ', this.customerList);
    }, err => {
      this.loader.stop();
      environment.production ? '' : console.log('Error : While loading post customer ', err);
    });
  }

  closeModal() {
    this.activeModal.close(this.selectedCustomer);
  }
  ngOnInit(): void {
    this.customer.name = this.custname ? this.custname : '';
    this.getCustomers();
  }

}
