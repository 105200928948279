import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import { InventoryServices } from '../../../inventory.service';

@Component({
  selector: 'app-binholddetails',
  templateUrl: './binholddetails.component.html',
  styleUrls: ['./binholddetails.component.scss']
})
export class BinholddetailsComponent implements OnInit {

  @ViewChild('itemData') itemdata;

  //#region userdata block
  appname = environment.APP_NAME;
  userdata: any;
  companyLogo: any = 'http://ezybooks.net/docs/logos/';
  format: any;
  totalRows: any = 0;
  listData: any = []
  //#endregion userdata block
  item: any;
  constructor(
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal, private toaster: NotificationService, private service: InventoryServices, private loader: NgxUiLoaderService,) {
    //#region userdata block
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.format = this.userdata.date_format
    this.companyLogo += this.userdata.logo;
    MY_DATE_FORMATS.display.dateInput = (this.userdata.date_format).toUpperCase();
    //#endregion userdata block
  }
  // InventoryItems/Get_Binholddetails
  ngOnInit(): void {
    this.item = this.itemdata;
    this.getProductBins()

  }
  getProductBins() {
    this.loader.start();
    this.service.getBinHoldDetails(this.item.binid, this.item.prodid).subscribe(res => {
      this.loader.stop();
      this.listData = res;
      if(this.listData.length==0){
        this.closeModal();
      }
    }, err => {
      this.loader.stop();
    });
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }
  refresh(item) {
    this.loader.start();
    this.service.unHoldQty(item.customerid, item.eid).subscribe(res => {
      this.loader.stop();
      this.toaster.Success('Transaction reverted');
      this.getProductBins();
      item.newcount = 0;
    }, err => {
      this.loader.stop();
    });
  }

}
